import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './../styles/Tabs.scss';
import { Action } from './Button';
import { Group, GroupProps } from '../views/partials/Group';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

export type TabsProps = {
  id: string;
  tabs: TabProps[];
  group: typeof Group;
  groupProps?: GroupProps;
  tabSwitcher: Function;
};

export type TabProps = {
  id: string;
  title: string;
  active: boolean;
  visible: boolean;
  forward_actions?: Action[];
  back_actions?: Action[];
  activate_actions?: Action[];
  deactivate_actions?: Action[];
};

export const Tabs: FunctionComponent<TabsProps> = ({ id, tabs, tabSwitcher, group, groupProps }) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const handleTab = (newTab: number) => {
    /**
     * Checks based on the action types , what action should be send to the server
     */
    let currentTab = tabs.findIndex((tab: TabProps) => tab.active);
    let diff = currentTab > newTab ? -1 : 1;
    let actions: { toSend: Action; toIgnore?: Action }[] = [];
    let newTabObject: TabProps = tabs[newTab];
    if (!!newTabObject.activate_actions) {
      /**
       * Checks if it is activate_actions, and sends the actions
       */
      let _action: { toSend: Action; toIgnore?: Action } = { toSend: newTabObject.activate_actions[0] };
      if (newTabObject.deactivate_actions) {
        _action.toIgnore = newTabObject.deactivate_actions[0];
      }
      actions.push(_action);
    } else {
      while (currentTab !== newTab) {
        let action = diff === -1 ? tabs[currentTab].back_actions : tabs[currentTab].forward_actions;
        if (action) {
          let otherAction = diff === 1 ? tabs[currentTab].back_actions : tabs[currentTab].forward_actions;
          let _action: { toSend: Action; toIgnore?: Action } = { toSend: action[0] };
          if (otherAction) _action.toIgnore = otherAction[0];
          actions.push(_action);
        }
        currentTab += diff;
      }
    }
    tabSwitcher(actions);
  };

  const [hidden, setHidden] = useState<number[]>([]);
  const [size, setSize] = useState([0, 0]);

  useEffect(() => {
    /**
     * Calculate the width and checks which one should fit , amd hide the first 5 of the items
     */
    let children =
      tabRef && tabRef.current
        ? Array.from(tabRef.current.children).filter((item) => item.className === 'tab-heading')
        : [];

    if (tabRef && tabRef.current && children.length > 0) {
      //Check if children are present (Null check 1)
      if (tabRef.current.previousSibling?.nodeName?.toLowerCase() === 'hr') {
        tabRef.current.previousSibling?.remove();
      }
      let activeTabIndex = tabs.findIndex((tab: TabProps) => tab.active);
      let left = (children[activeTabIndex > 5 ? activeTabIndex - 5 : 0] as HTMLElement)?.offsetLeft ?? 0; //Null check 2 if this line is executed before the HTML element is available
      tabRef.current.scrollLeft = left;
      let _hidden: number[] = [];
      children.forEach((child: Element, index: number) => {
        let rect = child.getBoundingClientRect();
        if (tabRef && tabRef.current) {
          if (rect.left <= 0 || rect.right >= tabRef.current.clientWidth) {
            _hidden.push(index);
          }
        }
      });
      setHidden(_hidden);
      if (tabRef.current.closest('.control-parent')?.firstChild?.nodeName?.toLowerCase() === 'hr') {
        tabRef.current.closest('.control-parent')?.firstChild?.remove();
      }
    }
  }, [tabs, size]);

  /* This useEffect will listen to the event, whennever the browser size will be changed by the user.
   ** To update the 3 dot dropdown list.*/
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div
      className={'internal-tabs tab-container' + (groupProps ? '' : ' empty')}
      data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
      data-html={true}
    >
      <div className='tab-switcher' ref={tabRef}>
        {tabs.map((tab, index) => (
          <>
            <span
              className='tab-heading'
              key={tab.id}
              id={tab.id}
              onClick={() => handleTab(index)}
              aria-selected={tabs.findIndex((tab: TabProps) => tab.active) === index}
            >
              {tab.title.toUpperCase()}
            </span>
            <span className='tab-heading-pipe'></span>
          </>
        ))}
      </div>
      {hidden.length !== 0 && (
        /* if there are hidden tabs in hidden(useState) array then it will show 3 dots dropdown.
         And also have removed "hidden={tabes.lenght <= 8}" checked from DropdownButton props which is no longger use*/
        <DropdownButton
          className='more-tabs'
          as={ButtonGroup}
          id={`LoadedTabs`}
          title=''
          variant='outline-dark'
          data-event={'ignore'}
        >
          {tabs.map((tab, index) => {
            if (hidden.indexOf(index) === -1) return '';
            return (
              <Dropdown.Item
                className='tab-heading'
                key={tab.id}
                id={tab.id}
                onClick={() => handleTab(index)}
                aria-selected={tabs.findIndex((tab: TabProps) => tab.active) === index}
              >
                {tab.title.toUpperCase()}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
      )}
      {/* Renders all tabs to group components */}
      {groupProps && React.createElement(group, { ...groupProps })}
    </div>
  );
};
