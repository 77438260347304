import { Localization } from '../localization/Localization';
import { XTString } from './models/XTData';

/* 
  REMARK: not sure what functionality was supposed to be covered by variable "replace" (see out function). So kept
  original code as reference at bottom of current file
*/
export class DecimalFormatter {
  replace = true;

  in(value: string, decimalSeperator: string, raw: boolean = false) {
    const xtNum = new XTString(value).serverFormatToNumber(decimalSeperator);
    if (xtNum.isNaN) return value; // keep original behavior
    if (raw) return xtNum.toXTStringClientFormat('.', '', true, false, true, true).toString();
    return xtNum
      .toXTStringClientFormat(
        Localization.instance.decimalSeparator,
        Localization.instance.groupSeparator,
        true,
        false,
        true,
        true
      )
      .toString();
  }
  out(value: string, decimalSeperator: string, groupSepLocale: string, externalize: boolean = true) {
    if (externalize) {
      let temp = value;
      // temp = temp.replaceAll(groupSepLocale, '');
      if (decimalSeperator == ',') temp = temp.replace('.', ',');
      if (temp.length == 0) value = '0';
      else {
        value = temp.toString();
      }
    } else {
      this.replace = false;
      value = this.in(value, decimalSeperator);
      this.replace = true;
    }

    return value;
  }
}

// export class DecimalFormatter {
//   replace = true;

//   in(value: string, decimalSeperator: string, groupSepLocale: string) {
//     /*
//       NTH revise code: "seems to be so wrong that it works again"
//       eg value = 100.50  ==> temp = 100.50   ==> d = 100.5 (1 decimal => temp replaced) temp = 100.50
//          value = 100.00  ==> temp = 100.00   ==> d = 100   (no decimals => ???? temp not replaced) temp = 100.00
//          value = 1000.00 ==> temp = 1,000.00 ==> d = 1     (???????? but also no decimals anymore => temp not replaced) temp = 1,000.00

//       BUGS FOUND 20220303:
//           value = 0100,50  ==> ... ==> string.empty       NOT OK
//           value = 01100,50 ==> ... ==> 01.100,50          NOT OK
//     */
//     value = value.trim();

//     if (value == '') return '';
//     let temp = value;

//     if (decimalSeperator == ',' || this.replace) {
//       temp = temp.replace(groupSepLocale, '');
//       temp = value = temp.replace(',', '.');
//       let decInd = value.indexOf('.');
//       temp =
//         value.substring(0, decInd === -1 ? value.length : decInd).replace(/(?=(?:\d{3})+\b)(?!\b)/g, ',') +
//         (decInd === -1 ? '' : temp.substring(value.indexOf('.'), value.length));
//     }

//     if (temp.length > 0) {
//       let signIndex = temp.indexOf('-');
//       /*if (signIndex == 0) {
//         temp = temp.substring(1) + '-';
//       } else */ if (signIndex == temp.length - 1) {
//         temp = '-' + temp.substring(0, temp.length - 1);
//       }

//       let d = parseFloat(temp);
//       if (d == 0 && this.replace) return '';

//       value = d.toString();

//       let noOFDec = value.indexOf('.') === -1 ? -1 : temp.length - temp.indexOf('.') - 1;
//       if (noOFDec !== -1) temp = d.toFixed(noOFDec);
//     }
//     const gs = Localization.instance.groupSeparator || ',';
//     const ds = Localization.instance.decimalSeparator || '.';
//     temp = temp.replace(/([,.])/g, (string) => (string === ',' ? gs : ds));
//     return temp;
//   }
//   out(value: string, decimalSeperator: string, groupSepLocale: string, externalize: boolean = true) {
//     if (externalize) {
//       let temp = value;
//       // temp = temp.replaceAll(groupSepLocale, '');
//       if (decimalSeperator == ',') temp = temp.replace('.', ',');
//       if (temp.length == 0) value = '0';
//       else {
//         value = temp.toString();
//       }
//     } else {
//       this.replace = false;
//       value = this.in(value, decimalSeperator, groupSepLocale);
//       this.replace = true;
//     }

//     return value;
//   }
// }
