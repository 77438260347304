import React, { FunctionComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as DropdownArrow } from '../../assets/dropdown-arrow.svg';
import { Localization } from '../../framework/localization/Localization';
import { connectField } from 'uniforms';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AccountCircle } from '@mui/icons-material';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import Box from '@mui/material/Box';

export const IconsCombo: FunctionComponent<any> = connectField(
  ({ value, onChange, allowedValues = [], ...props }: any) => {
    return (
      <Autocomplete
        fullWidth
        id='select-icon'
        sx={{ width: 300 }}
        options={allowedValues}
        autoHighlight
        getOptionLabel={(option: string) => option}
        renderOption={(props, option) => (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <SquareIcon size={'24px'}>{option ? Icons[option as keyof typeof Icons] : Icons.DollarSign}</SquareIcon>
            &nbsp;&nbsp;{option}
          </Box>
        )}
        value={value}
        onChange={(e, v) => onChange(v as string)}
        renderInput={(params) => (
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SquareIcon size={'24px'}>{value ? Icons[value as keyof typeof Icons] : Icons.DollarSign}</SquareIcon>
            <TextField
              {...params}
              fullWidth
              variant='standard'
              label='Choose an icon'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password' // disable autocomplete and autofill
              }}
            />
          </Box>
        )}
      />
    );
  }
);
