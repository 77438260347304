import { Action } from '../../../components/Button';
import { ContextItem, ContextOption } from '../../../components/DataTable';
import { Localization } from '../../localization/Localization';
import React, { Context } from 'react';
import { Command } from '../../base/command';
import { AperioViews } from '../../../types/AperioViews';

export type Placement = {
  left: number;
  top: number;
  bottom: number;
  right: number;
  height: number;
  width: number;
  anchor: string;
};

//TODO: Update component base props after all components are completed
export type ComponentBaseProps = {
  attributes: any;
  dirtyflag?: string;
  contextLoader: Function;
  contextMenuHandler: Function;
  actionHandler: Function;
  promptHandler: Function;
  loadMore: Function;
  updateTitle: (title: string[], panelID: string) => void;
  data: any;
  flags?: boolean[];
  id: string;
  isPanel: boolean;
  layout: any;
  order: number;
  panelID: string;
  windowID: string;
  windowProps: any;
  panelEvents?: any;
  protectedPanel?: string;
};

export type XTDataModel = {
  id: string;
  value: string;
  sflcontrolfmt?: string;
  ctrl?: XTDataModel;
  row?: XTDataModel;
};

type CommandContextType = {
  attachments: any[];
  errors: boolean;
  addToCommand: (panelID: string, id: string, value: string, dirtyflag: number) => void;
  addToRowCommand: (
    panelID: string,
    rowID: string,
    id: string,
    value: string,
    dirtyflag: number,
    flags?: string
  ) => void;
  removeFromCommand: (panelID: string, id: string, dirtyflag: number) => void;
  removeFromRowCommand: (panelID: string, rowID: string, id: string, dirtyflag: number) => void;
  promptHandler: (
    id: string,
    panelID: string,
    rowFieldID?: string,
    rowID?: string,
    alternativePromptKey?: string
  ) => void;
  setAction: (action: Action) => void;
  sendCommand: () => void;
  setScope: (ddspos: string, rowOffset?: number) => void;
  updateFlag: (flag: number, setTo: boolean) => void;
  windowData: Record<string, any>;
  settings?: Record<string, any>;
  setSelectedRows: (formID: string, panelID: string, tableID: string, ids: string[]) => void;
  setSelectedOption: (option: ContextOption, panelID: string) => void;
  localization: Localization;
  cursor: string;
  lastRows: Record<string, Record<string, Record<string, Record<string, string>>>>; // [formID][panelID][rowID][id]
  sendCustomCommand: (command: string, option?: { action: Action }) => void;
  windowID: string;
  command?: Command;
  reload: () => void;
  windowLayout: Record<string, any>;
  formAperioLinks: AperioViews.Link[];
  getCommand: () => Command | undefined;
  getFormLayout: () => any;
};

export const CommandContext: Context<CommandContextType> = React.createContext<CommandContextType>({
  attachments: [],
  errors: false,
  addToCommand: (panelID: string, id: string, value: string, dirtyflag: number) => {},
  addToRowCommand: (panelID: string, rowID: string, id: string, value: string, dirtyflag: number, flags?: string) => {},
  removeFromCommand: (panelID: string, id: string, dirtyflag: number) => {},
  removeFromRowCommand: (panelID: string, rowID: string, id: string, dirtyflag: number) => {},
  promptHandler: (
    id: string,
    panelID: string,
    rowFieldID?: string,
    rowID?: string,
    alternativePromptKey?: string
  ) => {},
  setAction: (action: Action) => {},
  sendCommand: () => {},
  setScope: (ddspos: string, rowOffset?: number) => {},
  updateFlag: (flag: number, setTo: boolean) => {},
  windowData: {},
  settings: {},
  setSelectedRows: (formID: string, panelID: string, tableID: string, ids: string[]) => {},
  setSelectedOption: (option: ContextOption) => {},
  localization: Localization.instance,
  cursor: '',
  lastRows: {},
  sendCustomCommand: (command: string, option?: { action: Action }) => {},
  windowID: '',
  reload: () => {},
  windowLayout: {},
  formAperioLinks: [],
  getCommand: () => undefined,
  getFormLayout: () => undefined
});
