import React, { useState, useEffect } from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Icons, SquareIcon } from '../components/SquareIcon';
import { Localization } from '../framework/localization/Localization';

export const Dark = () => {
  const getTheme = () => {
    const defaultTheme = localStorage.getItem('default_theme');
    const theme = localStorage.getItem('theme');
    if (theme === 'default') {
      return defaultTheme;
    }
    return theme;
  };

  const [checked, setChecked] = useState(getTheme() === 'dark');
  useEffect(() => {
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', getTheme() || '');
  }, []);

  const toggleThemeChange = () => {
    // toggle application theme between dark and default
    if (!checked) {
      localStorage.setItem('theme', 'dark');
      setChecked(true);
    } else {
      localStorage.setItem('theme', 'light');
      setChecked(false);
    }
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', getTheme() || '');
  };
  const resetTheme = () => {
    localStorage.setItem('theme', 'default');
    const theme = getTheme() || '';
    setChecked(theme === 'dark');
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   // dark mode
    //   localStorage.setItem('theme', 'dark');
    //   setChecked(true);
    // }
    document.getElementsByTagName('HTML')[0].setAttribute('data-theme', theme);
  };
  return (
    <div className='dark-mode'>
      <label className='switch'>
        <input type='checkbox' checked={checked} onChange={() => toggleThemeChange()} />
        <span className='slider round' />
      </label>
      <OverlayTrigger
        placement='right'
        overlay={
          <Tooltip className='custom' id={`tooltip-print`}>
            {Localization.instance.getString('Tooltip-Local-Mode')}
          </Tooltip>
        }
      >
        <span className='icon-client-modal-mode' onClick={() => resetTheme()}>
          <SquareIcon size='20px'>{Icons.Refresh2}</SquareIcon>
        </span>
      </OverlayTrigger>
    </div>
  );
};
