import { FunctionComponent, useState } from 'react';
import axios from 'axios';
import { b64_md5 } from './../assets/EncoderMd5';
import './../styles/Attachment.scss';
import { LoaderContainer, LoaderContainerType } from './LoaderContainer';
import { Modal } from 'react-bootstrap';
import { Localization } from '../framework/localization/Localization';
import { WindowActions } from '../types/actionTypes';
import { connect } from 'react-redux';
import { base64 } from '../helpers/base64';

/**
 * attachmentList: Attribute from XML,
 * removeAttachment: Remove  attachment from context,
 * addAttachment: Add attachment to context,
 * loadAttachments: Load the  attachments
 *
 */
type AttachmentProps = {
  attachmentList: Record<string, any>[];
  removeAttachment: (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void
  ) => void;
  addAttachment: (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void
  ) => void;
  loadAttachments?: (payload: any) => void;
  windowId: string;
};

export class Attachments {
  private attachmentLists: any[] = [];

  constructor(list: any[]) {
    this.attachmentLists = list;
  }

  /**
   *
   * @param list
   * Update the attachment list
   */

  setList(list: any[]) {
    this.attachmentLists = list;
  }

  /**
 *
 * @param category
 * @param docName
 * @param keyName
 * @param windowId
 * @param loadAttachments
 * Function to removed attachment from the list.

 */

  removeAttachmentFromList = (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void
  ): void => {
    const encodedKey = this.encodeKey(keyName);
    const cat: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let att;
    if (cat) {
      if (cat.keys) {
        att = cat.keys?.find((b: any) => b['$']['name'] === encodedKey)?.att;
      } else if (cat.att) {
        att = cat.att;
      }
    }
    if (att) {
      const ind = att.findIndex((a: any) => a['$']['name'] === docName);
      att.splice(ind, 1);
    }
    loadAttachments?.({
      windowId: windowId,
      attachments: this.attachmentLists,
      attachmentDetails: {
        cat: category,
        docName: docName,
        keyName: keyName
      }
    });
  };
  /**
   *
   * @param category
   * @param docName
   * @param keyName
   * @param windowId
   * @param loadAttachments
   * Function to create attachment to the list.
   *
   */

  addAttachmentToList = (
    category: string,
    docName: string,
    keyName: string,
    windowId: string,
    loadAttachments?: (payload: any) => void
  ): void => {
    const encodedKey = this.encodeKey(keyName);
    const categoryAttachments: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let attachments;
    if (categoryAttachments)
      attachments = categoryAttachments.keys?.find((b: any) => b['$']['name'] === encodedKey)?.att;
    if (!attachments) {
      const arr: any[] = [];
      if (categoryAttachments.keys?.find((b: any) => b['$']['name'] === encodedKey)) {
        categoryAttachments.keys.find((b: any) => b['$']['name'] === encodedKey).att.push(arr);
      } else {
        if (!categoryAttachments.keys) categoryAttachments.keys = [];
        categoryAttachments.keys?.push({
          $: {
            name: encodedKey
          },
          att: arr
        });
      }
      attachments = arr;
    }
    if (Array.isArray(attachments))
      attachments.push({
        $: {
          name: docName,
          type: docName.split('.')[1]
        }
      });
    loadAttachments?.({
      windowId: windowId,
      attachments: this.attachmentLists,
      attachmentDetails: {
        categoryAttachments: category,
        docName: docName,
        keyName: keyName
      }
    });
  };

  /**
   *
   * @param category
   * @param key
   * @returns
   * Function to find attachments.

   */

  findAttachments(category: string, key: string): any[] {
    const encodedKey = this.encodeKey(key);
    const cat: any = this.attachmentLists.find((a) => a['$']['id'] === category);
    let att = [];
    if (cat) att = cat.keys.find((b: any) => b['$']['name'] === encodedKey)?.att;
    return att;
  }
  /**
   *
   * @param keyRow
   * @param colHeader
   * @returns
   * Find whether the key is exist in attachment list or not.
   */

  hasKey = (keyRow: string[], colHeader?: string): boolean => {
    if (this.attachmentLists && keyRow) {
      for (let i = 0; i < this.attachmentLists.length; i++) {
        const cat: any = this.attachmentLists[i];
        if (cat.keys)
          for (let j = 0; j < keyRow.length; j++) {
            const encodedKey = this.encodeKey(keyRow[j]?.trim() || '');
            if (cat.keys.findIndex((b: any) => b['$']['name'] === encodedKey && b['att'].length > 0) !== -1) {
              if (cat.$?.column === colHeader) {
                return true;
              }
            }
          }
      }
    }
    return false;
  };
  /**
   *
   * @param keys
   * @returns
   * Function to create attachments list rows.

   */

  createAttachmentListRows(keys: Record<string, string>) {
    const dataSeparator = '@@££$$';
    const rows: any[] = [];
    const encodedKeys: string[] = [];
    const keyMap: Record<string, string> = {};
    Object.values(keys).forEach((key) => {
      encodedKeys.push(this.encodeKey(key));
      keyMap[this.encodeKey(key)] = key;
    });
    for (let i = 0; i < this.attachmentLists?.length; i++) {
      const cat: any = this.attachmentLists[i];
      if (cat.$.type?.toLowerCase() !== 'table')
        rows.push({
          entryType: 'cat',
          name:
            (Localization.instance.getString('Att_category_' + cat['$']['id']) || '') +
            ` (${cat.$.key.replaceAll(dataSeparator, ' ')})`,
          cat: cat.$.id,
          key: cat.$.key,
          type: cat.$.type
        });
      for (let j in cat.att) {
        let ob = cat.att[j];
        rows.push({
          entryType: 'att',
          name: ob['$']['name'],
          key: cat['$']['key'],
          cat: cat.$.id,
          type: ob['$']['type']
        });
      }
      if (
        rows.findIndex((a) => a.name === cat['$']['id']) === -1 &&
        Object.values(keys).length !== 0 &&
        !rows.find(
          (r: any) =>
            r.entryType === 'cat' &&
            r.name === cat['$']['id'] + ` (${keys[cat.$.key.split(',')[1]]})` &&
            r.key === cat.$.id
        ) &&
        cat.$.type?.toLowerCase() === 'table'
      ) {
        const keyPartsWithoutTable = cat.$.key.split(',').slice(1);
        if (keyPartsWithoutTable.length >= 1) {
          const joinedKeys = keyPartsWithoutTable.join(',');
          const replacedSeparatorKey = keys[joinedKeys]?.replaceAll(dataSeparator, ' ') || '';
          rows.push({
            entryType: 'cat',
            name:
              (Localization.instance.getString('Att_category_' + cat['$']['id']) || '') + ` (${replacedSeparatorKey})`,
            cat: cat.$.id,
            key: keys[joinedKeys],
            type: cat.$.type
          });
        }
      }
      for (let j in cat.keys) {
        const ob = cat.keys[j];
        if (encodedKeys.includes(ob['$']['name'])) {
          ob.att.forEach(
            (a: any) =>
              !rows.find(
                (r: any) =>
                  r.entryType === 'att' &&
                  r.cat === cat.$.id &&
                  r.key === keyMap[ob['$']['name']] &&
                  r.name === a['$']['name']
              ) &&
              rows.push({
                entryType: 'att',
                name: a['$']['name'],
                key: keyMap[ob['$']['name']],
                cat: cat.$.id,
                type: a['$']['type']
              })
          );
        }
      }
      if (rows.findIndex((a) => a.key === cat['$']['id']) > -1)
        rows.push({
          entryType: 'add',
          name: '',
          key: cat.$.id,
          type: ''
        });
    }
    return rows;
  }

  /**
   *
   * @param key
   * @returns
   * Encoding into b64_md5
   */
  encodeKey = (key: string = ''): string => {
    let keyBytes = new TextEncoder().encode(key);
    let encoded = b64_md5(String.fromCharCode(...keyBytes));
    encoded = encoded.replaceAll('-', 'V');
    encoded = encoded.replaceAll('=', 'W');
    encoded = encoded.replaceAll('+', 'X');
    encoded = encoded.replaceAll('/', 'Y');
    encoded = encoded.replaceAll('\\', 'Z');
    encoded = encoded.replaceAll('|', 'X');
    return 'F' + encoded;
  };
}

export const AttachmentComponent: FunctionComponent<AttachmentProps> = ({
  attachmentList,
  addAttachment,
  removeAttachment,
  windowId,
  loadAttachments
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [attId, setAttId] = useState('');
  const [attName, setAttName] = useState('');
  const [upCat, setUpCat] = useState('');
  const [upKey, setUpKey] = useState('');
  const [deleteKey, setDeleteKey] = useState('');

  const deleteAttachment = (cat: string, key: string, name: string) => {
    setLoading(true);
    axios
      .post('/client/delete-attachments', {
        category: cat,
        attachmentKey: key,
        attachmentName: name,
        qualifier: ''
      })
      .then((res) => {
        document.body.setAttribute('data-uploading-attachment', '1');
        const i = attachmentList.findIndex((att) => att.name === name);
        attachmentList.splice(i, 1);
        removeAttachment(cat, name, key, windowId, loadAttachments);
      })
      .catch((e) => {
        console.error(`Error from deleteAttachment ${e}`);
      })
      .finally(() => setLoading(false));
  };

  const downloadAttachment = (cat: string, key: string, name: string) => {
    setLoading(true);
    axios
      .post(
        '/client/download-attachments',
        {
          category: cat,
          attachmentKey: key,
          attachmentName: name,
          qualifier: ''
        },
        {
          responseType: 'blob'
        }
      )
      .then((res) => {
        let blob = new Blob([res.data]);
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
        link.remove();
      })
      .catch((e) => console.error(`Error from downloadAttachment ${e}`))
      .finally(() => setLoading(false));
  };

  const handleDefaultAction = (cat: string, key: string, name: string) => {
    downloadAttachment(cat, key, name);
  };

  const uploadFile = (file: File | undefined, files: File[]): any => {
    if (file) {
      const decodedKey = base64.base64ToBytes(upKey, true);
      const decodedCat = base64.base64ToBytes(upCat, true);
      setLoading(true);
      const formData: FormData = new FormData();
      formData.append('file', file);
      axios
        .post('/client/upload-attachments/' + upCat + '/' + upKey, formData, {
          maxContentLength: Infinity,
          maxBodyLength: Infinity
        })
        .then((res) => {
          document.body.setAttribute('data-uploading-attachment', '1');
          attachmentList.push({
            entryType: 'att',
            name: res.data.name,
            key: decodedKey,
            cat: decodedCat,
            type: res.data.name.substring(res.data.name.lastIndexOf('.') + 1)
          });
          addAttachment(decodedCat, res.data.name, decodedKey, windowId, loadAttachments);
        })
        .catch((e) => console.error(`Error from uploadFile attachment ${e}`))
        .finally(() => {
          if (files.length > 0) {
            const file = files.shift();
            uploadFile(file, files);
          } else {
            setLoading(false);
          }
        });
    }
  };

  const uploadFiles = (event: any) => {
    const arr: File[] = [...event.target.files];
    if (arr.length > 0) {
      const file = arr.shift();
      uploadFile(file, arr);
    }
    try {
      let input = document.getElementById('attachment-upload-prompt-' + windowId) as HTMLInputElement;
      if (input) input.value = '';
    } catch (e) {
      console.error(`Error from uploadFiles ${e}`);
    }
  };

  const uploadButtonClick = ({ cat, key }: { cat: string; key: string }) => {
    setUpKey(base64.bytesToBase64(key, true));
    setUpCat(base64.bytesToBase64(cat, true));
    document?.getElementById('attachment-upload-prompt-' + windowId)?.click();
  };

  const closeButton = () => {
    return (
      <svg
        width='20px'
        height='20px'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='primary-dark-icon panel-close'
      >
        <path d='M18 6L6 18' stroke='#00A3A5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
        <path d='M6 6L18 18' stroke='#00A3A5' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'></path>
      </svg>
    );
  };

  const plusBtn = () => {
    return (
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z'
          stroke='#ffffff'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path d='M12 8V16' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
        <path d='M8 12H16' stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
      </svg>
    );
  };

  return (
    <div className='attachment'>
      {loading ? (
        <LoaderContainer loaderType={LoaderContainerType.Full} text={Localization.instance.getString('TXT_Loading')} />
      ) : null}
      {attachmentList
        .filter((x: any) => x.entryType === 'cat')
        .map((entry: any, i: number) => {
          return (
            <table key={i} id='attachment-table' className='column-visiblity-table'>
              <thead>
                <tr>
                  <th
                    className={'attachment-table-upld-btn'}
                    title={Localization.instance.getString('ATTACHMENT_AddAttachment')}
                  >
                    <span onClick={() => uploadButtonClick(entry)}>{plusBtn()}</span>
                  </th>
                  <th>{entry.name}</th>
                  <th className='attachment-table-type'>{Localization.instance.getString('ATTACHMENT_Type')}</th>
                </tr>
              </thead>
              <tbody>
                {attachmentList
                  .filter((x: any) => x.entryType === 'att' && x.cat === entry.cat)
                  .map((att: any, i: number) => {
                    return (
                      <tr
                        style={{ cursor: 'pointer' }}
                        onDoubleClick={(e) => handleDefaultAction(att.cat, att.key, att.name)}
                      >
                        <td></td>
                        <td className={'attachment-name-col'}>{att.name}</td>
                        <td>
                          {att.type}
                          <span
                            title={Localization.instance.getString('ATTACHMENT_DeleteAttachment')}
                            className='btn-float-right'
                            onClick={() => {
                              setAttId(att.name);
                              setDeleteKey(att.key);
                              setAttName(att.name);
                              setIsModalShow(true);
                            }}
                          >
                            {closeButton()}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          );
        })}
      <input
        type='file'
        hidden={true}
        id={'attachment-upload-prompt-' + windowId}
        onChange={uploadFiles}
        multiple={true}
      />
      {isModalShow && (
        <div>
          <Modal
            show={isModalShow}
            onHide={() => setIsModalShow(false)}
            size='lg'
            backdrop='static'
            keyboard={false}
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header>
              <Modal.Title>
                <h4>{Localization.instance.getString('ATTACHMENT_ConfirmDelete')}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                {Localization.instance.getString('ATTACHMENT_ConfirmDeletionOf')} {attName}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                data-event='ignore'
                onClick={() => {
                  const att = attachmentList.find((a) => a.key === deleteKey && a.name === attId);
                  att && deleteAttachment(att.cat, att.key, att.name);
                  setIsModalShow(false);
                }}
                className='truncate btn btn-outline-dark btn-sm col-sm-2'
              >
                {Localization.instance.getString('Yes')}
              </button>
              <button
                data-event='ignore'
                onClick={() => setIsModalShow(false)}
                className='truncate btn btn-outline-dark btn-sm col-sm-2'
              >
                {Localization.instance.getString('No')}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  loadAttachments: (payload: any) => ({ type: WindowActions.LOAD_ATTACHMENTS, payload: payload })
};
export const Attachment = connect(() => {}, mapDispatchToProps)(AttachmentComponent);
