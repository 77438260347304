import { ValueFormatterExtension } from './ValueFormatterExtension';

export class PadLeadingZerosFormatter implements ValueFormatterExtension {
  in(value: string, limit: number) {
    while (value.length < limit) {
      value = `0${value}`;
    }
    return value;
  }

  out(value: string, limit?: number): string {
    return value.trim();
  }
}
