import React, { FunctionComponent, useContext, useState } from 'react';
import { Program } from '../../types/program';
import { WindowManger } from '../../framework/base/windowManager';
import { Widget } from '../../views/Dashboard';
import { ClientContext } from '../../App';
import { CellPlugin } from '@react-page/editor';
import { RootState } from '../../framework/base';
import { ConnectedProps, connect } from 'react-redux';
import { Col, Nav, NavDropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ReactComponent as DropdownArrow } from '../../assets/dropdown-arrow.svg';
import { Localization } from '../../framework/localization/Localization';

const mapStateToProps = (state: RootState) => {
  return {
    enquiries: state.desktop.enquiries
  };
};

const mapDispatchToProps = {
  quickLaunch: (program: Program, endLoading: Function) => WindowManger.Launch(program, endLoading, 'command'),
  loadEnquiryTemplateSettings: (enquiryTemplate: string, endLoading: Function) =>
    WindowManger.LoadEnquiryTemplateSettings(enquiryTemplate, endLoading)
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const EnquiriesComponent: FunctionComponent<Data & PropsFromRedux> = (props) => {
  const { startLoading, endLoading } = useContext(ClientContext);
  const [isEnquiryTemplateDropdownOpen, setIsEnquiryTemplateDropdownOpen] = useState<boolean>(false);
  const enquiryList = props.enquiries?.top?.enquiries?.[0]?.enq || [];
  const enquiryTemplate = props.enquiries?.top?.defaultTemplate[0].$.id || '';
  const enquiryTemplateList = props.enquiries?.top?.templates?.[0]?.template || [];
  const [hoveredEnquiryKey, setHoveredEnquiryKey] = useState<string>('');

  const enquiryInfos: { enquiryKey: string; desc: string }[] = enquiryList
    .filter((item: any) => !!item?.$?.desc?.trim())
    .map((item: any) => {
      return {
        enquiryKey: item?.$?.cmd || '',
        desc: `${item?.$?.desc?.trim() || ''}`
      };
    });

  return (
    <div className={'card'} style={{ height: props.height || '200px' }}>
      <Widget height={'auto'} className={'low-pad'}>
        <Row>
          <Col>
            <p>
              <strong>{props.title || Localization.instance.getString('TXT_ENQUIRY_PROGRAMS')}</strong>
            </p>
          </Col>
          <Col>
            <Nav id='select-template' className='mr-auto'>
              <OverlayTrigger
                placement='top'
                overlay={
                  <Tooltip className={`custom light`} id={`tooltip-enquiry-item`}>
                    {Localization.instance.getString('Formview_action_enquiries_change_template')}
                  </Tooltip>
                }
              >
                <NavDropdown
                  title={enquiryTemplate}
                  id='enquiry-template-dropdown'
                  show={isEnquiryTemplateDropdownOpen}
                  onToggle={(nextShow) => setIsEnquiryTemplateDropdownOpen(nextShow)}
                >
                  {enquiryTemplateList.map((item: any) => (
                    <NavDropdown.Item
                      data-event='ignore'
                      className={'dropdown-list-item'}
                      key={item.$.id}
                      onClick={() => {
                        startLoading();
                        props.loadEnquiryTemplateSettings(item.$.id, endLoading);
                      }}
                      onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key.toLowerCase() === 'enter') {
                          startLoading();
                          props.loadEnquiryTemplateSettings(item.$.id, endLoading);
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                    >
                      <span>{item.$.id}</span>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </OverlayTrigger>
            </Nav>
          </Col>
        </Row>
        <div className={'enquiries-body'}>
          <div className={'enquiries-container'}>
            {enquiryInfos.map((item) => (
              <div
                className={'enquiry'}
                key={item.enquiryKey}
                onClick={() => {
                  startLoading();
                  props.quickLaunch({ id: item.enquiryKey, text: item.desc }, endLoading);
                }}
                onMouseEnter={() => {
                  setHoveredEnquiryKey(item.enquiryKey);
                }}
                onMouseLeave={() => {
                  setHoveredEnquiryKey('');
                }}
              >
                {item.desc}
                <div className='enquiry-key'>
                  <div className={'display-key' + (hoveredEnquiryKey === item.enquiryKey ? 'active' : '')}>
                    {item.enquiryKey}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Widget>
    </div>
  );
};
export const Enquiries = connector(EnquiriesComponent);

type Data = {
  title: string;
  height: string;
};

const enquiries: CellPlugin<Data> = {
  Renderer: ({ data }) => {
    return <Enquiries title={data.title} height={data.height} />;
  },
  id: 'enquiries',
  title: 'Enquiries',
  description: 'DC1 Enquiries for user template',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default enquiries;
