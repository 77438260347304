import { AxiosError, AxiosResponse } from 'axios';
import ApplicationError from '../framework/ApplicationError';

export namespace RequestError {
  export type Checkpoint = {
    key: string[];
    windowId?: string;
    data?: Record<string, any>;
    extraInfo?: Record<string, any>;
  };
  export namespace ErrorInfo {
    export type JSON = {
      clientMessage: string;
      serverData?: any;
      endPoint?: string;
      errors: ApplicationError.JSON[];
      ignoreAllowed: boolean;
      retryAllowed: boolean;
      closeWindow: boolean;
      checkpoint?: Checkpoint;
    };
    export const toJSON = (errorInfo: ErrorInfo): ErrorInfo.JSON => {
      return {
        ...errorInfo,
        errors: errorInfo.errors.map((error) => error.toJSON())
      };
    };
    export const fromJSON = (errorInfoJSON: ErrorInfo.JSON): ErrorInfo => {
      return {
        ...errorInfoJSON,
        errors: errorInfoJSON.errors.map((json) => ApplicationError.fromJSON(json))
      };
    };
  }
  export type ErrorInfo = {
    clientMessage: string;
    serverData?: any;
    endPoint?: string;
    errors: ApplicationError[];
    ignoreAllowed: boolean;
    retryAllowed: boolean;
    closeWindow: boolean;
    checkpoint?: Checkpoint;
  };
  const clientsideConnectivityTypes = [
    ApplicationError.Type.CONNECTION_ERROR,
    ApplicationError.Type.NETWORK_ERROR,
    ApplicationError.Type.TIMEOUT_ERROR
  ];
  const serversideErrorTypes = [
    ApplicationError.Type.REDIRECTION_ERROR,
    ApplicationError.Type.CLIENT_ERROR,
    ApplicationError.Type.SERVER_ERROR
  ];
  export type ActionType = 'LAUNCH' | 'INTERACT' | 'NONE';
  export type ErrorLevel = 'ERROR' | 'WARNING';
  export type UserAction = 'NONE' | 'LOGIN' | 'LOGOUT' | 'CLOSE' | 'IGNORE' | 'RETRY' | 'UNKNOWN';
  export type JSON = {
    errorLevel: RequestError.ErrorLevel;
    clientHeadingMessage: string;
    serverHeadingMessage?: string;
    sorryMessage?: string;
    recoveryInstructions?: string[];
    supportMessage?: string;
    errorInfo: RequestError.ErrorInfo.JSON[];
    checkpoint?: RequestError.Checkpoint;
    authenticationRequired: boolean;
    allowLogout: boolean;
    allowCloseWindow: boolean;
    allowIgnore: boolean;
    allowRetry: boolean;
    windowId: string;
    actionType: RequestError.ActionType;
    actionArguments: any[]; // NTH: how to handle functions, ... ("redux state should not contain function"?) ??
    processResult: () => any; // NTH: how to handle functions, ... ("redux state should not contain function"?) ??
    userAction: RequestError.UserAction;
  };

  export const createFromError = (
    error: Error,
    errorMessageKey?: string,
    closeWindowRequested: boolean = false,
    windowId: string = 'DUMMY'
  ): RequestError => {
    // Create application error
    // ========================
    let applicationError: ApplicationError;
    if (error instanceof Error) {
      applicationError = ApplicationError.createUnexpectedError(
        'The application has trown an error',
        `The original message is: ${error.message || error.name}`
      );
    } else {
      applicationError = ApplicationError.createUnexpectedError('The application has trown an unidentified error');
    }

    // Create error info
    // =================
    const errorInfo: ErrorInfo = {
      clientMessage: 'ERR_Unexpected_error',
      errors: [applicationError],
      ignoreAllowed: false,
      retryAllowed: false,
      closeWindow: true
    };

    // Return result
    // =============
    return {
      errorLevel: 'ERROR',
      clientHeadingMessage: 'ERR_CLIENT_Something_went_wrong_on_our_end',
      serverHeadingMessage: errorMessageKey,
      sorryMessage:
        closeWindowRequested && errorMessageKey !== 'ERR_Launch_failed'
          ? 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab'
          : undefined,
      recoveryInstructions: undefined,
      supportMessage: 'TXT_Please_contact_your_application_administrator_and_report_this_issue',
      errorInfo: [errorInfo],
      checkpoint: undefined,
      authenticationRequired: false,
      allowLogout: false,
      allowCloseWindow: closeWindowRequested,
      allowIgnore: !closeWindowRequested,
      allowRetry: false,
      windowId: windowId,
      actionType: 'NONE',
      actionArguments: [],
      processResult: () => {},
      userAction: 'NONE'
    };
  };
  export const createFromApplicationError = (
    error: ApplicationError,
    errorMessageKey?: string,
    closeWindowRequested: boolean = false
  ): RequestError => {
    // Create error info
    // =================
    const errorInfo: ErrorInfo = {
      clientMessage: 'ERR_Unexpected_error',
      errors: [error],
      ignoreAllowed: false,
      retryAllowed: false,
      closeWindow: true
    };

    // Return result
    // =============
    return {
      errorLevel: 'ERROR',
      clientHeadingMessage: 'ERR_CLIENT_Something_went_wrong_on_our_end',
      serverHeadingMessage: errorMessageKey,
      sorryMessage: closeWindowRequested ? 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab' : undefined,
      recoveryInstructions: undefined,
      supportMessage: 'TXT_Please_contact_your_application_administrator_and_report_this_issue',
      errorInfo: [errorInfo],
      checkpoint: undefined,
      authenticationRequired: false,
      allowLogout: false,
      allowCloseWindow: closeWindowRequested,
      allowIgnore: !closeWindowRequested,
      allowRetry: false,
      windowId: `DUMMY`,
      actionType: 'NONE',
      actionArguments: [],
      processResult: () => {},
      userAction: 'NONE'
    };
  };
  export const createFromExportErrorInfo = (errorInfo: RequestError.ErrorInfo): RequestError => {
    // Initialize
    // ==========
    const supportMessage = errorInfo.retryAllowed
      ? 'TXT_Please_contact_your_application_administrator_if_this_problem_persists'
      : 'TXT_Please_contact_your_application_administrator_and_report_this_issue';

    const recoveryInstructions = errorInfo.retryAllowed ? ['TXT_Try_again_later'] : undefined;

    // Return result
    // =============
    return {
      errorLevel: 'ERROR',
      clientHeadingMessage: 'ERR_Export_failed',
      serverHeadingMessage: undefined,
      sorryMessage: undefined,
      recoveryInstructions: recoveryInstructions,
      supportMessage: supportMessage,
      errorInfo: [errorInfo],
      checkpoint: errorInfo.checkpoint,
      authenticationRequired: false,
      allowLogout: false,
      allowCloseWindow: false,
      allowIgnore: true,
      allowRetry: false,
      windowId: errorInfo.checkpoint?.windowId || `DUMMY EXPORT`,
      actionType: 'INTERACT',
      actionArguments: [],
      processResult: () => {},
      userAction: 'NONE'
    };
  };
  export const createFromUnexpectedResponseStatusLessThan300 = (
    actionType: ActionType,
    axiosResponse: AxiosResponse,
    windowId?: string
  ): RequestError => {
    const createErrorInfo = (clientMessage: string, error: ApplicationError): ErrorInfo[] => {
      return [
        {
          clientMessage: clientMessage,
          errors: [error],
          ignoreAllowed: false,
          retryAllowed: false,
          closeWindow: true
        }
      ];
    };

    // Initialize
    // ==========
    let clientHeadingMessage: string;
    let errorInfo: ErrorInfo[];

    let sorryMessage: string;
    if (actionType === 'INTERACT') {
      sorryMessage = 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab';
    } else {
      sorryMessage = 'TXT_We_are_sorry_but_we_are_not_able_to_execute_the_requested_action';
    }

    // Handle unexpected status codes             Remark: this function is not expected to be called for "known" codes
    // ------------------------------
    if (axiosResponse.status < 300) {
      clientHeadingMessage = 'ERR_CLIENT_Received_unexpected_response_code_from_the_server';
      const error = ApplicationError.createDataError(`Unexpected response status (${axiosResponse.status})`);
      errorInfo = createErrorInfo('ERR_Unexpected_response_status', error);
      //HTH logging metadata  & errorLevel ==> recognize case
    } else {
      clientHeadingMessage = 'ERR_FATAL_Programming_error__unjustified_program_execution';
      const error = ApplicationError.createProgrammingError(
        `RequestError.createFromSuccesfullyButUnexpectedResponse: should never be executed with a response status = ${axiosResponse.status}`
      );
      errorInfo = createErrorInfo(error.messageKey, error);
      //HTH logging metadata  & errorLevel ==> recognize case
    }

    // Return result
    // =============
    return {
      errorLevel: 'ERROR',
      clientHeadingMessage: clientHeadingMessage,
      serverHeadingMessage: undefined,
      sorryMessage: sorryMessage,
      recoveryInstructions: undefined,
      supportMessage: 'TXT_Please_contact_your_application_administrator_and_report_this_issue',
      errorInfo: errorInfo,
      checkpoint: undefined,
      authenticationRequired: false,
      allowLogout: false,
      allowCloseWindow: true,
      allowIgnore: false,
      allowRetry: false,
      windowId: windowId || `DUMMY ${actionType}`,
      actionType: actionType,
      actionArguments: [],
      processResult: () => {},
      userAction: 'NONE'
    };
  };
  export const createFromAxiosRequest = (
    actionType: ActionType,
    actionArguments: any[],
    processResult: () => any,
    errorInfo?: ErrorInfo[],
    axiosError?: AxiosError,
    windowId?: string
  ): RequestError => {
    const createSoftClientSideErrorInfo = (clientMessage: string, error: ApplicationError): ErrorInfo[] => {
      return [
        {
          clientMessage: clientMessage,
          errors: [error],
          ignoreAllowed: true,
          retryAllowed: true,
          closeWindow: false
        }
      ];
    };
    const createFatalClientSideErrorInfo = (clientMessage: string, error: ApplicationError): ErrorInfo[] => {
      return [
        {
          clientMessage: clientMessage,
          errors: [error],
          ignoreAllowed: false,
          retryAllowed: false,
          closeWindow: true
        }
      ];
    };
    const collectServerInfo = (errorInfo: ErrorInfo[]) => {
      /*
        Remark: in this case errorInfo contains the server side error/warnings. Currently it is expected to contain (ONE error) 
                XOR (one or multiple warnings) 
      */
      // Initialize
      // ----------
      const resultingOptions = { ignoreAllowed: true, retryAllowed: true, closeWindow: false };
      let checkpoint: Checkpoint | undefined = undefined;
      let clientMessage: string | undefined = undefined;

      // Loop through all errors
      // -----------------------
      errorInfo.forEach((error, index) => {
        // --> Determine resulting options
        if (!error.ignoreAllowed) resultingOptions.ignoreAllowed = false;
        if (!error.retryAllowed) resultingOptions.retryAllowed = false;
        if (error.closeWindow) {
          resultingOptions.ignoreAllowed = false; // Remark: close window equal to true expected to be supplied when data corrupt or Iseries session out of sync ==> can not be ignored
          resultingOptions.closeWindow = true;
        }

        // --> Get other info from first error only
        if (index === 0) {
          checkpoint = error.checkpoint; // remark: checkpoint will only be used if resulting retry is true (and in this case the retry should be started from the first checkpoint)
          clientMessage = error.clientMessage; // remark: if error only one record expected (else if warning: client message will be changed anyway by the caller)
        }
      });

      // Return result
      // -------------
      return { clientMessage, checkpoint, ...resultingOptions };
    };
    const handleAuthenticationErrors = (errorInfo: ErrorInfo[]) => {
      authenticationRequired = true;
      allowLogout = true;
      allowCloseWindow = false;
      allowIgnore = false;
      allowRetry = false;
      clientHeadingMessage = 'ERR_RETRIABLE_Looks_like_your_authentication_has_expired';
      recoveryInstructions = ['TXT_Reauthenticate_or_logout'];

      const info = collectServerInfo(errorInfo);
      serverHeadingMessage = info.clientMessage;
      checkpoint = info.checkpoint;
    };
    const handleUnexpectedErrors = (errorInfo: ErrorInfo[], errorMessage?: string) => {
      authenticationRequired = false;
      allowLogout = false;
      allowCloseWindow = true;
      allowIgnore = false;
      allowRetry = false;
      clientHeadingMessage = errorMessage || 'ERR_CLIENT_Something_went_wrong_on_our_end';
      if (actionType === 'INTERACT') {
        sorryMessage = 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab';
      } else {
        sorryMessage = 'TXT_We_are_sorry_but_we_are_not_able_to_execute_the_requested_action';
      }
      supportMessage = 'TXT_Please_contact_your_application_administrator_and_report_this_issue';

      const info = collectServerInfo(errorInfo);
      serverHeadingMessage = info.clientMessage;
      checkpoint = info.checkpoint;
    };
    const isErrorInfoMissing = (): boolean => {
      // Handle case error info is supplied
      // ----------------------------------
      if (errorInfo?.length || 0 > 0) return false;

      // Create clientside error info
      // ----------------------------
      const error = ApplicationError.createProgrammingError(
        'RequestError.createFromAxiosRequest: Expected data missing (errorInfo)'
      );
      errorInfo = createFatalClientSideErrorInfo(error.messageKey, error);

      // Handle as unexpected error
      // --------------------------
      errorLevel = 'ERROR';
      handleUnexpectedErrors(errorInfo, 'ERR_FATAL_Programming_error__mandatory_data_missing');
      //HTH logging metadata  & errorLevel ==> recognize case

      return true;
    };
    const isValidWarningInfo = (): boolean => {
      // Error info should be supplied by the server
      // -------------------------------------------
      if (isErrorInfoMissing()) return false;

      // Collect server info
      // -------------------
      const info = collectServerInfo(errorInfo!);

      // Warning should allow to ignore unless retry allowed
      // ---------------------------------------------------
      if (!info.ignoreAllowed) {
        errorLevel = 'ERROR';
        errorInfo![0].errors.push(
          ApplicationError.createProgrammingError(
            'RequestError.createFromAxiosRequest: unexpected data supplied (!ignoreAllowed)'
          )
        );
        handleUnexpectedErrors(errorInfo!, 'ERR_FATAL_Programming_error__unexpected_data_supplied');
        // HTH LOGGING
        return false;
      }

      // Warning can never request to close the window tab unless retry allowed
      // ----------------------------------------------------------------------
      if (info.closeWindow && !info.retryAllowed) {
        errorLevel = 'ERROR';
        errorInfo![0].errors.push(
          ApplicationError.createProgrammingError(
            'RequestError.createFromAxiosRequest: unexpected data supplied (closeWindow)'
          )
        );
        handleUnexpectedErrors(errorInfo!, 'ERR_FATAL_Programming_error__unexpected_data_supplied');
        // HTH LOGGING
        return false;
      }

      // At least on option should be allowed
      // ------------------------------------
      if (!info.closeWindow && !info.retryAllowed && !info.ignoreAllowed) {
        errorLevel = 'ERROR';
        errorInfo![0].errors.push(
          ApplicationError.createProgrammingError(
            'RequestError.createFromAxiosRequest: unexpected data supplied (ar least one option should be allowed)'
          )
        );
        handleUnexpectedErrors(errorInfo!, 'ERR_FATAL_Programming_error__unexpected_data_supplied');
        // HTH LOGGING
        return false;
      }

      // Warning info is valid
      // --------------------
      return true;
    };
    const isValidServersideInfo = (): boolean => {
      // Error info should be supplied by the server
      // -------------------------------------------
      if (isErrorInfoMissing()) return false;

      // Collect server info
      // -------------------
      const info = collectServerInfo(errorInfo!);

      // At least on option should be allowed
      // ------------------------------------
      if (!info.closeWindow && !info.retryAllowed && !info.ignoreAllowed) {
        errorLevel = 'ERROR';
        errorInfo![0].errors.push(
          ApplicationError.createProgrammingError(
            'RequestError.createFromAxiosRequest: unexpected data supplied (ar least one option should be allowed)'
          )
        );
        handleUnexpectedErrors(errorInfo!, 'ERR_FATAL_Programming_error__unexpected_data_supplied');
        // HTH LOGGING
        return false;
      }

      // Serverside info is valid
      // ------------------------
      return true;
    };
    const createAuthenticationError = (err: any): ApplicationError => {
      if (err?.name === 'ErrorResponse') {
        const message = err?.error_description || '';
        const code = err?.error || '';
        let details: string[];
        if (message && code) {
          details = [`${message} (${code})`];
        } else if (message) {
          details = [message];
        } else if (code) {
          details = [code];
        } else {
          details = ['Unknown authentication issue'];
        }
        return ApplicationError.createAuthenticationError(...details);
      } else if (err?.name === 'ErrorTimeout') {
        let details: string[];
        if (err?.message) {
          details = [err?.message];
        } else {
          details = ['Unknown authentication timeout issue'];
        }
        return ApplicationError.createAuthenticationError(...details);
      }
      return createUnexpectedApplicationError(err); // Program protection: not expected to be executed
    };
    const createUnexpectedApplicationError = (err: Error): ApplicationError => {
      const message = err?.message || '';
      const name = err?.name || '';
      const code = (err as any)?.code || ''; // Remark: quite some applications are returning also a code (eg typescript)

      let details: string[];
      if (message) {
        if (code || name) {
          details = [`${code || name}: ${message}`];
        } else {
          details = [`${message}`];
        }
      } else if (name) {
        if (code) {
          details = [`${code}: ${name}`];
        } else {
          details = [`${name}`];
        }
      } else if (code) {
        details = [`${code}`];
      } else {
        details = [`Unknown error`];
      }
      return ApplicationError.createUnexpectedError(...details);
    };

    // Initialize
    // ==========
    let authenticationRequired = false;
    let allowLogout = false;
    let allowCloseWindow = false;
    let allowIgnore = false;
    let allowRetry = false;
    let clientHeadingMessage: string = '???'; // Never expected to keep this value
    let serverHeadingMessage: string | undefined;
    let sorryMessage: string | undefined;
    let recoveryInstructions: string[] | undefined;
    let supportMessage: string | undefined;
    let checkpoint: Checkpoint | undefined;
    let errorLevel: ErrorLevel = axiosError ? 'ERROR' : 'WARNING';

    // Handle errors
    // ==============
    if (axiosError) {
      const baseError = ApplicationError.fromAxiosError(axiosError);
      // HTH LOGGING ?

      if (clientsideConnectivityTypes.indexOf(baseError.type) >= 0) {
        // Handle client side connectivity errors
        // --------------------------------------
        authenticationRequired = false;
        allowLogout = true;
        allowCloseWindow = false;
        allowIgnore = true;
        allowRetry = true;
        clientHeadingMessage = 'ERR_RETRIABLE_We_cannot_connect_to_the_server';
        serverHeadingMessage = undefined;
        sorryMessage = undefined;
        recoveryInstructions = [
          'TXT_Try_again_later__ignore_the_issue_or_logout',
          'TXT_Check_your_network_internet_connection',
          'TXT_Check_that_the_application_has_permission_to_access_the_web__you_might_be_connected_but_behind_a_firewall'
        ];
        supportMessage = 'TXT_Please_contact_your_application_administrator_if_this_problem_persists';
        checkpoint = undefined;
        errorInfo = createSoftClientSideErrorInfo('ERR_Clientside_connection_issue', baseError);
      } else if (baseError.type === ApplicationError.Type.REDIRECTION_ERROR) {
        // Handle unknown redirection             Remark: this function is not expected to be called for "known" redirection codes
        // --------------------------
        authenticationRequired = false;
        allowLogout = false;
        allowCloseWindow = true;
        allowIgnore = false;
        allowRetry = false;
        clientHeadingMessage = 'ERR_CLIENT_Received_unknown_redirection_instructions_from_the_server';
        serverHeadingMessage = undefined;
        if (actionType === 'INTERACT') {
          sorryMessage = 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab';
        } else {
          sorryMessage = 'TXT_We_are_sorry_but_we_are_not_able_to_execute_the_requested_action';
        }
        recoveryInstructions = undefined;
        supportMessage = 'TXT_Please_contact_your_application_administrator_and_report_this_issue';
        checkpoint = undefined;
        if (!errorInfo) {
          errorInfo = createFatalClientSideErrorInfo('ERR_Redirection_issue', baseError);
        } else {
          errorInfo[0]?.errors.push(baseError);
        }
      } else if ([401, 407].indexOf(axiosError.response?.status || 0) >= 0) {
        // -- Handle authentication errors
        // -- ----------------------------
        if (!errorInfo) errorInfo = createFatalClientSideErrorInfo(baseError.messageKey, baseError);
        const info = collectServerInfo(errorInfo);

        authenticationRequired = true;
        allowLogout = true;
        allowCloseWindow = false;
        allowIgnore = false;
        allowRetry = false;
        clientHeadingMessage = 'ERR_RETRIABLE_Looks_like_your_authentication_has_expired';
        recoveryInstructions = ['TXT_Reauthenticate_or_logout'];
        supportMessage = 'TXT_Please_contact_your_application_administrator_if_this_problem_persists';

        serverHeadingMessage = info.clientMessage;
        checkpoint = info.checkpoint;
      } else if (serversideErrorTypes.indexOf(baseError.type) >= 0) {
        // Handle (other) server side errors
        // ---------------------------------
        if (!errorInfo) errorInfo = createFatalClientSideErrorInfo(baseError.messageKey, baseError);
        if (isValidServersideInfo()) {
          // --> Initialize from server info
          const info = collectServerInfo(errorInfo!);
          authenticationRequired = false;
          allowLogout = false;
          allowCloseWindow = info.closeWindow;
          allowIgnore = info.ignoreAllowed;
          allowRetry = info.retryAllowed;
          serverHeadingMessage = info.clientMessage;
          checkpoint = info.checkpoint;

          // --> Handle other server errors
          if (info.closeWindow) {
            if (!allowRetry) {
              clientHeadingMessage = 'ERR_CLIENT_Something_went_wrong_on_our_end';
              if (actionType === 'INTERACT') {
                sorryMessage = 'TXT_We_are_sorry_but_we_need_to_close_your_current_window_tab';
              } else {
                sorryMessage = 'TXT_We_are_sorry_but_we_are_not_able_to_execute_the_requested_action';
              }
              supportMessage = 'TXT_Please_contact_your_application_administrator_and_report_this_issue';
            } else {
              clientHeadingMessage = 'ERR_RETRIABLE_Something_went_wrong_on_our_end';
              if (actionType === 'INTERACT') {
                recoveryInstructions = ['TXT_Try_again_later_or_close_the_window_tab'];
              } else {
                recoveryInstructions = ['TXT_Try_again_later'];
              }
              supportMessage = 'TXT_Please_contact_your_application_administrator_if_this_problem_persists';
            }
          } else {
            clientHeadingMessage = 'ERR_RETRIABLE_Something_went_wrong_on_our_end';
            if (allowRetry) {
              recoveryInstructions = ['TXT_Try_again_later_or_ignore_the_issue'];
            }
            supportMessage = 'TXT_Please_contact_your_application_administrator_if_this_problem_persists';
          }
        }
      } else {
        // Handle unexpected errors                         --> This includes "other" axios errors and programming errors
        // ------------------------
        if (axiosError.isAxiosError) {
          if (!errorInfo) errorInfo = createFatalClientSideErrorInfo('ERR_Unexpected_error', baseError);
          handleUnexpectedErrors(errorInfo);
        } else if (!errorInfo) {
          if (axiosError.name === 'ErrorResponse' || axiosError.name === 'ErrorTimeout') {
            // Remark: all oidc-client errors have a name equal to "ErrorResponse" or "ErrorTimeout"

            //--> Authentication errors
            const authenticationError = createAuthenticationError(axiosError);
            errorInfo = createFatalClientSideErrorInfo('ERR_Request_failed', authenticationError);
            if (errorInfo?.[0]) errorInfo[0].closeWindow = false;
            handleAuthenticationErrors(errorInfo);
          } else {
            //--> Other
            const unknowError = createUnexpectedApplicationError(axiosError);
            if (!errorInfo) errorInfo = createFatalClientSideErrorInfo('ERR_Unexpected_error', unknowError);
            handleUnexpectedErrors(errorInfo);
          }
        }
      }
    }

    // Handle warnings
    // ===============
    if (!axiosError && isValidWarningInfo()) {
      // --> Initialize from server info
      const info = collectServerInfo(errorInfo!);
      authenticationRequired = false;
      allowLogout = false;
      allowCloseWindow = info.closeWindow;
      allowIgnore = info.ignoreAllowed;
      allowRetry = info.retryAllowed;
      serverHeadingMessage = info.clientMessage;
      checkpoint = info.checkpoint;

      // --> Handle warnings
      clientHeadingMessage = 'TXT_Beware_request_only_partially_succeeded';
      if (allowRetry) {
        recoveryInstructions = ['TXT_Try_again_later_or_ignore_the_issue'];
      }
      supportMessage = 'TXT_Please_contact_your_application_administrator_if_this_problem_persists';
    }

    // Return result
    // =============
    return {
      errorLevel: errorLevel,
      clientHeadingMessage: clientHeadingMessage,
      serverHeadingMessage: serverHeadingMessage,
      sorryMessage: sorryMessage,
      recoveryInstructions: recoveryInstructions,
      supportMessage: supportMessage,
      errorInfo: errorInfo!,
      checkpoint: checkpoint,
      authenticationRequired: authenticationRequired,
      allowLogout: allowLogout,
      allowCloseWindow: allowCloseWindow,
      allowIgnore: allowIgnore,
      allowRetry: allowRetry,
      windowId: checkpoint?.windowId || windowId || `DUMMY ${actionType}`,
      actionType: actionType,
      actionArguments: actionArguments,
      processResult: processResult,
      userAction: 'NONE'
    };
  };
  export const toJSON = (requestError: RequestError): RequestError.JSON => {
    return {
      ...requestError,
      errorInfo: requestError.errorInfo.map((errorInfo) => ErrorInfo.toJSON(errorInfo))
    };
  };
  export const fromJSON = (requestErrorJSON: RequestError.JSON): RequestError => {
    return {
      ...requestErrorJSON,
      errorInfo: requestErrorJSON.errorInfo.map((json) => ErrorInfo.fromJSON(json))
    };
  };
}

export type RequestError = {
  errorLevel: RequestError.ErrorLevel;
  clientHeadingMessage: string;
  serverHeadingMessage?: string;
  sorryMessage?: string;
  recoveryInstructions?: string[];
  supportMessage?: string;
  errorInfo: RequestError.ErrorInfo[];
  checkpoint?: RequestError.Checkpoint;
  authenticationRequired: boolean;
  allowLogout: boolean;
  allowCloseWindow: boolean;
  allowIgnore: boolean;
  allowRetry: boolean;
  windowId: string;
  actionType: RequestError.ActionType;
  actionArguments: any[];
  processResult: () => any;
  userAction: RequestError.UserAction;
};
