import { useCallback, useState } from 'react';

export const useComplexState = <T extends Record<string, any>>(defaultValue: T): [T, (newValue: T) => void] => {
  const [state, setState] = useState(defaultValue);
  const setComplexState = useCallback((newValue: T) => {
    setState((old: T) => ({ ...old, ...newValue }));
  }, []);
  return [state, setComplexState];
};

// const [info, setInfo] = useComplexState({a: 1, b: 2, c:3});
//setInfo({a: 3});
