import { Context, FunctionComponent, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Widget } from '../../views/Dashboard';
import axios from 'axios';
import { Header } from '../../framework/dashboard/IWidget';
import { CellPlugin } from '@react-page/editor';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { connectField } from 'uniforms';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { debounce } from '../../helpers/debouncer';
import { WidgetTable } from './partials/WidgetTable';

const ColumnsContext: Context<{
  columns: Record<string, any>[];
  setColumns: (columns: Record<string, any>[]) => void;
}> = createContext<{
  columns: Record<string, any>[];
  setColumns: (columns: Record<string, any>[]) => void;
}>({
  columns: [],
  setColumns: (columns: Record<string, any>[]) => {}
});

const AnyTableWidget: FunctionComponent<any> = (props: any) => {
  const [response, setResponse] = useState<any>({});
  const { setColumns } = useContext(ColumnsContext);
  const headers = useMemo(() => {
    return response?.rows && response?.rows[0]
      ? Object.keys(response.rows[0])
          ?.map((x: any) => {
            return { label: x, key: x, width: 1 } as Header;
          })
          .filter((x) => props.column.includes(x.label))
      : [];
  }, [response, props.column]);

  useEffect(() => {
    const getData = async () => {
      let payload = {
        control: {
          method: props.apiMethod,
          limit: props.numberOfRecords || 10
        },
        params: {}
      };
      let res = await axios
        .request({
          url: '/aperio/api/service',
          data: payload,
          method: 'POST'
        })
        .catch((err) => {
          console.error(`Error from AnyTableWidget ${err}`);
        });
      const data = {
        rows: res?.data?.data?.items
      };

      setColumns(
        data?.rows && data?.rows[0]
          ? Object.keys(data.rows[0])?.map((x: any) => {
              return { label: x, key: x, width: 3 };
            })
          : []
      );

      setResponse(data || {});
    };
    const [getDebouncedData, cancel] = debounce(getData, 500);

    getDebouncedData();

    return cancel;
  }, [props, setColumns]);

  return <WidgetTable title={props.title || props.apiMethod} headers={headers} rows={response.rows} />;
};

const anyTable: CellPlugin<{
  title: string;
  column: string[];
  numberOfRecords: string;
  height: string;
  apiMethod: string;
}> = {
  Renderer: ({ data }: any) => {
    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <AnyTableWidget
            title={data.title}
            numberOfRecords={data.numberOfRecords}
            height={data.height}
            apiMethod={data.apiMethod}
            column={data.column}
          />
        </Widget>
      </div>
    );
  },
  id: 'new-any-table',
  title: 'Table',
  description: 'Display list from selected API method',
  version: 1,
  Provider: ({ children, ...props }) => {
    const [columns, setColumns] = useState<Record<string, any>[]>([]);
    return <ColumnsContext.Provider value={{ columns, setColumns }}>{children}</ColumnsContext.Provider>;
  },
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        apiMethod: {
          type: 'string'
        },
        column: {
          type: 'array',
          items: {
            type: 'string'
          },
          uniforms: {
            component: connectField(({ column, apiMethod, onChange, ...props }: any) => {
              const [columnName, setColumnName] = useState<string[]>(props?.value ?? []);
              const { columns } = useContext(ColumnsContext);

              const handleChange = (event: SelectChangeEvent<typeof columnName>) => {
                const {
                  target: { value }
                } = event;
                setColumnName(
                  // On autofill we get a stringified value.
                  typeof value === 'string' ? value.split(',') : value
                );
              };
              return (
                <FormControl fullWidth>
                  <InputLabel variant='standard' id='multiple-select-label'>
                    Columns to display
                  </InputLabel>
                  <Select
                    labelId='multiple-select-label'
                    id='multiple-select'
                    multiple
                    //variant='standard'
                    value={columnName}
                    onChange={(e) => {
                      handleChange(e);
                      onChange(e.target.value as string);
                    }}
                    input={<OutlinedInput label='Columns' />}
                    renderValue={(selected) => (selected ? selected.join(', ') : '')}
                  >
                    {columns?.map((column: Record<string, any>) => (
                      <MenuItem key={column.label} value={column.label}>
                        <Checkbox checked={columnName.indexOf(column.label) > -1} />
                        <ListItemText primary={column.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            })
          }
        },
        numberOfRecords: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default anyTable;
