import { format, parse } from 'date-fns';
import DatePicker from '../../components/DatePicker';
import { Localization } from '../localization/Localization';

const PARSED_DATE_FORMAT1 = 'MM/dd/yy';
const PARSED_DATE_FORMAT2 = 'MM/dd/yyy';
const PARSED_DATE_FORMAT3 = 'MM-dd-yy';

export class DateFormatter {
  in_DEPRECATED(value: string, dateFormat: string, centuryBreakYear: number, localization?: Localization): Date | null {
    //NTH_SKE: remove function (invalid century determination, ...)
    if (!value || !value.trim() || Number(value) === 0) return null;
    let result;
    if (value.length === 5) {
      value = `0${value}`;
    }
    try {
      if (localization) result = parse(value, 'P', new Date(), { locale: localization?.format });
      else result = parse(value, dateFormat, new Date());
      if (+format(result, 'yy') >= centuryBreakYear) {
        result.setFullYear(result.getFullYear() - 100);
      }
    } catch (e) {
      console.error(`Error in_DEPRECATED ${e} , ${dateFormat}`);
      result = null;
    }
    return result;
  }

  out(value: Date, dateFormat: string): string {
    const result = format(new Date(value), dateFormat);
    return result;
  }

  /*
          let dateFormat = format6;
          let val = _value?.trim();
          if (val?.length === 8) dateFormat = format8;
          if (val?.includes(dateSeparator)) dateFormat = format8Sep;
          let date = Formatters.Date.in(val, dateFormat, +century_break_year);
          if (+_value?.trim() === 0) _value = '';
          _value = date ? format(date, localization.dateFormat) : _value;
  */
  convertValueFromServerFormatToClientFormat(
    value: string | null,
    serverInfo: {
      format6: string;
      format8: string;
      format8Sep: string;
      dateSeparator: string;
      century_break_year: string;
    },
    clientFormat: string,
    raw: boolean = false
  ): string {
    // Case no value supplied
    // ======================
    if (!value || !value.trim() || Number(value) === 0) return '';

    // Determine server format used
    // ============================
    let serverFormat = serverInfo.format6;
    value = value.trim();
    if (value.includes(serverInfo.dateSeparator)) {
      serverFormat = serverInfo.format8Sep;
    } else if (value.length > 6) {
      serverFormat = serverInfo.format8;
    }

    // Handle "maximum" date
    // =====================

    // --> verify whether "maximum" date supplied
    let isMaximumDate: boolean = false;
    if (serverFormat === serverInfo.format6) {
      if (value === '999999') isMaximumDate = true;
    } else if (serverFormat === serverInfo.format8) {
      if (value === '99999999') isMaximumDate = true;
    } else if (serverFormat === serverInfo.format8Sep) {
      const sep = serverInfo.dateSeparator;
      if (value === `99${sep}99${sep}99`) isMaximumDate = true;
    }

    // --> return "maximum" date in client format
    if (isMaximumDate) return clientFormat.toLowerCase().replaceAll('d', '9').replaceAll('m', '9').replaceAll('y', '9');

    // Append leading zero
    // ===================
    if (serverFormat === serverInfo.format6) {
      if (value.length === 5) value = `0${value}`;
    } else if (serverFormat === serverInfo.format8) {
      if (value.length === 7) value = `0${value}`;
    } else if (serverFormat === serverInfo.format8Sep) {
      let dateParts = value.split(serverInfo.dateSeparator);
      value = dateParts
        .reduce((result, part) => result + serverInfo.dateSeparator + (part.length === 1 ? `0${part}` : part), '')
        .substring(1);
    }

    // Determine resulting date
    // ========================
    let date: Date | null;
    try {
      // parse value from server format to date
      date = parse(value, serverFormat, new Date());

      // adjust century                                                 // remark: fns has its own system to determine the century (by proximity with referenceDate) ==> always recalculate century by Enterprise rule
      if (serverFormat !== serverInfo.format8) {
        //HTH TEST format8 (may - strange enough - be needed too!!)
        const today = new Date();
        const currentCentury = Math.floor(today.getFullYear() / 100);
        const year = +format(date, 'yy');
        if (year < +serverInfo.century_break_year) {
          date.setFullYear(currentCentury * 100 + year);
        } else {
          date.setFullYear((currentCentury - 1) * 100 + year);
        }
      }
    } catch (e) {
      console.error(`Error from ServerFormatToClientFormat step1 ${e} , ${value} ,${serverFormat}`);
      date = null;
    }

    // Return date in client format
    // ============================
    if (date) {
      try {
        if (raw) return format(date, 'dd-MM-yyyy');
        return format(date, clientFormat);
      } catch (e) {
        console.error(`Error from ServerFormatToClientFormat step2 ${e} , ${value} ,${serverFormat}`);
        return '';
      }
    } else {
      return '';
    }
  }

  parseValueFromClientFormatToServerFormat(
    value: string | null,
    clientFormat: string,
    serverInfo: { format6: string; century_break_year: string }
  ): { serverValue: string; clientValue: string; errors?: DateFormatter.ParseError[] } {
    const getClientValue = (
      suppliedDay: string,
      suppliedMonth: string,
      suppliedYear: string,
      dateSeparator: string,
      sortedDateParts: string[]
    ): string => {
      let result = '';
      for (let i = 0; i < sortedDateParts.length; i++) {
        switch (sortedDateParts[i]) {
          case 'd':
            result = `${result}${suppliedDay}` + (i < sortedDateParts.length - 1 ? dateSeparator : '');
            break;
          case 'M':
            result = `${result}${suppliedMonth}` + (i < sortedDateParts.length - 1 ? dateSeparator : '');
            break;
          default:
            result = `${result}${suppliedYear}` + (i < sortedDateParts.length - 1 ? dateSeparator : '');
        }
      }
      return result;
    };

    const getServerValue = (
      suppliedDay: string,
      suppliedMonth: string,
      suppliedYear: string,
      format6: string
    ): string => {
      let result = '';
      if (suppliedYear.length === 4) suppliedYear = suppliedYear.substring(2);
      for (let i = 0; i < 3; i++) {
        switch (format6.toLowerCase().substr(i * 2, 2)) {
          case 'dd':
            result = `${result}${suppliedDay}`;
            break;
          case 'mm':
            result = `${result}${suppliedMonth}`;
            break;
          default:
            result = `${result}${suppliedYear}`;
        }
      }
      return result;
    };

    // Case no value supplied
    // ======================
    if (!value || !value.trim() || Number(value) === 0) return { serverValue: '', clientValue: '' };

    // Collect date format client info
    // ===============================
    let f = clientFormat;

    // determine current date separator
    const dateSeparator = f.replace(/[Mdy]/g, '').charAt(0) || '';

    // create array with dateparts in expected sort order                     ==> eg ['d', 'M', 'y'] or ['M', 'd', 'y']
    const sortedDateParts: string[] = [];
    for (let i = 0; i < f.length && sortedDateParts.length < 3; i++) {
      const c = f.charAt(i);
      if (!sortedDateParts.includes(c) && c !== dateSeparator) sortedDateParts.push(c);
    }

    // Ignore/remove all invalid characters
    // ====================================
    value = value.replace(new RegExp(`[^0-9${dateSeparator}]`, 'g'), '');
    if (!value || !value.trim() || Number(value) === 0) return { serverValue: '', clientValue: '' };

    // Determine supplied date parts
    // =============================
    let suppliedDay: string;
    let suppliedMonth: string;
    let suppliedYear: string;

    // Case date separators supplied
    // -----------------------------
    if (value.includes(dateSeparator)) {
      // Get date parts
      let dateParts = value.split(dateSeparator);

      // Exact 3 date parts expected
      if (dateParts.length !== 3)
        return {
          serverValue: '',
          clientValue: value,
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.InvalidNumberOfDateSeparatorsUsed }]
        };

      // Extract supplied date parts
      suppliedDay = dateParts[sortedDateParts.indexOf('d')].trim();
      suppliedMonth = dateParts[sortedDateParts.indexOf('M')].trim();
      suppliedYear = dateParts[sortedDateParts.indexOf('y')].trim();

      // Day number mandatory
      if (suppliedDay.length === 0)
        return {
          serverValue: '',
          clientValue: value,
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.DayNumberMissing }]
        };

      // Month number mandatory
      if (suppliedMonth.length === 0)
        return {
          serverValue: '',
          clientValue: value,
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.MonthNumberMissing }]
        };

      // Year mandatory
      if (suppliedYear.length === 0)
        return {
          serverValue: '',
          clientValue: value,
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.YearMissing }]
        };

      // Add/remove leading zeros supplied day
      if (suppliedDay.length > 2) {
        while (suppliedDay.startsWith('0') && suppliedDay.length > 2) suppliedDay = suppliedDay.substring(1); // remove "not needed" leading zero(s)
      } else if (suppliedDay.length === 1) {
        suppliedDay = '0' + suppliedDay; // add leading zero
      }

      // Add/remove leading zeros supplied month
      if (suppliedMonth.length > 2) {
        while (suppliedMonth.startsWith('0') && suppliedMonth.length > 2) suppliedMonth = suppliedMonth.substring(1); // remove "not needed" leading zero(s)
      } else if (suppliedMonth.length === 1) {
        suppliedMonth = '0' + suppliedMonth; // add leading zero
      }

      // Add/remove leading zeros supplied year
      if (suppliedYear.length > 4) {
        while (suppliedYear.startsWith('0') && suppliedYear.length > 4) suppliedYear = suppliedYear.substring(1); // remove "not needed" leading zero(s)
      } else if (suppliedYear.length === 3) {
        suppliedYear = '0' + suppliedYear; // add leading zero
      } else if (suppliedYear.length === 1) {
        suppliedYear = '0' + suppliedYear; // add leading zero
      }

      // Case date separators NOT supplied
      // ---------------------------------
    } else {
      let val = value;

      // --> add leading zero (if appropriate)
      if (val.length === 5 || val.length === 7) val = '0' + val;

      // --> must have valid length
      if (val.length !== 6 && val.length !== 8)
        return {
          serverValue: '',
          clientValue: value,
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.InvalidLength }]
        };

      // --> determine start positions date parts
      let startPositions = [0, 2, 4];
      if (val.length === 8)
        startPositions = startPositions.map((pos, i) => (i > sortedDateParts.indexOf('y') ? pos + 2 : pos));

      // --> extract supplied date parts
      suppliedDay = val.substr(startPositions[sortedDateParts.indexOf('d')], 2);
      suppliedMonth = val.substr(startPositions[sortedDateParts.indexOf('M')], 2);
      suppliedYear = val.substr(startPositions[sortedDateParts.indexOf('y')], val.length === 8 ? 4 : 2);
    }

    // Add century to year part (if appropriate)
    // =========================================
    const today = new Date();
    const currentCentury = Math.floor(today.getFullYear() / 100);
    if (suppliedYear.length === 2) {
      // add current century
      if (+suppliedYear < +serverInfo.century_break_year) {
        suppliedYear = currentCentury.toString() + suppliedYear;

        // handle "maximum" date
      } else if (suppliedYear === '99' && suppliedMonth === '99' && suppliedDay === '99') {
        suppliedYear = '9999';

        // add previous century
      } else {
        suppliedYear = (currentCentury - 1).toString() + suppliedYear;
      }
    }

    // Handle "maximum" date
    // =====================
    if (suppliedYear === '9999' && suppliedMonth === '99' && suppliedDay === '99') {
      return { serverValue: '999999', clientValue: getClientValue('99', '99', '9999', dateSeparator, sortedDateParts) };

      // Must be valid dateparts
      // =======================
    } else {
      // Year validation
      // ---------------

      // --> Maximum enterprise year must be respected
      if (+suppliedYear >= currentCentury * 100 + +serverInfo.century_break_year) {
        return {
          serverValue: '',
          clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts),
          errors: [
            {
              errorMessage: DateFormatter.ParseError.ErrorMessage.MaximumYearNotRespected,
              params: [(currentCentury * 100 + +serverInfo.century_break_year - 1).toString()]
            }
          ]
        };

        // --> Minimum enterprise year must be respected
      } else if (+suppliedYear < (currentCentury - 1) * 100 + +serverInfo.century_break_year) {
        return {
          serverValue: '',
          clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts),
          errors: [
            {
              errorMessage: DateFormatter.ParseError.ErrorMessage.MinimumYearNotRespected,
              params: [((currentCentury - 1) * 100 + +serverInfo.century_break_year).toString()]
            }
          ]
        };
      }

      // Month validation
      // ---------------
      if (+suppliedMonth < 1 || +suppliedMonth > 12) {
        return {
          serverValue: '',
          clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts),
          errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.InvalidMonth }]
        };
      }

      // Day validation
      // --------------
      const monthDays = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //0 is dummy
      if (+suppliedDay < 1 || +suppliedDay > monthDays[+suppliedMonth]) {
        return {
          serverValue: '',
          clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts),
          errors: [
            {
              errorMessage: DateFormatter.ParseError.ErrorMessage.InvalidDay,
              params: [monthDays[+suppliedMonth].toString(), suppliedMonth]
            }
          ]
        };
      } else if (+suppliedDay === 29 && +suppliedMonth === 2) {
        if (+suppliedYear % 4 || (!(+suppliedYear % 100) && +suppliedYear % 400))
          return {
            serverValue: '',
            clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts),
            errors: [{ errorMessage: DateFormatter.ParseError.ErrorMessage.NotLeapYear, params: [suppliedYear] }]
          };
      }
    }

    // Return result
    // =============
    return {
      serverValue: getServerValue(suppliedDay, suppliedMonth, suppliedYear, serverInfo.format6),
      clientValue: getClientValue(suppliedDay, suppliedMonth, suppliedYear, dateSeparator, sortedDateParts)
    };
  }
}
export namespace DateFormatter {
  export namespace ParseError {
    export enum ErrorMessage {
      InvalidNumberOfDateSeparatorsUsed = 'DATE_InvalidNumberOfDateSeparatorsUsed',
      DayNumberMissing = 'DATE_DayNumberMissing',
      MonthNumberMissing = 'DATE_MonthNumberMissing',
      YearMissing = 'DATE_YearMissing',
      InvalidLength = 'DATE_InvalidLength',
      MaximumYearNotRespected = 'DATE_MaximumYearNotRespected',
      MinimumYearNotRespected = 'DATE_MinimumYearNotRespected',
      InvalidMonth = 'DATE_InvalidMonth',
      InvalidDay = 'DATE_InvalidDay',
      NotLeapYear = 'DATE_NotLeapYear'
    }
  }
  export type ParseError = {
    errorMessage: ParseError.ErrorMessage;
    params?: string[];
  };
}
