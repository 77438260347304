import React, { Component, FormEvent } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import '../styles/ForgotPassword.scss';
import axios from 'axios';
import { Localization } from '../framework/localization/Localization';
import { LoadingSetters } from '..';

type ResetState = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  validated: boolean;
  error: string;
  loading: boolean;
};

class ResetPassword extends Component<LoadingSetters> {
  state: ResetState;

  constructor(props: LoadingSetters) {
    super(props);
    this.resetPassword = this.resetPassword.bind(this);
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      validated: false,
      error: 'Confirm new password field is required',
      loading: false
    };
  }

  resetPassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let user = JSON.parse(sessionStorage.getItem('user') || '');
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      this.setState({ validated: true, error: 'Password is required' });
      return;
    }
    const data = new FormData(e.currentTarget);
    if (data.get('new-password') !== data.get('confirm-password')) {
      e.stopPropagation();
      // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
      // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
      ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
      form.checkValidity();
      this.setState({ validated: true, error: 'Passwords do not match' });
      return;
    }
    if (data.get('old-password') === data.get('confirm-password')) {
      e.stopPropagation();
      // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
      // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
      ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
      form.checkValidity();
      this.setState({ validated: true, error: 'New password same as old password' });
      return;
    }
    this.setState({ oldPassword: data.get('old-password'), newPassword: data.get('new-password'), loading: true });
    this.props.startLoading();
    axios
      .post('/auth/reset-password', {
        user: user.id,
        password: data.get('old-password'),
        newPassword: data.get('new-password')
      })
      .then((res) => {
        window.location.href = '/app';
      })
      .catch(() => {
        //TODO: Handle specific errors
        // ((e.target as HTMLFormElement)['old-password'] as HTMLInputElement).value = '';
        // ((e.target as HTMLFormElement)['new-password'] as HTMLInputElement).value = '';
        ((e.target as HTMLFormElement)['confirm-password'] as HTMLInputElement).value = '';
        form.checkValidity();
        this.setState({ error: 'There was an error changing the password! Please try again!' });
      })
      .finally(() => {
        this.setState({ loading: false });
        this.props.endLoading();
      });
  }

  render() {
    return (
      <Container
        fluid
        style={{
          height: '100vh',
          background: 'url("' + process.env.REACT_APP_LOGIN_BACKGROUND + '"), url("/login-bg.png")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Row style={{ height: '100vh' }} className='align-items-end'>
          <Col sm={0} md={7} lg={8} style={{ padding: '0px 100px', margin: '160px 0px' }}>
            {/*<Image src="/logo-white.png" hidden style={{ margin: '32px 0px' }} />*/}
            <p className='login-banner' hidden>
              A milestone in the Iptor transformation journey
            </p>
            <p className='login-subtitle' hidden>
              Version 12 is the key platform for distribution and publishing businesses to turn digital disruption into
              digital opportunity.
            </p>
          </Col>
          <Col style={{ height: '100vh' }} sm={12} md={5} lg={4}>
            <div
              style={{
                height: 'calc(100vh - 30px)',
                margin: '15px 0',
                padding: '24px 0px',
                position: 'relative',
                overflow: 'auto'
              }}
              className='bg-white'
            >
              <Image style={{ paddingLeft: '24px' }} src={'/logo-color.png'} />
              <h6 className='welcome-message' style={{ marginTop: '32px' }}>
                {Localization.instance.getString('TXT_SET_NEW_PASSWORD')}
              </h6>
              <Form
                noValidate
                style={{ marginTop: '40px', padding: '0 48px' }}
                validated={this.state.validated}
                onSubmit={this.resetPassword}
                onChange={() => this.setState({ validated: false })}
              >
                <Form.Label>{Localization.instance.getString('TXT_OLD_PASSWORD')}</Form.Label>
                <Form.Control id='old-password' name='old-password' type='password' required />
                <Form.Control.Feedback type='invalid'>
                  {Localization.instance.getString('TXT_OLD_PASSWORD_FIELD_REQUIRED')}
                </Form.Control.Feedback>
                <Form.Label style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_NEW_PASSWORD')}
                </Form.Label>
                <Form.Control id='new-password' name='new-password' type='password' required />
                <Form.Control.Feedback type='invalid'>
                  {Localization.instance.getString('TXT_NEW_PASSWORD_FIELD_REQUIRED')}
                </Form.Control.Feedback>
                <Form.Label style={{ marginTop: '32px' }}>
                  {Localization.instance.getString('TXT_CONFIRM_NEW_PASSWORD')}
                </Form.Label>
                <Form.Control id='confirm-password' name='confirm-password' type='password' required />
                <Form.Control.Feedback type='invalid'>{this.state.error}</Form.Control.Feedback>
                <Button
                  disabled={this.state.loading}
                  style={{
                    marginTop: '37px',
                    width: '100%',
                    background: '#00a3a5',
                    height: '40px',
                    fontSize: '12px',
                    padding: '12px auto'
                  }}
                  type='submit'
                >
                  {Localization.instance.getString('TXT_RESET_PASSWORD_CONFIRM')}
                </Button>
                <Button
                  onClick={() => (window.location.href = '/app')}
                  style={{
                    marginTop: '16px',
                    width: '100%',
                    border: 'none',
                    height: '40px',
                    fontSize: '12px',
                    padding: '12px auto',
                    background: 'none',
                    color: '#525252'
                  }}
                >
                  {Localization.instance.getString('TXT_SKIP')}
                </Button>
              </Form>
              <p className='copyright-notice-login'>
                {Localization.instance.getString('TXT_COPYRIGHT_FOOTER')}
                <br />
                {Localization.instance.getString('TXT_PRIVACY_POLICY')} |{' '}
                {Localization.instance.getString('TXT_UK_LEGAL')} |{' '}
                {Localization.instance.getString('TXT_CODE_OF_CONDUCT')}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPassword;
