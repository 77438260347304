import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { Dispatch } from 'redux';

export const initialState = {
  loading: false,
  hasErrors: false,
  shipmentLines: {}
};

// A slice for shipmentLines with our three reducers
const shipmentLinesSlice = createSlice({
  name: 'shipmentLines',
  initialState,
  reducers: {
    getshipmentLines: (state) => {
      state.loading = true;
    },
    getshipmentLinesSuccess: (state, { payload }) => {
      state.shipmentLines = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getshipmentLinesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    }
  }
});

// Three actions generated from the slice
export const { getshipmentLines, getshipmentLinesSuccess, getshipmentLinesFailure } = shipmentLinesSlice.actions;

// A selector
export const shipmentLinesSelector = (state: any) => state;

// The reducer
export default shipmentLinesSlice.reducer;

// Asynchronous thunk action
export function fetchshipmentLines(shipment: number, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getshipmentLines());
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentLines.get',
          params: { shipment: shipment }
        }
      })
        .then((result) => {
          dispatch(getshipmentLinesSuccess(result));
        })
        .catch((err) => {
          // Do somthing
          dispatch(getshipmentLinesFailure());
          console.error(`Error from fetchshipmentLines ${err}`);
        });
    } catch (error) {
      dispatch(getshipmentLinesFailure());
      // alert("Failed")
    }
  };
}
