import { FunctionComponent, useEffect, useState } from 'react';
import axios from 'axios';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import { WidgetTable } from './partials/WidgetTable';
import { Localization } from '../../framework/localization/Localization';

const LastBusinessPartners: FunctionComponent<any> = (props: any) => {
  const noOfRecords = props.numberOfRecords || 10;
  const headers = [
    { label: 'Business Partner', key: 'businessPartner', width: 4 },
    { label: 'Internal name', key: 'internalName', width: 4 },
    { label: 'Type', key: 'type', width: 1 },
    { label: 'Area', key: 'area', width: 1 }
  ];
  const [response, setResponse] = useState<any>({});
  const getData = async () => {
    let payload = {
      control: {
        method: 'businessPartners.get',
        limit: noOfRecords,
        orderBy: 'creationDate DESC, businessPartner ASC'
      }
    };
    let res = await axios
      .request({
        url: '/aperio/api/service',
        data: payload,
        method: 'POST'
      })
      .catch((err) => {
        console.error(`Error from LastBusinessPartners ${err}`);
      });
    const data = {
      rows: res?.data?.data?.items
    };
    setResponse(data || {});
  };

  useEffect(() => {
    getData();
  }, [props]);

  return (
    <WidgetTable
      title={props.title || Localization.instance.getString('DASHBOARD_BusinessPartners')}
      headers={headers}
      rows={response.rows}
    />
  );
};

const lastBusinessPartners: CellPlugin = {
  Renderer: ({ data }: any) => {
    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <LastBusinessPartners title={data.title} numberOfRecords={data.numberOfRecords} height={data.height} />
        </Widget>
      </div>
    );
  },
  id: 'new-bp-table',
  title: 'NEW Business Partners',
  description: 'Display list of Business Partners created recently',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        numberOfRecords: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default lastBusinessPartners;
