import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { OTLPExporterError } from '@opentelemetry/otlp-exporter-base';
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  TracerConfig,
  WebTracerProvider
} from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { auth } from '.';

export const StartOTEL = () => {
  const providerConfig: TracerConfig = {
    resource: new Resource({
      'service.name': 'eui-web-client'
    })
  };

  const provider = new WebTracerProvider(providerConfig);

  const exporter = new OTLPTraceExporter({
    url: '/v1/traces',
    headers: {}
  });

  provider.addSpanProcessor(new BatchSpanProcessor(exporter));

  // Hook send functionality with new interceptor for attaching token
  const send = exporter.send;
  exporter.send = function (objects: any[], onSuccess: () => void, onError: (error: OTLPExporterError) => void) {
    auth.getUser().then((user) => {
      if (user) {
        // NOTE: Do not remove @ts-ignore
        // exporter.headers does not set the headers for OTEL lib only _headers does which is not exported
        // @ts-ignore
        exporter._headers['Authorization'] = `Bearer ${user?.access_token}`;
        send.call(this, objects, onSuccess, onError);
      }
    });
  };
  // we will use ConsoleSpanExporter to check the generated spans in dev console
  //   provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

  provider.register({
    contextManager: new ZoneContextManager()
  });
};

export const StartAutoInstrumentation = () => {
  registerInstrumentations({
    instrumentations: [
      // getWebAutoInstrumentations initializes all the package.
      // it's possible to configure each instrumentation if needed.
      getWebAutoInstrumentations()
    ]
  });
};

// Function for getting OTEL attributes from the URL for each request
export const generateOTELAttrs = (url?: string) => {
  let title = 'send-request-';
  let crumbs = url?.split(/\//g)?.slice(1) ?? []; //Split url by "/" and remove the first empty element
  let windowID: string | undefined;
  let rssURL: string | undefined;
  let exportType: string | undefined;
  if (crumbs?.[0] === 'window') {
    switch (crumbs.length) {
      case 3: // /window/:window/interact
        windowID = crumbs.splice(1, 1)[0];
        break;
      case 4: // /window/:window/load/context
        windowID = crumbs.splice(1, 1)[0];
        break;
      case 5: // /window/:session/:window/export/:type
        crumbs.splice(1, 1);
        windowID = crumbs.splice(2, 1)[0];
        exportType = crumbs.splice(4, 1)[0];
        break;
      case 1:
      default:
        break;
    }
  }
  if (crumbs?.[1] === 'rss-proxy') {
    // /client/rss-proxy/:url
    rssURL = crumbs.pop();
  }
  title += crumbs.join('-');
  return { title, windowID, rssURL, exportType };
};
