import { FunctionComponent, MouseEvent, useEffect, useRef, useState } from 'react';
import { Button as ActionButton, ButtonProps as ActionButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import '../styles/Button.scss';
import { Icons, SquareIcon } from './SquareIcon';
import React from 'react';
import { XT } from '../framework/handlers/xt';
import { LocalStorage } from '../framework/handlers/localStorage';

// TODO: attributes-> required
/*
 * action: button action,
 * attributes: XML attributes,
 * size: button size,
 * variant: button variant like primary, secondary, outline primary etc...
 * */
export type ButtonProps = {
  action: Action;
  actionHandler: (action: Action, e: MouseEvent<HTMLElement> | undefined) => void;
  attributes?: Record<string, any>;
  className?: string;
  disabled?: boolean;
  id: string;
  name: string;
  size?: ActionButtonProps['size'];
  tooltip?: string;
  variant: ButtonVariant;
  visible?: boolean;
  showFunctionTooltip?: boolean;
};

export type Action = {
  alternativename?: string;
  command?: string;
  event: string;
  field?: string;
  name?: string;
  option?: string;
  text?: string;
  returnpanel?: string;
  returnfield?: string;
  disableconstraints?: string;
  dynamicField?: string;
  variables?: string;
  overrideSQL?: string;
  errorMessage?: string;
  exportType?: string;
};

const getImage = (image: string) => {
  let icon = Icons.Help;
  switch (image) {
    case 'promptbutton_half_up':
      icon = Icons.PromptArrow;
      break;
    case 'find':
      icon = Icons.Search;
      break;
  }
  return (
    <SquareIcon className='primary-dark-icon' size='12px'>
      {icon}
    </SquareIcon>
  );
};

export const Button: FunctionComponent<ButtonProps> = ({
  action,
  attributes,
  actionHandler,
  className = '',
  disabled = false,
  id,
  name,
  size = 'lg',
  tooltip,
  variant,
  visible = true,
  showFunctionTooltip
}) => {
  const butRef = useRef<HTMLButtonElement>(null);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    // sets coordinate for tabindex
    if (butRef.current !== null) {
      let button = butRef.current as HTMLButtonElement;
      let window = button.closest('.panel-area') as HTMLElement;
      if (window) {
        // Remark: window only available for buttons inside panel area (e.g. next/previous buttons)
        let nBlocks = window?.scrollWidth / 15;
        let x = ~~(button.getBoundingClientRect().left / 15);
        let y = ~~((nBlocks * button.getBoundingClientRect().top) / 15);
        setX(x);
        setY(y);
      } // else (e.g. global buttons): default values x & y used to get resulting tabindex: 0 (= x + y = 0 + 0)
    }
  }, []);

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={
        'button-container'
      } /*data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}*/ /*data-html={true}*/
    >
      <OverlayTrigger
        placement='top'
        delay={50}
        trigger={['hover']}
        overlay={
          <Tooltip className='custom' id={`tooltip-${id}`}>
            {tooltip || name}
          </Tooltip>
        }
      >
        <ActionButton
          ref={butRef}
          className={className + (attributes?.image ? ' image-btn' : '')}
          disabled={disabled}
          hidden={!visible}
          id={id}
          size={size}
          variant={variant}
          data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
          data-initial-focus={['PAGEUP', 'PAGEDOWN'].includes(action?.event) ? 'false' : 'true'} // used to skip such inline buttons (same as in XT)
          tabIndex={visible && !disabled ? x + y : -1}
          onClick={(e) => actionHandler(action, e)} // performs action on click
          onKeyUp={(e: React.KeyboardEvent) => {
            if (
              ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
              LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
            ) {
              XT.selectNextElement();
            }
          }}
        >
          {!!attributes?.image ? getImage(attributes.image) : name} {showFunctionTooltip ? tooltip?.split('/')[1] : ''}
        </ActionButton>
      </OverlayTrigger>
    </div>
  );
};
