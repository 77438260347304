import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { Dispatch } from 'redux';

export const initialState = {
  loading: false,
  hasErrors: false,
  login: {}
};

// A slice for login with our three reducers
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    getlogin: (state) => {
      state.loading = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.login = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getLoginFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    }
  }
});

// Three actions generated from the slice
export const { getlogin, getLoginSuccess, getLoginFailure } = loginSlice.actions;

// A selector
export const loginSelector = (state: any) => state;

// The reducer
export default loginSlice.reducer;
