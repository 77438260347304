import React from 'react';
import { Component } from 'react';
import { ComponentBaseProps } from '../../framework/parsers/layout/types';
import { XT } from '../../framework/handlers/xt';
import { ColorMap } from '../../types/colorMap';
import { HighlightFocus } from '../../framework/your-note/NotesProvider';

export type GroupProps = ComponentBaseProps & {
  isPanel: boolean;
  style: any;
};

type GroupState = {
  hasFocus: boolean;
  tabIndex: number;
};

export class Group extends Component<GroupProps, GroupState> {
  refGroup: React.RefObject<HTMLDivElement>;
  _getGroupComponentsResult: any; // BEWARE: do NOT access directly

  constructor(props: GroupProps) {
    super(props);
    this.state = { hasFocus: false, tabIndex: -1 };
    this.refGroup = React.createRef();
    this.determineHasEditableRadioButtons = this.determineHasEditableRadioButtons.bind(this);
    this.determineArrowNavigationAllowed = this.determineArrowNavigationAllowed.bind(this);
    this.getGroupComponents = this.getGroupComponents.bind(this);
  }

  componentDidMount() {
    XT.loadContextMenu(this.props); //Load actions and contexts on mount
    if (this.refGroup.current) {
      const scrollInfo = XT.getScrollInfo(this.refGroup);
      this.setState({ ...this.state, tabIndex: scrollInfo.x + scrollInfo.y });
    }

    let isGrid = this.props.attributes.layout === 'grid';
    let components = this.getGroupComponents(isGrid) || [];
    let titles = components.find((x: any) => x.componentName === 'titles');
    if (titles) {
      //Moved to component mount events instead of render function
      this.props.updateTitle(titles.props.crumbs, this.props.panelID);
      titles.props.crumbs = [];
      components.splice(
        components.findIndex((x: any) => x.componentName === 'titles'),
        1
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<GroupProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (this.props.data.row) {
      if (this.props.data.row !== prevProps.data.row) {
        XT.loadContextMenu(this.props);
      }
    }

    let isGrid = this.props.attributes.layout === 'grid';
    let components = this.getGroupComponents(isGrid) || [];
    let titles = components.find((x: any) => x.componentName === 'titles');
    if (titles) {
      this.props.updateTitle(titles.props.crumbs, this.props.panelID);
      titles.props.crumbs = [];
      components.splice(
        components.findIndex((x: any) => x.componentName === 'titles'),
        1
      );
    }
  }

  determineHasEditableRadioButtons(components: any): boolean {
    if (this.props.isPanel) return false;
    let radioButtons: any[] = components.filter((x: any) => {
      if (x.componentName !== 'radio') return false;
      if (!x.props.visible) return false; // Remark: "resulting" visible (constraints included)
      if (x.props.disabled) return false; // Remark: "resulting" disabled (constraints included)
      if (x.props.readOnly) return false;
      return true;
    });
    return radioButtons.length > 0;
  }

  determineArrowNavigationAllowed(hasEditableRadioButtons: boolean): boolean {
    let props: any = this.props; //NTH: fix type (visible, disabled & readOnly are available but are not included in the type definantion)
    if (!hasEditableRadioButtons) {
      return false;
    } else if (!props.visible) {
      return false;
    } else if (props.disabled || props.readOnly) {
      return false;
    } else if (this.state.hasFocus) {
      // --> keep default behavior arrow (down/up ==> select previous/next radio button)
      return false;
    }
    return true;
  }

  getGroupComponents(isGrid: boolean) {
    this._getGroupComponentsResult = XT.getComponents(this.props, this._getGroupComponentsResult);
    return this._getGroupComponentsResult.components.filter(
      (c: any) => c.componentActive && (isGrid || c.props.visible)
    );
  }

  render() {
    let isGrid = this.props.attributes.layout === 'grid';
    let components = this.getGroupComponents(isGrid) || [];
    let hasEditableRadioButtons = this.determineHasEditableRadioButtons(components);
    let arrowNavigationAllowed = this.determineArrowNavigationAllowed(hasEditableRadioButtons);

    let height = this.props.attributes.height;
    let top = 6;
    let origHeight = 0;
    let hasFixedHeightTable = false;
    return (
      <div
        className={`xt-group ${!!this.props.attributes.text ? 'has-title' : ''} ${isGrid ? 'grid' : ''} ${
          hasEditableRadioButtons ? 'radio-buttons-group' : ''
        }`}
        id={this.props.id}
        // style={{ paddingBottom: components.find((v: any) => v.props.id === 'text' && v.props.visible) ? '100px' : '' }}
        ref={this.refGroup}
        data-arrow-navigation={arrowNavigationAllowed ? 'true' : 'false'}
        tabIndex={hasEditableRadioButtons ? this.state.tabIndex : undefined}
        onFocus={(e: React.FocusEvent) => {
          if (hasEditableRadioButtons) {
            // Adjust state
            if (!this.state.hasFocus) this.setState({ ...this.state, hasFocus: true });

            // Redirect focus to selected (or "first") radio button if focus given directly to the div (case arrow navigation)
            if (e.target === e.currentTarget) {
              if ((e.target as any).directFocus) {
                // Remove temporary property
                // -------------------------
                delete (e.target as any).directFocus;

                let radioButtons = Array.from(
                  e.target.querySelectorAll<HTMLInputElement>(
                    'input[type=radio][tabindex]:not([disabled]):not([hidden])'
                  )
                );
                if (radioButtons.length > 0) {
                  let radioButton = radioButtons.find((button) => button.checked) || radioButtons[0]; //NTH: determine first based on something avilable in XT attribute (eg like ddspos?)
                  radioButton.focus();
                }
              }
            }
          }
        }}
        onBlur={(e: React.FocusEvent) => {
          if (!e.currentTarget.contains(e.relatedTarget) && this.state.hasFocus)
            this.setState({ ...this.state, hasFocus: false });
        }}
      >
        <span className='heading' hidden={!this.props.attributes.text}>
          {XT.getDynamicValue(this.props.windowProps, this.props.attributes.text || '')}
        </span>
        {components //
          .map((v: any) => {
            v.props.windowProps = this.props.windowProps;
            let attrs = v.props?.attributes;
            let whm = 1.32;
            let wwm = 1.32;

            v.props.actionHandler = this.props.actionHandler;
            let background: string = 'transparent';
            if (v.props.attributes.background) {
              let _background = v.props.attributes.background;
              if (_background.startsWith('rgb')) {
                background = _background;
              } else {
                background = ColorMap[_background] || 'transparent';
              }
            }
            if (+attrs.xpos < 0 && attrs.width) {
              attrs.width = +attrs.width + +attrs.xpos;
            }
            //Reset anchor positions if negative

            ['xpos', 'ypos', 'rpos', 'bpos'].forEach((pos: string) => {
              attrs[pos] = attrs[pos] < 0 ? 6 : attrs[pos];
              if (attrs[pos] === undefined) delete attrs[pos];
            });

            if (attrs.anchor === 'LRT' && attrs.autopagedown?.toLowerCase() === 'false' && !!attrs.pagesize) {
              attrs.minHeight = (+attrs.pagesize + 2) * (35 / whm); // + 2 = +1 for header & +1 for table menu; usage filter will lead to scrollbar on table (accepted as filter not very usefull on this kind of tables and as such expected to be used very rarely)
              height = (+attrs.pagesize + 2) * (35 / whm);
              origHeight = +attrs.height;
              hasFixedHeightTable = true;
              top = +attrs.ypos;
            }

            if (hasFixedHeightTable && top < +attrs.ypos && attrs.anchor !== 'B') {
              attrs.top = `${+attrs.ypos - origHeight + height}`;
            }

            //Check all anchors and remove height/width
            if (attrs.anchor) {
              'LTRB'.split('').forEach((anc: string, index: number) => {
                if (attrs.anchor.includes(anc))
                  if (attrs.anchor.includes('LTRB'[(index + 2) % 4])) {
                    if ('LR'.includes(anc) && attrs.xpos && attrs.rpos) delete attrs.width;
                    else if ('TB'.includes(anc) && attrs.ypos && attrs.bpos) delete attrs.height;
                  }
              });
              if (
                attrs.anchor.includes('B') &&
                !attrs.anchor.includes('T') &&
                (!!attrs.height || v.componentName !== 'group')
              ) {
                delete attrs.ypos;
              }
              if (attrs.anchor.includes('R') && !attrs.anchor.includes('L') && !!attrs.width) {
                delete attrs.xpos;
              }
            }

            //Deactivate anchor positions based on anchors and width/height
            ['xpos', 'ypos', 'rpos', 'bpos'].forEach((pos: string) => {});

            if (!attrs.anchor) {
              if (attrs.ypos) delete attrs.bpos;
            }

            //Determine value overflow for the component
            let componentOverflow = 'visible';
            if (v.componentName === 'table') {
              componentOverflow = 'unset';
            } else if (
              (this.props as any)?.formID === 'SpooledFileViewDialog' &&
              this.props.panelID === 'DetailPanel' &&
              v.props.id === 'text'
            ) {
              componentOverflow = 'auto';
            }

            return (
              <div
                data-anchor={attrs.anchor}
                data-panel={this.props.panelID}
                data-ddspos={v.props.attributes.ddspos}
                data-ddspanel={v.props.attributes.ddspanel}
                data-padchar={v.props.attributes.padchar}
                data-limit={v.props.attributes.limit}
                data-dirtyflag={v.props.attributes.dirtyflag || this.props.dirtyflag}
                key={v.props.id}
                className={`control-parent ${v.componentName === 'table' ? 'table-container' : ''}`}
                style={
                  isGrid
                    ? {
                        background: background,
                        position: 'relative',
                        display: 'block',
                        overflow: 'visible',
                        // minHeight: '30px',
                        // height: '22px',
                        minWidth: v.componentName === 'editprompt' ? '30px' : '',
                        flexBasis: this.props.attributes.isACP
                          ? attrs.width + '%'
                          : this.props.attributes.numcols
                          ? 100 / this.props.attributes.numcols + '%'
                          : 'auto',
                        height: (attrs.height ? attrs.height * whm + 'px' : '31px') || '31px',
                        width:
                          v.componentName === 'table'
                            ? '100%'
                            : attrs.width
                            ? attrs.width * wwm + (this.props.attributes.isACP ? '%' : 'px')
                            : 'auto',
                        padding: v.componentName === 'table' ? '2px' : '0px 2px', // how to recognize grid? isGrid and v.componentName === 'table' doesn't work properly here
                        visibility: v.props.visible && !v.props.attributes.hide ? 'visible' : 'hidden'
                      }
                    : {
                        background: background,
                        position: 'absolute',
                        display: 'block',
                        alignItems: 'center',
                        overflow: componentOverflow,
                        minHeight: attrs.minHeight ? `${attrs.minHeight * whm}px` : '0px',
                        minWidth: v.componentName === 'editprompt' ? '35px' : '',
                        height:
                          (+attrs.height + (!!v.props.attributes.text && v.componentName === 'group' ? 6 : 0)) * whm +
                          'px',
                        width:
                          (v.props.id === 'FMPOSL' && this.props.panelID === 'WNDD0011S') || !attrs.width
                            ? 'auto'
                            : v.componentName === 'table'
                            ? '100%'
                            : attrs.width
                            ? attrs.width * wwm + 'px'
                            : 'auto',
                        top: attrs.top
                          ? `${attrs.top * whm}px`
                          : attrs.ypos
                          ? ~~(
                              (v.componentName === 'combo-editable' ? +attrs.ypos - 2 : attrs.ypos) * whm +
                              (!!this.props.attributes.text ? 6 : 0)
                            ) + 'px'
                          : 'auto',
                        left: attrs.xpos ? ~~(attrs.xpos * wwm) + 'px' : 'auto',
                        right: attrs.rpos ? ~~(attrs.rpos * wwm) + 'px' : 'auto',
                        bottom: attrs.bpos
                          ? ~~((v.componentName === 'combo-editable' ? +attrs.bpos + 2 : attrs.bpos) * whm + 8) + 'px'
                          : 'auto'
                        //overflow: 'auto'
                      }
                }
              >
                {v.props.visible && (
                  <HighlightFocus elementID={v.props.id}>
                    {React.createElement(v.component, {
                      ...v.props,
                      key: v.props.id,
                      className: v.props.className + (v.props.name === 'dynamictable' ? ' dynamictableLabel' : '')
                    })}
                  </HighlightFocus>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}
