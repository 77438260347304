import React, { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import './../../styles/Setting.scss';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Localization } from '../../framework/localization/Localization';
import { LocaleContext } from '../../App';
import { Dark } from '../../components/Dark';
import { SettingsCombo, Option } from '../../components/SettingsCombo';
import { TooltipContext } from '../../helpers/TooltipContext';
import { NumpadSignsBehavior } from '../../components/NumpadSignsBehavior';
import { LocalStorage } from '../../framework/handlers/localStorage';

export namespace FormattingOptions {
  export function getDateOptions() {
    return [
      { value: 'dd/MM/yyyy', label: Localization.instance.getString('dd/MM/yyyy') },
      { value: 'yyyy/MM/dd', label: Localization.instance.getString('yyyy/MM/dd') },
      { value: 'yyyy/dd/MM', label: Localization.instance.getString('yyyy/dd/MM') },
      { value: 'MM/dd/yyyy', label: Localization.instance.getString('MM/dd/yyyy') },
      { value: 'dd-MM-yyyy', label: Localization.instance.getString('dd-MM-yyyy') },
      { value: 'yyyy-MM-dd', label: Localization.instance.getString('yyyy-MM-dd') },
      { value: 'MM-dd-yyyy', label: Localization.instance.getString('MM-dd-yyyy') },
      { value: 'yyyy-dd-MM', label: Localization.instance.getString('yyyy-dd-MM') },
      { value: 'dd.MM.yyyy', label: Localization.instance.getString('dd.MM.yyyy') },
      { value: 'yyyy.MM.dd', label: Localization.instance.getString('yyyy.MM.dd') },
      { value: 'yyyy.dd.MM', label: Localization.instance.getString('yyyy.dd.MM') },
      { value: 'MM.dd.yyyy', label: Localization.instance.getString('MM.dd.yyyy') }
    ];
  }
  export function getDecimalSeparatorOptions() {
    return [
      { label: '.' + ' (' + Localization.instance.getString('Local-Point') + ')', value: '.' },
      { label: ',' + ' (' + Localization.instance.getString('Local-Comma') + ')', value: ',' }
    ];
  }
  export function getGroupSeparatorOptions() {
    return [
      { label: '.' + ' (' + Localization.instance.getString('Local-Point') + ')', value: '.' },
      { label: ',' + ' (' + Localization.instance.getString('Local-Comma') + ')', value: ',' },
      { label: ' ' + ' (' + Localization.instance.getString('KEYBOARD_BUTTON_Space') + ')', value: ' ' }
    ];
  }
  export function getLocaleFormattingOptions(): LocaleFormattingOptions {
    return {
      date: getDateOptions(),
      decimalSeparator: getDecimalSeparatorOptions(),
      groupSeparator: getGroupSeparatorOptions()
    };
  }
}
export type LocaleFormattingOptions = {
  date: Option[];
  decimalSeparator: Option[];
  groupSeparator: Option[];
};

export type SettingProps = {
  className?: string;
  hide: () => void;
  isShown: boolean;
  showFunctionTooltip?: boolean;
  toggleFunctionTooltip?: (tooltipBoolean: boolean) => void;
};

export const Setting: FunctionComponent<SettingProps> = ({ isShown, hide, ...props }) => {
  const { showFunctionTooltip, toggleFunctionTooltip } = useContext(TooltipContext);
  const [checked, setChecked] = useState(showFunctionTooltip || false);
  const isSettingsEnabled = () => {
    if (!checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };
  const { localeSettings, setCurrentLocale } = useContext(LocaleContext);
  const [tempLocaleInfo, setTempLocaleInfo] = useState({
    localeOption: Localization.Maintenance.getLocaleOptionValueFromCurrentLocale(),
    decimalSeparator: Localization.instance.decimalSeparator,
    groupSeparator: Localization.instance.groupSeparator,
    dateFormat: Localization.instance.dateFormat,
    localeOptionsList: Localization.Maintenance.getLocaleOptionsList()
  });
  const [tempEnqSetting, setTempEnqSetting] = useState(sessionStorage.getItem('enquiry_window') || 'new');
  const [numpadSignKeysBehavior, setNumpadSignKeysBehavior] = useState(LocalStorage.NumpadSignsBehavior.getSettings());
  const [localeFormattingOptions, setLocaleFormattingOptions] = useState<LocaleFormattingOptions>(
    FormattingOptions.getLocaleFormattingOptions()
  );

  useEffect(() => {
    setTempLocaleInfo({
      localeOption: Localization.Maintenance.getLocaleOptionValueFromCurrentLocale(),
      decimalSeparator: Localization.instance.decimalSeparator,
      groupSeparator: Localization.instance.groupSeparator,
      dateFormat: Localization.instance.dateFormat,
      localeOptionsList: Localization.Maintenance.getLocaleOptionsList()
    });
    setLocaleFormattingOptions(FormattingOptions.getLocaleFormattingOptions());
  }, [localeSettings]);

  useEffect(() => {
    /*
      Remark: there are multiple instances of the Setting component (one per toolbar AND desktop and program tabs have their own
      instance of the toolbar). As a result, the values should be reloaded if something was changed through another instance of Setting
    */
    setNumpadSignKeysBehavior(LocalStorage.NumpadSignsBehavior.getSettings());
  }, [LocalStorage.NumpadSignsBehavior.getSettings()]);

  const windowOptions = [
    { label: 'WINDOW_OPTION_CurrentWindow', value: 'current' },
    { label: 'WINDOW_OPTION_NewWindow', value: 'new' }
  ];

  const applySettings = async () => {
    sessionStorage.setItem('enquiry_window', tempEnqSetting);
    LocalStorage.NumpadSignsBehavior.save(numpadSignKeysBehavior.actAsTab, numpadSignKeysBehavior.clearIfAllSelected);
    await Localization.Maintenance.setSettingsByLocaleOptionValue(
      tempLocaleInfo.localeOption,
      tempLocaleInfo.dateFormat,
      tempLocaleInfo.decimalSeparator,
      tempLocaleInfo.groupSeparator
    );
    setCurrentLocale();
    toggleFunctionTooltip?.(checked);
    localStorage.setItem('showFunctionTooltip', `${checked}`);
  };
  const resetBasedOnLanguage = async () => {
    const tempDefaultInfo = Localization.Maintenance.getDefaultValueBasedOnLanguage(
      tempLocaleInfo.localeOption,
      Localization.instance
    );
    await Localization.Maintenance.setSettingsByLocaleOptionValue(
      tempDefaultInfo.locale,
      tempDefaultInfo.dateFormat,
      tempDefaultInfo.decimalSeparator,
      tempDefaultInfo.groupSeparator
    );
    setCurrentLocale();
  };

  return (
    <>
      <Modal
        className={'right-overlay settings-modal'}
        show={isShown}
        animation={false}
        onHide={hide}
        size='lg'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className='mr-3'>
              <SquareIcon className='icon-modal-header' size='20px'>
                {Icons.Settings}
              </SquareIcon>
            </span>
            <span>{Localization.instance.getString('SETTINGS_ClientSettings')}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Setting-Content-container'>
            <Row className={'setting-row'}>
              <Col md={6}>{Localization.instance.getString('SETTINGS_Locale') + ' :'}</Col>
              <Col md={6}>
                {tempLocaleInfo.localeOptionsList.find(
                  (a) => a.value?.toLowerCase() === tempLocaleInfo.localeOption?.toLocaleLowerCase()
                )?.label || 'Default'}
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip className='custom' id={`tooltip-print`}>
                      {Localization.instance.getString('Tooltip-Local-Language')}
                    </Tooltip>
                  }
                >
                  <span className='local-language-icon' onClick={resetBasedOnLanguage}>
                    <SquareIcon size='20px'>{Icons.Refresh2}</SquareIcon>
                  </span>
                </OverlayTrigger>
              </Col>
              <Col md={6}>{Localization.instance.getString('SETTINGS_DateFormat') + ' :'}</Col>
              <Col md={6}>
                <SettingsCombo
                  options={localeFormattingOptions.date}
                  defaultValue={{ value: tempLocaleInfo.dateFormat, label: '' }}
                  translateOptionLabels={false}
                  onChangeOption={(option: Option) =>
                    setTempLocaleInfo({ ...tempLocaleInfo, dateFormat: option.value })
                  }
                />
              </Col>
              <Col md={6}>{Localization.instance.getString('SETTINGS_DecimalSeparator') + ' :'}</Col>
              <Col md={6}>
                <SettingsCombo
                  options={localeFormattingOptions.decimalSeparator}
                  defaultValue={{ value: tempLocaleInfo.decimalSeparator, label: '' }}
                  translateOptionLabels={false}
                  onChangeOption={(option: Option) =>
                    setTempLocaleInfo({ ...tempLocaleInfo, decimalSeparator: option.value })
                  }
                />
              </Col>
              <Col md={6}>
                <p>{Localization.instance.getString('SETTINGS_GroupSeparator') + ' :'}</p>
              </Col>
              <Col md={6}>
                <SettingsCombo
                  options={localeFormattingOptions.groupSeparator}
                  defaultValue={{ value: tempLocaleInfo.groupSeparator, label: '' }}
                  translateOptionLabels={false}
                  onChangeOption={(option: Option) =>
                    setTempLocaleInfo({ ...tempLocaleInfo, groupSeparator: option.value })
                  }
                />
              </Col>
            </Row>
            <p></p>
            <Row className={'setting-row2'}>
              <Col md={6}>{Localization.instance.getString('SETTINGS_DarkMode')}</Col>
              <Col md={6}>
                <Dark />
              </Col>
              <Col md={6}>{Localization.instance.getString('SETTINGS_ToolTip')}</Col>
              <Col md={6}>
                <div className='dark-mode'>
                  <label className='switch'>
                    <input type='checkbox' checked={checked} onChange={isSettingsEnabled} />
                    <span className='slider round' />
                  </label>
                </div>
              </Col>
              <Col md={6}>{Localization.instance.getString('SETTINGS_EnquiryIn') + ' :'}</Col>
              <Col md={6}>
                <SettingsCombo
                  options={windowOptions}
                  defaultValue={
                    windowOptions.find((a) => a.value === tempEnqSetting) || {
                      label: '',
                      value: ''
                    }
                  }
                  translateOptionLabels={true}
                  onChangeOption={(option: Option) => setTempEnqSetting(option.value)}
                />
              </Col>
            </Row>
            <Row className={'setting-row'}>
              <Col md={6}>{Localization.instance.getString('SETTINGS_NumpadSignkeysBehavior') + ' :'}</Col>
              <Col md={6}>
                <NumpadSignsBehavior
                  actAsTab={numpadSignKeysBehavior.actAsTab}
                  clearIfAllSelected={numpadSignKeysBehavior.clearIfAllSelected}
                  onChange={(actAsTab, clearIfAllSelected) =>
                    setNumpadSignKeysBehavior({ actAsTab: actAsTab, clearIfAllSelected: clearIfAllSelected })
                  }
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'flex-end' }}>
          <button
            className='btn-outline-dark'
            onClick={() => {
              setNumpadSignKeysBehavior(LocalStorage.NumpadSignsBehavior.getSettings()); // restore current settings to ignore changes
              setTempEnqSetting(sessionStorage.getItem('enquiry_window') || 'new');
              setTempLocaleInfo({
                ...tempLocaleInfo,
                localeOption: Localization.Maintenance.getLocaleOptionValueFromCurrentLocale(),
                decimalSeparator: Localization.instance.decimalSeparator,
                groupSeparator: Localization.instance.groupSeparator,
                dateFormat: Localization.instance.dateFormat,
                localeOptionsList: Localization.Maintenance.getLocaleOptionsList()
              });
              hide();
            }}
          >
            {' '}
            {Localization.instance.getString('SETTINGS_BUTTON_Cancel')}{' '}
          </button>
          <button
            className='btn-outline-dark'
            disabled={tempLocaleInfo.decimalSeparator === tempLocaleInfo.groupSeparator}
            onClick={() => {
              applySettings();
              hide();
            }}
          >
            {' '}
            {Localization.instance.getString('SETTINGS_BUTTON_OK')}{' '}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
