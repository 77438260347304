// NOTE: Keeping entire old code for reference till new one is properly tested
// export class XTString extends String {
//   constructor(str: string = '') {
//     super(str);
//   }
//
//   serverFormatToNumber = (decimalSeparator: string) : XTNumber => {
//     return this.toNumber(decimalSeparator, decimalSeparator === '.' ? ',' : '.');
//   };
//
//   clientFormatToNumber = (decimalSeparator: string, groupSeparator: string) : XTNumber => {
//     return this.toNumber(decimalSeparator, groupSeparator);
//   };
//
//   private toNumber = (decimalSeparator: string, groupSeparator: string): XTNumber => {
//     // Initialize
//     // ==========
//     let xt = String(this);
//
//
//     // Convert to js number format
//     // ===========================
//
//     // --> remove leading/trailing blanks
//     xt = xt.trim();
//
//     // --> replace trailing minus sign by leading minus sign
//     if (xt.endsWith('-')) xt = `-${xt.replace('-', '')}`;
//
//     // --> remove group separators
//     xt = xt.replaceAll(groupSeparator, '');
//
//     // --> replace decimal separator with decimal point
//     if (decimalSeparator !== '.') xt = xt.replace(decimalSeparator, '.');
//
//
//     // Determine number of decimals supplied
//     // =====================================
//     let numberOfDecimals = xt.indexOf('.') === -1 ? 0 : xt.length - xt.indexOf('.') - 1;
//
//
//     // Convert to js number
//     // ====================
//     if (xt === '') xt = '0';                              // parseFloat('') results in NaN (eg A/P transactions enquiry for fields where number of decimals is determined by the application, eg depending on currency )
//     let num = parseFloat(xt);
//
//
//     // Get number of leading zeros                        // NTH: explain why only needed if numberOfDecimals > 0 and value not zero and ... ??????????
//     // ===========================
//     let padWithZero = 0;
//     let xtTemp = String(xt);
//     while (xtTemp && num !== 0 && numberOfDecimals > 0 && Number(xtTemp.substring(0, xtTemp.indexOf('.'))) !== 0 && xtTemp[0] === '0') {
//       padWithZero++;
//       xtTemp = xtTemp.slice(1);
//     };
//
//
//     // Return result
//     // =============
//     return new XTNumber(num, numberOfDecimals, padWithZero);
//   };
// };
//
// export class XTNumber extends Number {
//
//   readonly numberOfDecimals: number;
//   readonly isNaN: boolean = false;
//   readonly padWithZero: number = 0;
//
//   constructor(num: number, numberOfDecimals: number = 0, padWithZero: number = 0) {
//     super(num);
//     this.numberOfDecimals = numberOfDecimals;
//     this.isNaN = isNaN(num);
//     this.padWithZero = padWithZero;
//   };
//
//   public toXTStringServerFormat = (
//     decimalSeparator: string,
//     allowDecimals: boolean,
//     allowZero: boolean = false,
//     allowNegative: boolean = true,
//     limit: number = 0,
//     padChar?: string | null,
//   ) : XTString => {
//     // Execute base formatting
//     // =======================
//     let result = this.toStringBase(decimalSeparator, allowDecimals, allowZero, allowNegative);
//
//     // Handle padding
//     // ==============
//     if (limit > 0) {
//       let padding = String(padChar || '');
//       if (padChar !== undefined && padChar !== null) {
//         padding = padChar === '' ? ' ' : padding;
//       }
//       result = result.padStart(limit, padding);                       // NTH_SKE: do we need to adjust the limit for minus and decimal separator ??????
//     };
//
//     // Return result
//     // =============
//     return new XTString(result);
//   };
//
//   public toXTStringClientFormat = (
//     decimalSeparator: string,
//     groupSeparator: string,
//     allowDecimals: boolean,
//     allowZero: boolean = false,
//     allowNegative: boolean = true,
//     includeGrouping?: boolean
//   ) : XTString => {
//     // Execute base formatting
//     // =======================
//     let result = this.toStringBase(decimalSeparator, allowDecimals, allowZero, allowNegative);
//
//     // Insert group separators (if requested)
//     // ======================================
//     if (includeGrouping && result.trim() !== '') {
//
//       // --> initialize
//       let temp = result.lastIndexOf(decimalSeparator) !== -1 ? result.substring(result.lastIndexOf(decimalSeparator)) : '';
//       let ind = result.length - temp.length;
//
//       // --> prepend leading digits with inclusion of grouping separators
//       while (ind > 0) {
//         temp = groupSeparator + result.substring(ind - 3, ind) + temp;
//         ind -= 3;
//       };
//
//       // --> remove not needed group separator
//       if (temp.charAt(0) === groupSeparator) temp = temp.substring(1);
//       if (temp.startsWith('-' + groupSeparator)) temp = '-' + temp.substring(2);
//
//       // --> save result
//       result = temp;
//     }
//
//     // Return result
//     // =============
//     return new XTString(result);
//   };
//
//   private toStringBase = (decimalSeparator: string, allowDecimals: boolean, allowZero: boolean = false, allowNegative: boolean = true) : string => {
//     // Initialize
//     // ==========
//     let num = Number(this);
//
//     // Handle invalid number
//     // =====================
//     if (isNaN(num)) return '';
//
//     // Handle negative number not allowed
//     // ==================================
//     if (!allowNegative && num < 0) num = num * -1;
//
//     // Handle zeros not allowed
//     // ========================
//     if (!allowZero && num === 0) return '';
//
//     // Format number
//     // =============
//
//     // --> Format number to js format
//     let result = `${num}`;
//
//     // --> Remove decimals (if appropriate)
//     if (allowDecimals !== undefined) {
//       if (!allowDecimals && result.includes('.')) result = result.slice(0, result.indexOf('.'));
//     } else if (this.numberOfDecimals === 0 && result.includes('.')) result = result.slice(0, result.indexOf('.'));
//
//     // --> Replace js decimal point with requested decimal separator
//     result = result.replace('.', decimalSeparator);
//
//     // --> Restore leading zeros (if appropriate)
//     if (this.padWithZero) result = result.padStart(this.padWithZero + result.length, '0');
//
//     // --> Restore decimals
//     if (this.numberOfDecimals > 0) {
//       let currentNumberOfDecimals = 0;
//
//       // ° initialize
//       if (result.includes(decimalSeparator)) {
//         currentNumberOfDecimals = result.length - result.indexOf(decimalSeparator) - 1;
//       } else {
//         result = `${result}${decimalSeparator}`;
//       };
//
//       // ° append zeros until requested number of decimals reached
//       while (currentNumberOfDecimals < this.numberOfDecimals) {
//         result = `${result}0`;
//         currentNumberOfDecimals++;
//       };
//     };
//
//     // Return result
//     // =============
//     return result;
//   };
// };

export class XTString extends String {
  constructor(str: string = '') {
    super(str);
  }

  serverFormatToNumber = (decimalSeparator: string): XTNumber => {
    return this.toNumber(decimalSeparator, decimalSeparator === '.' ? ',' : '.');
  };

  clientFormatToNumber = (decimalSeparator: string, groupSeparator: string): XTNumber => {
    return this.toNumber(decimalSeparator, groupSeparator);
  };

  static isSafeNumber(serverValue: string, decimalSeparator: string) {
    // Initialize
    let value = serverValue.trim();

    // Remove minus sign
    value = value.replace('-', '');

    // Remove all grouping separators
    const groupingSeparator = decimalSeparator === '.' ? ',' : '.';
    value = value.replaceAll(groupingSeparator, '');

    // Split on decimal separator
    let [integerPart, fractionalPart] = value.split(decimalSeparator);
    fractionalPart = fractionalPart || '';

    // Integer part should be numeric
    if (integerPart !== integerPart.replace(/[^.0-9]/g, '')) {
      return false;
    }

    // Fractional part should be numeric
    if (fractionalPart !== fractionalPart.replace(/[^.0-9]/g, '')) {
      return false;
    }

    // Remove leading zeros integral part
    while (integerPart.startsWith('0')) {
      integerPart = integerPart.slice(1);
    }

    // Remove trailing zeros fractional part
    while (fractionalPart.endsWith('0')) {
      fractionalPart = fractionalPart.substring(0, fractionalPart.length - 1);
    }

    // Calculate total length both parts
    const combineLength = integerPart.length + fractionalPart.length;

    // Safe if length less than length max integer
    return combineLength < Number.MAX_SAFE_INTEGER.toString().length;
  }

  private toNumber = (decimalSeparator: string, groupSeparator: string): XTNumber => {
    // Initialize
    // ==========
    let xt = String(this);

    // Convert to js number format
    // ===========================

    // --> remove leading/trailing blanks
    xt = xt.trim();

    // --> replace trailing minus sign by leading minus sign
    if (xt.endsWith('-')) xt = `-${xt.replace('-', '')}`;

    // --> remove group separators
    xt = xt.replaceAll(groupSeparator, '');

    // --> replace decimal separator with decimal point
    if (decimalSeparator !== '.') xt = xt.replace(decimalSeparator, '.');

    // Determine number of decimals supplied
    // =====================================
    let numberOfDecimals = xt.indexOf('.') === -1 ? 0 : xt.length - xt.indexOf('.') - 1;

    // Convert to js number
    // ====================
    if (xt === '') xt = '0'; // parseFloat('') results in NaN (eg A/P transactions enquiry for fields where number of decimals is determined by the application, eg depending on currency )
    let num = parseFloat(xt);

    // Get integral and fractional part
    // ================================
    let [integralPart, fractionalPart] = xt.split('.');
    if (!fractionalPart) fractionalPart = '';

    // Get number of leading zeros                        // NTH: explain why only needed if numberOfDecimals > 0 and value not zero and ... ??????????
    // ===========================
    let padWithZero = 0;
    let xtTemp = String(xt);
    while (
      xtTemp &&
      num !== 0 &&
      numberOfDecimals > 0 &&
      Number(xtTemp.substring(0, xtTemp.indexOf('.'))) !== 0 &&
      xtTemp[0] === '0'
    ) {
      padWithZero++;
      xtTemp = xtTemp.slice(1);
    }

    // Return result
    // =============
    return new XTNumber(integralPart, fractionalPart, numberOfDecimals, padWithZero);
  };
}

export class XTNumber {
  private integral: string;
  private fractional: string;
  private isNegative: boolean;
  readonly numberOfDecimals: number;
  readonly isNaN: boolean = false;
  readonly padWithZero: number = 0;

  constructor(integralPart: string, fractionalPart: string, numberOfDecimals: number = 0, padWithZero: number = 0) {
    // Determine whether negative number
    // =================================
    if (integralPart.trim().startsWith('-')) {
      this.isNegative = true;
      integralPart = integralPart.trim().substring(1);
    } else {
      this.isNegative = false;
    }

    // Initialize integral part
    // ========================
    this.integral = integralPart.trim() === '' ? '0' : integralPart.trim();

    // Initialize fractional part
    // ==========================
    this.fractional = fractionalPart.trim();

    // Initialize is not numeric
    // =========================

    // --> initialize
    let _integral: bigint;
    let _fractional: bigint;
    this.isNaN = false;

    // --> check integral part
    try {
      _integral = BigInt(this.integral);
    } catch (e) {
      this.isNaN = true;
      this.isNegative = false;
    }

    // --> check fractional part
    if (this.fractional !== '' && !this.isNaN) {
      try {
        _fractional = BigInt(this.fractional);
      } catch (e) {
        this.isNaN = true;
        this.isNegative = false;
      }
    }

    // Adjust isNegative for zero                       // -0, -0.00, ... ==> 0
    // ==========================
    if (!this.isNaN && this.isNegative && _integral! === BigInt(0)) {
      if (this.fractional === '') this.isNegative = false;
      if (_fractional! === BigInt(0)) this.isNegative = false;
    }

    // Get other parameters
    // ====================
    this.numberOfDecimals = numberOfDecimals;
    this.padWithZero = padWithZero;
  }

  public toXTStringServerFormat = (
    decimalSeparator: string,
    allowDecimals: boolean,
    allowZero: boolean = false,
    allowNegative: boolean = true,
    limit: number = 0,
    padChar?: string | null
  ): XTString => {
    // Execute base formatting
    // =======================
    let result = this.toXTStringBase(decimalSeparator, allowDecimals, allowZero, allowNegative, this.padWithZero);

    // Handle padding
    // ==============
    if (limit > 0) {
      let padding = String(padChar || '');
      if (padChar !== undefined && padChar !== null) {
        padding = padChar === '' ? ' ' : padding;
      }
      result = result.padStart(limit, padding); // NTH_SKE: do we need to adjust the limit for minus and decimal separator ??????
    }

    // Return result
    // =============
    return new XTString(result);
  };

  public toXTStringClientFormat = (
    decimalSeparator: string,
    groupSeparator: string,
    allowDecimals: boolean,
    allowZero: boolean = false,
    allowNegative: boolean = true,
    includeGrouping?: boolean
  ): XTString => {
    // Initialize pad with zero
    // ========================
    /* 
      Remark: still not clear what this is supposed to be doing (see remark in XTstring class), anyway it seems to be conflicting with
      the use of grouping seperators: eg returns 0.100,50. 
    */
    const padWithZero = includeGrouping ? 0 : this.padWithZero;

    // Execute base formatting
    // =======================
    let result = this.toXTStringBase(decimalSeparator, allowDecimals, allowZero, allowNegative, padWithZero);

    // Insert group separators (if requested)
    // ======================================
    if (includeGrouping && result.trim() !== '') {
      // --> initialize
      let temp =
        result.lastIndexOf(decimalSeparator) !== -1 ? result.substring(result.lastIndexOf(decimalSeparator)) : '';
      let ind = result.length - temp.length;

      // --> prepend leading digits with inclusion of grouping separators
      while (ind > 0) {
        temp = groupSeparator + result.substring(ind - 3, ind) + temp;
        ind -= 3;
      }

      // --> remove not needed group separator
      if (temp.charAt(0) === groupSeparator) temp = temp.substring(1);
      if (temp.startsWith('-' + groupSeparator)) temp = '-' + temp.substring(2);

      // --> save result
      result = temp;
    }

    // Return result
    // =============
    return new XTString(result);
  };

  private toXTStringBase = (
    decimalSeparator: string,
    allowDecimals: boolean,
    allowZero: boolean = false,
    allowNegative: boolean = true,
    padWithZero: number = 0
  ): string => {
    // Handle invalid number
    // =====================
    if (this.isNaN) return '';

    // Convert parts to BigInt
    // =======================
    const _integral = BigInt(this.integral);
    const _fractional = this.fractional === '' ? BigInt(0) : BigInt(this.fractional);

    // Handle zeros not allowed
    // ========================
    if (!allowZero && _integral === BigInt(0) && _fractional === BigInt(0)) return '';

    // Handle negative number not allowed
    // ==================================
    const _isNegative = !allowNegative ? false : this.isNegative;

    // Format number
    // =============

    // --> Format number to js format
    let result = `${_isNegative ? '-' : ''}${_integral}`;
    if (this.fractional !== '') result = result + '.' + this.fractional;

    // --> Remove decimals (if appropriate)
    if (allowDecimals !== undefined) {
      if (!allowDecimals && result.includes('.')) result = result.slice(0, result.indexOf('.'));
    } else if (this.numberOfDecimals === 0 && result.includes('.')) result = result.slice(0, result.indexOf('.'));

    // --> Replace js decimal point with requested decimal separator
    result = result.replace('.', decimalSeparator);

    // --> Restore leading zeros (if appropriate)
    if (padWithZero) result = result.padStart(padWithZero + result.length, '0');

    // --> Restore decimals
    if (this.numberOfDecimals > 0) {
      let currentNumberOfDecimals = 0;

      // ° initialize
      if (result.includes(decimalSeparator)) {
        currentNumberOfDecimals = result.length - result.indexOf(decimalSeparator) - 1;
      } else {
        result = `${result}${decimalSeparator}`;
      }

      // ° append zeros until requested number of decimals reached
      while (currentNumberOfDecimals < this.numberOfDecimals) {
        result = `${result}0`;
        currentNumberOfDecimals++;
      }
    }

    // Return result
    // =============
    return result;
  };

  public toString(): string {
    if (this.isNaN) return 'NaN';
    let result = `${this.isNegative ? '-' : ''}${BigInt(this.integral)}`;
    if (this.fractional !== '') {
      let _fractional = this.fractional.replace(/0*$/, ''); // remove trailing zeros
      if (_fractional !== '') result = result + '.' + _fractional;
    }
    return result;
  }

  public toNumber_UNSAFE(): number {
    // REMARK: "UNSAFE" suffix to draw attention of developers that this function (might) looses precision for
    // large numbers (eg pallet numbers are defined as integers with a length of 18 in Enterprise, this can NOT
    // be converted to a JS Number without loosing precision)
    if (this.isNaN) return NaN;
    let result = `${this.isNegative ? '-' : ''}${BigInt(this.integral)}`;
    if (this.fractional !== '') result = result + '.' + this.fractional;
    return Number(result);
  }

  public isEqualTo(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) === 0;
  }
  public isNotEqualTo(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) !== 0;
  }
  public isGreaterThan(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) > 0;
  }
  public isGreaterThanOrEqualTo(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) >= 0;
  }
  public isLessThan(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) < 0;
  }
  public isLessThanOrEqualTo(xtNum: XTNumber) {
    return XTNumber.compare(this, xtNum) <= 0;
  }

  public static compare(a: XTNumber, b: XTNumber): number {
    // Move not numeric ones to the end of the list
    // ============================================
    if (a.isNaN || b.isNaN) {
      if (a.isNaN) {
        if (b.isNaN) {
          return `${a.integral}.${a.fractional}`.localeCompare(`${b.integral}.${b.fractional}`);
        } else {
          return 1;
        }
      } else {
        return -1;
      }
    }

    // Handle values with different sign
    // =================================
    if (a.isNegative !== b.isNegative) {
      if (a.isNegative) {
        return -1;
      } else {
        return 1;
      }
    }

    // Compare integral parts
    // ======================

    // --> Prepare integral values to allow numeric compare
    const a_integral = BigInt(a.integral);
    const b_integral = BigInt(b.integral);

    // --> Handle different integral parts
    if (a_integral > b_integral) {
      return a.isNegative ? -1 : 1;
    } else if (a_integral < b_integral) {
      return a.isNegative ? 1 : -1;
    }

    // Compare fractional parts
    // ========================

    // --> Initialize fractional values to allow "numeric" compare
    let a_fractional = a.fractional === '' ? '0' : a.fractional;
    let b_fractional = b.fractional === '' ? '0' : b.fractional;

    // --> Append trailing zeros until both have the same length
    /*
      0.5 is greater than 0.08, but converting fractional parts to bigint would result in 5 < 8
      0.5 is equal to 0.50, but converting fractional parts to bigint would result in 5 < 50
      0.5 is equal to 0.50, but comparing the raw string value would also result in 5 < 50
    */
    if (a_fractional.length > b_fractional.length) {
      b_fractional = b_fractional.padEnd(a_fractional.length, '0');
    } else if (b_fractional.length > a_fractional.length) {
      a_fractional = a_fractional.padEnd(b_fractional.length, '0');
    }

    // --> Handle different fractional parts
    if (a_fractional > b_fractional) {
      return a.isNegative ? -1 : 1;
    } else if (a_fractional < b_fractional) {
      return a.isNegative ? 1 : -1;
    }

    // Handle values are identical
    // ===========================
    return 0;
  }
}
