import { FunctionComponent, useEffect, useMemo, useState, useCallback } from 'react';
import './index.scss';
import { useDropzone } from 'react-dropzone';
import { LoaderContainer, LoaderContainerType } from '../../components/LoaderContainer';
import { Localization } from '../../framework/localization/Localization';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import axios from 'axios';
import { Button } from '../../components/Button';

type FileUploadProps = {
  id?: string;
  startLoading?: Function;
  endLoading?: Function;
  CloseWindow?: Function;
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  // flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  minHeight: '20vh',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginTop: '20px',
  marginBottom: '10px'
};

const focusedStyle = {
  borderColor: '#00a3a5'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FileUpload: FunctionComponent<FileUploadProps> = (props) => {
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [status, setStatus] = useState({ show: false, message: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get('/client/fetch-folders')
      .then((res) => {
        setFolders(res.data);
        setSelectedFolder(res.data[0]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(`Error from FileUpload fetchfolders ${e}`);
      });
  }, []);

  const onDrop = useCallback((acceptedFiles: any, rejectFiles: any) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles(acceptedFiles);
      setStatus({ ...status, show: false });
    } else {
      setSelectedFiles([]);
      setStatus({ show: true, message: Localization.instance.getString('TXT_FileUploadWarning') });
    }
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles: 5
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const updateSelectedFiles = (id: number) => {
    let currentFiles = [...selectedFiles];
    let updatedFiles = currentFiles.filter((file, i) => i !== id);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = () => {
    setStatus({ show: true, message: Localization.instance.getString('TXT_Uploading') });
    let data = new FormData();
    selectedFiles.forEach((file: string | Blob) => {
      data.append('file', file);
    });
    data.append('folder', selectedFolder);
    axios
      .post('/client/file-upload', data)
      .then((res) => {
        if (res.status === 200) {
          setSelectedFiles([]);
          setStatus({ show: true, message: Localization.instance.getString('TXT_FileUploadSuccess') });
        }
      })
      .catch((e) => {
        setStatus({ show: true, message: Localization.instance.getString('TXT_FileUploadError') });
      });
  };

  const closeWindowHandler = () => {
    props.startLoading?.();
    props.CloseWindow?.(props.id, props.endLoading);
  };

  return !loading ? (
    <div className='file-upload-wrapper'>
      <section className='container'>
        <div className='select-folder'>
          <span className='select-label'>{Localization.instance.getString('TXT_SelectFolder')} :</span>
          <select
            className='select'
            value={selectedFolder}
            onChange={(e) => setSelectedFolder(e.target.value)}
            disabled={folders.length <= 0}
          >
            {folders.map((fname: any) => (
              <option value={fname}>{fname}</option>
            ))}
            )
          </select>
        </div>
        {folders.length <= 0 && (
          <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
            {Localization.instance.getString('TXT_SelectedFolderError')}
          </div>
        )}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <p>{Localization.instance.getString('TXT_DragNDrop')}</p>
        </div>
        {status.show === true && <div className='status'>{status.message}</div>}
        {selectedFiles.length !== 0 && (
          <div>
            <h5>{Localization.instance.getString('TXT_SelectedFiles')}</h5>
            <div className='file-list'>
              <ol type='1'>
                {selectedFiles.map((file: any, id: number) => (
                  <li key={id} style={{ fontSize: '14px', padding: '5px' }}>
                    <div className='list-item'>
                      {file.name} - {(file.size / 1024).toFixed(1)} kb
                      <button className='item-btn' onClick={() => updateSelectedFiles(id)}>
                        X
                      </button>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        )}
      </section>
      <div className='footer button-bar row no-gutters'>
        <button className='btn truncate btn btn-outline-dark btn-lg' onClick={() => closeWindowHandler()}>
          {Localization.instance.getString('TXT_Exit')}
        </button>
        <button
          className='btn truncate btn btn-outline-dark btn-lg'
          onClick={() => handleUpload()}
          disabled={selectedFiles.length === 0 || folders.length <= 0}
        >
          {Localization.instance.getString('TXT_Upload')}
        </button>
      </div>
    </div>
  ) : (
    <LoaderContainer loaderType={LoaderContainerType.ImageOnly} text={Localization.instance.getString('TXT_Loading')} />
  );
};
export default FileUpload;
