import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { LoadingSetters } from '..';

export const Logout = (props: LoadingSetters) => {
  const auth = useAuth();

  useEffect(() => {
    props.startLoading();
    sessionStorage.clear();
    console.log('logout');
    auth
      .signoutRedirect({
        redirectMethod: 'replace',
        id_token_hint: auth.user?.id_token,
        post_logout_redirect_uri: window.location.origin
      })
      .catch((error: Error) => console.error(error))
      .finally(() => {
        props.endLoading();
      });
  }, [props, auth]);
  return <p>Logout</p>;
};
