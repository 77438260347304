import React, { useState, FunctionComponent } from 'react';
import './index.scss';

type PicklistPrintProps = {
  handleClose?: () => void;
  shipmentData: any;
  onPicklistPrint: (arg: any) => void;
};

const PicklistPrint: FunctionComponent<PicklistPrintProps> = ({
  handleClose,
  shipmentData,
  onPicklistPrint
}) => {
  const [params, setParams] = useState({
    shipment: shipmentData.shipmentNumber
  });

  const handleChange = (e: any) => {};

  return (
    <React.Fragment>
      <div className='header'>
        <h5>Create pick lists</h5>
      </div>
      <div style={{ height: 'calc(100vh - 235px)', overflow: 'auto' }}>
        <div style={{ padding: '20px 10px 20px 50px' }}>
          <div style={{ marginBottom: '20px' }}>
            <label className='label'>Shipment number</label>
            <input className='uidOutputOnly' type='text' readOnly value={params.shipment} name='shipmentNumber' />
          </div>

          <div style={{ marginBottom: '15px' }}>
            <div>
              <label className='label'>Description</label>
              <input
                className='uidOutputOnly'
                type='text'
                readOnly
                value={shipmentData.description}
                name='description'
              />
            </div>
            <div>
              <label className='label'>Handler</label>
              <input className='uidOutputOnly' type='text' readOnly value={shipmentData.handler} name='handler' />
            </div>
            <div>
              <label className='label'>Dispatch date</label>
              <input className='uidOutputOnly' type='text' readOnly value={shipmentData.date} name='dispatchDate' />
            </div>
            <div>
              <label className='label'>Time</label>
              <input className='uidOutputOnly' type='text' readOnly value={shipmentData.time} name='time' />
            </div>
            <div>
              <label className='label'>Warehouse</label>
              <input className='uidOutputOnly' type='text' readOnly value={shipmentData.warehouse} name='warehouse' />
            </div>
          </div>
          {/* <div>
            <div>
              <label className="label">Printer queue</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Number of copies</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Hold on spool file</label>
              <input type="checkbox" />
            </div>
          </div> */}
        </div>
      </div>
      <div className='footer'>
        <button onClick={() => onPicklistPrint(params)}>Ok</button>
        <button onClick={handleClose}>Back</button>
      </div>
    </React.Fragment>
  );
};

export default PicklistPrint;
