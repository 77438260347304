import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Doughnut } from 'react-chartjs-2';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import { Localization } from '../../framework/localization/Localization';

const pieChart: CellPlugin = {
  Renderer: ({ data }: any) => {
    const [response, setResponse] = useState<any>({});
    const getData = async () => {
      const date = new Date();
      const millisec = date.getTime();
      const id = millisec.toString(36);
      let payload: any = {
        IptorAPI: '1.0',
        method: 'salesOrderHeaders.get',
        id: id
      };

      payload.params = {
        selectStatusFrom: 10,
        selectStatusTo: 50
      };

      let res = await axios
        .request({
          url: '/aperio/api/service',
          data: payload,
          method: 'POST'
        })
        .catch((err) => {
          console.error(`Error from pieChart ${err}`);
        });
      let { items }: any = res?.data?.data || { items: [] };
      let status: any = {};

      if (items) {
        items.forEach((item: any) => {
          if (status[item.orderStatus]) status[item.orderStatus] += item.netAmount;
          else status[item.orderStatus] = item.netAmount;
        });
      }
      setResponse({
        labels: Object.keys(status),
        data: Object.values(status)
      });
      // setResponse(res?.data || {});
    };
    useEffect(() => {
      getData();
    }, [data]);

    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'100%'} className={'low-pad'}>
          <Row>
            <Col sm={12}>
              <p>
                <strong>{data.title || Localization.instance.getString('DASHBOARD_Sales_Orders')}</strong>
              </p>
            </Col>
          </Row>
          <span style={{ flex: '1 1 100%' }}>
            <Doughnut
              data={{
                labels: response?.labels || [],
                datasets: [
                  {
                    label: data.title,
                    data: response?.data || [],
                    backgroundColor: [
                      '#00a3a5',
                      '#eb617c',
                      '#2f3c46',
                      '#60798b',
                      '#d45087',
                      '#f95d6a',
                      '#ff7c43',
                      '#ffa600'
                    ]
                  }
                ]
              }}
              options={{
                plugins: {
                  title: { display: false, text: '' },
                  legend: { position: 'bottom', labels: { color: '#AAA' } }
                },
                maintainAspectRatio: false,
                animation: { duration: 0 }
              }}
            />
          </span>
        </Widget>
      </div>
    );
  },
  id: 'pie-chart',
  title: 'Sales orders',
  description: 'Donut chart displaying sales order headers by status',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default pieChart;
