import React, { ChangeEvent, Component, KeyboardEvent as KEvent } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Form,
  InputGroup,
  Button,
  Modal,
  Badge,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap';
import axios, { AxiosResponse } from 'axios';
import { Company } from '../types/company';
import { User } from '../types/user';
import '../styles/Client.scss';
import { Program } from '../types/program';
import { WindowManger } from '../framework/base/windowManager';
import { Desktop } from './layouts/Desktop';
import { connect } from 'react-redux';
import { AuthType } from './Login';
import { Icons, SquareIcon } from '../components/SquareIcon';
import { SettingsActions, WindowActions } from '../types/actionTypes';
import hotkeys from 'hotkeys-js';
import { XT } from '../framework/handlers/xt';
import { LocalStorage } from '../framework/handlers/localStorage';
import { setDefaultLocale } from 'react-datepicker';
import { Localization } from '../framework/localization/Localization';
import { AuthTokenEmitter, LoadingSetters } from '../index';
import { Socket, io } from 'socket.io-client';
import { ImportantLinks } from './partials/ImportantLinks';
import withReactContent from 'sweetalert2-react-content';
import _Swal, { SweetAlertResult } from 'sweetalert2';
import '../styles/Dark.scss';
import '../styles/Light.scss';
import '../styles/DarkMode.scss';
import { jsPDF } from 'jspdf';
import { ClientContext } from '../App';
import { exportToClip } from '../components/helpers/exportUtils';
import ApplicationEvents from '../framework/ApplicationEvents';
import * as htmlparser2 from 'htmlparser2';
import { windowHotKeys, desktopHotkeys, clientHotkeys, enterHotkeys, enquiryHotKeys } from '../helpers/hotkeys';
import { withAuth } from 'react-oidc-context';
import { RequestError } from '../types/RequestError';
import ApplicationError from '../framework/ApplicationError';
import { base64 } from '../helpers/base64';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { NotesConnectionStatus } from '../framework/your-note/NotesProvider';

const Swal: any = withReactContent(_Swal);

type ClientProps = LoadingSetters & {
  company: Company;
  user: User;
  settings?: Record<string, any>;
  launch?: Function;
  updateSettings: Function;
  updateQuickLinks: Function;
  updateAutoLinks: Function;
  saveSettings: Function;
  quickLinks?: Program[];
  autoLinks?: Program[];
  enquiries?: Record<string, any>;
  loadClientSettings: Function;
  registerRequestError: Function;
  clientSettings: Record<string, any>;
  locale: Locale;
  isWindowOpened?: boolean;
  connectionInfo: Record<string, any>;
  versionInfo: Record<string, string>;
  loadEnquiryTemplateSettings: (enquiryTemplate: string, endLoading: Function) => {};
  appId: string;
};

type ClientState = {
  menu: Record<string, any>[];
  filter: string;
  menuOpen: boolean;
  menuStack: Record<string, any>[];
  currentMenu: Record<string, any>[];
  filteredMenu: Record<string, any>[];
  flattenedMenu?: Record<string, any>;
  filterCount?: number;
  loading?: boolean;
  quickLinksOpen: boolean;
  enquiriesOpen: boolean;
  isShowModal: boolean;
  isShowAbout: boolean;
  messages: string[];
  showImportantLinks: boolean;
  autoplayTooltipVisibleIndex: number;

  enquiryBottom: number;
  enquiryLeft: number;
  cb?: Function;
  connectionIssues: boolean;
};

class ClientComponent extends Component<ClientProps & AuthType, ClientState> {
  state: ClientState;
  enquiries: Record<string, Program> = {};
  socket?: Socket;
  minLettersToRun = 2;
  buttonRef: any;
  private enquiryRef: React.RefObject<HTMLDivElement>;
  private menuRef: React.RefObject<HTMLDivElement>;

  private hamburgerMenuRef: React.RefObject<HTMLAnchorElement>;
  private bookmarkRef: React.RefObject<HTMLAnchorElement>;
  private companyNameRef: React.RefObject<HTMLDivElement>;
  private mongoStatusRef: React.RefObject<HTMLDivElement>;
  private helpCenterRef: React.RefObject<HTMLDivElement>;
  private messageRef: React.RefObject<HTMLDivElement>;
  private useActionRef: React.RefObject<HTMLDivElement>;
  private enquiryBindingState: 'NOT_STARTED' | 'STARTED' | 'UNMOUNTED' = 'NOT_STARTED';
  constructor(props: ClientProps & AuthType) {
    super(props);
    this.state = {
      messages: [],
      filter: '',
      menuOpen: false,
      menu: [],
      currentMenu: [],
      menuStack: [],
      filteredMenu: [],
      loading: true,
      quickLinksOpen: false,
      enquiriesOpen: false,
      isShowModal: false,
      isShowAbout: false,
      showImportantLinks: false,
      enquiryBottom: 0,
      enquiryLeft: 0,
      connectionIssues: false,
      autoplayTooltipVisibleIndex: -1
    };
    this.logout = this.logout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.fetchMenu = this.fetchMenu.bind(this);
    this.navigateOrLaunch = this.navigateOrLaunch.bind(this);
    this.popMenu = this.popMenu.bind(this);
    this.initiateSearch = this.initiateSearch.bind(this);
    this.launch = this.launch.bind(this);
    this.quickLaunch = this.quickLaunch.bind(this);
    this.codeLaunch = this.codeLaunch.bind(this);
    this.selectEnquiryTemplate = this.selectEnquiryTemplate.bind(this);
    this.startExport = this.startExport.bind(this);
    this.stopExport = this.stopExport.bind(this);
    this.selectCompany = this.selectCompany.bind(this);
    this.updateToken = this.updateToken.bind(this);
    this.restoreFocus = this.restoreFocus.bind(this);
    this.resetMenuButton = this.resetMenuButton.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
    this.enquiryMenuOnMouseEnter = this.enquiryMenuOnMouseEnter.bind(this);
    this.enquiryRef = React.createRef();
    this.menuRef = React.createRef();
    this.hamburgerMenuRef = React.createRef();
    this.companyNameRef = React.createRef();
    this.mongoStatusRef = React.createRef();
    this.helpCenterRef = React.createRef();
    this.bookmarkRef = React.createRef();
    this.messageRef = React.createRef();
    this.useActionRef = React.createRef();
  }

  selectCompany(company: Company) {
    this.props.startLoading();
    window.history.replaceState(null, '', '/change');
    const userId = this.props.auth?.user?.profile.preferred_username || this.props.user.id;
    const environmentId = sessionStorage.getItem('env') || '';
    LocalStorage.LoginDefaults.save(userId, environmentId, company.id);
    ApplicationEvents.emit.companyChanged(company.id);
    sessionStorage.setItem('company', JSON.stringify(company));
    window.location.replace('/app');
  }

  checkDarkMode(e: any) {
    const newColorScheme = e.matches ? 'dark' : 'light';
    localStorage.setItem('default_theme', newColorScheme);
    if (localStorage.getItem('theme') === 'default')
      document.getElementsByTagName('HTML')[0].setAttribute('data-theme', newColorScheme);
  }

  isBase64(str: string) {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      // check whether given string is encoded or not
      if (base64.bytesToBase64(base64.base64ToBytes(str, false), false) === str.trim()) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  updateToken(token: string) {
    if (this.socket?.connected) {
      this.socket.emit('token_update', { token: token });
    }
  }

  componentDidMount() {
    this.enquiryBindingState = 'NOT_STARTED';
    AuthTokenEmitter.on('token', this.updateToken);
    const company = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';

    // Cover the case where bookmark includes: .../app               prevent get menu api to be launched without a company
    if (!company || Object.keys(company ?? {}).length === 0) {
      sessionStorage.clear(); // Ensure session is clear for a new login
      window.location.replace('/'); //Navigate to login
      return;
    }

    hotkeys.filter = (e) => {
      // if (e.key.toLocaleLowerCase() === 'f4') {
      //   console.log('');
      //   console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
      //   console.log('&&&  FILTER   &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
      //   console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&');
      //   console.log('&&&  ' + new Date());
      //   console.log('&&&  window id =              ' + this.props.id || '');
      //   console.log('&&&  is dialog =              ' + (this.props.isDialog || 'FALSE'));
      //   console.log('&&&  hotkeys scope =          ' + hotkeys.getScope() || '');
      //   console.log('&&&  event type =             ' + e.type);
      //   console.log('&&&  this.props.loading =     ' + (this.props.loading || false) + (this.props.loading ? ' ==> RETURN FALSE' : ''));
      //   console.log('&&&');
      // }
      if (this.props.loading) {
        return false;
      }

      if ((e.target as HTMLElement).closest('.import-modal')) {
        if (e.key.toLowerCase() === 'enter' || /^f[0-9]{1,2}/.test(e.key.toLowerCase())) {
          return false;
        }
      }

      if ((e.target as HTMLElement).getAttribute('data-event') === 'textarea') {
        if (e.key.toLowerCase() === 'enter' || e.key.toLowerCase() === 'pagedown' || e.key.toLowerCase() === 'pageup') {
          return false;
        }
      }
      // if (e.key.toLocaleLowerCase() === 'f4') {
      //   console.log('&&&  data-event =            ', (e.target as HTMLElement).getAttribute('data-event'));
      // };
      if ((e.target as HTMLElement).getAttribute('data-event') === 'table') {
        // if (e.key.toLocaleLowerCase() === 'f4') {
        //   console.log('&&&  ==> TABLE MODE');
        //   if (e.key.toLocaleLowerCase() === 't' && e.altKey && !(e.ctrlKey || e.metaKey)) {
        //     console.log('&&&  ==> HANDLE KEYBOARD EVENT <ALT+T>');
        //   } else if (/^f[0-9]{1,2}/.test(e.code.toLowerCase())) {
        //     console.log('&&&  ==> HANDLE KEYBOARD EVENT ' + e.code);
        //   } else {
        //     console.log('&&&  ==> IGNORE KEYBOARD EVENT ' + e.code);
        //   }
        // }
        if (
          e.key.toLowerCase() === 'enter' ||
          e.key.toLowerCase() === 'pagedown' ||
          e.key.toLowerCase() === 'pageup' ||
          e.key.toLowerCase() === 'up' ||
          e.key.toLowerCase() === 'down' ||
          (e.key.toLocaleLowerCase() === 'f1' && e.altKey && !(e.ctrlKey || e.metaKey))
        ) {
          return false;
        }
        return true;
      }
      // if (e.key.toLocaleLowerCase() === 'f4') {
      //   console.log('&&&  ==> GENERAL MODE');
      //   if ((e.target as HTMLElement).getAttribute('data-event') !== 'ignore') {
      //     console.log('&&&  ==> HANDLE KEYBOARD EVENT ' + e.code);
      //   } else {
      //     console.log('&&&  ==> IGNORE KEYBOARD EVENT ' + e.code);
      //   }
      // }
      let allowHotKeys = false;
      const pressedKeys = [e.key.toLowerCase() === ' ' ? 'space' : e.key.toLowerCase()];
      if (e.ctrlKey) pressedKeys.push('ctrl');
      if (e.altKey) pressedKeys.push('alt');
      if (e.shiftKey) pressedKeys.push('shift');
      if (e.metaKey) pressedKeys.push('cmd');
      let keyBindings = [
        ...windowHotKeys,
        ...desktopHotkeys,
        ...clientHotkeys,
        ...enquiryHotKeys,
        ...enterHotkeys
      ].map((k) => k.split('+'));
      for (let keyBinding of keyBindings) {
        if (keyBinding.length === pressedKeys.length) {
          allowHotKeys = pressedKeys.every((key) =>
            keyBinding.map((k: string) => k.toLowerCase()).includes(key.toLowerCase())
          );
          if (allowHotKeys) {
            break;
          }
        }
      }
      return allowHotKeys && (e.target as HTMLElement).getAttribute('data-event') !== 'ignore';
    };

    this.socket = io({
      reconnection: false,
      extraHeaders: {
        sessions: sessionStorage.getItem('eui_session_id') || '',
        authorization: this.props.auth?.user?.access_token || '',
        company: company ? company.id : '',
        'x-env': sessionStorage.getItem('env') || '',
        'xt-cid': this.props.appId || '' // xt-cid header defined on the XT side, to identify requests from specific App instances /  browser tabs
      }
    });

    this.socket.on('export:done', ({ session, window, type }: any) => {
      axios
        .get(`/window/${session}/${window}/export/${type}`, { responseType: 'blob' })
        .then(async (res) => {
          const link = document.createElement('a');
          const url = URL.createObjectURL(res.data);
          link.setAttribute('href', url);
          link.setAttribute('download', `iptor_query_export.${type}`);
          link.setAttribute('target', '_blank');
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
          // this.props.endLoading();
          // this.state.cb?.();
          // document.body.removeAttribute('exporting');
        })
        .finally(() => {
          document.body.removeAttribute('exporting');
          this.props.endLoading();
          // this.state.cb?.();
        });
      // const link = document.createElement('a');
      // link.setAttribute('href', `/window/${session}/${window}/export/${type}`);
      // link.setAttribute('download', `iptor_query_export.${type}`);
      // link.setAttribute('target', '_blank');
      // link.click();
      // link.remove();
      // this.props.endLoading();
      // WindowManger.CloseWindow(res.data.window_id, endLoading);
    });
    this.socket.on('eof:done', ({ session, window, type }: any) => {
      /*if(type === 'pdf') {
        axios
          .get(`/window/${session}/${window}/export/pdf`)
          .then((res) => {
            const lines = res.data.split('\n');

            const columns = lines[0]?.split(',');
            const rows = lines.slice(1)?.map((x: string) => x?.split(','));

            //--XT Copy
            exportToPdf(columns, rows, 'export.pdf');
          })
          .finally(() => {
            document.body.removeAttribute('exporting');
            this.props.endLoading();
          });
      } else*/
      axios
        .get(`/window/${session}/${window}/export/${type}`, { responseType: 'blob' })
        .then(async (res) => {
          if (type === 'clipboard') {
            const ROW_SEP = '^#^';
            const COL_SEP = '^¤^';
            const text = (await res.data.text()) ?? '';
            const lines = text.split('\n');
            let optionalHeader = [''];
            if (text.startsWith('exportHeaderRows')) {
              optionalHeader = text.split(ROW_SEP).splice(1);
            }

            const columns = lines[optionalHeader.length > 1 ? 1 : 0]?.split(',');
            const rows = lines.splice(optionalHeader.length > 1 ? 2 : 1).map((x: string) => x?.split(COL_SEP));
            optionalHeader.pop();

            //--XT Copy
            exportToClip(columns, rows, optionalHeader);
          } else {
            const link = document.createElement('a');
            const url = URL.createObjectURL(res.data);
            link.setAttribute('href', url);
            link.setAttribute('download', `iptor_end_of_file_export.${type}`);
            link.setAttribute('target', '_blank');
            link.click();
            URL.revokeObjectURL(url);
            link.remove();
            // this.props.endLoading();
            // this.state.cb?.();
            // document.body.removeAttribute('exporting');
          }
        })
        .finally(() => {
          document.body.removeAttribute('exporting');
          this.props.endLoading();
          this.state.cb?.();
        });
      // WindowManger.CloseWindow(res.data.window_id, endLoading);
    });
    this.socket.on('pdf:done', ({ data, window: windowID, session }: any) => {
      axios
        .get(`/window/${session}/${windowID}/export/html`)
        .then((res) => {
          let printOnly = document.createElement('div');
          const parseData = htmlparser2.parseDocument(res.data);
          const innerChild = Object.values(parseData.children[0]);
          const responseData = innerChild[5][0].data;
          let objectData;
          let innerChildData;
          if (responseData.startsWith('{')) {
            objectData = JSON.parse(responseData);
            innerChildData = objectData.base64;
          } else {
            innerChildData = responseData;
          }
          let baseStr64Image = 'data:image/png;base64,';
          if (this.isBase64(innerChildData)) {
            baseStr64Image = baseStr64Image + innerChildData;
          } else {
            printOnly.innerHTML = res.data;
          }
          //--XT Copy
          const { height, width, fontSize, lineHeight } = XT.computePageSize(data?.Attributes?.$);

          var doc = new jsPDF({
            orientation: width > height ? 'l' : 'p',
            unit: 'pt',
            format: [height, width],
            putOnlyUsedFonts: true
          });
          doc.setFont('Courier');
          doc.setFontSize(fontSize);
          doc.setLineHeightFactor(1.5);
          if (this.isBase64(innerChildData)) {
            let imageWidth = objectData.width;
            let imageHeight = objectData.height;
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            if (imageHeight > pageHeight || imageWidth > pageWidth) {
              const ratioH = imageHeight / pageHeight;
              const ratioW = imageWidth / pageWidth;
              const ratio = ratioH > ratioW ? ratioH : ratioW;
              imageWidth = imageWidth / ratio;
              imageHeight = imageHeight / ratio;
            }

            doc.addImage(baseStr64Image, 'PNG', 0, 0, imageWidth, imageHeight);
          } else {
            let pages = printOnly.querySelectorAll('.page');
            pages.forEach((element, index) => {
              doc.text((element as HTMLElement).innerText, 36, 30);
              if (index < pages.length - 1) doc.addPage();
            });
          }
          doc.save('SpooledFile.pdf');
        })
        .finally(() => {
          document.body.removeAttribute('exporting');
          this.props.endLoading();
        });
    });
    const setExtraHeadersForSocket = () => {
      if (this.socket) {
        this.socket.io.opts.extraHeaders = {
          sessions: sessionStorage.getItem('eui_session_id') || '',
          authorization: this.props.auth?.user?.access_token || '',
          'xt-authorization': sessionStorage.getItem('xt-authorization') || '',
          'x-env': sessionStorage.getItem('env') || '',
          'xt-cid': this.props.appId || '' // xt-cid header defined on the XT side, to identify requests from specific App instances /  browser tabs
        };
      }
    };
    const tryReconnect = () => {
      setTimeout(() => {
        let isTokenRefreshFailed: boolean = false;

        if (this.props.auth?.user?.expires_in && this.props.auth?.user?.expires_in < 15) {
          this.props.auth
            .signinSilent()
            .then(() => {
              console.log('refreshed auth token: reconnecting socket');
              setExtraHeadersForSocket();
            })
            .catch((err: any) => {
              isTokenRefreshFailed = true;
              console.error(`error on update auth token: ${JSON.parse(err)}`);
              tryReconnect();
            });
        } else {
          console.log('auth token not expired: reconnecting socket');
          setExtraHeadersForSocket();
        }
        if (!isTokenRefreshFailed) {
          this.socket?.io.open((err: any) => {
            if (err) {
              tryReconnect();
            }
          });
        }
      }, 5000);
    };
    this.socket.on('disconnect', (reason) => {
      if (reason !== 'io client disconnect') {
        tryReconnect();
        setTimeout(() => {
          if (this.socket?.disconnected && document.body.hasAttribute('exporting')) {
            this.props.endLoading();
            Swal.fire({
              title: Localization.instance.getString('SWAL_EXPORT_FAILED_TITLE'),
              text: Localization.instance.getString('SWAL_EXPORT_FAILED_TEXT'),
              icon: 'error',
              confirmButtonColor: '#00a3a5',
              cancelButtonColor: '#00a3a5',
              showCancelButton: false,
              allowOutsideClick: false,
              stopKeydownPropagation: false,
              confirmButtonText: Localization.instance.getString('TXT_OK')
            });
            document.body.removeAttribute('exporting');
          }
        }, 120000); // Update error timeout to 120 seconds
      }
    });
    this.socket.on('export:error', (data: any) => {
      let requestError: RequestError | undefined = undefined;
      try {
        if (data?.error) {
          const errorInfo = RequestError.ErrorInfo.fromJSON(data.error);
          requestError = RequestError.createFromExportErrorInfo(errorInfo);
        } else {
          requestError = RequestError.createFromApplicationError(
            ApplicationError.createProgrammingError('Export error info expected'),
            'ERR_Export_failed',
            false
          ); // NTH: logging
        }
      } catch (err: any) {
        requestError = RequestError.createFromError(err, 'ERR_Export_failed', false); // NTH: logging
      } finally {
        if (requestError) this.props.registerRequestError(requestError);
      }
      document.body.removeAttribute('exporting');
      this.props.endLoading();
    });
    this.socket.on('mongo:off', () => {
      if (this.state.connectionIssues !== true) {
        this.setState({ connectionIssues: true });
      }
    });
    this.socket.on('mongo:on', () => {
      if (this.state.connectionIssues !== false) {
        this.setState({ connectionIssues: false });
      }
    });
    localStorage.setItem('default_theme', 'light');
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      localStorage.setItem('default_theme', 'dark');
      if (localStorage.getItem('theme') === 'default')
        document.getElementsByTagName('HTML')[0].setAttribute('data-theme', 'dark');
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.checkDarkMode);
    this.fetchMenu();
    //Global hotkeys
    hotkeys('cmd+space,ctrl+space,ctrl+q,cmd+q', { keyup: true, keydown: true, scope: 'all' }, (event, handler) => {
      if (event.type === 'keyup' && !event.repeat) {
        switch (handler.key) {
          case 'ctrl+space' || 'cmd+space':
            if (this.state.menuOpen) {
              this.toggleMenu();
            } else if (!XT.isApplicationInModalMode(this.props.loading)) {
              this.toggleMenu();
            }
            break;
          case 'ctrl+q' || 'cmd+q':
            if (!XT.isApplicationInModalMode(this.props.loading)) this.logout();
            break;
          default:
            break;
        }
      }
      return false;
    });
    this.socket?.on('messages', (ev: { messages: string[] }) => {
      this.setState({ messages: [...this.state.messages, ...ev.messages] });
    });
    this.hamburgerMenuRef.current?.removeAttribute('href');
    this.bookmarkRef.current?.removeAttribute('href');
    this.companyNameRef.current?.children[0].removeAttribute('href');
    this.mongoStatusRef.current?.children[0].removeAttribute('href');
    this.helpCenterRef.current?.children[0].removeAttribute('href');
    this.messageRef.current?.children[0].removeAttribute('href');
    this.useActionRef.current?.children[0].removeAttribute('href');
    // this.props.autoLinks?.forEach((a) => {
    //   this.launch(a);
    // });
    // document.addEventListener('beforeunload', () => {
    //   this.props.startLoading();
    //   axios
    //     .post('/settings', { settings: this.props.clientSettings })
    //     .then(() => {
    //       this.props.endLoading();
    //     })
    // });
  }

  // shouldComponentUpdate(nextProps: Readonly<ClientProps>, nextState: Readonly<{}>, nextContext: any): boolean {
  //   return !nextProps.loading;
  // }

  componentDidUpdate(prevProps: ClientProps, prevState: ClientState): void {
    if (
      (prevState.showImportantLinks && !this.state.showImportantLinks) ||
      (prevState.menuOpen && !this.state.menuOpen) ||
      (prevState.isShowModal && !this.state.isShowModal) ||
      (prevState.isShowAbout && !this.state.isShowAbout)
    ) {
      setTimeout(this.restoreFocus, 0);
    }
    if (!prevState.isShowAbout && this.state.isShowAbout)
      setTimeout(() => {
        this.buttonRef?.focus();
      }, 0);
    if (prevProps.enquiries !== this.props.enquiries) {
      this.unbindEnquiryTemplateKeys();
      this.bindEnquiryTemplateKeys();
    }
  }
  bindEnquiryTemplateKeys() {
    const enqBindings: string[] = [];
    this.props.enquiries?.top?.enquiries?.[0]?.enq
      ?.filter((menu: any) => !!menu.$.desc.trim())
      .forEach((menu: any) => {
        this.enquiries[`shift+f${+menu.$.cmd.replace('F', '') - 12}`] = { id: menu.$.cmd, text: menu.$.desc };
        enqBindings.push(`shift+f${+menu.$.cmd.replace('F', '') - 12}`);
      });
    hotkeys(enqBindings.join(','), { keyup: true, keydown: true, scope: 'all' }, (event, handler) => {
      if (
        sessionStorage.getItem('enquiry_window') === 'current' &&
        ['dashboard', 'all'].indexOf(hotkeys.getScope()) === -1
      ) {
        return;
      }
      if (event.type === 'keydown' && !event.repeat) {
        let enq = this.enquiries[handler.shortcut];
        if (enq) this.quickLaunch(enq, 'command');
      }
      return false;
    });
  }
  unbindEnquiryTemplateKeys() {
    hotkeys.unbind(enquiryHotKeys.join(','), 'all');
  }
  restoreFocus() {
    let _desktop = document.querySelector('.desktop');
    if (_desktop) {
      (_desktop as any).directFocus = true;
      (_desktop as HTMLElement).focus();
    }
  }

  componentWillUnmount() {
    this.socket?.disconnect();
    this.enquiryBindingState = 'UNMOUNTED';
    hotkeys.unbind([...enquiryHotKeys, ...clientHotkeys].join(','), 'all');
    AuthTokenEmitter.off('token', this.updateToken);
  }

  getFilteredMenu = (target: HTMLInputElement) => {
    const vals = target.value.trim().toLowerCase().split(' ');

    let filteredMenu: any[] = [];
    let count = 0;
    for (let key in this.state.flattenedMenu) {
      let value = this.state.flattenedMenu[key].toString();
      let matchAllWords = vals.map((val) => !!(value.toLowerCase().indexOf(val) > -1)).reduce((a, b) => a && b);

      if (key.indexOf('text') > -1) {
        if (matchAllWords && this.state.flattenedMenu[key.replace('text', 'id')]) {
          let id = this.state.flattenedMenu[key.replace('text', 'id')];
          let view = this.state.flattenedMenu[key.replace('text', 'view')];
          if (filteredMenu.filter((x: any) => x.text.toLowerCase() === value.toLowerCase()).length === 0) {
            count++;
            filteredMenu.push({
              text: value,
              id: id,
              view: view
            });
          }
        }
      } else if (key.indexOf('id') > -1) {
        if (matchAllWords && this.state.flattenedMenu[key.replace('id', 'text')]) {
          if (filteredMenu.filter((x: any) => x.id.toLowerCase() === value.toLowerCase()).length === 0) {
            count++;
            let text = this.state.flattenedMenu[key.replace('id', 'text')];
            let view = this.state.flattenedMenu[key.replace('id', 'view')];
            filteredMenu.push({
              id: value,
              text: text,
              view: view
            });
          }
        }
      }
    }
    if (!!target.value.trim()) return { filteredMenu: filteredMenu, filterCount: count };
    else return { filteredMenu: [], filterCount: undefined };
  };

  initiateSearch(e: ChangeEvent) {
    this.setState({ filter: (e.target as HTMLInputElement).value, filterCount: 0, filteredMenu: [] });
    setTimeout(() => {
      const trimmedValue = (e.target as HTMLInputElement).value.replaceAll(' ', '');
      if (trimmedValue.length >= this.minLettersToRun) {
        const { filteredMenu, filterCount } = this.getFilteredMenu(e.target as HTMLInputElement);
        this.setState({ filteredMenu: filteredMenu, filterCount: filterCount });
      }
    }, 500);
  }

  flatten(data: Record<string, any>) {
    let result: any = {};

    let recurse = (cur: any, prop: any) => {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        let l: number = 0;
        for (let i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
        if (l === 0) result[prop] = [];
      } else {
        let isEmpty = true;
        for (let p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) result[prop] = {};
      }
    };

    recurse(data, '');
    return result;
  }

  fetchMenu() {
    this.props.startLoading();
    axios
      .get('/client/menu')
      .then(async (res: AxiosResponse) => {
        if (res?.data?.menu?.['form']?.['ctrl']?.[0]?.['value']?.length > 0) {
          this.setState({
            menu: res.data.menu['form']['ctrl'][0],
            menuStack: [res.data.menu['form']['ctrl'][0]],
            currentMenu: res.data.menu['form']['ctrl'][0]['value'].map((x: any, i: number) => {
              x.key = i;
              return x;
            }),
            flattenedMenu: this.flatten(res.data.menu)
          });
        } else {
          throw Error(`Error when fetching data from '/client/menu' endpoint`);
        }
        const constValueToFormatMiliseconds = 36; //TODO: get it from config
        const date = new Date();
        const millisec = date.getTime();
        const id = millisec.toString(constValueToFormatMiliseconds);
        const payload = {
          IptorAPI: '1.0',
          method: 'apiUserProfile.get',
          id: id
        };
        let _res;
        try {
          _res = await axios.post(`/aperio/api/service`, payload);
        } catch (error) {
          console.error(`Error from fetchMenu step1 ${error}`);
        }
        let payload2 = {
          IptorAPI: '1.0',
          method: 'buildNumber.get',
          id: id
        };
        let resBuildNumber;
        try {
          resBuildNumber = await axios.post(`/aperio/api/service`, payload2);
        } catch (error) {
          console.error(`Error from fetchMenu step2 ${error}`);
        }
        payload2 = {
          IptorAPI: '1.0',
          method: 'apiMetadataInfo.get',
          id: id
        };
        let resMetadataInfo;
        try {
          resMetadataInfo = await axios.post(`/aperio/api/service`, payload2);
        } catch (error) {
          console.error(`Error from fetchMenu step3 ${error}`);
        }
        res.data.settings.fullName = _res?.data?.data?.text || res.data.settings?.regionals?.user[0].$.userId;
        this.props.updateSettings({
          settings: res.data.settings,
          driverList: res.data.driverList,
          session: !this.props.isWindowOpened ? res.data.session : undefined, // Protection needed for strict mode only (as code running twice)
          attachments: res.data.attachments,
          enquiries: res.data.enquiries,
          dashboard: res.data.dashboard,
          connectionInfo: res.data.connectionInfo,
          //versionInfo: res.data.versionInfo
          versionInfo: { ...res.data.versionInfo, ...resBuildNumber?.data?.data, ...resMetadataInfo?.data?.data }
        });
        this.props.loadClientSettings(res.data.clientSettings);
        this.setState({ loading: false });
        //Load required configs
        await XT.loadConfigs();
        if (this.enquiryBindingState !== 'UNMOUNTED' && this.enquiryBindingState !== 'STARTED') {
          this.enquiryBindingState = 'STARTED';
          let enqBindings: string[] = [];
          this.props.enquiries?.top?.enquiries?.[0]?.enq
            ?.filter((menu: any) => !!menu.$.desc.trim())
            .forEach((menu: any) => {
              this.enquiries[`shift+f${+menu.$.cmd.replace('F', '') - 12}`] = { id: menu.$.cmd, text: menu.$.desc };
              enqBindings.push(`shift+f${+menu.$.cmd.replace('F', '') - 12}`);
            });
          hotkeys(enqBindings.join(','), { keyup: true, keydown: true, scope: 'all' }, (event, handler) => {
            if (
              sessionStorage.getItem('enquiry_window') === 'current' &&
              ['dashboard', 'all'].indexOf(hotkeys.getScope()) === -1
            )
              return;
            // console.log(this.props);
            if (event.type === 'keydown' && !event.repeat) {
              // if (handler.shortcut.toLowerCase() === 'alt+f1') {
              //   const urlOpenedInNewTab = window.open('https://dc1help.iptor.com/r10/');
              //   if (urlOpenedInNewTab) urlOpenedInNewTab.opener = null;
              //   return false;
              // }
              let enq = this.enquiries[handler.shortcut];
              if (enq) this.quickLaunch(enq, 'command');
            }
            return false;
          });
        }
        this.props.endLoading();
        setDefaultLocale(this.props.locale.toString());
        this.socket?.emit('message', {
          command: 'START_POLLING',
          user: res.data.settings?.regionals?.user[0].$.userId
        });
        if (!this.props.isWindowOpened) {
          for (let index in this.props.quickLinks) {
            const item = this.props.quickLinks[index as any];
            if (item.isAutoStart) {
              await this.quickLaunch(item);
              // await sleep(1);
            }
          }
        }
      })
      .catch((e: Error) => {
        console.error(e);
        Swal.fire({
          title: Localization.instance.getString('MENU_FETCH_FAILED_TITLE'),
          text: Localization.instance.getString('MENU_FETCH_FAILED_TEXT'),
          icon: 'error',
          confirmButtonColor: '#00a3a5',
          showCancelButton: false,
          allowOutsideClick: false,
          stopKeydownPropagation: false,
          confirmButtonText: Localization.instance.getString('TXT_OK')
        }).then((result: SweetAlertResult) => {
          if (result?.isConfirmed) window.location.replace('/logout');
        });
      })
      .finally(() => {
        this.props.endLoading();
      });
  }

  startExport(windowID: string, title: string, cb: Function) {
    this.setState({ cb: cb });
    // this.socket?.off('eof:done');
    // this.socket?.on('eof:done', ({ data, doNotReload }: Record<string, any>) => {
    //   cb(data, doNotReload);
    // });
    // this.socket.off(windowID);
    // this.socket.emit('message', {
    //   command: 'START_EXPORT',
    //   windowID,
    //   xml: command,
    //   disabledOn,
    //   authorization: sessionStorage.getItem('xt-authorization') || ''
    // });
    // this.socket.on(windowID, (ev: { command: string; data: Record<string, any>; error: string }) => {
    //   if (!!ev.error) {
    //     Swal.fire({
    //       text: ev.error,
    //       icon: 'warning',
    //       confirmButtonColor: '#00a3a5',
    //       cancelButtonColor: '#00a3a5',
    //       showCancelButton: false,
    //       allowOutsideClick:  false,
    //       confirmButtonText: Localization.instance.getString('TXT_OK')
    //     });
    //     cb({ error: ev.error });
    //     return;
    //   }
    //   if (!ev.data) ev.data = {};
    //   ev.data.command = ev.command;
    //   cb(ev.data);
    //   if (['COMPLETE', 'APPEND'].indexOf(ev.command) === -1) {
    //     // debugger;
    //     this.socket.off(windowID);
    //   }
    // });
  }

  stopExport(windowID: string, abort?: boolean) {
    this.socket?.emit('message', { command: 'STOP_EXPORT' + (abort ? '_ABORT' : ''), windowID });
  }

  selectEnquiryTemplate(enquiryTemplate: string) {
    this.toggleMenu();
    this.props.startLoading();
    this.props.loadEnquiryTemplateSettings(enquiryTemplate, this.props.endLoading);
  }

  toggle(): void {
    this.setState({ ...this.state, isShowModal: !this.state.isShowModal });
  }

  toggleAbout(): void {
    this.setState({ ...this.state, isShowAbout: !this.state.isShowAbout });
  }

  logout() {
    //TODO: Update with session handling
    if (this.props.isWindowOpened) {
      this.toggle();
    } else {
      WindowManger.logout(this.props.startLoading, this.props.endLoading);
    }
  }

  toggleMenu() {
    setTimeout(() => {
      if (this.state.menuOpen) (document.querySelector('#search-input.form-control') as HTMLInputElement).focus();
    }, 200);
    this.setState({
      menuOpen: !this.state.menuOpen,
      filter: '',
      filteredMenu: [],
      filterCount: 0
    });
  }

  popMenu() {
    let menu: Record<string, any> = this.state.menu;
    let stack = this.state.menuStack;
    if (stack[stack.length - 1].value !== menu['value']) {
      stack.pop();
      this.setState({
        currentMenu: stack[stack.length - 1]['value'].map((x: any, i: number) => {
          x.key = i;
          return x;
        }),
        menuStack: stack
      });
    } else if (this.state.enquiriesOpen) {
      this.setState({ enquiriesOpen: false });
    } else if (stack.length < 1 || stack[stack.length - 1].value === menu['value']) {
      this.toggleMenu();
    }
  }

  navigateOrLaunch(menu: any) {
    //Check if folder
    //TODO: Confirm logic
    if (!!menu['value']) {
      let stack = this.state.menuStack;
      stack.push(menu);
      this.setState({
        currentMenu: menu['value'].map((x: any, i: number) => {
          x.key = i;
          return x;
        }),
        menuStack: stack
      });
    } else {
      this.launch(menu.$);
    }
  }

  async launch(program: Program) {
    if (program.view === 'QueryManagerView' || program.view === 'BrowserView' || program.view === 'DashboardView') {
      if (program.view === 'QueryManagerView') {
        this.quickLaunch(program, 'aperio_program');
      } else if (program.view === 'DashboardView') {
        // const constValueToFormatMiliseconds = 36; //TODO: get it from config
        // const date = new Date();
        // const millisec = date.getTime();
        // const id = millisec.toString(constValueToFormatMiliseconds);
        // const payload = {
        //   IptorAPI: '1.0',
        //   method: 'user.get',
        //   id: id
        // };
        // let _res = await axios.post(`/aperio/api/service`, payload);
        // sessionStorage.setItem(
        //   'persist:root',
        //   `{"auth":${JSON.stringify(
        //     JSON.stringify({
        //       login: true,
        //       loading: false,
        //       error: false,
        //       user: _res.data.data
        //     })
        //   )},"_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}`
        // );
        // const urlOpenedInNewTab = window.open(program.id.slice(0, -11));
        // if (urlOpenedInNewTab) urlOpenedInNewTab.opener = null;
        this.quickLaunch(program, 'aperio_program');
      } else {
        const urlOpenedInNewTab = window.open(program.id);
        if (urlOpenedInNewTab) urlOpenedInNewTab.opener = null;
      }
    } else if (program.view === '*ApplicationLink') {
      let [appID, launchID] = program.id?.split('|') || [-1, -1];
      let application = XT.applicationLinks?.applications?.find((app: any) => app.id === appID);
      if (application) {
        let launcher = application.launcher.find((launch: any) => launch.id === launchID);
        if (launcher) {
          let url = '';
          let launcherKey = launcher.parameter;
          //?is there any other param apart 1,2,.. and company????
          if (launcherKey.includes('|company|')) {
            url = launcherKey.replace('|company|', this.props.company.id || '');
          } else {
            url = launcherKey;
          }
          const aurl = window.URL.createObjectURL(new Blob([`[Command]\nArguments=${url}`]));
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = aurl;
          // the filename you want
          a.download = 'Start_PlannerView.ipv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(aurl);
          a.remove();
        }
      }
    } else this.quickLaunch(program);
    this.toggleMenu();
  }

  async quickLaunch(program: Program, type = 'program') {
    this.props.startLoading();
    await (this.props.launch && this.props.launch(program, this.props.endLoading, type));
    this.setState({
      quickLinksOpen: false,
      enquiriesOpen: false,
      menuOpen: false,
      filter: '',
      filteredMenu: [],
      filterCount: 0
    });
  }

  codeLaunch(e: KEvent) {
    if (e.code.toLowerCase().includes('enter')) {
      let target = e.target as HTMLInputElement;
      let program: Program | null = null;
      for (let key in this.state.flattenedMenu) {
        if (key.indexOf('id') > -1) {
          let value = this.state.flattenedMenu[key];
          if (
            value.toLowerCase() === target.value.toLowerCase() &&
            this.state.flattenedMenu[key.replace('id', 'text')]
          ) {
            program = {
              id: value,
              text: this.state.flattenedMenu[key.replace('id', 'text')],
              view: this.state.flattenedMenu[key.replace('id', 'view')]
            };
          }
        }
      }
      if (!!program) {
        this.launch(program);
      }
    }
  }

  resetMenuButton() {
    let menu: Record<string, any> = this.state.menu;
    setTimeout(() => {
      if (this.state.menuOpen) (document.querySelector('#search-input.form-control') as HTMLInputElement).focus();
    }, 200);
    this.setState({
      menuOpen: true,
      currentMenu: menu['value'].map((x: any, i: number) => {
        x.key = i;
        return x;
      }),
      menuStack: [this.state.menu],
      filter: '',
      filteredMenu: [],
      filterCount: 0
    });
  }

  getMenuItems(menuId: string, flattenedMenu: Record<string, any> | undefined) {
    let program: Program | null = null;
    for (let key in flattenedMenu) {
      if (key.indexOf('id') > -1) {
        let value = flattenedMenu[key];
        if (value.toLowerCase() === menuId.toLowerCase() && flattenedMenu[key.replace('id', 'text')]) {
          program = {
            id: value,
            text: flattenedMenu[key.replace('id', 'text')],
            view: flattenedMenu[key.replace('id', 'view')]
          };
        }
      }
    }
    return program;
  }

  enquiryMenuOnMouseEnter() {
    this.setState({
      enquiryBottom: this.enquiryRef?.current?.getBoundingClientRect().bottom || 0,
      enquiryLeft: this.enquiryRef?.current?.getBoundingClientRect().width || 0
    });
    this.menuRef?.current?.classList?.add('firefox-scrollbar-width');
  }

  render() {
    //TODO: Update in static layout sprints (Move individual components to partials)
    return (
      <DndProvider backend={HTML5Backend}>
        <ClientContext.Provider
          value={{
            startLoading: this.props.startLoading,
            endLoading: this.props.endLoading,
            flattenedMenu: this.state.flattenedMenu,
            getMenuItems: this.getMenuItems
          }}
        >
          <div
            className='client-div'
            onKeyDown={(e) => {
              if (e.code.toLocaleLowerCase() === 'numpaddecimal') {
                e.preventDefault();
                e.stopPropagation();
                if (
                  document.activeElement &&
                  ['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase())
                ) {
                  type textElmType = HTMLInputElement | HTMLTextAreaElement;
                  const activeElement = document.activeElement as textElmType;
                  const selectionStart = activeElement.selectionStart ?? 0;
                  const selectionEnd = activeElement.selectionEnd ?? selectionStart;
                  const nativeInputValueSetter =
                    document.activeElement.tagName.toLowerCase() === 'input'
                      ? Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set
                      : Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value')?.set;
                  const currValue = activeElement.value;
                  const newValue =
                    currValue.slice(0, selectionStart) +
                    Localization.instance.decimalSeparator +
                    currValue.slice(selectionEnd);
                  nativeInputValueSetter?.call(activeElement, newValue);
                  const newCursorPosition = selectionStart + Localization.instance.decimalSeparator.length;
                  activeElement.setSelectionRange(newCursorPosition, newCursorPosition);
                  activeElement.dispatchEvent(new Event('change', { bubbles: true }));
                }
              }
            }}
            onKeyUp={(e) => {
              if (e.code.toLocaleLowerCase() === 'numpaddecimal') {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <div hidden={!this.state.menuOpen} className='overlay-full' onClick={this.toggleMenu} />
            <div
              className={`slide-out-menu ${this.state.menuOpen ? 'open' : ''} ${this.state.loading ? 'loading' : ''}`}
            >
              <h6 className='truncate'>
                <span onClick={this.popMenu}>
                  <SquareIcon className='icon-grey-fill menu-back-btn' size='22px'>
                    {Icons.LeftArrowCircle}
                  </SquareIcon>
                </span>
                {Localization.instance.getString('View_menu')}
                {`${
                  this.state.menuStack.length > 1
                    ? (this.state.menuStack.length > 2 ? '/ ... / ' : '/ ') +
                      this.state.menuStack[this.state.menuStack.length - 1]['$']['text']
                    : ''
                }`}
                <button className='btn btn-primary pl-4 pr-4 ml-4 menu-btn' onClick={this.resetMenuButton}>
                  {Localization.instance.getString('TXT_Reset')}
                </button>
              </h6>
              <div className='menu-search'>
                <Form.Label className='text-left' style={{ fontSize: '12px', color: 'white' }}>
                  {Localization.instance.getString('TXT_SEARCH_OR_LAUNCH')}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    onKeyPress={this.codeLaunch}
                    onKeyUp={(e: KEvent) => {
                      if (e.code.toLowerCase() === 'escape') {
                        this.toggleMenu();
                        (e.target as HTMLElement).blur();
                      }
                    }}
                    data-event='ignore'
                    autoComplete='off'
                    value={this.state.filter}
                    id='search-input'
                    onChange={this.initiateSearch}
                    style={{ borderRadius: '4px 0 0 4px' }}
                    placeholder={Localization.instance.getString('TXT_SearchforOrlaunchaprogram')}
                    type='text'
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <SquareIcon className='icon-primary-dark-menu-outline' size='24px'>
                        {Icons.RightArrow}
                      </SquareIcon>{' '}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <div hidden={this.state.filter.length === 0} className='search-output'>
                  <h6 className='truncate'>
                    {this.state.filter.length >= this.minLettersToRun
                      ? Localization.instance.getString('TXT_SearchingFor') + ` "${this.state.filter}"`
                      : Localization.instance.getString('TXT_AtLeast') +
                        ' ' +
                        this.minLettersToRun +
                        ' ' +
                        Localization.instance.getString('TXT_Letters')}
                  </h6>
                  {this.state.filteredMenu.map((menu: any, index: number) => {
                    return (
                      <p
                        tabIndex={0}
                        className='search-item'
                        data-event={'ignore'}
                        onKeyDown={(e) => {
                          if (e.key.toLowerCase().indexOf('enter') > -1) this.launch(menu);
                        }}
                        key={index}
                        onClick={() => this.launch(menu)}
                      >
                        {menu['text']}
                        <div
                          className={`quick-actions ${
                            this.props.quickLinks?.find((item) => item.text === menu['text'])
                              ? 'display-quicklinks-icon'
                              : ''
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.updateQuickLinks(menu);
                            this.props.saveSettings();
                            return false;
                          }}
                        >
                          <OverlayTrigger
                            placement='right'
                            overlay={
                              <Tooltip className='custom' id={`tooltip-autoplay-start`}>
                                {`${
                                  this.props.quickLinks?.find((item) => item.text === menu['text'])
                                    ? Localization.instance.getString('Remove_Favourite')
                                    : Localization.instance.getString('Add_Favourite')
                                }`}
                              </Tooltip>
                            }
                            rootClose
                          >
                            <span>
                              <SquareIcon
                                className={`${
                                  this.props.quickLinks?.find((item) => item.text === menu['text'])
                                    ? 'icon-grey-fill'
                                    : 'icon-grey-menu-outline'
                                }`}
                                size='14px'
                              >
                                {Icons.Star}
                              </SquareIcon>
                            </span>
                          </OverlayTrigger>
                        </div>
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className='menu-container' ref={this.menuRef}>
                <div hidden={this.state.enquiriesOpen}>
                  <div
                    onClick={() => this.setState({ enquiriesOpen: true })}
                    className={`menu-item folder enquiry-folder`}
                  >
                    <SquareIcon className='icon-grey-menu-outline' size='22px'>
                      {Icons.Help}
                    </SquareIcon>
                    {Localization.instance.getString('TXT_ENQUIRY_PROGRAMS')} (
                    {this.props.enquiries?.top?.defaultTemplate?.[0]?.$?.id || 'default'})
                  </div>
                  <h6 className='quick-links-header'>
                    <SquareIcon className='icon-grey-menu-outline' size='22px'>
                      {Icons.Star}
                    </SquareIcon>
                    {Localization.instance.getString('TXT_QUICK_LINKS')} ({(this.props.quickLinks || []).length})
                  </h6>
                  <div className={'quick-links-container' + (this.state.quickLinksOpen ? ' open' : '')}>
                    {(
                      this.props.quickLinks?.slice(0, this.state.quickLinksOpen ? this.props.quickLinks?.length : 3) ||
                      []
                    )?.map((menu: any, i: number) => {
                      menu = { ...menu, ...this.getMenuItems(menu.id, this.state.flattenedMenu) };
                      return (
                        <div key={i} onClick={() => this.launch(menu)} className={`menu-item`}>
                          <div
                            className='autoplay'
                            onClick={(e) => {
                              e.stopPropagation();
                              if (!menu.isAutoStart) {
                                if (
                                  (this.props.quickLinks?.filter((q: Program) => q.isAutoStart)?.length || 0) >=
                                  (XT.globalConfig?.window?.autoStartLimit || 5)
                                ) {
                                  Swal.fire({
                                    text: Localization.instance.getString('AUTO_START_MaximumNumberError'),
                                    icon: 'warning',
                                    confirmButtonColor: '#00a3a5',
                                    cancelButtonColor: '#00a3a5',
                                    showCancelButton: false,
                                    allowOutsideClick: false,
                                    stopKeydownPropagation: false,
                                    confirmButtonText: Localization.instance.getString('TXT_OK')
                                  });
                                  return;
                                }
                              }
                              this.props.updateAutoLinks(menu);
                              return false;
                            }}
                          >
                            <OverlayTrigger
                              placement='right'
                              trigger={['hover']}
                              overlay={
                                <Tooltip className='custom' id={`tooltip-autoplay`}>
                                  {menu.isAutoStart
                                    ? Localization.instance.getString('Disable_Autoplay')
                                    : Localization.instance.getString('Enable_Autoplay')}
                                </Tooltip>
                              }
                              show={this.state.autoplayTooltipVisibleIndex === i}
                              onToggle={(open) => this.setState({ autoplayTooltipVisibleIndex: open ? i : -1 })}
                            >
                              <span onClick={() => this.setState({ autoplayTooltipVisibleIndex: -1 })}>
                                <SquareIcon className='icon-grey-fill' size='18px'>
                                  {menu.isAutoStart ? Icons.PlayClassicStop : Icons.PlayClassicStart}
                                </SquareIcon>
                              </span>
                            </OverlayTrigger>
                          </div>
                          {menu['text']}
                          <div
                            className='quick-actions'
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.updateQuickLinks(menu);
                              this.props.saveSettings();
                            }}
                          >
                            <OverlayTrigger
                              placement='left'
                              overlay={
                                <Tooltip className='custom' id={`tooltip-autoplay-start`}>
                                  {Localization.instance.getString('Remove_Favourite')}
                                </Tooltip>
                              }
                              rootClose
                            >
                              <span>
                                <SquareIcon className='icon-grey-fill' size='18px'>
                                  {Icons.Star}
                                </SquareIcon>
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      );
                    })}
                    {(this.props.quickLinks?.length || 3) > 3 && (
                      <div className={'menu-item quick-links-more-container'}>
                        <Button
                          className='quick-links-more'
                          variant={this.state.quickLinksOpen ? 'outline-secondary' : 'secondary'}
                          size='sm'
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ quickLinksOpen: !this.state.quickLinksOpen });
                            (e.target as HTMLElement).blur();
                          }}
                        >
                          {this.state.quickLinksOpen
                            ? Localization.instance.getString('Show_less')
                            : `${Localization.instance.getString('Show_more')} (${
                                (this.props.quickLinks?.length || 3) - 3
                              })`}
                        </Button>
                      </div>
                    )}
                  </div>
                  {this.state.currentMenu.map((menu: any) => {
                    return (
                      <div
                        key={menu['$']['text']}
                        onClick={() => this.navigateOrLaunch(menu)}
                        className={`menu-item ${menu['value'] ? 'folder' : ''}`}
                      >
                        {menu['$']['text']}
                        {!menu['value'] && (
                          <div
                            className={`quick-actions ${
                              this.props.quickLinks?.find((item) => item.text === menu['$']['text'])
                                ? 'display-quicklinks-icon'
                                : ''
                            }`}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.updateQuickLinks(menu.$);
                              this.props.saveSettings();
                              return false;
                            }}
                          >
                            <OverlayTrigger
                              placement='right'
                              overlay={
                                <Tooltip className='custom' id={`tooltip-autoplay-start`}>
                                  {`${
                                    this.props.quickLinks?.find((item) => item.text === menu['$']['text'])
                                      ? Localization.instance.getString('Remove_Favourite')
                                      : Localization.instance.getString('Add_Favourite')
                                  }`}
                                </Tooltip>
                              }
                              rootClose
                            >
                              <span>
                                <SquareIcon
                                  className={`${
                                    this.props.quickLinks?.find((item) => item.text === menu['$']['text'])
                                      ? 'icon-primary-menu-fill'
                                      : 'grey-menu-icon'
                                  }`}
                                  size='18px'
                                >
                                  {Icons.Star}
                                </SquareIcon>
                              </span>
                            </OverlayTrigger>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div hidden={!this.state.enquiriesOpen}>
                  {this.props.enquiries?.top?.enquiries?.[0]?.enq
                    ?.filter((menu: any) => !!menu.$.desc.trim())
                    .map((menu: any) => {
                      return (
                        <div
                          key={menu['$']['cmd']}
                          onClick={() => this.quickLaunch({ id: menu.$.cmd, text: menu.$.desc }, 'command')}
                          className={`menu-item`}
                        >
                          {menu['$']['desc']} (Shift + F{+menu.$.cmd.replace('F', '') - 12})
                        </div>
                      );
                    })}
                  <div
                    ref={this.enquiryRef}
                    id='change-enquiry-template'
                    className={`menu-item folder`}
                    onMouseEnter={this.enquiryMenuOnMouseEnter}
                    onMouseLeave={() => this.menuRef?.current?.classList?.remove('firefox-scrollbar-width')}
                  >
                    {Localization.instance.getString('Formview_action_enquiries_change_template')}
                    <ul
                      style={{
                        bottom: window.innerHeight - this.state.enquiryBottom - 16 + 'px',
                        left: this.state.enquiryLeft + 'px'
                      }}
                      className='list-unstyled'
                    >
                      {this.props.enquiries?.top?.templates?.[0]?.template?.map((temp: any, i: number) => {
                        return (
                          <li
                            key={temp.$.id}
                            className={`menu-item`}
                            onClick={() => this.selectEnquiryTemplate(temp.$.id)}
                          >
                            {temp.$.id}
                            {this.props.enquiries?.top?.defaultTemplate?.[0]?.$?.id === temp.$.id && (
                              <div className='selected'>
                                <SquareIcon size='16px'>{Icons.Check}</SquareIcon>
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Navbar
              bg='white'
              style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)' }}
              onMouseDown={(e: React.MouseEvent) => {
                if (e.target === e.currentTarget) this.restoreFocus(); // no nav element clicked? ==> give focus back to active window
              }}
            >
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip className='custom' id={`tooltip-help`}>
                    {Localization.instance.getString('TXT_MenuItem')}
                  </Tooltip>
                }
                rootClose
              >
                <Nav.Link id='menu-link' className='mr-auto' onClick={this.toggleMenu} ref={this.hamburgerMenuRef}>
                  <SquareIcon className='icon-primary-nav' size='24px'>
                    {Icons.HamburgerMenu}
                  </SquareIcon>
                </Nav.Link>
              </OverlayTrigger>
              {process.env.REACT_APP_IS_DISPATCHER ? (
                <Nav.Link id='company-link' className='mr-auto'>
                  <p className={'company-name-dispatcher'}>{this.props.company.name}</p>
                </Nav.Link>
              ) : (
                <Nav id='company-link' className='mr-auto'>
                  <NavDropdown
                    id='company-name'
                    title={this.props.company.id ? this.props.company.id + ' - ' + this.props.company.name : ''}
                    onToggle={(isOpen: boolean) => {
                      if (!isOpen)
                        setTimeout(() => {
                          if (!this.state.isShowModal) this.restoreFocus();
                        }, 0); // Remark: without time-out and modal condition you get "focus" flickering
                    }}
                    ref={this.companyNameRef}
                  >
                    {this.props.user?.settings?.regionals?.company?.map((c: { $: { code: string; name: string } }) => {
                      return (
                        <NavDropdown.Item
                          onClick={() => {
                            if (this.props.isWindowOpened) {
                              this.toggle();
                            } else {
                              this.selectCompany({ id: c.$.code, name: c.$.name });
                            }
                          }}
                          onMouseOver={() => {
                            let anchor = Array.from(document.getElementsByClassName('company-dropdown'));
                            anchor.forEach((el) => el.removeAttribute('href'));
                          }}
                          className='company-dropdown'
                          ref={this.companyNameRef}
                        >
                          <span className='dropdown-code'>{c.$.code}</span> -{' '}
                          <span className='dropdown-name'>{c.$.name}</span>
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                </Nav>
              )}
              <Nav className='custom-nav-wrapper '>
                <NavDropdown
                  alignRight
                  id='app-status'
                  title={
                    this.state.connectionIssues && (
                      <SquareIcon size='24px' className={'icon-primary-nav-warning-pulse'}>
                        {Icons.Alert}
                      </SquareIcon>
                    )
                  }
                  onToggle={(isOpen: boolean) => {
                    if (!isOpen) this.restoreFocus();
                  }}
                  ref={this.mongoStatusRef}
                >
                  <NavDropdown.Header>
                    <small>{Localization.instance.getString('TXT_AppStatus')}</small>
                  </NavDropdown.Header>
                  <NavDropdown.Divider />
                  <NavDropdown.Header>
                    {this.state.connectionIssues
                      ? Localization.instance.getString('TXT_AppStatus_MongoDbOff')
                      : Localization.instance.getString('TXT_AppStatus_Ok')}
                  </NavDropdown.Header>
                </NavDropdown>
                <NavDropdown
                  id='help-action'
                  title={
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip className='custom' id={`tooltip-help`} style={{ margin: '10px' }}>
                          {Localization.instance.getString('TXT_HelpCentre')}
                        </Tooltip>
                      }
                      rootClose
                    >
                      <span>
                        <SquareIcon className='icon-primary-nav' size='24px'>
                          {Icons.HelpCircle}
                        </SquareIcon>
                      </span>
                    </OverlayTrigger>
                  }
                  onToggle={(isOpen: boolean) => {
                    if (!isOpen) this.restoreFocus();
                  }}
                  ref={this.helpCenterRef}
                >
                  {/* <NavDropdown.Item>View Shortcut</NavDropdown.Item>
                <NavDropdown.Item>About XT</NavDropdown.Item> */}
                  <NavDropdown.Item
                    href={`${XT.globalConfig?.helpLinkBase}/`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {Localization.instance.getString('TXT_HelpCentre')}
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={() => this.setState({ showImportantLinks: true })} ref={this.bookmarkRef}>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip className='custom' id={`tooltip-Links`} style={{ margin: '10px' }}>
                        {Localization.instance.getString('TXT_ImportantLinks')}
                      </Tooltip>
                    }
                    rootClose
                  >
                    <span>
                      <SquareIcon className='icon-primary-nav' size='24px'>
                        {Icons.Bookmark}
                      </SquareIcon>
                    </span>
                  </OverlayTrigger>
                </Nav.Link>
                <NavDropdown
                  ref={this.messageRef}
                  id='message-action'
                  onClick={() => setTimeout(() => this.setState({ messages: [] }), 10000)}
                  onToggle={(isOpen: boolean) => {
                    if (!isOpen) this.restoreFocus();
                  }}
                  title={
                    <>
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip className='custom' id={`tooltip-help`} style={{ margin: '10px' }}>
                            {Localization.instance.getString('TXT_Messages')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <span>
                          <SquareIcon className='icon-primary-nav' size='24px'>
                            {Icons.Notification}
                          </SquareIcon>
                        </span>
                      </OverlayTrigger>

                      {this.state.messages.length > 0 && (
                        <Badge pill variant='primary'>
                          {this.state.messages.length}
                        </Badge>
                      )}
                    </>
                  }
                >
                  <NavDropdown.Header>
                    <small>{Localization.instance.getString('TXT_Messages')}</small>
                  </NavDropdown.Header>
                  {this.state.messages.map((message: string) => (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Header>{message}</NavDropdown.Header>
                    </>
                  ))}
                </NavDropdown>
                <NavDropdown
                  id='user-action'
                  ref={this.useActionRef}
                  onToggle={(isOpen: boolean) => {
                    if (!isOpen)
                      setTimeout(() => {
                        if (!this.state.isShowModal && !this.state.isShowAbout) this.restoreFocus();
                      }, 0); // Remark: without time-out and modal condition you get "focus" flickering
                  }}
                  title={
                    <div className='custom-avatar-body'>
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip className='custom' id={`tooltip-help`} style={{ padding: '20px' }}>
                            {Localization.instance.getString('TXT_USER_ACTION_MANAGE_ACCOUNT')}
                          </Tooltip>
                        }
                        rootClose
                      >
                        <span>{this.props.auth?.user?.profile.name?.split(' ').join('')?.replace(/[a-z]/g, '')}</span>
                      </OverlayTrigger>
                    </div>
                  }
                  className='custom-avatar'
                >
                  <NavDropdown.Header>
                    {this.props.auth?.user?.profile.name || this.props.user.name} <br />
                    <small>{Localization.instance.getString('TXT_COMPANY_LABEL') + this.props.company.name}</small>{' '}
                    <br />
                  </NavDropdown.Header>
                  <NavDropdown.Divider />
                  {process.env.REACT_APP_IS_DISPATCHER ? (
                    <NavDropdown.Item href={`/reset-password`}>
                      {Localization.instance.getString('TXT_USER_ACTION_CHANGE_PASSWORD')}
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item onClick={() => this.manageAccount()}>
                      {Localization.instance.getString('TXT_USER_ACTION_MANAGE_ACCOUNT')}
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={this.toggleAbout}>
                    {Localization.instance.getString('TXT_USER_ACTION_ABOUT')}
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={this.logout}>
                    {Localization.instance.getString('TXT_USER_ACTION_LOGOUT')}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar>
            <ImportantLinks
              hide={() => {
                this.setState({ showImportantLinks: false });
              }}
              isShown={this.state.showImportantLinks}
            />
            <Desktop
              startExport={this.startExport}
              stopExport={this.stopExport}
              loading={this.props.loading}
              flattenedMenu={this.state.flattenedMenu}
              startLoading={this.props.startLoading}
              endLoading={this.props.endLoading}
              socket={this.socket}
            />
            {this.state.isShowModal && (
              <div className='custom-signature'>
                <Modal
                  show={this.state.isShowModal}
                  onHide={this.toggle.bind(this)}
                  size='lg'
                  backdrop='static'
                  keyboard={false}
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                  className='termibate'
                >
                  <Modal.Header>
                    <Modal.Title>
                      <h4>Iptor.com</h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>{Localization.instance.getString('ERROR_CloseTabsFirst')}</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      data-event='ignore'
                      onClick={this.toggle.bind(this)}
                      className='truncate btn-padded-modal btn btn-outline-dark btn-sm col-sm-2'
                    >
                      {Localization.instance.getString('TXT_OK')}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
            {this.state.isShowAbout && (
              <div className='custom-about'>
                <Modal
                  show={this.state.isShowAbout}
                  onHide={this.toggleAbout}
                  size='lg'
                  backdrop='static'
                  keyboard={false}
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                  className='about'
                >
                  <Modal.Header>
                    <Modal.Title>
                      <h4>
                        <Image style={{ paddingRight: '24px', paddingBottom: '8px' }} src={'/logo-color.png'} />
                        Iptor.com ERP UI
                      </h4>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>{Localization.instance.getString('TXT_ABOUT_SOFTWARE_SUMMARY')}</p>
                    <h6>
                      {Localization.instance.getString('TXT_ABOUT_VERSION') + ':'}{' '}
                      {this.props.versionInfo.appVersion || '-'}
                      <br />
                      {Localization.instance.getString('TXT_ABOUT_BUILD_NBR') + ':'}{' '}
                      {this.props.versionInfo.buildNumber || '-'}
                      <br />
                      {Localization.instance.getString('TXT_ABOUT_METADATA_VERSION') + ':'}{' '}
                      {this.props.versionInfo.version?.split(' ').pop() || '-'}
                      <br />
                      {Localization.instance.getString('TXT_ABOUT_APIFRAMEWORK_VERSION') + ':'}{' '}
                      {this.props.versionInfo.apiFrameworkVersion?.split(' ').pop() || '-'}
                      <br />
                      {
                        <SquareIcon
                          size='24px'
                          className={this.state.connectionIssues ? 'icon-primary-nav-warning' : 'icon-primary-nav-ok'}
                        >
                          {this.state.connectionIssues ? Icons.Alert : Icons.CheckCircle}
                        </SquareIcon>
                      }{' '}
                      {this.state.connectionIssues
                        ? Localization.instance.getString('TXT_Mongo_OFF')
                        : Localization.instance.getString('TXT_Mongo_ON')}
                      <br />
                      <NotesConnectionStatus />
                    </h6>
                    <br />
                    <br />
                    <hr />
                    <p>
                      &copy;{' '}
                      {Localization.instance.getString('TXT_ABOUT_COPYRIGHT_ID') +
                        ' ' +
                        new Date().getFullYear() +
                        ' ' +
                        Localization.instance.getString('TXT_ABOUT_COPYRIGHT_TEXT')}
                    </p>
                    <p>
                      {Localization.instance.getString('TXT_ABOUT_MADE_POSSIBLE_BY')}{' '}
                      <a href={`${XT.globalConfig?.thirdPartyLicensesLink}/`} target='_blank' rel='noopener noreferrer'>
                        {Localization.instance.getString('TXT_ABOUT_OPEN_SOURCE_SOFTWARE')}
                      </a>
                      {'.'}
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      ref={(ref) => {
                        this.buttonRef = ref;
                      }}
                      data-event='ignore'
                      onClick={this.toggleAbout}
                      className='btn btn-primary col-sm-2'
                    >
                      {' '}
                      {Localization.instance.getString('TXT_OK')}{' '}
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </div>
        </ClientContext.Provider>
      </DndProvider>
    );
  }

  manageAccount = () => {
    window.history.replaceState(null, '', '/app?change');
    window.location.href =
      this.props.auth?.user?.profile.iss +
      '/account?referrer=' +
      this.props.auth?.settings.client_id +
      '&referrer_uri=' +
      encodeURIComponent(window.location.href);
  };
}

const mapStateToProps = ({ desktop, settings }: any) => {
  let props: {
    company: Company;
    user: User;
    quickLinks: Program[];
    autoLinks: Program[];
    enquiries: Program[];
    clientSettings: Record<string, any>;
    locale: Locale;
    isWindowOpened: boolean;
    connectionInfo: Record<string, any>;
    versionInfo: Record<string, string>;
  } = {
    company: {
      id: desktop.settings?.regionals?.user[0].$.activeCompany,
      name:
        desktop.settings?.regionals?.company.find(
          (x: any) => x.$.code === desktop.settings.regionals.user[0].$.activeCompany
        )?.$.name || ''
    },
    user: {
      id: desktop.settings?.regionals?.user[0].$.userId,
      username: desktop.settings?.regionals?.user[0].$.userId,
      name: desktop.settings?.fullName
    },
    quickLinks: settings.quickLinks,
    autoLinks: settings.autoLinks,
    enquiries: desktop.enquiries,
    clientSettings: settings,
    locale: desktop.localeSettings.locale,
    isWindowOpened: Object.keys(desktop.windows).length > 1,
    connectionInfo: desktop.connectionInfo,
    versionInfo: desktop.versionInfo
  };
  window.sessionStorage.setItem('user', JSON.stringify(props.user));
  if (props.company.id) {
    window.sessionStorage.setItem('company', JSON.stringify(props.company));
  }
  props.user.settings = desktop.settings;
  return props;
};

const mapDispatchToProps = {
  launch: (program: Program, endLoading: Function, type: string) => WindowManger.Launch(program, endLoading, type),
  updateSettings: (payload: Record<string, any>) => ({ type: WindowActions.UPDATE_SETTINGS, payload: payload }),
  updateQuickLinks: (program: Program) => ({ type: SettingsActions.QUICK_LINKS_UPDATE, payload: { program } }),
  updateAutoLinks: (program: Program) => ({ type: SettingsActions.AUTO_LINKS_UPDATE, payload: { program } }),
  loadClientSettings: (payload: any) => ({ type: SettingsActions.LOAD, payload: payload }),
  saveSettings: () => WindowManger.UpdateSettings(),
  registerRequestError: (requestError: RequestError) => ({
    type: WindowActions.NEW_REQUEST_ERROR,
    payload: { requestError }
  }),
  loadEnquiryTemplateSettings: (enquiryTemplate: string, endLoading: Function) =>
    WindowManger.LoadEnquiryTemplateSettings(enquiryTemplate, endLoading)
};
export const Client = connect(mapStateToProps, mapDispatchToProps)(withAuth(ClientComponent));
