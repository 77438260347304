import React, { cloneElement } from 'react';
import type { ReactElement } from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
// import { utils, writeFile } from 'xlsx';
import { Worksheet, Workbook, Cell, ValueType } from 'exceljs';

import type { DataGridProps } from 'react-data-grid';
import { RemoveTrailingDotsFormatter } from '../../framework/parsers/RemoveTrailingDotsFormatter';
import { Formatters } from '../../framework/parsers';
import { Column } from '../DataTable';
import { XTString } from '../../framework/parsers/models/XTData';
import { XT } from '../../framework/handlers/xt';
import { PositiveIntegerFormatter } from '../../framework/parsers/PositiveIntegerFormatter';
import { PositiveIntegerWithZerosFormatter } from '../../framework/parsers/PositiveIntegerWithZerosFormatter';
import { IntegerFormatter } from '../../framework/parsers/IntegerFormatter';

/*
export const generateRows = (
  columns: any,
  rows: any,
  decimalsign: string,
  dateSeparator: string,
  format6: string,
  format8: string,
  format8Sep: string,
  localization: Localization,
  century_break_year: string,
  yes: string,
  no: string
) => {
  return [...rows].map((row: any) => {
    let _row = {...row};
    Object.entries(_row).forEach(([key, _]) => {

      // Get corresponding column
      // ========================
      let col = columns.find((c: Column) => c.id === key);

      // Handle column data           // remark: ignore other row properties (like e.g. flags, ...)
      // ==================
      if (col) {

        // --> determine content type
        let contentType = col?.mask || col?.contenttype;
        if (col?.contenttype === 'boolean') {
          contentType = 'boolean';
        };
        if (col?.formatter?.toLowerCase() === 'time') {
          contentType = 'time';
        };
        if (col?.mask === 'integer') {
          contentType = 'integer';
        };

        // --> get value (remark: server format)
        let value = _row[key] || '';
        if (col?.formatter === 'RemoveTrailingDots') {
          value = new RemoveTrailingDotsFormatter().in(value);
        };

        // --> determine whether editable
        // let isEditable = col?.readonly === 'false';
        // if (col?.disableconstraints) {
        //   const dConstraint = new Constraint(col?.disableconstraints);
        //   isEditable = !dConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], false);
        // }

        // --> determine whether visible
        // let visible = true;
        // if (col?.visibleconstraints) {
        //  let colVConstraint = new Constraint(col.visibleconstraints);
        //  if (!colVConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
        //    visible = false;
        //  }
        // }

        // --> parse from server format
        switch (contentType) {
          // case 'boolean':
          //   if (!isEditable)
          //     value = Formatters.BooleanFormatter.in((value || ' ').trim(), col?.value || yes, col?.deselectedvalue || no) ? (
          //       <SquareIcon size="16px">{Icons.Check}</SquareIcon>
          //     ) : null;
          //   break;
          case 'time':
            value = value.trim();
            if (value.startsWith('24')) value = value.replace('24', '00');
            if (col?.limit === '5') {
              value = `0${value}`;
            }
            let time = Formatters.Time.in(value?.trim() || '');
            value = time ? Formatters.Time.out(time, +(col?.limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
            break;
          case 'date':
            value = Formatters.Date.convertValueFromServerFormatToClientFormat(value, {format6, format8, format8Sep, dateSeparator, century_break_year}, localization.dateFormat);
            break;
          case 'positiveinteger':
          case 'positiveinteger_with_zeros':
          case 'decimal':
          case 'positivedecimal':
          case 'integer':
          case 'numeric':
          case 'decimal_with_zeros':
            let num = new XTString(value).serverFormatToNumber(decimalsign);
            if (!num.isNaN) value = `${num}`;
            break;
          default:
            if (value === `${value}`) value = value?.trimEnd();
            break;
        };

        // --> get result
        _row[key] = value || '';
      };
    });
    return _row;
  });
};
*/
export enum exportType {
  csv = 'csv',
  xlsx = 'xlsx',
  pdf = 'pdf',
  clipboard = 'clipboard'
}
export type exportToFile = {
  export: (columns: any, rows: any, fileName: string) => Promise<void>;
  type: exportType;
  generateRows: (columns: any, rows: any, decimalsign: string, dateFormattingServerInfo: any, ...args: any) => any[];
  decimalsign: string;
  dateFormattingServerInfo: any;
};
export const generateRows_ISO = (
  columns: any,
  rows: any,
  decimalsign: string,
  dateFormattingServerInfo: {
    format6: string;
    format8: string;
    format8Sep: string;
    dateSeparator: string;
    century_break_year: string;
  },
  yes: string,
  no: string
) => {
  return [...rows].map((row: any) => {
    let _row = { ...row };
    Object.entries(_row).forEach(([key, _]) => {
      // Get corresponding column
      // ========================
      let col = columns.find((c: Column) => c.id === key);

      // Handle column data           // remark: ignore other row properties (like e.g. flags, ...)
      // ==================
      if (col) {
        // --> determine content type
        let contentType = col?.mask || col?.contenttype;
        if (col?.contenttype === 'boolean') {
          contentType = 'boolean';
        }
        if (col?.formatter?.toLowerCase() === 'time') {
          contentType = 'time';
        }
        if (col?.mask === 'integer') {
          contentType = 'integer';
        }

        // --> get value (remark: server format)
        let value = _row[key] || '';
        if (col?.formatter === 'RemoveTrailingDots') {
          value = new RemoveTrailingDotsFormatter().in(value);
        }

        // --> determine whether editable
        // let isEditable = col?.readonly === 'false';
        // if (col?.disableconstraints) {
        //   const dConstraint = new Constraint(col?.disableconstraints);
        //   isEditable = !dConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], false);
        // }

        // --> determine whether visible
        // let visible = true;
        // if (col?.visibleconstraints) {
        //  let colVConstraint = new Constraint(col.visibleconstraints);
        //  if (!colVConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
        //    visible = false;
        //  }
        // }

        // --> convert from server format to ISO format
        switch (contentType) {
          case 'boolean':
            value = value.trim();
            if (value === col?.value) {
              value = 'true';
            } else if (value === col?.deselectedvalue) {
              value = 'false';
            } else if (value === yes) {
              value = 'true';
            } else if (value === no) {
              value = 'false';
            }
            break;
          case 'time':
            value = value.trim();
            // if (value.startsWith('24')) value = value.replace('24', '00');
            let time = Formatters.Time.in(value?.trim() || '');
            value = time ? Formatters.Time.out(time, +(col?.limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
            break;
          case 'date':
            value = Formatters.Date.convertValueFromServerFormatToClientFormat(
              value,
              dateFormattingServerInfo,
              'yyyy-MM-dd'
            );
            break;
          case 'positiveinteger':
          case 'positiveinteger_with_zeros':
          case 'decimal':
          case 'delimitedquantity':
          case 'positivedecimal':
          case 'integer':
          case 'numeric':
          case 'decimal_with_zeros':
            const decimalSeparator = XT.isCommaAlwaysServerDecimalSeparator(col?.formatter, col?.mask)
              ? ','
              : decimalsign;
            let num = new XTString(value).serverFormatToNumber(decimalSeparator);
            if (!num.isNaN) value = `${num}`;
            break;
          default:
            if (value === `${value}`) value = value?.trimEnd();
            break;
        }

        // --> get result
        _row[key] = `${value}` ? value : '';
      }
    });
    return _row;
  });
};

export const generateRows_XLSX = (
  columns: any,
  rows: any,
  decimalsign: string,
  dateFormattingServerInfo: {
    format6: string;
    format8: string;
    format8Sep: string;
    dateSeparator: string;
    century_break_year: string;
  }
) => {
  return [...rows].map((row: any) => {
    let _row = { ...row };
    Object.entries(_row).forEach(([key, _]) => {
      const toNumber = (serverValue: string, decimalSeparator: string) => {
        // Initialize
        let value = (serverValue || '').trim();

        // Remove minus sign
        let isNegative = false;
        if (value.includes('-')) {
          value = value.replace('-', '');
          isNegative = true;
        }

        // Remove all grouping separators
        const groupingSeparator = decimalSeparator === '.' ? ',' : '.';
        value = value.replaceAll(groupingSeparator, '');

        // Split on decimal separator
        let [integerPart, fractionalPart] = value.split(decimalSeparator);
        fractionalPart = fractionalPart || '';

        // Integer part should be numeric
        if (integerPart !== integerPart.replace(/[^.0-9]/g, '')) {
          return serverValue;
        }

        // Fractional part should be numeric
        if (fractionalPart !== fractionalPart.replace(/[^.0-9]/g, '')) {
          return serverValue;
        }

        // Remove leading zeros integral part
        while (integerPart.startsWith('0')) {
          integerPart = integerPart.slice(1);
        }

        // Rebuild the number
        value = integerPart || '0';
        const fractionalPartAllZeroes = fractionalPart.replace(/[^0]/g, '0');
        if (value === '0' && fractionalPart === fractionalPartAllZeroes) fractionalPart = '';
        if (fractionalPart) value += '.' + fractionalPart;
        if (isNegative) value = '-' + value;

        // Return result
        return value;
      };
      // Get corresponding column
      // ========================
      let col = columns.find((c: Column) => c.id === key);

      // Handle column data           // remark: ignore other row properties (like e.g. flags, ...)
      // ==================
      if (col) {
        // --> determine content type
        let contentType = col?.mask || col?.contenttype;
        if (col?.contenttype === 'boolean') {
          contentType = 'boolean';
        }
        if (col?.formatter?.toLowerCase() === 'time') {
          contentType = 'time';
        }
        if (col?.mask === 'integer' || col?.mask === 'positiveinteger' || col?.mask === 'positiveinteger_with_zeros') {
          contentType = col.mask;
        }

        if (col?.formatter === 'integer') {
          contentType = 'integer';
        }

        // --> get value (remark: server format)
        let value = _row[key] || '';
        if (col?.formatter === 'RemoveTrailingDots') {
          value = new RemoveTrailingDotsFormatter().in(value);
        }

        // --> determine whether editable
        // let isEditable = col?.readonly === 'false';
        // if (col?.disableconstraints) {
        //   const dConstraint = new Constraint(col?.disableconstraints);
        //   isEditable = !dConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], false);
        // }

        // --> determine whether visible
        // let visible = true;
        // if (col?.visibleconstraints) {
        //   let colVConstraint = new Constraint(col.visibleconstraints);
        //   if (!colVConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
        //     visible = false;
        //   }
        // }

        // --> parse from server format
        switch (contentType) {
          // case 'boolean':
          //   if (!isEditable)
          //     value = Formatters.BooleanFormatter.in((value || ' ').trim(), col?.value || yes, col?.deselectedvalue || no) ? (
          //       <SquareIcon size="16px">{Icons.Check}</SquareIcon>
          //     ) : null;
          //   break;
          case 'time':
            value = value.trim();
            // if (value.startsWith('24')) value = value.replace('24', '00');
            if (col?.limit === '5') {
              value = `0${value}`;
            }
            let time = Formatters.Time.in(value?.trim() || '');
            value = time ? Formatters.Time.out(time, +(col?.limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
            break;
          case 'date':
            let date = Formatters.Date.convertValueFromServerFormatToClientFormat(
              value,
              dateFormattingServerInfo,
              'yyyy-MM-dd'
            );
            if (date === '') {
              value = null;
            } else if (date === '9999-99-99') {
              value = new Date(9999, 11, 31, 23, 59, 59);
            } else {
              try {
                value = new Date(date + 'T00:00:00.000Z');
              } catch (e) {
                value = value?.trimEnd();
              }
            }
            break;
          case 'positiveinteger':
          case 'positiveinteger_with_zeros':
          case 'integer':
            if (!(Number.MAX_SAFE_INTEGER.toString().length - 1 < +col?.limit)) {
              // Remark: although decimal separator not needed in case of "real" integer values, the correct decimal separator
              // is here passed anyway: program protection (to handle invalid configurations: configured as integer but data send with
              // decimal separator)
              const decimalSeparator = XT.isCommaAlwaysServerDecimalSeparator(col?.formatter, col?.mask)
                ? ','
                : decimalsign;
              let num = new XTString(value).serverFormatToNumber(decimalSeparator);
              if (!num.isNaN) value = num.toNumber_UNSAFE(); // conversion to a number is SAFE under these conditions
            } // else keep "string" server value (including leading zeros, eg pallet numbers are defined as positive integers with a lenght of 18)
            break;
          case 'numeric':
          case 'decimal':
          case 'delimitedquantity':
          case 'positivedecimal':
          case 'decimal_with_zeros':
            const decimalSeparator = XT.isCommaAlwaysServerDecimalSeparator(col?.formatter, col?.mask)
              ? ','
              : decimalsign;
            value = toNumber(value, decimalSeparator);
            break;
          default:
            if (value === `${value}`) value = value?.trimEnd();
            break;
        }

        // --> get result
        _row[key] = `${value}` ? value : '';
      }
    });
    return _row;
  });
};

// export const generateRows_ServerFormat = (columns: any, rows: any) => {
//   return [...rows].map((row: any) => {
//     let _row = { ...row };
//     Object.entries(_row).forEach(([key, _]) => {
//       // Get corresponding column
//       // ========================
//       let col = columns.find((c: Column) => c.id === key);

//       // Handle column data           // remark: ignore other row properties (like e.g. flags, ...)
//       // ==================
//       if (col) {
//         // --> get value (remark: server format)
//         let value = _row[key] || '';
//         if (col?.formatter === 'RemoveTrailingDots') {
//           value = new RemoveTrailingDotsFormatter().in(value);
//         }

//         // --> get result
//         _row[key] = value || '';
//       }
//     });
//     return _row;
//   });
// };

export const generateRows_ClientFormat = (
  columns: any,
  rows: any,
  decimalsign: string,
  dateFormattingServerInfo: {
    format6: string;
    format8: string;
    format8Sep: string;
    dateSeparator: string;
    century_break_year: string;
  },
  yes: string,
  no: string,
  clientFormattingSettings: {
    dateFormat: string;
    decimalSeparator: string;
    groupSeparator: string;
  }
) => {
  return [...rows].map((row: any) => {
    let _row = { ...row };
    Object.entries(_row).forEach(([key, _]) => {
      // Get corresponding column
      // ========================
      let col = columns.find((c: Column) => c.id === key);

      // Handle column data           // remark: ignore other row properties (like e.g. flags, ...)
      // ==================
      if (col) {
        // --> determine content type
        let contentType = col.mask || col.contenttype;
        if (col.contenttype === 'boolean') {
          contentType = 'boolean';
        }
        if (col.formatter?.toLowerCase() === 'time') {
          contentType = 'time';
        }
        if (col.mask === 'integer' || col.formatter?.toLowerCase() === 'integer') {
          contentType = 'integer';
        }

        // --> get value (remark: server format)
        let value = _row[key] || '';
        if (col.formatter === 'RemoveTrailingDots') {
          value = new RemoveTrailingDotsFormatter().in(value);
        }

        // --> determine whether editable
        // let isEditable = col?.readonly === 'false';
        // if (col?.disableconstraints) {
        //   const dConstraint = new Constraint(col?.disableconstraints);
        //   isEditable = !dConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], false);
        // }

        // --> determine whether visible
        // let visible = true;
        // if (col?.visibleconstraints) {
        //  let colVConstraint = new Constraint(col.visibleconstraints);
        //  if (!colVConstraint.evaluate(_row?.flags?.split('').map((x: string) => x === '1') || [], true)) {
        //    visible = false;
        //  }
        // }

        // --> convert from server format to client format
        const decSep: string = XT.isCommaAlwaysServerDecimalSeparator(col.formatter, col.mask) ? ',' : decimalsign;
        switch (contentType) {
          case 'boolean':
            const boolValue = Formatters.BooleanFormatter.in(
              (value || '').trim(),
              col.value || yes,
              col.deselectedvalue || no
            );
            if (boolValue === true) {
              value = '+'; // '\u2713'; unicode not supported ==> NTH: image
            } else if (boolValue === undefined && col.state3?.toLowerCase?.() === 'true') {
              value = '?';
            } else {
              value = '';
            }
            break;
          case 'time':
            value = value.trim();
            // if (value.startsWith('24')) value = value.replace('24', '00');
            if (col.limit === '5') {
              value = `0${value}`;
            }
            let time = Formatters.Time.in(value?.trim() || '');
            value = time ? Formatters.Time.out(time, +(col.limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
            break;
          case 'date':
            value = Formatters.Date.convertValueFromServerFormatToClientFormat(
              value,
              dateFormattingServerInfo,
              clientFormattingSettings.dateFormat
            );
            break;
          case 'positiveinteger':
            const temp = new PositiveIntegerFormatter().in(value);
            value = temp === 'NaN' ? value : temp;
            break;
          case 'positiveinteger_with_zeros':
            const temp0 = new PositiveIntegerWithZerosFormatter().in(value);
            value = temp0 === 'NaN' ? value : temp0;
            break;
          case 'integer':
            value = new IntegerFormatter().in(value);
            break;
          case 'decimal':
          case 'delimitedquantity':
            const xtNum = new XTString(value).serverFormatToNumber(decSep);
            if (!xtNum.isNaN) {
              value = xtNum
                .toXTStringClientFormat(
                  clientFormattingSettings.decimalSeparator,
                  clientFormattingSettings.groupSeparator,
                  true,
                  false,
                  true,
                  true
                )
                .toString();
            }
            break;
          case 'positivedecimal':
            const xtNum0 = new XTString(value).serverFormatToNumber(decSep);
            if (!xtNum0.isNaN) {
              value = xtNum0
                .toXTStringClientFormat(
                  clientFormattingSettings.decimalSeparator,
                  clientFormattingSettings.groupSeparator,
                  true,
                  false,
                  false,
                  true
                )
                .toString();
            }
            break;
          case 'decimal_with_zeros':
            const xtNum1 = new XTString(value).serverFormatToNumber(decSep);
            if (!xtNum1.isNaN) {
              value = xtNum1
                .toXTStringClientFormat(
                  clientFormattingSettings.decimalSeparator,
                  clientFormattingSettings.groupSeparator,
                  true,
                  true,
                  false,
                  true
                )
                .toString();
            }
            break;
          case 'numeric':
            const xtNum2 = new XTString(value).serverFormatToNumber(decSep);
            if (!xtNum2.isNaN) {
              value = xtNum2
                .toXTStringClientFormat(
                  clientFormattingSettings.decimalSeparator,
                  clientFormattingSettings.groupSeparator,
                  true,
                  false,
                  true,
                  true
                )
                .toString();
            }
            break;
          // case 'image': // "NTH ?" or "not used anyway ?"
          //   const icon = XT.getIcon(value);
          //   if (icon) {
          //     value = <SquareIcon size='16px'>{icon}</SquareIcon>;
          //   }
          //   break;
          default:
            if (value === `${value}`) value = value?.trimEnd();
            break;
        }

        // --> set result
        _row[key] = `${value}` ? value : '';
      }
    });
    return _row;
  });
};

export async function exportToCsv(columns: any, rows: any, fileName: string, optionalHeader?: string[]) {
  // const { head, body, foot } =
  let content = '';
  if (optionalHeader) {
    content += [...optionalHeader].join(',\n') + '\n';
  }
  content += [columns.map((c: any) => c.name.trim()), ...rows.map((r: any) => columns.map((c: any) => r[c.id]))]
    .map((cells) => cells.map(serialiseCellValue).join(','))
    .join('\n');
  return new Promise<void>((resolve) => {
    downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
    resolve();
  });
}

export async function exportToXlsx(columns: any, rows: any, fileName: string, optionalHeader?: string[]) {
  // const content = [columns.map((c: any) => c.name), ...rows.map((r: any) => columns.map((c: any) => r[c.id]))];
  // const [{ utils, writeFile }] = await Promise.all([import('xlsx')]);
  const _wb = new Workbook();
  const _ws = _wb.addWorksheet('Sheet 1');
  const alignment = columns.map((col: any) => col.cellClass.split('-')[1]);

  _ws.columns = columns.map((col: any) => ({
    key: col.id,
    // header: col.name, REMARK: Header not added as it reserves the first row for the column headers.
    style: { alignment: { horizontal: col.textalign || col.cellClass.split('-')[1] } }
  }));

  if (optionalHeader) {
    optionalHeader.forEach((r: any) => {
      const row = [r];
      let _row = _ws.addRow(row);
      _row.font = { name: 'Courier New', family: 3 };
      _row.eachCell((cell) => {
        cell.style.alignment = { horizontal: 'left' };
      });
      _row.commit();
    });
  }
  const headerRow = columns.map((col: any) => col.name.trimStart());
  let _headerRow = _ws.addRow(headerRow);
  _headerRow.font = { bold: true };
  _headerRow.eachCell((cell, colNumber) => {
    cell.alignment = { horizontal: alignment[colNumber - 1] };
  });
  _headerRow.commit();

  rows.forEach((r: any) => {
    const row = _ws.columns.map((c: any) => r[c.key]);
    let _row = _ws.addRow(row);
    Object.values(columns)
      .filter((col: any) => col.contenttype === 'numeric')
      .forEach((col: any, index: number) => {
        let cell = _row.getCell(col.id);
        let value = cell.value?.toString() ?? '';
        if (XTString.isSafeNumber(value, '.')) {
          cell.value = Number(value);
          let [_, dec] = value.split('.'); // 272.200 => 272.2 => 200
          cell.model.type = ValueType.Number;
          if (dec) {
            cell.numFmt = `#0.${'0'.repeat(dec.length)}`;
            // value = { value: num, numFmt: `#0.${'0'.repeat(dec.length)}`, model: { type: ValueType.Number } };
          } else {
            cell.numFmt = `#0`;
          }
        } else {
          cell.model.type = ValueType.String;
        }
      });
    _row.commit();
  });

  _ws.columns.forEach((col) => {
    let dataMaxLength = 0;
    if (col.values) {
      col.values.forEach((val) => {
        if (val instanceof Date) {
          dataMaxLength = 10;
        } else if (val && !optionalHeader?.includes(val.toString())) {
          dataMaxLength = val.toString().trim().length > dataMaxLength ? val.toString().trim().length : dataMaxLength;
        }
      });
    }
    col.width = dataMaxLength * 1.6;
  });
  return _wb.xlsx.writeBuffer().then((data: any) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    downloadFile(fileName, blob);
  });
  // const wb = utils.book_new();
  // const ws = utils.aoa_to_sheet(content);
  // utils.book_append_sheet(wb, ws, 'Sheet 1');
  // writeFile(wb, fileName);
}

export async function exportToClip(columns: any, rows: any, optionalHeader?: string[]) {
  // const { head, body, foot } =
  // const content = [columns.map((c: any) => c.name), ...rows.map((r: any) => columns.map((c: any) => r[c.id]))]
  //   .map((cells) => cells.map(serialiseCellValue).join('\t'))
  //   .join('\n');
  const optionalHeaderString = optionalHeader?.join('\n');
  let table = optionalHeaderString ? optionalHeaderString + '\n' : '';
  table += columns.map((c: any) => c.name?.trim() ?? c?.trim()).join('\t');
  table += '\n';
  table += rows.map((r: any) => columns.map((c: any, i: number) => r[c.id] ?? r[i]).join('\t')).join('\n');
  // .forEach((row: string[]) => {
  //   let tr = document.createElement('tr');
  //   row.forEach((cell: string) => {
  //     tr.innerHTML += `<td>${cell}</td>`;
  //   });
  //   table.appendChild(tr);
  // });

  /* let body = document.body,
    range,
    sel;
  body.appendChild(table);
  if (document.createRange && window.getSelection) {
    range = document.createRange();
    sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      try {
        range.selectNodeContents(table);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(table);
        sel.addRange(range);
      }
    }
  } else if (body.createTextRange) {
    range = body.createTextRange();
    range.moveToElementText(table);
    range.select();
  }*/
  navigator.clipboard.writeText(table || '').catch((err) => {
    console.error(`Error from exportToClip ${err}`);
  });
  // table.remove();
  return new Promise<void>((resolve) => resolve());
  // downloadFile(fileName, new Blob([content], { type: 'text/csv;charset=utf-8;' }));
}

export async function exportToPdf(columns: any, rows: any, fileName: string, optionalHeader?: string[]) {
  // const [{ jsPDF }, autoTable, { head, body }] = await Promise.all([
  //   import('jspdf'),
  //   (await import('jspdf-autotable')).default,
  //   { head: [columns.map((c: any) => c.name)], body: [...rows.map((r: any) => columns.map((c: any) => r[c.id]))] }
  // ]);
  const { head, body } = {
    head: [columns.map((c: any) => c.name ?? c)],
    body: [...rows.map((r: any) => columns.map((c: any, i: number) => r[c.id] ?? r[i]))]
  };
  const doc = new jsPDF({
    orientation: 'l',
    unit: 'px'
  });

  let currentY = optionalHeader ? 0 : 20;
  if (optionalHeader) {
    const lineHeight = doc.getLineHeight();
    const marginTop = 10;
    currentY = marginTop;

    optionalHeader.forEach((row: string) => {
      doc.setFontSize(8);
      doc.text(row, 30, currentY);
      currentY += lineHeight;
    });
  }

  autoTable(doc, {
    head,
    body,
    startY: currentY,
    horizontalPageBreak: true,
    styles: { cellPadding: 1.5, fontSize: 8, cellWidth: 'wrap' },
    tableWidth: 'wrap'
  });
  return doc.save(fileName, { returnPromise: true });
}

async function getGridContent<R, SR>(gridElement: ReactElement<DataGridProps<R, SR>>) {
  const { renderToStaticMarkup } = await import('react-dom/server');
  const grid = document.createElement('div');
  grid.innerHTML = renderToStaticMarkup(
    cloneElement(gridElement, {
      enableVirtualization: false
    })
  );

  return {
    head: getRows('.rdg-header-row'),
    body: getRows('.rdg-row:not(.rdg-summary-row)'),
    foot: getRows('.rdg-summary-row')
  };

  function getRows(selector: string) {
    return Array.from(grid.querySelectorAll<HTMLDivElement>(selector)).map((gridRow) => {
      return Array.from(gridRow.querySelectorAll<HTMLDivElement>('.rdg-cell')).map((gridCell) => gridCell.innerText);
    });
  }
}

function serialiseCellValue(value: unknown) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',') || formattedValue.includes('"') ? `"${formattedValue}"` : formattedValue;
  }
  return value;
}

export function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}
