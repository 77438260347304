export namespace AperioViews {
  export enum EventTypes {
    ON_ROW_SELECTION = 'selection',
    ON_FIELD_UPDATE = 'update',
    ON_FIELD_BLUR = 'focusout',
    ON_FORM_LOAD = 'load'
  }

  export enum FieldTypes {
    TEXT = 'text',
    NUMERIC = 'numeric',
    BOOLEAN = 'boolean'
  }

  export type Link = {
    formId: KeyTypes.FormId;
    aperioFormId: string;
    panelIds: KeyTypes.PanelId[];
    events: Link.Event[];
    parameters: Link.Parameter[];
  };
}
export type AperioViews = {
  id: string; //                      eg: "part.AperioView"
  path: string; //                    eg: "/xtview"
  links: AperioViews.Link[]; //       Remark: in (near) future, we want it to implement wildcards (so no added value to store it in a Record)
  errors: string[];
};

export namespace AperioViewCommand {
  export enum ErrorTypes {
    ERROR = 'error',
    WARNING = 'warning'
  }

  export type Error = {
    type: ErrorTypes;
    message: string;
  };

  export type Params = Record<string, string | number | boolean | undefined>; // < aperioId, value >
}

export namespace AperioViews.KeyTypes {
  export type FormId = string;
  export type PanelId = string;
  export type TableId = string;
  export type FieldId = string; // NTH: when implementing wildcards ==> keep FieldId only for real id's, create new one where wildcards supported eg FieldExpression
}

export namespace AperioViews.Link {
  export type FormEvent = {
    type: EventTypes.ON_FORM_LOAD;
  };

  export type TableEvent = {
    type: EventTypes.ON_ROW_SELECTION;
    tableId: KeyTypes.TableId;
  };

  export type FieldEvent = {
    type: EventTypes.ON_FIELD_BLUR | EventTypes.ON_FIELD_UPDATE;
    panelId?: KeyTypes.PanelId;
    fieldId: KeyTypes.FieldId;
  };

  export type Event = FormEvent | TableEvent | FieldEvent;

  export type Parameter = {
    aperioId: string;
    panelId?: KeyTypes.PanelId;
    tableId?: KeyTypes.TableId;
    fieldId: KeyTypes.FieldId;
    fieldType: FieldTypes;
  };
}

export type AperioViewCommand = {
  aperioForm: string;
  params: AperioViewCommand.Params;
  errors: AperioViewCommand.Error[];
};
