import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../framework/base';
import { AppState, ErrorType } from '../framework/base/appReducer';
import { AppActions } from '../types/actionTypes';
import './../styles/ErrorModal.scss';

export const ErrorModal = () => {
  const dispatch = useDispatch();
  const appState: AppState = useSelector<RootState, AppState>((state) => state.app);

  const onClick = () => {
    dispatch({ type: AppActions.CLEAR_ERROR });
    if (appState.error.action) appState.error.action();
  };

  return (
    <Modal show={appState.error.exists} id={'error-modal-dialog'}>
      <ModalHeader>
        <Modal.Title>{appState.error.title ?? 'Error occured'}</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <span className='select-span'>{appState.error.message}</span>
      </ModalBody>
      <ModalFooter>
        <Button variant='primary' onClick={() => onClick()} id={'error-modal-button'}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
