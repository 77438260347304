import { DateFormatter } from './DateFormatter';
import { DecimalFormatter } from './DecimalFormatter';
import { IntegerFormatter } from './IntegerFormatter';
import { IntegerWithZerosFormatter } from './IntegerWithZerosFormatter';
import { NumberFormatter } from './NumberFormatter';
import { PadLeadingZerosFormatter } from './PadLeadingZerosFormatter';
import { PadLeadingZerosOrShowEmptyValueFormatter } from './PadLeadingZerosOrShowEmptyValueFormatter';
import { PeriodFormatter } from './PeriodFormatter';
import { PositiveIntegerWithZerosFormatter } from './PositiveIntegerWithZerosFormatter';
import { PositiveIntegerFormatter } from './PositiveIntegerFormatter';
import { RemoveTextLimitMarkerFormatter } from './RemoveTextLimitMarkerFormatter';
import { RemoveTrailingBlanksFormatter } from './RemoveTrailingBlanksFormatter';
import { RemoveTrailingDotsFormatter } from './RemoveTrailingDotsFormatter';
import { TimeValueFormatter } from './TimeValueFormatter';
import { BooleanFormatter } from './BooleanFormatter';

export const Formatters = {
  FormatterNumeric: new NumberFormatter(),
  Date: new DateFormatter(),
  Decimal: new DecimalFormatter(),
  Integer: new IntegerFormatter(),
  RemoveTrailingDots: new RemoveTrailingDotsFormatter(),
  RemoveTrailingBlanks: new RemoveTrailingBlanksFormatter(),
  RemoveTextLimitMarker: new RemoveTextLimitMarkerFormatter(),
  PadZerosOrShowEmpty: new PadLeadingZerosOrShowEmptyValueFormatter(),
  FormattedNumericAllowZeros: new NumberFormatter(),
  PositiveIntegerWithZeros: new PositiveIntegerWithZerosFormatter(),
  PositiveInteger: new PositiveIntegerFormatter(),
  IntegerWithZeros: new IntegerWithZerosFormatter(),
  Time: new TimeValueFormatter(),
  Numeric: new NumberFormatter(),
  Period: new PeriodFormatter(),
  PadZeros: new PadLeadingZerosFormatter(),
  BooleanFormatter: new BooleanFormatter()
};

// extra->> PositiveDecimalFormatter,PositiveDecimalWithZerosFormatter ---> not found inside sql file
