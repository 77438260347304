import { AppActions } from '../../types/actionTypes';
export enum ErrorType {
  axiosError,
  envNotPermitted,
  appNotAuthenticated,
  appLevelError
}
export type AppState = {
  error: {
    exists: boolean;
    type?: ErrorType;
    message?: string;
    title?: string;
    action?: Function;
  };
};
const initialState: AppState = {
  error: { exists: false }
};

export const AppReducer = (state = initialState, action: { type: AppActions; payload: any }) => {
  switch (action.type) {
    case AppActions.CLEAR_ERROR: {
      return { error: { exists: false, type: undefined, message: undefined, title: undefined, action: undefined } };
    }
    case AppActions.SET_ERROR: {
      return {
        error: {
          exists: true,
          type: action.payload.type,
          message: action.payload.message,
          title: action.payload.title,
          action: action.payload.action
        }
      };
    }
    default:
      return state;
  }
};
