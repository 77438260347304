import { HomePage } from './pages/HomePage';
import FileUpload from './FileUpload';
import configStore from './configureStore';
import { Provider } from 'react-redux';
import { WindowProps } from '../views/partials/Window';
import { MemoizedQueryBuilderFrame } from './query-builder';

export interface AppsFunctions {
  print?: Function;
  refresh?: Function;
}

const apps: Record<string, any> = {
  OutboundShipment: (props: WindowProps) => {
    const { store } = configStore();
    return (
      <Provider store={store}>
        <HomePage {...props} />
      </Provider>
    );
  },
  UI_FILEUP: (props: WindowProps) => {
    const { store } = configStore();
    return (
      <Provider store={store}>
        <FileUpload {...props} />
      </Provider>
    );
  },

  QueryBuilder: MemoizedQueryBuilderFrame
};

export default apps;
