import { Component } from 'react';
import './../../styles/Loading.scss';
import { LoaderContainer, LoaderContainerType } from '../../components/LoaderContainer';
import { Localization } from '../../framework/localization/Localization';
import { LoaderProps } from '../..';

export class Loader extends Component<LoaderProps> {
  render() {
    return (
      <div hidden={!this.props.loading} id='loader-overlay'>
        <LoaderContainer
          loaderType={LoaderContainerType.Full}
          text={Localization.instance.getString('LOADER_Loading')}
        ></LoaderContainer>
      </div>
    );
  }
}
