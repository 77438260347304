import { Component, createElement } from 'react';
import { WindowProps } from './Window';
import { connect } from 'react-redux';
import { Localization } from '../../framework/localization/Localization';
import { Command } from '../../framework/base/command';
import { WindowManger } from '../../framework/base/windowManager';
import { WindowActions } from '../../types/actionTypes';
import apps, { AppsFunctions } from '../../apps';
import { Toolbar } from './Toolbar';
import { Container } from 'react-bootstrap';
import '../../styles/Dashboard.scss';
import React from 'react';

class AperioWindowComponent extends Component<WindowProps> {
  state: { initialised: boolean };
  appRef: React.MutableRefObject<AppsFunctions | null>;
  constructor(props: WindowProps) {
    super(props);
    this.state = { initialised: false };
    this.appRef = React.createRef();
    this.appRef.current = {};
    this.setInitalised = this.setInitalised.bind(this);
  }
  setInitalised() {
    this.setState({ initalised: !this.state.initialised });
  }

  render() {
    return (
      <Container fluid className='full-width no-pad no-mar' style={{ height: '100%' }}>
        <div className='aperio-dashboard-container'>
          {createElement(apps[this.props.program.id], {
            ...this.props,
            setInitialised: this.setInitalised,
            ref: this.appRef
          })}
        </div>
        <Toolbar
          startLoading={this.props.startLoading}
          endLoading={this.props.endLoading}
          quickLaunch={this.props.quickLaunch}
          refresh={this.appRef.current?.refresh}
          // print={this.appRef.current?.print}
        />
      </Container>
    );
  }
}

export const AperioWindow = connect(
  (state: any, oldProps: WindowProps) => {
    let window = state.desktop.windows[oldProps.id];

    return {
      driverList: state.desktop.driverList[window?.activeDriver],
      localization: Localization.instance,
      settings: state.desktop.settings,
      actionDefinitions: window?.actionDefinitions,
      contextDefinitions: window?.contextDefinition,
      tabSequence: window?.data?.form.$?.callstack
        ?.split(',')
        ?.find((x: string) => ['GDMR001', 'GDMR201'].indexOf(x) > -1)
        ? window?.tabSequenceBP
        : window?.tabSequenceItem
    };
  },
  {
    interact: (
      windowID: string,
      command: Command,
      endLoading: Function,
      scopeHandler: Function,
      errHandler: Function,
      novalidate?: boolean,
      isPageLoad?: boolean
    ) => WindowManger.Interact(windowID, command, endLoading, scopeHandler, errHandler, novalidate, isPageLoad),
    loadContext: WindowManger.LoadContext,
    loadActions: WindowManger.LoadActions,
    quickLaunch: WindowManger.Launch,
    updateContext: (payload: any) => ({ type: WindowActions.UPDATE_CONTEXT, payload: payload }),
    CloseWindow: (windowId: string, endLoading: Function) => WindowManger.CloseWindow(windowId, endLoading)
  }
)(AperioWindowComponent);
