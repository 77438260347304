import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import Header from './Header';
import { useSelector, useDispatch } from 'react-redux';
import DataGrid, { Row as GridRow, RowRendererProps } from 'react-data-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { WindowProps } from '../../../views/partials/Window';

import '../../../styles/Table.scss';
import {
  shipmentHeadersSelector,
  fetchshipmentHeaders,
  fetchshipmentHeadersStartDate,
  fetchshipmentHeadersEndDate,
  printshipmentHeadersDocuments,
  printshipmentHeadersPickList,
  printshipmentHeadersTransportNote,
  closeshipmentHeadersShipment
} from '../../api/shipmentHeaders';
import { shipmentLinesSelector, fetchshipmentLines } from '../../api/shipmentLines';
import { shipmentAddressSelector, fetchshipmentAddress } from '../../api/shipmentAddresses';
import { wareHousesSelector, fetchwareHouses } from '../../api/warehouses';
import { headerColumns, lineColumns, addressColumns, colMenuFlag } from '../../../constants/ShipmentDashboard';
import { createPortal } from 'react-dom';
import { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } from '@firefox-devtools/react-contextmenu';
import { Col, Row } from 'react-bootstrap';
import { LoaderContainer, LoaderContainerType } from '../../../components/LoaderContainer';
import ShipmentDocument from './components/ShipmentDocument/index';
import CloseShipment from './components/CloseShipment/index';
import TransportNotePrint from './components/TransportNotePrint';
import PicklistPrint from './components/PicklistPrint';
import { Localization } from '../../../framework/localization/Localization';

enum ContextListItems {
  PicklistPrint = 'Create pick lists',
  TransportNotePrint = 'Transport note printout',
  ShipmentDocumentPrint = 'Shipment documents printout',
  CloseShipment = 'Close shipment'
}

export function HomePage(props: WindowProps) {
  const dispatch = useDispatch();
  // const { login } = useSelector(loginSelector);
  const { shipmentHeaders } = useSelector(shipmentHeadersSelector);
  const { shipmentLines } = useSelector(shipmentLinesSelector);
  const { shipmentAddress } = useSelector(shipmentAddressSelector);
  const { wareHouses } = useSelector(wareHousesSelector);

  const [shipmentHeadersData, setShipmentData] = useState<[]>([]);
  const [shipmentLineData, setShipmentLineData] = useState<[]>([]);
  const [shipmentAddressData, setShipmentAddressData] = useState<[]>([]);
  const [wareHousesData, setWareHousesData] = useState<[]>([]);
  const [authtoken, setAuthToken] = useState<string>('');

  const [selectedWareHouse, setSelectedWareHouse] = React.useState<string>('NDC');
  const [selectedshipmentNumber, setSelectedshipmentNumber] = React.useState<number>();
  const [startDate, setStartDate] = useState(new Date(Date.UTC(2010, 0o1 - 1, 20)));
  const [endDate, setEndDate] = useState(new Date());
  const [selected, setSelected] = useState(null);
  const [showList, setShowList] = useState(colMenuFlag);
  const [headCol, setHeadCol] = useState(headerColumns);
  const [updatedHeadCol, setUpdatedHeadCol] = useState(headerColumns);
  const [lineCol, setLineCol] = useState(lineColumns);
  const [updatedLineCol, setUpdatedLineCol] = useState(lineColumns);
  const [addressCol, setAddressCol] = useState(addressColumns);
  const [updatedAddressCol, setUpdatedAddressCol] = useState(addressColumns);
  const [showSD, setShowSD] = useState(false); // show Shipment document panel
  const [showCS, setShowCS] = useState(false); // show Close shipment panel
  const [showTNP, setShowTNP] = useState(false); // show Transport note print panel
  const [showPP, setShowPP] = useState(false); // show Picklist print panel

  // useEffect(() => {
  //   dispatch(fetchlogin());
  // }, []);

  useEffect(() => {
    // if (login) {
    //   setAuthToken(login.login.data.token);

    dispatch(fetchwareHouses(''));
    dispatch(fetchshipmentHeaders(selectedWareHouse, ''));
    // }
  }, []);

  useEffect(() => {
    if (selectedWareHouse) {
      dispatch(fetchshipmentHeaders(selectedWareHouse, ''));
    }
  }, [selectedWareHouse]);

  useEffect(() => {
    try {
      if (shipmentHeaders !== undefined) {
        setShipmentData(shipmentHeaders.shipmentHeaders.data.data.items);
        setSelectedshipmentNumber(shipmentHeaders.shipmentHeaders.data.data.items[0].shipmentNumber);
        dispatch(fetchshipmentLines(shipmentHeaders.shipmentHeaders.data.data.items[0].shipmentNumber, authtoken));
        dispatch(fetchshipmentAddress(shipmentHeaders.shipmentHeaders.data.data.items[0].shipmentNumber, authtoken));
      }
    } catch (e) {}
  }, [shipmentHeaders.shipmentHeaders]);

  useEffect(() => {
    try {
      if (shipmentLines?.shipmentLines?.data?.data?.items !== undefined) {
        setShipmentLineData(shipmentLines.shipmentLines.data.data.items);
      }
    } catch (e) {
      console.error(`Error from HomePage shipmentLines ${e}`);
    }
  }, [shipmentLines.shipmentLines]);

  useEffect(() => {
    try {
      if (shipmentAddress?.shipmentAddress?.data?.data?.items !== undefined) {
        setShipmentAddressData(shipmentAddress.shipmentAddress.data.data.items);
      }
    } catch (e) {
      console.error(`Error from HomePage shipmentAddress ${e}`);
    }
  }, [shipmentAddress.shipmentAddress]);

  useEffect(() => {
    try {
      if (wareHouses?.wareHouses?.data?.data?.items != undefined) {
        setWareHousesData(wareHouses.wareHouses.data.data.items);
      }
    } catch (e) {
      console.error(`Error from HomePage wareHouses ${e}`);
    }
  }, [wareHouses]);

  function yyyymmdd(dateIn: any) {
    var yyyy = dateIn.getFullYear();
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
    return Number(10000 * yyyy + 100 * mm + dd); // Leading zeros for mm and dd
  }
  function selectedStartDate(date: any) {
    setStartDate(date);
    dispatch(fetchshipmentHeadersStartDate(selectedWareHouse, yyyymmdd(date), authtoken));
  }
  function selectedEndDate(date: any) {
    setEndDate(date);
    dispatch(fetchshipmentHeadersEndDate(selectedWareHouse, yyyymmdd(startDate), yyyymmdd(date), authtoken));
  }

  const renderWareHouseList = () => {
    if (wareHousesData === undefined) return;
    return wareHousesData.map((warehouse: any) => (
      <option value={warehouse.warehouse}>{warehouse.warehouse.trim() + ' - ' + warehouse.description.trim()}</option>
    ));
  };

  const onShipDocumentPrint = (params: any) => {
    dispatch(printshipmentHeadersDocuments(params));
    setShowSD((prevState) => !prevState);
  };

  const onPickListPrint = (params: any) => {
    dispatch(printshipmentHeadersPickList(params));
    setShowPP((prevState) => !prevState);
  };

  const onTransportNotePrint = (params: any) => {
    dispatch(printshipmentHeadersTransportNote(params));
    setShowTNP((prevState) => !prevState);
  };

  const onCloseShipment = (params: any) => {
    dispatch(closeshipmentHeadersShipment(params));
    setShowCS((prevState) => !prevState);
  };

  // @ts-ignore
  function onRowDoubleClick(selectedRow: any) {
    if (selectedRow.shipmentNumber) {
      setSelectedshipmentNumber(selectedRow.shipmentNumber);
      dispatch(fetchshipmentLines(selectedRow.shipmentNumber, authtoken));
      dispatch(fetchshipmentAddress(selectedRow.shipmentNumber, authtoken));
    }
  }

  const onRowClick = (i: any, obj: any) => {
    setSelected(obj.shipmentNumber);
  };

  const getRowClass = (params: any) => {
    if (params.shipmentNumber === selected) {
      return 'selected';
    }
  };

  const hideColumn = (e: any, data: any, setUpdatedData: any, setUpdatedCol: any) => {
    let stateData = [...data];
    const updatedData = stateData.map((obj) => {
      if (obj.key === e.target.value) {
        return { ...obj, isVisible: !obj.isVisible };
      }
      return obj;
    });
    setUpdatedData(updatedData);
    const visibleData = updatedData.filter((obj) => obj.isVisible === true);
    setUpdatedCol(visibleData);
  };

  const closeWindowHandler = () => {
    props.startLoading?.();
    props.CloseWindow?.(props.id, props.endLoading);
  };

  return (
    <>
      {!showSD && !showCS && !showTNP && !showPP && (
        <React.Fragment>
          <div className='App'>
            <Row style={{ marginTop: '15px' }}>
              <Col>
                {wareHousesData !== undefined && (
                  <div className='select-menu'>
                    <span
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        flex: '1 1 0'
                      }}
                    >
                      Warehouse :
                    </span>
                    <select
                      style={{
                        margin: '4px'
                      }}
                      value={selectedWareHouse}
                      onChange={(e) => setSelectedWareHouse(e.target.value)}
                    >
                      {renderWareHouseList()}
                    </select>
                  </div>
                )}
              </Col>
              <Col>
                <div className='select-menu'>
                  <span
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      flex: '1 1 50%'
                    }}
                  >
                    {' '}
                    Start Date :
                  </span>
                  <DatePicker selected={startDate} onChange={(date) => selectedStartDate(date)} />
                </div>
              </Col>
              <Col>
                <div className='select-menu'>
                  <span
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      flex: '1 1 50%'
                    }}
                  >
                    {' '}
                    End Date :
                  </span>
                  <DatePicker selected={endDate} onChange={(date) => selectedEndDate(date)} />
                </div>
              </Col>
            </Row>
            {/*<div className="rows" style={{ margin: '16px', display: 'flex' }}>*/}
            {/*  {wareHousesData !== undefined && (*/}
            {/*    <div style={{ height: '50px' }}>*/}
            {/*      <div>*/}
            {/*        <span style={{ fontWeight: 'bold' }}> Select WareHouse</span>*/}

            {/*        <select*/}
            {/*          style={{ margin: '4px', width: '50%' }}*/}
            {/*          value={selectedWareHouse}*/}
            {/*          onChange={(e) => setSelectedWareHouse(e.target.value)}*/}
            {/*        >*/}
            {/*          {renderWareHouseList()}*/}
            {/*        </select>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*  <div>*/}
            {/*    <span style={{ fontWeight: 'bold' }}> Start Date</span>*/}
            {/*    <DatePicker className="row" selected={startDate} onChange={(date) => selectedStartDate(date)} />*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <span style={{ fontWeight: 'bold' }}> End Date</span>*/}
            {/*    <DatePicker className="row" selected={endDate} onChange={(date) => selectedEndDate(date)} />*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div style={{ marginLeft: '16px', paddingTop: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Shipment Header</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  position: 'relative'
                }}
              >
                <button
                  className='selector-btn'
                  onClick={() =>
                    setShowList({
                      ...showList,
                      headerMenulist: !showList.headerMenulist
                    })
                  }
                >
                  Edit Column Visibility
                </button>
                {showList.headerMenulist && (
                  <div className='col-selector'>
                    {updatedHeadCol.map((column, index) => (
                      <div className='selector-list'>
                        <input
                          className='list-box'
                          type='checkbox'
                          defaultChecked={column.isVisible}
                          key={column.key}
                          name={column.name}
                          value={column.key}
                          onChange={(e) => hideColumn(e, updatedHeadCol, setUpdatedHeadCol, setHeadCol)}
                        />
                        <span style={{ marginLeft: '8px' }}>{column.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {shipmentHeadersData && (
                <DataGrid
                  rowRenderer={(props: RowRendererProps<any>) => {
                    return (
                      <ContextMenuTrigger
                        id='grid-context-menu'
                        collect={() => setSelectedshipmentNumber(props.row.shipmentNumber)}
                      >
                        <GridRow onDoubleClick={(e) => onRowDoubleClick(props.row)} {...props} />
                      </ContextMenuTrigger>
                    );
                  }}
                  rowClass={getRowClass}
                  onRowClick={(i, obj) => onRowClick(i, obj)}
                  className={'rdg-light data-table ignore-event fill-grid'}
                  style={{
                    height: '150px',
                    marginTop: '1px',
                    resize: 'vertical'
                  }}
                  columns={headCol}
                  // rows={shipmentHeadersData}
                  rows={!shipmentHeaders.loading ? shipmentHeadersData : []}
                  emptyRowsRenderer={() =>
                    shipmentHeaders.loading && (
                      <LoaderContainer
                        loaderType={LoaderContainerType.ImageOnly}
                        text={Localization.instance.getString('TXT_Loading')}
                      />
                    )
                  }
                />
              )}
            </div>
            <div style={{ marginLeft: '16px', paddingTop: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Shipment Lines</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  position: 'relative'
                }}
              >
                <button
                  className='selector-btn'
                  onClick={() =>
                    setShowList({
                      ...showList,
                      lineMenuList: !showList.lineMenuList
                    })
                  }
                >
                  Edit Column Visibility
                </button>
                {showList.lineMenuList && (
                  <div className='col-selector'>
                    {updatedLineCol.map((column, index) => (
                      <div className='selector-list'>
                        <input
                          className='list-box'
                          type='checkbox'
                          defaultChecked={column.isVisible}
                          key={column.key}
                          name={column.name}
                          value={column.key}
                          onChange={(e) => hideColumn(e, updatedLineCol, setUpdatedLineCol, setLineCol)}
                        />
                        <span style={{ marginLeft: '8px' }}>{column.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {shipmentLineData && (
                <DataGrid
                  style={{
                    height: '125px',
                    marginTop: '1px',
                    resize: 'vertical'
                  }}
                  columns={lineCol}
                  // rows={shipmentLineData}
                  rows={!shipmentLines.loading && !shipmentHeaders.loading ? shipmentLineData : []}
                  className={'rdg-light data-table ignore-event fill-grid'}
                  emptyRowsRenderer={() =>
                    (shipmentLines.loading || shipmentHeaders.loading) && (
                      <LoaderContainer
                        loaderType={LoaderContainerType.ImageOnly}
                        text={Localization.instance.getString('TXT_Loading')}
                      />
                    )
                  }
                />
              )}
            </div>
            <div style={{ marginLeft: '16px', paddingTop: '10px' }}>
              <span style={{ fontWeight: 'bold' }}>Shipment Addresses</span>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  position: 'relative'
                }}
              >
                <button
                  className='selector-btn'
                  onClick={() =>
                    setShowList({
                      ...showList,
                      addressMenuList: !showList.addressMenuList
                    })
                  }
                >
                  Edit Column Visibility
                </button>
                {showList.addressMenuList && (
                  <div className='col-selector'>
                    {updatedAddressCol.map((column, index) => (
                      <div className='selector-list'>
                        <input
                          className='list-box'
                          type='checkbox'
                          defaultChecked={column.isVisible}
                          key={column.key}
                          name={column.name}
                          value={column.key}
                          onChange={(e) => hideColumn(e, updatedAddressCol, setUpdatedAddressCol, setAddressCol)}
                        />
                        <span style={{ marginLeft: '8px' }}>{column.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {shipmentAddressData && (
                <DataGrid
                  style={{
                    height: '125px',
                    marginTop: '1px',
                    resize: 'vertical'
                  }}
                  columns={addressCol}
                  rows={!shipmentAddress.loading && !shipmentHeaders.loading ? shipmentAddressData : []}
                  className={'rdg-light data-table ignore-event fill-grid'}
                  emptyRowsRenderer={() =>
                    (shipmentAddress.loading || shipmentHeaders.loading) && (
                      <LoaderContainer
                        loaderType={LoaderContainerType.ImageOnly}
                        text={Localization.instance.getString('TXT_Loading')}
                      />
                    )
                  }
                />
              )}
            </div>
            {/* Context menu */}
            {createPortal(
              <div className='rows' style={{ display: 'flex' }}>
                <ContextMenu id='grid-context-menu' style={{ backgroundColor: 'white' }}>
                  <MenuItem onClick={() => setShowPP((prevState) => !prevState)}>
                    {ContextListItems.PicklistPrint}
                  </MenuItem>
                  <MenuItem onClick={() => setShowTNP((prevState) => !prevState)}>
                    {ContextListItems.TransportNotePrint}
                  </MenuItem>
                  <MenuItem onClick={() => setShowSD((prevState) => !prevState)}>
                    {ContextListItems.ShipmentDocumentPrint}
                  </MenuItem>
                  <MenuItem onClick={() => setShowCS((prevState) => !prevState)}>
                    {ContextListItems.CloseShipment}
                  </MenuItem>
                </ContextMenu>
              </div>,
              document.body
            )}
          </div>
          {/* <Footer/> */}
          {/* <div className="uidfooter">
            <button className="btn btn-outline-dark btn-lg" onClick={closeWindowHandler}>
              Close
            </button>
          </div> */}
          <div className='container-fluid'>
            <div className='button-bar row no-gutters'>
              <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='row no-gutters'>
                  <div className='col-md-1'>
                    <div className='button-container'>
                      <button
                        id='BTN3'
                        data-arrow-navigation='true'
                        type='button'
                        className='truncate btn-block btn btn-outline-dark btn-lg'
                        onClick={closeWindowHandler}
                      >
                        Exit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {showSD && (
        <ShipmentDocument
          handleClose={() => setShowSD((prevState) => !prevState)}
          shipmentNumber={selectedshipmentNumber}
          onShipDocumentPrint={(params: any) => onShipDocumentPrint(params)}
        />
      )}
      {showCS && (
        <CloseShipment
          handleClose={() => setShowCS((prevState) => !prevState)}
          shipmentData={shipmentHeadersData.find((data: any) => data.shipmentNumber === selectedshipmentNumber)}
          onCloseShipment={(params: any) => onCloseShipment(params)}
        />
      )}
      {showTNP && (
        <TransportNotePrint
          handleClose={() => setShowTNP((prevState) => !prevState)}
          shipmentData={shipmentHeadersData.find((data: any) => data.shipmentNumber === selectedshipmentNumber)}
          onTransportNotePrint={(params: any) => onTransportNotePrint(params)}
        />
      )}
      {showPP && (
        <PicklistPrint
          handleClose={() => setShowPP((prevState) => !prevState)}
          shipmentData={shipmentHeadersData.find((data: any) => data.shipmentNumber === selectedshipmentNumber)}
          onPicklistPrint={(params: any) => onPickListPrint(params)}
        />
      )}
    </>
  );
}
