import { ValueFormatterExtension } from './ValueFormatterExtension';

export class IntegerWithZerosFormatter implements ValueFormatterExtension {
  in(value: string) {
    if (~~value == 0) value = '0';
    return '';
  }

  out(value: string, limit: number) {
    if (value == null || value.trim().length == 0) {
      value.padEnd(limit, '0');
    }

    return value;
  }
}
