import { useEffect, useState } from 'react';

export const connectLoader = <P extends Object>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper = (props: P & { loading: boolean }) => {
    const [resultingProps, setResultingProps] = useState<P>(props);
    useEffect(() => {
      if (!props.loading) {
        setResultingProps(props);
      }
    });
    return <WrappedComponent {...resultingProps} />;
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  Wrapper.displayName = `connectLoader(${displayName})`;

  return Wrapper;
};

// Original version Archit: kept to allow quick revert if needed
//  |--> typing added & only changed behavior while "Loading" (trying to limit deviation from default REACT behavior)
// import { cloneDeep, isEqual } from 'lodash';

// export const connectLoader = (WrappedComponent: any) => {
//   return (props: any) => {
//     const [newProps, setNewProps] = useState(props);
//     useEffect(() => {
//       //TODO: Remove console logs before merge
//       console.error('=========== Props changed ================: ', props.loading);
//       if (!props.loading && !isEqual(props, newProps)) {
//         setNewProps(props);
//         console.log('=========== Props updated ================: ', props.loading);
//       }
//     }, [props]);
//     return <WrappedComponent {...newProps} />;
//   };
// };
