import React, { FunctionComponent, KeyboardEvent, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Aperio } from '../../components/Aperio';
import { XT } from '../../framework/handlers/xt';
import { ContextHelp } from '../../components/ContextHelp';
import { connect } from 'react-redux';
import { ErrorMessage, ErrorMessages } from '../../components/ErrorMessages';
import { WindowActions } from '../../types/actionTypes';
import { Attachment, Attachments } from '../../components/Attachment';
import ReactTooltip from 'react-tooltip';
import { Localization } from '../../framework/localization/Localization';
import { RootState } from '../../framework/base';
import { AperioViewCommand, AperioViews } from '../../types/AperioViews';
import { Badge, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import '../../styles/Notes.scss';
import { useNotes } from '../../framework/your-note/NotesProvider';
import { Notes, AddNotebutton, AutoSortNotes } from '../../framework/your-note/Notes';
import { useAuth } from 'react-oidc-context';

type ContextProps = {
  contextArea?: string;
  aperioViews?: AperioViews;
  aperioViewCommand?: AperioViewCommand;
  activeAperioLinks?: AperioViews.Link[];
  attachments?: any;
  attachmentKeys?: any;
  resizerRef?: RefObject<HTMLDivElement>;
  updateContext?: (payload: any) => void;
  data?: Record<string, any>;
  window: string;
  messages?: ErrorMessage[];
  isDialog: boolean;
  restoreFocusWindow?: () => void;
  settings?: Record<string, any>;
};

const ContextAreaComponent: FunctionComponent<Partial<ContextProps>> = ({
  contextArea,
  aperioViews,
  aperioViewCommand,
  activeAperioLinks,
  attachments,
  window: windowId,
  attachmentKeys,
  resizerRef,
  updateContext,
  data,
  messages,
  isDialog,
  restoreFocusWindow,
  settings
}) => {
  const _attachments = new Attachments(attachments);
  XT.attachments[data?.form.$.id] = _attachments;
  const [attachmentList, setAttachmentList] = useState(attachments);

  const source = useMemo(() => {
    if (aperioViews?.path) {
      // Determine culture from current locale
      let culture = Localization.instance.locale.toLocaleLowerCase().trim();
      if (culture.length > 2) {
        culture = culture.substring(0, 2) + culture.substring(2).toLocaleUpperCase(); //eg nl_be => nl_BE
      }

      // Get company id
      const companyInfo = sessionStorage.getItem('company') ? JSON.parse(sessionStorage.getItem('company') ?? '') : '';
      const companyId = companyInfo?.id || '';

      // Add parameters to configuration url
      const url = new URL(aperioViews.path);
      url.searchParams.append('lng', culture);
      url.searchParams.append('env-company', companyId);

      // Return resiulting ource
      return url.toString();
    } else {
      return '';
    }
  }, [aperioViews?.path]);

  useEffect(() => {
    XT.attachments[data?.form.$.id].setList(attachments);
  }, [attachments]);

  useEffect(() => {
    restoreFocusWindow?.(); // (for now) whenever type of context changes always return focus to the active window (as agreed)
    // originally only restore focus when contextarea closed: if (contextArea?.trim() === '') restoreFocusWindow?.();
  }, [contextArea]);

  setTimeout(() => ReactTooltip.rebuild(), 100);

  const auth = useAuth();

  const { connectionStatus, notes, setEnabled } = useNotes();

  useEffect(() => {
    setEnabled(contextArea === 'your-note');
  }, [contextArea]);

  return (
    <>
      <ErrorMessages
        messages={messages}
        type='error'
        visible={!!messages && contextArea !== 'error'}
        showMore={() => updateContext?.({ contextArea: 'error' })}
      />
      <div hidden={isDialog} className={'panel-actions'}>
        {connectionStatus && (
          <OverlayTrigger
            placement='top'
            delay={50}
            overlay={
              <Tooltip className='custom' id={`tooltip-help`}>
                {Localization.instance.getString('CONTEXT_AREA_Your_Notes')}
              </Tooltip>
            }
            rootClose
          >
            <span
              className={contextArea === 'your-note' ? 'active' : ''}
              onClick={() => updateContext?.({ contextArea: 'your-note' })}
            >
              <SquareIcon size={'20px'} className={'icon-primary-nav'}>
                {Icons.Csv}
              </SquareIcon>
              {notes?.length > 0 && (
                <Badge className={'note-length-badge'} pill variant='primary'>
                  {notes.length}
                </Badge>
              )}
            </span>
          </OverlayTrigger>
        )}
        {(activeAperioLinks?.length || 0) > 0 && (
          <OverlayTrigger
            placement='top'
            delay={50}
            overlay={
              <Tooltip className='custom' id={`tooltip-help`}>
                {Localization.instance.getString('Extended_information')}
              </Tooltip>
            }
            rootClose
          >
            <span
              className={contextArea === 'aperio' ? 'active' : ''}
              onClick={() => updateContext?.({ contextArea: 'aperio' })}
            >
              <SquareIcon size={'20px'} className={'icon-primary-nav'}>
                {Icons.Information}
              </SquareIcon>
            </span>
          </OverlayTrigger>
        )}
        {attachments && attachments.length > 0 && (
          <OverlayTrigger
            placement='top'
            delay={50}
            overlay={
              <Tooltip className='custom' id={`tooltip-help`}>
                {Localization.instance.getString('Attachments')}
              </Tooltip>
            }
            rootClose
          >
            <span
              className={contextArea === 'attachments' ? 'active' : ''}
              onClick={() => updateContext?.({ contextArea: 'attachments' })}
            >
              <SquareIcon size={'20px'} className={'icon-primary-nav'}>
                {Icons.Paperclip}
              </SquareIcon>
            </span>
          </OverlayTrigger>
        )}
        <OverlayTrigger
          placement='top'
          delay={50}
          overlay={
            <Tooltip className='custom' id={`tooltip-help`}>
              {Localization.instance.getString('Context_help')}
            </Tooltip>
          }
          rootClose
        >
          <span
            className={contextArea === 'help' ? 'active' : ''}
            onClick={() => updateContext?.({ contextArea: 'help' })}
          >
            <SquareIcon size={'20px'} className={'icon-primary-nav'}>
              {Icons.HelpCircle}
            </SquareIcon>
          </span>
        </OverlayTrigger>
      </div>
      <div ref={resizerRef} hidden={!contextArea} className={'context-area'}>
        <span
          onMouseDown={() => {
            document.body.className += ' no-select resizing';
            resizerRef?.current?.setAttribute('data-resizing', 'true');
            setTimeout(() => ReactTooltip.rebuild(), 100);
          }}
          className='resizer'
        />
        <div className={'panel-container'}>
          <h6 style={{ position: 'relative' }}>
            {contextArea === 'your-note' && <AddNotebutton />}
            {contextArea === 'help' && Localization.instance.getString('CONTEXT_AREA_ContextHelp')}
            {contextArea === 'aperio' && Localization.instance.getString('CONTEXT_AREA_MoreInformation')}
            {contextArea === 'attachments' && Localization.instance.getString('CONTEXT_AREA_Attachments')}
            {contextArea === 'error' && Localization.instance.getString('CONTEXT_AREA_Messages')}
            {contextArea === 'your-note' && Localization.instance.getString('CONTEXT_AREA_Your_Notes')}
            {contextArea === 'your-note' && <AutoSortNotes />}
            <Button
              variant='flat'
              className={'context-close panel-close'}
              onClick={() => updateContext?.({ contextArea: '' })}
            >
              <SquareIcon size={'20px'} className={'icon-primary-nav'}>
                {Icons.Close}
              </SquareIcon>
            </Button>
            {contextArea === 'help' && (
              <a
                href={`${XT.globalConfig?.helpLinkBase}/help/${data?.form.$.id}/?simple`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <SquareIcon size={'20px'} className={'icon-primary-nav panel-close'}>
                  {Icons.Open}
                </SquareIcon>
              </a>
            )}
          </h6>
        </div>
        {contextArea === 'aperio' && (
          <Aperio
            source={source}
            aperioForm={aperioViewCommand?.aperioForm || ''}
            params={aperioViewCommand?.params || {}}
            errors={aperioViewCommand?.errors || []}
          />
        )}
        {contextArea === 'help' && (
          <ContextHelp
            anchor={
              data?.form.screen_data?.[0]?.format_name?.find(
                (x: any) => x.$.opcode === 'EXFMT' || x.$.opcode === 'READ'
              ).$.id
            }
            aperioFormId={data?.form.$.id}
            windowId={windowId ?? ''}
          />
        )}
        {contextArea === 'error' && (
          <div className='details-list'>
            {messages?.map((message, index) => {
              return (
                <div key={index + 1} className='message-details bottom-border'>
                  <div className='title'>{!!message.title ? message.title : message.body}</div>
                  {!!message.title && <div className='body'>{message.body}</div>}
                </div>
              );
            })}
          </div>
        )}
        {contextArea === 'attachments' && (
          <Attachment
            windowId={windowId ?? ''}
            addAttachment={XT.attachments[data?.form.$.id]?.addAttachmentToList}
            removeAttachment={XT.attachments[data?.form.$.id]?.removeAttachmentFromList}
            attachmentList={XT.attachments[data?.form.$.id]?.createAttachmentListRows(attachmentKeys || [])}
          />
        )}
        {contextArea === 'your-note' && <Notes companies={settings?.regionals?.company} />}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState, props: ContextProps) => {
  let window = state.desktop.windows[props.window];
  let contextArea = state.desktop.contextAreas[props.window];
  return {
    contextArea: window?.contextArea,
    aperioViews: state.desktop.settings.regionals?.aperioviews as AperioViews,
    aperioViewCommand: contextArea?.aperioViewCommand,
    activeAperioLinks: contextArea?.activeAperioLinks,
    attachments: window?.attachments,
    attachmentKeys: window?.attachmentKeys,
    updateContext: window?.updateContext,
    data: window?.data,
    settings: state.desktop.settings
  };
};

const mapDispatchToProps = {
  updateContext: (payload: any) => ({ type: WindowActions.UPDATE_CONTEXT, payload: payload })
};

export const ContextArea = connect(mapStateToProps, mapDispatchToProps)(ContextAreaComponent);
