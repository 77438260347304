import React, { useState, FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.scss';

type ShipmentDocumentProps = {
  handleClose?: () => void;
  shipmentNumber: any;
  onShipDocumentPrint: (arg: any) => void;
};

const selectPrintOptions = [
  { label: 'Loading list', name: 'printLoadingList' },
  { label: 'Route delivery list', name: 'printRouteDeliveryList' },
  { label: 'Shipment manifest', name: 'printShipmentManifest' },
  { label: 'Shipment confirmation', name: 'printShipmentConfirmation' }
];
const SpecificationOptions = [
  { label: 'Returns', name: 'returns' },
  { label: 'Package information', name: 'packageInformation' },
  { label: 'Order lines', name: 'orderLines' }
];

const ShipmentDocument: FunctionComponent<ShipmentDocumentProps> = ({
  handleClose,
  shipmentNumber,
  onShipDocumentPrint
}) => {
  const [params, setParams] = useState({
    shipment: shipmentNumber,
    printLoadingList: false,
    printRouteDeliveryList: false,
    printShipmentManifest: false,
    printShipmentConfirmation: false,
    returns: false,
    packageInformation: false,
    orderLines: false
  });

  const handleChange = (e: any) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setParams({
      ...params,
      [e.target.name]: value
    });
  };

  return (
    <React.Fragment>
      <div className='header'>
        <h5>Shipment documents printout</h5>
      </div>
      <div style={{ height: 'calc(100vh - 235px)', overflow: 'auto' }}>
        <div style={{ padding: '20px 10px 20px 50px' }}>
          <div style={{ marginBottom: '15px' }}>
            <label className='label'>Shipment number</label>
            <input
              className='uidOutputOnly'
              type='text'
              readOnly
              value={params.shipment}
              name='shipmentNumber'
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <div style={{ fontWeight: 600, marginBottom: '5px' }}>Select printouts :</div>
            {selectPrintOptions.map((obj, idx) => {
              return (
                <div key={idx}>
                  <label className='label'>{obj.label}</label>
                  <input className='boxes' type='checkbox' name={obj.name} onChange={(e) => handleChange(e)} />
                </div>
              );
            })}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <div style={{ fontWeight: 600, marginBottom: '5px' }}>Specification :</div>
            {SpecificationOptions.map((obj, idx) => {
              return (
                <div key={idx}>
                  <label className='label'>{obj.label}</label>
                  <input className='boxes' type='checkbox' name={obj.name} onChange={(e) => handleChange(e)} />
                </div>
              );
            })}
          </div>
          {/* <div>
            <div>
              <label className="label">Printer queue</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Number of copies</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Hold on spool file</label>
              <input type="checkbox" />
            </div>
          </div> */}
        </div>
      </div>
      <div className='footer'>
        <button onClick={() => onShipDocumentPrint(params)}>Ok</button>
        <button onClick={handleClose}>Back</button>
      </div>
    </React.Fragment>
  );
};

export default ShipmentDocument;
