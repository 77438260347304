import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { Dispatch } from 'redux';

export const initialState = {
  loading: false,
  hasErrors: false,
  shipmentAddress: {}
};

// A slice for shipmentAddress with our three reducers
const shipmentAddressSlice = createSlice({
  name: 'shipmentAddress',
  initialState,
  reducers: {
    getshipmentAddress: (state) => {
      state.loading = true;
    },
    getshipmentAddressSuccess: (state, { payload }) => {
      state.shipmentAddress = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getshipmentAddressFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    }
  }
});

// Three actions generated from the slice
export const {
  getshipmentAddress,
  getshipmentAddressSuccess,
  getshipmentAddressFailure
} = shipmentAddressSlice.actions;

// A selector
export const shipmentAddressSelector = (state: any) => state;

// The reducer
export default shipmentAddressSlice.reducer;

// Asynchronous thunk action
export function fetchshipmentAddress(shipment: number, token: string) {
  return async (dispatch: Dispatch) => {
    dispatch(getshipmentAddress());
    try {
      const url = BASE_URL + '/service';

      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidOutboundShipmentAddresses.get',
          params: { shipment: shipment }
        }
      })
        .then((result) => {
          dispatch(getshipmentAddressSuccess(result));
          // alert("shipmentAddress Successful")
        })
        .catch((err) => {
          // Do somthing
          dispatch(getshipmentAddressFailure());
          console.error(`Error from fetchshipmentAddress ${err}`);
          // alert("shipmentAddress Failed. Please check your credentials")
        });
    } catch (error) {
      dispatch(getshipmentAddressFailure());
      // alert("Failed")
    }
  };
}
