import { ValueFormatterExtension } from './ValueFormatterExtension';

export class RemoveTrailingDotsFormatter implements ValueFormatterExtension {
  in(value: string) {
    while (value[value.length - 1] === '.' || value[value.length - 1] === '.' || value[value.length - 1] === ':') {
      value = value.slice(0, -1);
    }
    return value;
  }

  out(value: string): any {
    return value;
  }
}
