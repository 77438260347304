import { DecimalFormatter } from '../parsers/DecimalFormatter';
import { Localization } from '../localization/Localization';

export class PositiveDecimalWithZerosFormatter {
  in(value: string, decimalSeperator: string) {
    /*
    value = value.trim();

    if (decimalSeperator == ',') {
      value = value.replace(',', '.');
    }
    let noOFDec = value.indexOf('.') === -1 ? -1 : value.length - value.indexOf('.') - 1;
    let d = parseFloat(value);
    if (d == 0) return `0${decimalSeperator}00`;
    if (d < 0) d = d * -1;
    value = d.toString();
    if (noOFDec !== -1) value = d.toFixed(noOFDec);
    let temp = value;
    const gs = Localization.instance.groupSeparator || ',';
    const ds = Localization.instance.decimalSeparator || '.';
    temp = temp.replace(/([,.])/g, (string) => (string === ',' ? gs : ds));
    return temp;
    */
    /* 
      REMARK: 
        ° FAST FIX, NTH clean-up 
        ° original code returns 1.00 for input value 1,000.12
        ° original code returns 0.00 for every zero value (eg 0, 0.000)
        ° original code returns 0.00 for every code even if client decimal seperator is comma (,))
        ° original code returns Nan if value string empty 
    */

    const groupSepLocale = decimalSeperator === '.' ? ',' : '.';
    let temp = new DecimalFormatter().in(value, decimalSeperator);
    if (temp.startsWith('-')) temp = temp.substring(1);
    const ds = Localization.instance.decimalSeparator;
    const gs = Localization.instance.groupSeparator;
    if (temp.replaceAll(ds, '').replaceAll(gs, '').replaceAll('0', '').trim() === '') {
      value = value.trim();
      if (value.indexOf(decimalSeperator) === -1) return '0';
      const noOFDec = value.length - value.indexOf(decimalSeperator) - 1;
      return `0${ds}${'0'.repeat(noOFDec)}`;
    }
    return temp;
  }

  out(value: string, decimalSeperator: string, groupSepLocale: string, externalize: boolean) {
    if (externalize) {
      let temp = value;
      // temp = temp.replace(groupSepLocale, '');
      // if (decimalSeperator == ',') temp = temp.replace(',', '.');
      if (decimalSeperator == ',') temp = temp.replace('.', ',');
      // let d = parseFloat(temp);
      value = temp.toString();
    } else {
      value = this.in(value, decimalSeperator);
    }

    return value;
  }
}
