import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import { WidgetTable } from './partials/WidgetTable';
import { Localization } from '../../framework/localization/Localization';
import { RootState, selectCurrentUser } from '../../framework/base';
const DashboardTable: FunctionComponent<any> = (props: any) => {
  const headers = [
    { label: 'Type', key: 'eventType', width: 3 },
    { label: 'Message', key: 'messageText', width: 9 }
  ];
  const [response, setResponse] = useState<any>({});
  const getData = async () => {
    const date = new Date();
    const millisec = date.getTime();
    const id = millisec.toString(36);
    let payload = {
      IptorAPI: '1.0',
      method: 'mobileAlertMessages.get',
      id: id,
      params: {
        receiver: props.user.id
      }
    };
    let res = await axios
      .request({
        url: '/aperio/api/service',
        data: payload,
        method: 'POST'
      })
      .catch((err) => {
        console.error(`Error from DashboardTable ${err}`);
      });
    const data = {
      rows: res?.data?.data?.items
    };
    setResponse(data || {});
  };

  useEffect(() => {
    getData();
  }, [props]);

  return (
    <WidgetTable
      title={props.title || Localization.instance.getString('DASHBOARD_MobileAlerts')}
      headers={headers}
      rows={response.rows}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const props = {
    user: selectCurrentUser(state)
  };
  return props;
};

const DashboardTableComponent = connect(mapStateToProps)(DashboardTable);

const dashboardTable: CellPlugin = {
  Renderer: ({ data }: any) => {
    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <DashboardTableComponent title={data.title} height={data.height} />
        </Widget>
      </div>
    );
  },
  id: 'alerts-table',
  title: 'Mobile alerts',
  description: 'Display mobile alerts from DC1',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default dashboardTable;
