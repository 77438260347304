import store from '../base/index';

enum LocalStorageKeys {
  LOGIN_DEFAULTS = 'loginDefaults',
  NUMPAD_SIGNS_BEHAVIOR = 'numpadSignsBehavior'
}

namespace LoginDefaults {
  export type UserId = string;
  type EnvironmentId = string;
  type CompanyId = string;
  export type EnvironmentInfo = {
    prevEnvironmentId: EnvironmentId;
    prevCompanyId: Record<EnvironmentId, CompanyId>;
  };
}
type LoginDefaults = Record<LoginDefaults.UserId, LoginDefaults.EnvironmentInfo>;

namespace NumpadSignsBehavior {
  export type UserId = string;
  export type Settings = {
    actAsTab: boolean;
    clearIfAllSelected: boolean;
  };
}
type NumpadSignsBehavior = Record<NumpadSignsBehavior.UserId, NumpadSignsBehavior.Settings>;

export class LocalStorage {
  private static _currentUserId: string;
  private static _loginDefaults: LoginDefaults;
  private static _numpadSignsBehavior: NumpadSignsBehavior;

  private static get currentUserId(): string {
    if (!LocalStorage._currentUserId) {
      const { desktop } = store.getState();
      const userId = desktop.settings?.regionals?.user?.[0]?.$?.userId || '';
      LocalStorage._currentUserId = userId.trimEnd().toLocaleUpperCase();
    }
    return LocalStorage._currentUserId;
  }

  private static get loginDefaults(): LoginDefaults {
    if (!LocalStorage._loginDefaults) {
      try {
        const info = localStorage.getItem(LocalStorageKeys.LOGIN_DEFAULTS);
        LocalStorage._loginDefaults = (!!info ? JSON.parse(info) : null) || {};
      } catch (e) {
        LocalStorage._loginDefaults = {};
      }
    }
    return LocalStorage._loginDefaults;
  }

  private static get numpadSignsBehavior(): NumpadSignsBehavior {
    if (!LocalStorage._numpadSignsBehavior) {
      try {
        const info = localStorage.getItem(LocalStorageKeys.NUMPAD_SIGNS_BEHAVIOR);
        LocalStorage._numpadSignsBehavior = (!!info ? JSON.parse(info) : null) || {};
      } catch (e) {
        LocalStorage._numpadSignsBehavior = {};
      }
    }
    return LocalStorage._numpadSignsBehavior;
  }

  public static LoginDefaults = {
    getEnvironmentId: (userId: string): string | null => {
      if (!userId) return null;
      return LocalStorage.loginDefaults[userId.trimEnd().toLocaleUpperCase()]?.prevEnvironmentId || null;
    },

    getCompanyId: (userId: string, environmentId: string): string | null => {
      if (!userId) return null;
      const environmentKey = environmentId ? environmentId.trimEnd() : '*NONE';
      return LocalStorage.loginDefaults[userId.trimEnd().toLocaleUpperCase()]?.prevCompanyId[environmentKey] || null;
    },

    save: (userId: string, environmentId: string, companyId: string) => {
      if (!userId) return;
      userId = userId.trimEnd().toLocaleUpperCase();
      environmentId = (environmentId || '').trimEnd();
      const environmentKey = environmentId ? environmentId : '*NONE';
      companyId = (companyId || '').trimEnd();

      let loginDefaults = LocalStorage.loginDefaults;
      let userDefaults = loginDefaults[userId];
      if (userDefaults) {
        userDefaults.prevEnvironmentId = environmentId;
        if (userDefaults.prevCompanyId) {
          userDefaults.prevCompanyId = { ...userDefaults.prevCompanyId, [environmentKey]: companyId };
        } else {
          userDefaults.prevCompanyId = { [environmentKey]: companyId };
        }
      } else {
        userDefaults = { prevEnvironmentId: environmentId, prevCompanyId: { [environmentKey]: companyId } };
      }
      loginDefaults = { ...loginDefaults, [userId]: userDefaults };
      localStorage.setItem(LocalStorageKeys.LOGIN_DEFAULTS, JSON.stringify(loginDefaults));
      LocalStorage._loginDefaults = loginDefaults;
    }
  };

  public static NumpadSignsBehavior = {
    getSettings: (): NumpadSignsBehavior.Settings => {
      const userSettings = LocalStorage.numpadSignsBehavior[LocalStorage.currentUserId] || null;
      if (!userSettings) {
        LocalStorage.NumpadSignsBehavior.save(false, false); // create settings with default behavior
        return LocalStorage._numpadSignsBehavior[LocalStorage.currentUserId];
      } else {
        return userSettings;
      }
    },

    save: (actAsTab: boolean, clearIfAllSelected: boolean) => {
      let numpadSignsBehavior = LocalStorage.numpadSignsBehavior;
      let userSettings = numpadSignsBehavior[LocalStorage.currentUserId];
      if (userSettings) {
        if (userSettings.actAsTab === actAsTab && userSettings.clearIfAllSelected === clearIfAllSelected) return;
        userSettings = { actAsTab: actAsTab, clearIfAllSelected: clearIfAllSelected }; //Remark: new instance needed to allow Setting component(s) to be aware of the changes
      } else {
        userSettings = { actAsTab: actAsTab, clearIfAllSelected: clearIfAllSelected };
      }
      numpadSignsBehavior = { ...numpadSignsBehavior, [LocalStorage.currentUserId]: userSettings };
      localStorage.setItem(LocalStorageKeys.NUMPAD_SIGNS_BEHAVIOR, JSON.stringify(numpadSignsBehavior));
      LocalStorage._numpadSignsBehavior = numpadSignsBehavior;
    }
  };
}
