import React from 'react';
import { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ComponentBaseProps } from '../../framework/parsers/layout/types';
import { WindowActions } from '../../types/actionTypes';
import { XT } from '../../framework/handlers/xt';
import { ColorMap } from '../../types/colorMap';
import { HighlightFocus } from '../../framework/your-note/NotesProvider';

//TODO: Update types
export type PanelProps = ComponentBaseProps & {
  executedPanel?: string;
  updateTab: Function;
  panelCount: number;
};

class PanelComponent extends Component<PanelProps> {
  state: { height: number; considered: string[] };
  _getPanelComponentsResult: any; // BEWARE: do NOT access directly
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();
  innerContainerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: PanelProps) {
    super(props);
    this.state = { height: 0, considered: [] };
    this.getPanelComponents = this.getPanelComponents.bind(this);
  }

  componentDidMount() {
    XT.loadContextMenu(this.props);
    if (!!this.props.protectedPanel && this.props.protectedPanel !== this.props.panelID) {
      let panelElements = document.querySelectorAll(`.window.active .xt-panel[data-panel='${this.props.panelID}'] *`);
      panelElements.forEach((element: Element) => {
        // let target = element as HTMLElement;
        element.setAttribute('disabled', 'true');
        // element.className += ' panel-protection-enabled';
      });
    }
    let components = this.getPanelComponents();
    let titles = components.find((x: any) => x.componentName === 'titles');
    if (titles) {
      this.props.updateTitle(titles.props.crumbs, this.props.panelID);
    }
  }

  componentWillUnmount(): void {
    this.props.updateTitle([], this.props.panelID);
  }

  componentDidUpdate(prevProps: Readonly<PanelProps>, prevState: Readonly<{}>, snapshot?: any) {
    let components = this.getPanelComponents();
    let titles = components.find((x: any) => x.componentName === 'titles');
    if (titles) {
      this.props.updateTitle(titles.props.crumbs, this.props.panelID);
    }
    if (!!this.props.protectedPanel && this.props.protectedPanel !== this.props.panelID) {
      let panelElements = document.querySelectorAll(`.window.active .xt-panel[data-panel='${this.props.panelID}'] *`);
      panelElements.forEach((element: Element) => {
        element.setAttribute('disabled', 'true');
      });
    }
    if (this.props.data.row) {
      if (this.props.data.row !== prevProps.data.row) {
        XT.loadContextMenu(this.props);
      }
    }
  }

  getPanelComponents() {
    this._getPanelComponentsResult = XT.getComponents(this.props, this._getPanelComponentsResult);
    return this._getPanelComponentsResult.components.filter((c: any) => c.componentActive);
  }

  render() {
    let isGrid = this.props.attributes.layout === 'grid';
    let components = this.getPanelComponents();
    let whm = 1.32;
    let wwm = 1.32;
    let fixedGroupWithSingleResizeableTableFactor = 1.66;
    let panelHeight = 0;
    const isSpooledFileViewDialogDetailPanel =
      'SpooledFileViewDialog-DetailPanel' === this.props.windowProps.data.form.$.id + '-' + this.props.panelID;
    const isSpooledfilesDetailPanel =
      'Spooledfiles-DetailPanel' === this.props.windowProps.data.form.$.id + '-' + this.props.panelID;
    const activeAndResizableGroupsInPanel = components.filter(
      (c: any) => c.componentName === 'group' && c.componentLayout.$.anchor === 'LRTB' && c.componentActive === true
    );
    const fixedHeightTablesInPanel = this.props.layout?.group
      ?.find((c: any) => c.table)
      ?.table?.filter(
        (x: any) => x.$.anchor === 'LRT' && x.$.autopagedown?.toLowerCase() === 'false' && !!x.$.pagesize
      );
    const resizeableTableInPanel = this.props.layout?.group
      ?.find((c: any) => c.table)
      ?.table?.filter(
        (x: any) => x.$.anchor === 'LRTB' && !x.$.autopagedown && x.$.autopagedowncount && !!x.$.pagesize
      );

    const isFixedGroupWithSingleResizeableTableExists = this.props.layout?.group?.find(
      (v: any) => v.$?.anchor === 'LRT' && v.table?.length === 1 && v.table[0].$?.anchor === 'LRTB'
    );

    components
      .filter((v: any) => v.props.visible && v.componentName !== 'titles')
      .forEach((v: any) => {
        v.props.windowProps = this.props.windowProps;
        let attrs = v.props.attributes;
        if (['group', 'table', 'tab'].indexOf(v.componentName) > -1) {
          if (!!attrs.height || !!attrs.tempHeight) {
            if (+attrs.height > 1500 || +attrs.tempHeight > 1500) return;
            panelHeight += +(attrs.anchor === 'LRTB' && (+attrs.height > 300 || +attrs.tempHeight > 300)
              ? 300
              : +attrs.height || +attrs.tempHeight || 0);
          }
        }
      });

    // Some hardcoded stuff to get tables on "w/w reports, option layout" without scrollbars for accepted resolution
    if (this.props.windowProps.data.form.$.id === 'RWRD120') {
      if (this.props.panelID === 'SUBFILE1') {
        panelHeight = (9 * 35 + 0 + 10 + 2) / fixedGroupWithSingleResizeableTableFactor; // remark: +2 to compensate table.ypos = 1 & table.bpos = 1 (needed for 701 server only??)
        const group = components.find((g: any) => g.props.attributes.id === 'panelgroup2');
        if (group?.props?.attributes) {
          group.props.attributes.height = panelHeight;
          group.props.attributes.xpos = 0; // "temporary" fix left alignment (NTH: global fix)
          group.props.attributes.ypos = 0;
        }
        const table = group.layout[0].table[0].$;
        if (table) {
          table.ypos = 1; //remark: value 0 makes table invisible
          table.bpos = 1; //remark: value 0 makes table invisible
        }
      } else if (this.props.panelID === 'SUBFILE2') {
        const group = components.find((g: any) => g.props.attributes.id === 'panelgroup3');
        if (group?.props?.attributes) {
          group.props.attributes.ypos = 0;
          group.props.attributes.bpos = 0;
        }
      }
    }

    return (
      <Container
        data-executed={this.props.executedPanel === this.props.panelID}
        id={this.props.windowProps.data.form.$.id + '-' + this.props.panelID}
        fluid
        ref={this.containerRef}
        style={{
          position: 'relative',
          minHeight:
            panelHeight === 0 || isSpooledFileViewDialogDetailPanel || isFixedGroupWithSingleResizeableTableExists
              ? 'auto'
              : (activeAndResizableGroupsInPanel?.length > 0 &&
                  (fixedHeightTablesInPanel?.length > 0 || this.props.attributes.fillvert === 'true')) ||
                (resizeableTableInPanel?.length > 0 && this.props.attributes.fillvert !== 'true') ||
                (activeAndResizableGroupsInPanel?.length > 0 && !fixedHeightTablesInPanel && !resizeableTableInPanel)
              ? panelHeight * whm + 'px'
              : 'fit-content'
        }}
        data-panel={this.props.panelID}
        data-protectedpanel={this.props.protectedPanel}
        className={`xt-panel ${
          (this.props.attributes.fillvert === 'true' ||
            this.props.panelCount === 1 ||
            isSpooledfilesDetailPanel ||
            isSpooledFileViewDialogDetailPanel) &&
          components?.filter((c: any) => !!c.props.visible && c.componentName !== 'titles').length > 0 &&
          'fillvert'
        } ${
          !!this.props.protectedPanel && this.props.panelID !== this.props.protectedPanel
            ? 'panel-protection-enabled'
            : ''
        }`}
      >
        {components
          .filter((v: any) => v.props.visible && v.componentName !== 'titles')
          .map((v: any, i: number) => {
            let attrs = v.props?.attributes;
            if (v.componentName === 'tab') {
              v.props.tabSwitcher = this.props.updateTab;
            }
            v.props.actionHandler = this.props.actionHandler;
            let background: string = 'transparent';
            if (v.props.attributes.background) {
              let _background = v.props.attributes.background;
              if (_background.startsWith('rgb')) {
                background = _background;
              } else {
                background = ColorMap[_background] || 'transparent';
              }
            }
            if (v.componentName === 'group') {
              let components = XT.getComponents({ ...v.props }).components.filter(
                (g: any) => g.componentActive && !!g.props.visible
              );
              if (components.length === 0) return null;
            }

            const hasPanelDynamicTable = v.props.layout?.dynamictable ? true : false;
            // v.props.actionHandler = this.props.actionHandler;

            if (+attrs.xpos < 0 && attrs.width) {
              attrs.width = attrs.width + +attrs.xpos;
            }

            ['xpos', 'ypos', 'rpos', 'bpos'].forEach((pos: string) => {
              attrs[pos] = attrs[pos] < 0 ? 6 : attrs[pos];
              if (attrs[pos] === undefined) delete attrs[pos];
            });

            if (attrs.anchor === 'LRTB' && v.componentName === 'table') attrs.bpos = +attrs.bpos + 6;
            //Check all anchors and remove height/width

            if (attrs.anchor) {
              'LTRB'.split('').forEach((anc: string, index: number) => {
                if (attrs.anchor.includes(anc)) {
                  if (attrs.anchor.includes('LTRB'[(index + 2) % 4])) {
                    if ('LR'.includes(anc)) delete attrs.width;
                    else if ('TB'.includes(anc) && !attrs.tempHeight) {
                      if (
                        !!attrs.height &&
                        (this.props.executedPanel === this.props.panelID || attrs.anchor === 'LRTB')
                      ) {
                        attrs.tempHeight = `${attrs.height}`;
                      } else if (this.props.executedPanel !== this.props.panelID) {
                        attrs.tempHeight = 30;
                      }
                      delete attrs.height;
                    }
                  }
                }
              });

              if (
                attrs.anchor !== 'LRTB' &&
                attrs.anchor.includes('B') &&
                !attrs.anchor.includes('T') &&
                (!!attrs.height || v.componentName !== 'group')
              ) {
                delete attrs.ypos;
              }
              if (
                attrs.anchor !== 'LRTB' &&
                attrs.anchor.includes('R') &&
                !attrs.anchor.includes('L') &&
                !!attrs.width
              ) {
                delete attrs.xpos;
              }

              v.props.attributes.fillvert = this.props.attributes.fillvert;
            }
            if (!attrs.anchor) {
              if (attrs.ypos) delete attrs.bpos;
            } else if (attrs.anchor === 'LRTB' && !attrs.bpos && !!attrs.tempHeight && !!panelHeight) {
              attrs.bpos = panelHeight - attrs.ypos - attrs.tempHeight - 1;
            }
            const isGroupActiveAndResizable =
              v.componentName === 'group' && v.componentLayout.$.anchor === 'LRTB' && v.componentActive === true;
            const fixedHeightTablesInComponent = v.props.layout?.table?.filter(
              (x: any) => x.$.anchor === 'LRT' && x.$.autopagedown?.toLowerCase() === 'false' && !!x.$.pagesize
            );
            const isFixedGroupWithSingleResizeableTable =
              v.componentName === 'group' &&
              v.componentLayout.$.anchor === 'LRT' &&
              v.componentActive &&
              v.componentLayout.table?.length === 1 &&
              v.componentLayout.table[0]?.$?.anchor === 'LRTB';

            //Determine value overflow for the component
            let componentOverflow = 'visible';
            if (v.componentName === 'table') {
              componentOverflow = 'unset';
            }

            return (
              <div
                data-anchor={attrs.anchor}
                data-panel={this.props.panelID}
                data-ddspos={v.props.attributes.ddspos}
                data-ddspanel={v.props.attributes.ddspanel}
                data-padchar={v.props.attributes.padchar}
                data-limit={v.props.attributes.limit}
                data-dirtyflag={v.props.attributes.dirtyflag || this.props.dirtyflag}
                key={this.props.windowProps.data.form.$.id + '-' + v.props.id}
                className={`control-parent ${v.componentName === 'table' ? 'table-container' : ''}`}
                ref={this.innerContainerRef}
                style={
                  isGrid
                    ? {
                        background: background,
                        position: 'relative',
                        display: 'block',
                        overflow: 'visible',
                        // minHeight: '30px',
                        // height: '22px',
                        minWidth: v.componentName === 'editprompt' ? '30px' : '',
                        flexBasis: this.props.attributes.isACP
                          ? attrs.width + '%'
                          : this.props.attributes.numcols
                          ? 100 / this.props.attributes.numcols + '%'
                          : 'auto',
                        height: (attrs.height ? attrs.height * whm + 'px' : '32px') || '32px',
                        width:
                          v.componentName === 'table'
                            ? '100%'
                            : attrs.width
                            ? attrs.width * wwm + (this.props.attributes.isACP ? '%' : 'px')
                            : 'auto',
                        paddingRight: '2px',
                        paddingLeft: '2px'
                      }
                    : {
                        background: background,
                        position:
                          this.props.attributes.fillvert === 'true' ||
                          attrs.anchor === 'LRTB' ||
                          isSpooledFileViewDialogDetailPanel
                            ? 'absolute'
                            : 'relative',
                        display: 'block',
                        alignItems: 'center',
                        overflow: componentOverflow,
                        minHeight:
                          !(v.componentName === 'tab' && !v.props.groupProps) && attrs.minHeight
                            ? `${attrs.minHeight * whm}px`
                            : isGroupActiveAndResizable && fixedHeightTablesInComponent?.length > 0 && attrs.tempHeight
                            ? `${attrs.tempHeight * whm}px`
                            : '0px',
                        minWidth: v.componentName === 'editprompt' ? '35px' : '',
                        height:
                          v.componentName === 'tab' && !v.props.groupProps
                            ? 'auto'
                            : (+attrs.height + (!!v.props.attributes.text ? 6 : 0)) *
                                (hasPanelDynamicTable ? 0.7 : 1) *
                                (isFixedGroupWithSingleResizeableTable
                                  ? fixedGroupWithSingleResizeableTableFactor
                                  : whm) +
                              'px',
                        width:
                          (v.props.id === 'FMPOSL' && this.props.panelID === 'WNDD0011S') || attrs.anchor?.length > 2
                            ? 'auto'
                            : v.componentName === 'table'
                            ? '100%'
                            : attrs.width
                            ? attrs.width * wwm + 'px'
                            : 'auto',

                        //top - needs to be 0 on main panel (container) - how to check that???
                        top:
                          (attrs.ypos && this.props.attributes.fillvert === 'true') ||
                          attrs.anchor === 'LRTB' ||
                          this.props.windowProps.data.form.$.id === 'SpooledFileViewDialog'
                            ? ~~(
                                //TODO: Maybe fix the combo in XML layouts (Discussion needed)
                                (
                                  (v.componentName === 'combo-editable' ? +attrs.ypos - 2 : attrs.ypos) * whm +
                                  (!!this.props.attributes.text ? 6 : 0)
                                )
                              ) + 'px'
                            : attrs.anchor === 'LR' && this.innerContainerRef.current?.style?.position === 'relative'
                            ? '0px'
                            : 'auto',
                        left: attrs.xpos ? ~~(attrs.xpos * wwm) + 'px' : 'auto',
                        right: attrs.rpos ? ~~(attrs.rpos * wwm) + 'px' : 'auto',
                        bottom:
                          !(v.componentName === 'tab' && !v.props.groupProps) && attrs.bpos //TODO: Maybe fix the combo in XML layouts (Discussion needed)
                            ? ~~((v.componentName === 'combo-editable' ? +attrs.bpos + 2 : attrs.bpos) * whm + 8) + 'px'
                            : 'auto'
                        //overflow: 'auto'
                      }
                }
              >
                {i > 1 && <hr />}
                <HighlightFocus elementID={v.props.id}>
                  {React.createElement(v.component, { ...v.props, key: v.props.id })}
                </HighlightFocus>
              </div>
            );
          })}
      </Container>
    );
  }
}
export const Panel = connect(null, {
  setTitle: (id: string, title: string) => ({ type: WindowActions.RENAME, payload: { id: id, title: title } })
})(PanelComponent);
