import axios, { AxiosError, AxiosResponse } from 'axios';
import { Note } from './Note';
import {
  Component,
  useContext,
  useMemo,
  useState,
  createContext,
  useEffect,
  PropsWithChildren,
  useRef,
  useCallback
} from 'react';
import { SquareIcon, Icons } from '../../components/SquareIcon';
import { useAuth } from 'react-oidc-context';

type NotesContextType = any;

const NotesContext = createContext<NotesContextType>({});
const NotesWindowContext = createContext<NotesContextType>({});

export const NotesHealthProvider = ({ children }: PropsWithChildren<any>) => {
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [configured, setConfigured] = useState(true);
  const auth = useAuth();
  const roles = useMemo(() => auth.user?.extendedUserProfile()?.roles, [auth]);
  const [editMode, setEditMode] = useState(false);
  const [editRole, setEditRole] = useState<string | 'note-admin'>('note-admin');
  const readonly = useMemo(() => !editRole || !roles?.includes(editRole), [roles, editRole]);
  const editable = useMemo(() => !!editRole && roles?.includes(editRole), [roles, editRole]);

  useEffect(() => {
    if (connectionStatus)
      axios
        .get('/notes/roles/admin')
        .then((res) => setEditRole(res.data ?? 'note-admin'))
        .catch((e) => setEditRole('note-admin'));
  }, [connectionStatus]);

  const checkHealth = useCallback(
    () =>
      axios
        .get(`/notes/health/ready`)
        .then(() => setConnectionStatus(true))
        .catch((e: AxiosError) => {
          setConnectionStatus(false);
          if (e.response?.status === 501) {
            setConfigured(false);
          }
        }),
    []
  );

  useEffect(() => {
    checkHealth();
  }, []);

  useEffect(() => {
    if (!configured) return;
    const id = setInterval(() => checkHealth(), 300000);
    return () => {
      clearInterval(id);
    };
  }, [configured]);

  return (
    <NotesContext.Provider
      value={{
        connectionStatus,
        readonly,
        editMode,
        setEditMode,
        configured,
        editable
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export const useNotes = () => {
  const notesHealthContext = useContext(NotesContext);
  const notesContext = useContext(NotesWindowContext);
  return { ...notesHealthContext, ...notesContext };
};

// const pick = (object: any, ...args: string[]) => {
//   return object
// }

// export const useNotes = () => {
//   const notes = _useNotes();
//   return (({}) => ({}))(notes);
// }

export const NotesConnectionStatus = () => {
  const { connectionStatus, configured } = useNotes();
  return (
    <>
      {
        <SquareIcon size='24px' className={!connectionStatus ? 'icon-primary-nav-warning' : 'icon-primary-nav-ok'}>
          {configured ? (!connectionStatus ? Icons.Alert : Icons.CheckCircle) : Icons.Error}
        </SquareIcon>
      }{' '}
      {configured ? (connectionStatus ? 'Your note available' : 'Your note unavailable') : 'Your note not configured'}
    </>
  );
};

export const NotesProvider = ({ children, formKey, rootCheck }: PropsWithChildren<any>) => {
  const [focussedElement, _setFocussedElement] = useState('');
  const focussedElementRef = useRef(focussedElement);
  const [fieldValue, _setFieldValue] = useState<string>('');
  const fieldValueRef = useRef(fieldValue);
  const [displayValue, setDisplayValue] = useState<string>('');
  const [_notes, setNotes] = useState<any[]>([]);
  const [fetchingNotes, setFetchingNotes] = useState(false);
  const [sort, setSort] = useState(true);
  const [elemLabels, setElemLabels] = useState<any>({});
  const [allLabels, setAllLabels] = useState<string[]>([]);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ text: '', regex: false });
  const [selectedNote, selectNote] = useState<Record<string, any>>({});
  const [_fullscreen, setFullscreen] = useState(false);

  const { connectionStatus } = useContext(NotesContext);

  const flattenedLabels = useMemo<string[]>(() => {
    const _flat = new Set<string>();
    for (let label of elemLabels?.elemLabels ?? []) {
      _flat.add(label);
    }
    for (let label of elemLabels?.fields?.flatMap((field: any) => field.elemLabels) ?? []) {
      _flat.add(label);
    }
    return Array.from(_flat);
  }, [elemLabels]);

  const selectedLabels = useMemo(() => {
    return formKey === focussedElement
      ? elemLabels.elemLabels ?? []
      : elemLabels.fields?.find((field: any) => field.fieldKey === focussedElement)?.elemLabels ?? [];
  }, [formKey, focussedElement, elemLabels]);

  const refreshLabels = useCallback(() => {
    axios
      .get(`/notes/labels`)
      .then((res: AxiosResponse) => {
        // console.log(res.data);
        setAllLabels(res.data);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    if (connectionStatus) {
      setLoading(true);
      axios
        .get(`/notes/elementLabels/${formKey}`)
        .then((res: AxiosResponse) => {
          // console.log(res.data);
          setElemLabels(res.data);
          _setFieldValue('');
          _setFocussedElement(formKey);
          fieldValueRef.current = '';
          focussedElementRef.current = formKey;
          setNotes([]);
        })
        .catch((e: AxiosError) => {
          console.error(e);
          setElemLabels({
            fields: [],
            elemLabels: [],
            formKey: formKey
          });
          _setFieldValue('');
          _setFocussedElement(formKey);
          fieldValueRef.current = '';
          focussedElementRef.current = formKey;
          setNotes([]);
        })
        .finally(() => setLoading(false));
      refreshLabels();
    } else {
      setElemLabels({
        fields: [],
        elemLabels: [],
        formKey: formKey
      });
      _setFieldValue('');
      _setFocussedElement(formKey);
      fieldValueRef.current = '';
      focussedElementRef.current = formKey;
      setNotes([]);
    }
  }, [formKey, connectionStatus]);

  const refreshNotes = useCallback(() => {
    setLoading(true);
    axios
      .get(`/notes/notes`, {
        params: {
          elemLabels: fieldValue ? selectedLabels : flattenedLabels.join(','),
          fieldValue: fieldValue
        }
      })
      .then((res: AxiosResponse) => {
        // console.log(res.data);
        setNotes((notes) => (fieldValue ? [...notes.filter((note: any) => !note.fieldValue), ...res.data] : res.data));
      })
      .catch((e: AxiosError) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  }, [fieldValue, selectedLabels, flattenedLabels]);

  const labels = useMemo(() => (fieldValue ? selectedLabels : flattenedLabels), [
    fieldValue,
    selectedLabels,
    flattenedLabels
  ]);
  useEffect(() => {
    if (labels?.length > 0) {
      setLoading(true);
      const params: any = { elemLabels: labels.join(',') };
      if (fieldValueRef.current) {
        params.fieldValue = fieldValueRef.current;
      }
      axios
        .get(`/notes/notes`, {
          params
        })
        .then((res: AxiosResponse) => {
          // console.log(res.data);
          setNotes((notes) =>
            fieldValueRef.current ? [...notes.filter((note: any) => !note.fieldValue), ...res.data] : res.data
          );
        })
        .catch((e: AxiosError) => {
          console.error(e);
        })
        .finally(() => setLoading(false));
    } else {
      setNotes((notes) => [...notes.filter((note: any) => !note.fieldValue)]);
    }
  }, [fieldValue, labels]);

  const notes = useMemo(() => {
    return _notes
      .map((n) => ({
        ...n,
        order:
          (sort && fieldValue && n.fieldValue === fieldValue ? 2 : 0) +
          (sort ? n.elemLabels?.reduce((p: number, c: string) => p + selectedLabels.indexOf(c), 0) : 0)
      }))
      .sort((a, b) => b.order - a.order)
      .filter((note) =>
        new RegExp(`.*${filter.text.split('').join(filter.regex ? '.*' : '')}.*`, 'mig').test(note?.text)
      );
  }, [_notes, fieldValue, sort, selectedLabels, filter]);

  const setFieldValue = (fieldValue: string) => {
    fieldValueRef.current = fieldValue;
    _setFieldValue(fieldValue);
    setDisplayValue(fieldValue);
  };

  const setFocussedElement = (elementID: string) => {
    focussedElementRef.current = elementID;
    _setFocussedElement(elementID);
  };

  const openNote = useCallback((note: Record<string, any>) => {
    selectNote(note);
  }, []);

  const closeNote = useCallback(() => {
    selectNote({});
  }, []);

  const updateNote = useCallback((newNote) => {
    setNotes((notes) =>
      notes.map((note) => {
        if (note.id === newNote.id) {
          note = { ...newNote };
        }
      })
    );
  }, []);

  // const saveNote = useCallback(
  //   (id: string) => {
  //     const note = notes.find((note) => note.id === id);
  //     if (note) {
  //       if (note.id === 'new') {
  //       }
  //     }
  //   },
  //   [notes]
  // );

  const filterNotes = useCallback((text: string, regex: boolean = false) => {
    setFilter({ text, regex });
  }, []);

  return (
    <NotesWindowContext.Provider
      value={{
        formKey,
        focussedElement,
        focussedElementRef,
        selectedLabels,
        setFocussedElement,
        fieldValue,
        setFieldValue,
        displayValue,
        setDisplayValue,
        notes,
        setNotes,
        fetchingNotes,
        sort,
        setSort,
        elemLabels,
        setElemLabels,
        loading,
        setLoading,
        flattenedLabels,
        refreshNotes,
        enabled,
        setEnabled,
        filterNotes,
        allLabels,
        openNote,
        closeNote,
        updateNote,
        selectedNote,
        _fullscreen,
        setFullscreen
      }}
    >
      <span
        onFocus={(e) => {
          if (rootCheck(e)) {
            setFocussedElement(formKey);
          }
        }}
      >
        {children}
      </span>
    </NotesWindowContext.Provider>
  );
};

export const HighlightFocus = ({ children, elementID, condition }: PropsWithChildren<any>) => {
  const { focussedElement, enabled } = useNotes();
  return (
    <span
      className={enabled && focussedElement === elementID && (condition ? condition() : true) ? 'note-highlight' : ''}
    >
      {children}
    </span>
  );
};

// export const useConnectValue = (elementID: string, value: string) => {
//   const { focussedElement, setFieldValue } = useNotes();
//   useEffect(() => {
//     if (elementID === focussedElement) setFieldValue(value);
//   }, [focussedElement, elementID, value]);
// };

export const connectNote = <P extends { id: string } & unknown>(WrappedComponent: React.ComponentType<P>) => {
  const Wrapper = (props: P & { loading: boolean }) => {
    const { focussedElement, setFocussedElement } = useNotes();
    // console.log(focussedElement);
    return (
      <span
        onFocus={(e) => {
          // console.log('NOTES: Focus capture', e.target.id, props.id);
          setFocussedElement(props.id);
          // console.log('NOTES: Focus set');
        }}
      >
        <WrappedComponent {...props} />
      </span>
    );
  };

  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  Wrapper.displayName = `connectNote(${displayName})`;

  return Wrapper;
};
