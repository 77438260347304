import { PropsWithChildren } from 'react';
import React, { Context } from 'react';
import { ClientLogger } from '../ClientLogger';

export type GetLogger = (componentName: string) => ClientLogger;
export type LoggerContextType = {
  getLogger: GetLogger;
};

export const LoggerContext: Context<LoggerContextType> = React.createContext<LoggerContextType>({
  getLogger: (componentName: string) => {
    throw new Error(`${componentName} must be used within LoggerProvider`);
  }
});

export declare type LoggerProviderProps = PropsWithChildren<{
  getLogger: GetLogger;
}>;

export const LoggerProvider = ({ getLogger, children }: LoggerProviderProps) => {
  return <LoggerContext.Provider value={{ getLogger: getLogger }}>{children}</LoggerContext.Provider>;
};
