import React, { Component, ForwardedRef, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { WindowProps } from '../views/partials/Window';
import { CommandContext } from '../framework/parsers/layout/types';
import '../styles/Calendar.scss';
import { XT } from '../framework/handlers/xt';
import { Action, Button } from './Button';
import { Command } from '../framework/base/command';
import { Knot, KnotElement } from '../framework/base/models/knot';
import { connect } from 'react-redux';
import { Localization } from '../framework/localization/Localization';
import { WindowManger } from '../framework/base/windowManager';
import { Col, Dropdown, Form, FormControl, Row } from 'react-bootstrap';
import { ReactComponent as DropdownArrow } from '../assets/dropdown-arrow.svg';
import { Icons, SquareIcon } from './SquareIcon';

type CalendarProps = WindowProps & {};

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy, resetFilter }: any, ref: ForwardedRef<any>) => {
    const [value, setValue] = useState('');
    const ulRef = useRef<HTMLUListElement>(null);
    useEffect(() => {
      if (!resetFilter) {
        const activeElement = ulRef?.current?.querySelector('.active');
        activeElement?.scrollIntoView();
      }
    }, [value, ulRef.current, resetFilter]);

    useEffect(() => {
      if (resetFilter) {
        setValue('');
      }
    }, [resetFilter]);

    return (
      <div className={'custom-dropdown-searchable ' + className} ref={ref} style={style} aria-labelledby={labeledBy}>
        <FormControl
          autoComplete='off'
          autoFocus
          className='mx-3 my-2 w-auto form-control text-font text-style custom-dropdown-search-field'
          id={'custom-calendar-filter-input'}
          placeholder={Localization.instance.getString('CALENDAR_TypeToFilter')}
          onChange={(e) => setValue(e.target.value.trim())}
          value={value}
          data-event='ignore'
          onKeyDown={(e: React.KeyboardEvent) => {
            const activeElement = ulRef?.current?.querySelector('.active') as HTMLElement;
            if (e.key.toLowerCase() === 'tab' && !e.shiftKey) {
              e.preventDefault();
              if (activeElement) {
                activeElement?.focus();
              } else {
                (ulRef.current?.firstChild as HTMLElement)?.focus();
              }
            } else if (e.key.toLowerCase() === 'tab' && e.shiftKey) {
              e.preventDefault();
              (ulRef.current?.closest('.show.dropdown') as HTMLElement)?.click();
            } else if (e.key.toLowerCase() === 'enter' && ulRef.current?.childNodes?.length === 1) {
              e.preventDefault();
              (ulRef.current.firstChild as HTMLElement).click();
            }
          }}
        />
        <ul className='list-unstyled' ref={ulRef}>
          {React.Children.toArray(children).filter((child: any) => {
            return !value || child.props.value.toLowerCase().includes(value);
          })}
        </ul>
      </div>
    );
  }
);

type CalendarState = {
  isYearDropdownOpen: boolean;
  isMonthDropdownOpen: boolean;
  resetDropdownFilter: boolean;
};

class CalendarComponent extends Component<CalendarProps> {
  state: CalendarState;
  private refYear = React.createRef<HTMLButtonElement>();
  private refMonth = React.createRef<HTMLButtonElement>();
  private refCalendar = React.createRef<HTMLDivElement>();
  private refPrevious: HTMLButtonElement | null = null;
  private refNext: HTMLButtonElement | null = null;

  // calender configuration
  config: any = {
    daysPrefix: 'FMD',
    buttonPrefix: 'FMB',
    weekCol: 'FMWEEK',
    weekdayFormat: 'FMD*LB',
    days: ['01', '02', '03', '04', '05', '06', '07'],
    months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    weeks: ['1', '2', '3', '4', '5', '6'],
    fields: {
      calendar: 'CICAID',
      month: 'FMMONT',
      year: 'FMYEAR'
    },
    labels: {
      calendar: 'CICDES',
      month: 'FMMOND',
      week: 'FMWKLB'
    },
    fieldsPanel: 'ASTD9001',
    dataPanel: 'ASTD9002',
    colors: {
      highlight: 'teal',
      holiday: 'red',
      disabled: 'grey'
    },
    colorConditions: {
      highlight: 2,
      holiday: 5,
      disabled: 4
    },
    conditionIncr: 5,
    colorPriority: ['disabled', 'highlight', 'holiday'],
    clearFlags: [81, 82, 87, 88],
    commands: {
      PAGEDOWN: 87,
      PAGEUP: 88
    }
  };

  command: Command = new Command(this.props.data.form.$.id, new Knot(KnotElement.INFDS, {}));
  context!: React.ContextType<typeof CommandContext>;

  initCommand(props: WindowProps) {
    // initiating the command
    let data = props.data.form;
    this.command = new Command(data.$.id, new Knot(KnotElement.INFDS, {}));
    for (let index in data.panel) {
      let panel = data.panel[index];
      if (panel.$.id.indexOf('MSG') > -1) continue;
      let flags = data.panel[data.panel.length - 1].$.flags.split('').map((x: string) => x === '1');
      this.config.clearFlags.forEach((x: number) => (flags[x - 1] = false));
      this.command.childSet(
        panel.$.id,
        new Knot(KnotElement.PANEL, { id: panel.$.id, flags: flags.map((x: boolean) => (x ? '1' : '0')).join('') })
      );
    }
    let defaultPanel = props.data.form.panel.find((x: any) => x.$.id !== 'CLRDSP' && x.$.id.indexOf('MSG') === -1);
    let panel = defaultPanel?.$.id || '';
    this.command.setPanel(panel);
  }

  handleInitialFocus() {
    // 1. selected date or current date
    let target = this.refCalendar.current?.querySelector('.calendar-day.conditional-highlight');

    // 2. first day of the month
    if (!target) {
      const days = Array.from(
        this.refCalendar.current?.querySelectorAll('.calendar-day:not(.conditional-disabled)') || []
      ).sort((a, b) => a.id.localeCompare(b.id));
      if (days.length > 0) target = days[0];
    }

    // 3. current year
    if (target) {
      (target as HTMLElement).focus();
    } else {
      this.refYear.current?.focus();
    }
  }

  componentDidMount() {
    this.initCommand(this.props);
    this.refPrevious = this.refCalendar.current?.querySelector('#previous') || null;
    this.refNext = this.refCalendar.current?.querySelector('#next') || null;
    this.handleInitialFocus();
  }

  componentDidUpdate(prevProps: Readonly<CalendarProps>, prevState: Readonly<CalendarState>, snapshot?: any) {
    this.initCommand(this.props);
    if (prevProps.data !== this.props.data) this.handleInitialFocus();
    if (this.state.isYearDropdownOpen)
      (this.refCalendar.current?.querySelector('#custom-calendar-filter-input') as HTMLElement)?.focus();
    if (this.state.isMonthDropdownOpen) {
      const currentMonth = XT.getValueFromWindow(this.props, '', this.config.fields.month);
      const currentMonthString = currentMonth > 9 ? currentMonth : '0' + currentMonth;
      const elementWithCurrentMonthInDropdown = document.querySelector(
        `.dropdown-list.show [value = "${currentMonthString}"]`
      ) as HTMLElement;
      elementWithCurrentMonthInDropdown?.scrollIntoView();
      elementWithCurrentMonthInDropdown?.focus();
    }
  }

  protected get weeks() {
    //Rows
    return this.props.data.form.panel.find((p: any) => p.$.id === this.config.dataPanel)?.row;
  }

  protected get weekDays() {
    return this.config.days.map((d: string) => this.config.weekdayFormat.replace('*', d.slice(-1)));
  }

  protected get years() {
    const { settings } = this.context;
    const { century_break_year } = settings?.regionals.dateformats[0].$ || {};
    const year = new Date().getFullYear() - 100 - ((new Date().getFullYear() - 100) % 100) + +century_break_year;
    let years: string[] = [];
    for (let i = 0; i < 100; i++) {
      years.push(`${year + i}`);
    }
    return years;
  }

  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      isYearDropdownOpen: false,
      isMonthDropdownOpen: false,
      resetDropdownFilter: false
    };
    this.actionHandler = this.actionHandler.bind(this);
    this.addToRowCommand = this.addToRowCommand.bind(this);
    this.addToCommand = this.addToCommand.bind(this);
    this.isSelectedMonth = this.isSelectedMonth.bind(this);
    this.handleInitialFocus = this.handleInitialFocus.bind(this);
  }

  actionHandler(action: Action, e?: MouseEvent<HTMLElement>) {
    // according to the event, update the state
    (e?.target as HTMLElement)?.blur();
    this.command.setAction(action.event);
    this.command.updateFlag(this.config.commands[action.event] || -1, true);
    this.props.startLoading?.();
    this.props.interact?.(this.props.id, this.command, this.props.endLoading, () => {});
  }

  addToCommand(panelID: string, id: string, value: string, dirtyflag: number): void {
    //  add commands to respective panelID list and update flag
    this.command.children[panelID]?.childSet(
      id,
      new Knot(KnotElement.CTRL, {
        id: id,
        value: value
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/&/g, '&amp;')
          .replace(/'<'/g, '&apos;')
          .replace(/"/g, '&quot;')
      })
    );
    this.command.updateFlag(dirtyflag, true);
  }

  addToRowCommand(panelID: string, rowID: string, id: string, value: string, dirtyflag: number, flags?: string): void {
    if (!this.command.children[panelID]?.children?.[rowID]) {
      this.command.children[panelID]?.childSet(
        rowID,
        new Knot(KnotElement.ROW, { id: rowID, flags: this.command.children[panelID].attributeGet('flags') || '' })
      );
    }
    this.command.children[panelID]?.children?.[rowID]?.childSet(
      id,
      new Knot(KnotElement.CTRL, {
        id: id,
        value: value
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/&/g, '&amp;')
          .replace(/'<'/g, '&apos;')
          .replace(/"/g, '&quot;')
      })
    );
    this.command.updateFlag(dirtyflag, true);
  }

  isSelectedMonth(month: string): boolean {
    const selectedMonth = XT.getValueFromWindow(this.props, '', this.config.fields.month).trim();
    return +selectedMonth === +month.trim();
  }

  render() {
    let weeks = this.weeks;
    return (
      <div role='dialog' aria-modal='true' aria-labelledby='dialog-calendar' tabIndex={-1}>
        <div className={'overlay-full'} />
        <div
          className='card-calendar'
          onKeyDown={(e: React.KeyboardEvent) => {
            // Keep focus inside calendar control
            // ==================================
            if (e.key.toLowerCase() === 'tab' && e.shiftKey && document.activeElement === this.refYear.current) {
              this.refNext?.focus();
              e.preventDefault();
              e.stopPropagation();
              return false;
            } else if (e.key.toLowerCase() === 'tab' && !e.shiftKey && document.activeElement === this.refNext) {
              this.refYear?.current?.focus();
              e.preventDefault();
              e.stopPropagation();
              return false;
            }

            // Handle basic function keys
            // ==========================
            if (['pagedown', 'pageup', 'f3', 'f12'].includes(e.key.toLowerCase()) && !e.shiftKey) {
              this.actionHandler({ event: e.key.toUpperCase() });
              e.preventDefault();
              e.stopPropagation();
              return false;
            }

            // Select date
            // ===========
            if (
              e.key.toLowerCase() === 'enter' &&
              !e.shiftKey &&
              document.activeElement?.classList?.contains('calendar-day')
            ) {
              if (!document.activeElement.classList?.contains('conditional-disabled')) {
                const [row, field] = document.activeElement.id.split('-');
                const rowId = row.substring(1);
                const buttonId = field.replace(this.config.daysPrefix, this.config.buttonPrefix);
                this.addToRowCommand(this.config.dataPanel, rowId, buttonId, 'X', -1);
                this.actionHandler({ event: 'ENTER' });
              }
            }

            // // Initialize year search
            // // ======================
            // if (
            //   ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key) &&
            //   !e.altKey &&
            //   !e.shiftKey &&
            //   !e.ctrlKey &&
            //   !e.metaKey &&
            //   document.activeElement === this.refYear.current
            // ) {
            //   //NTH: open year combo & use key pressed to replace value of filter year input control
            // }

            // Handle arrow navigation
            // =======================
            let target: Element | null = null;
            if (e.key.toLowerCase() === 'arrowup' && !e.shiftKey) {
              if (document.activeElement === this.refYear.current) {
                target = this.refPrevious;
              } else if (document.activeElement === this.refMonth.current) {
                target = this.refNext;
              } else if (document.activeElement === this.refPrevious) {
                const days = Array.from(
                  this.refCalendar.current?.querySelectorAll('.calendar-day:not(.conditional-disabled)') || []
                )
                  .filter((day) => ['01', '02', '03'].includes(day.id.slice(-2)))
                  .sort((a, b) => a.id.localeCompare(b.id));
                if (days.length > 0) target = days[days.length - 1];
              } else if (document.activeElement === this.refNext) {
                const days = Array.from(
                  this.refCalendar.current?.querySelectorAll('.calendar-day:not(.conditional-disabled)') || []
                )
                  .filter((day) => ['04', '05', '06', '07'].includes(day.id.slice(-2)))
                  .sort((a, b) => a.id.localeCompare(b.id));
                if (days.length > 0) target = days[days.length - 1];
              } else if (document.activeElement?.classList.contains('calendar-day')) {
                const [rowId, field] = document.activeElement.id.split('-');
                const row = +rowId.substring(1);
                if (row > 1) {
                  target = this.refCalendar.current?.querySelector(`#R${row - 1}-${field}`) || null;
                  if (target?.classList.contains('conditional-disabled')) target = null;
                }
                if (!target) {
                  if (['04', '05', '06', '07'].includes(field.slice(-2))) {
                    target = this.refMonth.current;
                  } else {
                    target = this.refYear.current;
                  }
                }
              }
            } else if (e.key.toLowerCase() === 'arrowdown' && !e.shiftKey) {
              if (document.activeElement === this.refPrevious) {
                target = this.refYear.current;
              } else if (document.activeElement === this.refNext) {
                target = this.refMonth.current;
              } else if (document.activeElement === this.refYear.current) {
                const days = Array.from(
                  this.refCalendar.current?.querySelectorAll('.calendar-day:not(.conditional-disabled)') || []
                )
                  .filter((day) => ['01', '02', '03'].includes(day.id.slice(-2)))
                  .sort((a, b) => a.id.localeCompare(b.id));
                if (days.length > 0) target = days[0];
              } else if (document.activeElement === this.refMonth.current) {
                const days = Array.from(
                  this.refCalendar.current?.querySelectorAll('.calendar-day:not(.conditional-disabled)') || []
                )
                  .filter((day) => ['04', '05', '06', '07'].includes(day.id.slice(-2)))
                  .sort((a, b) => a.id.localeCompare(b.id));
                if (days.length > 0) target = days[0];
              } else if (document.activeElement?.classList.contains('calendar-day')) {
                const [rowId, field] = document.activeElement.id.split('-');
                const row = +rowId.substring(1);
                if (row > 0) {
                  target = this.refCalendar.current?.querySelector(`#R${row + 1}-${field}`) || null;
                  if (target?.classList.contains('conditional-disabled')) target = null;
                }
                if (!target) {
                  if (['04', '05', '06', '07'].includes(field.slice(-2))) {
                    target = this.refNext;
                  } else {
                    target = this.refPrevious;
                  }
                }
              }
            }

            if (target) {
              (target as HTMLElement)?.focus();
              e.preventDefault();
              e.stopPropagation();
              return false;
            }

            return true;
          }}
          ref={this.refCalendar}
        >
          <div className='card-calendar__header'>
            <Col xs={12} xl={12}>
              <h3>
                {Localization.instance.getString('CALENDAR_Calendar') + ':'}{' '}
                {XT.getValueFromWindow(this.props, '', this.config.fields.calendar)} |{' '}
                {XT.getValueFromWindow(this.props, '', this.config.labels.calendar)}
                <span
                  className={'float-right pointer'}
                  onClick={() => {
                    this.actionHandler({ event: 'F12' });
                  }}
                >
                  <SquareIcon size={'24px'} className={'icon-calendar'}>
                    {Icons.Close}
                  </SquareIcon>
                </span>
              </h3>
            </Col>
          </div>

          <div className='d-flex mb-3 '>
            <Col xs={6} md={6} className={'calendar-sub-heading'}>
              <label>{Localization.instance.getString('CALENDAR_Year')}</label>
              <Dropdown
                show={this.state.isYearDropdownOpen}
                id={'combo-year'}
                onToggle={(isOpen: boolean) => {
                  this.setState({ isYearDropdownOpen: isOpen, resetDropdownFilter: !isOpen });
                }}
              >
                <Dropdown.Toggle
                  data-event='ignore'
                  className={`combo-text-style combo-text-font dropdown-box full-width dropdown-toggle-year`}
                  tabIndex={0}
                  ref={this.refYear}
                >
                  <span className={'selected-item'}>
                    {XT.getValueFromWindow(this.props, '', this.config.fields.year)}
                  </span>
                  <DropdownArrow className={'combo-icon-image'} />
                </Dropdown.Toggle>
                {
                  <Dropdown.Menu
                    as={CustomMenu}
                    resetFilter={this.state.resetDropdownFilter}
                    className={`dropdown-list`}
                  >
                    {this.years.map((year: string, index: number) => (
                      <Dropdown.Item
                        data-event='ignore'
                        className={
                          'dropdown-list-item' +
                          (XT.getValueFromWindow(this.props, '', this.config.fields.year) === year ? ' active' : '')
                        }
                        key={`calendar-year-${index + 1}`}
                        onClick={() => {
                          this.command.setCursor('12,4');
                          this.addToCommand(this.config.fieldsPanel, this.config.fields.year, year, -1);
                          this.actionHandler({ event: 'ENTER' });
                        }}
                        value={year}
                        eventKey={year}
                      >
                        <span>{year}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                }
              </Dropdown>
            </Col>
            <Col xs={6} md={6} className={'calendar-sub-heading'}>
              <label>{Localization.instance.getString('CALENDAR_Month')}</label>
              <Dropdown
                id={'combo-month'}
                show={this.state.isMonthDropdownOpen}
                onToggle={(isOpen: boolean) => {
                  this.setState({ isMonthDropdownOpen: isOpen });
                }}
              >
                <Dropdown.Toggle
                  tabIndex={0}
                  data-event='ignore'
                  className={`combo-text-style combo-text-font dropdown-box full-width`}
                  ref={this.refMonth}
                >
                  <span className={'selected-item'}>
                    {XT.getValueFromWindow(this.props, '', this.config.fields.month)} (
                    {XT.getValueFromWindow(this.props, '', this.config.labels.month)?.trim()})
                  </span>
                  <DropdownArrow className={'combo-icon-image'} />
                </Dropdown.Toggle>
                {
                  <Dropdown.Menu className={`full-width dropdown-list`}>
                    {this.config.months.map((month: string, index: number) => (
                      <Dropdown.Item
                        data-event='ignore'
                        className={'dropdown-list-item' + (this.isSelectedMonth(month) ? ' active' : '')}
                        key={`calendar-month-${index + 1}`}
                        onClick={() => {
                          this.command.setCursor('31,4');
                          this.addToCommand(
                            this.config.fieldsPanel,
                            this.config.fields.month,
                            month.padStart(2, ' '),
                            -1
                          );
                          this.actionHandler({ event: 'ENTER' });
                        }}
                        value={month}
                      >
                        <span>{month}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                }
              </Dropdown>
            </Col>
          </div>
          <div className='card-calendar__body'>
            <ul className='card-calendar__body--days'>
              <li className={'week'}>{XT.getValueFromWindow(this.props, '', this.config.labels.week)}</li>
              {this.weekDays.map((day: string, index: number) => {
                return <li key={index}>{XT.getValueFromWindow(this.props, '', day)}</li>;
              })}
            </ul>
            <ul className='card-calendar__body--dates'>
              {weeks
                ?.filter((row: any) =>
                  row.ctrl
                    .filter((c: any) => c.$.id.startsWith(this.config.daysPrefix))
                    .some((day: any, dIndex: number) => {
                      let conditionalType = this.config.colorPriority.find((type: string) => {
                        return (
                          row.$.flags[this.config.colorConditions[type] + dIndex * this.config.conditionIncr - 1] ===
                          '1'
                        );
                      });
                      return conditionalType !== 'disabled';
                    })
                )
                .map((row: any, index: number) => {
                  let days = row.ctrl.filter((c: any) => c.$.id.startsWith(this.config.daysPrefix));
                  let week = row.ctrl.find((c: any) => c.$.id === this.config.weekCol);
                  return [
                    <li className={'week'}>{week.$.value}</li>,
                    ...days.map((day: any, dIndex: number) => {
                      let conditionalType = this.config.colorPriority.find((type: string) => {
                        return (
                          row.$.flags[this.config.colorConditions[type] + dIndex * this.config.conditionIncr - 1] ===
                          '1'
                        );
                      });
                      return (
                        <li
                          onClick={() => {
                            if (conditionalType === 'disabled') return;
                            let button = day.$.id.replace(this.config.daysPrefix, this.config.buttonPrefix);
                            this.addToRowCommand(this.config.dataPanel, row.$.id, button, 'X', -1);
                            // this.command.setAction('ENTER');
                            this.actionHandler({ event: 'ENTER' });
                          }}
                          className={`conditional-${conditionalType || 'default'} calendar-day`}
                          key={`week-${index}-day-${dIndex}`}
                          tabIndex={conditionalType === 'disabled' ? -1 : 0}
                          id={`R${row.$.id}-${day.$.id}`}
                        >
                          {day.$.value}
                        </li>
                      );
                    })
                  ];
                })}
            </ul>
          </div>
          <div className='card-calendar__footer'>
            <Row>
              <Col md={3}>
                <Button
                  className={'btn-block'}
                  action={{ event: 'PAGEUP' }}
                  actionHandler={this.actionHandler}
                  id={'previous'}
                  name={'Previous'}
                  variant={'primary'}
                />
              </Col>
              <Col md={{ span: 3, offset: 6 }}>
                <Button
                  className={'btn-block'}
                  action={{ event: 'PAGEDOWN' }}
                  actionHandler={this.actionHandler}
                  id={'next'}
                  name={'Next'}
                  variant={'primary'}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

CalendarComponent.contextType = CommandContext;

export const Calendar = connect(
  (state: any, oldProps: WindowProps) => {
    let window = state.desktop.windows[oldProps.id];
    return {
      localization: Localization.instance
    };
  },
  {
    interact: (windowID: string, command: Command, endLoading: Function, scopeHandler: Function) =>
      WindowManger.Interact(windowID, command, endLoading, scopeHandler, undefined)
  }
)(CalendarComponent);
