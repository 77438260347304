import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { Combo } from '../components/Combo';
import { DataTable } from '../components/DataTable';
import DatePicker from '../components/DatePicker';
import { Edit } from '../components/Edit';
import { Label } from '../components/Label';
import { RadioButton } from '../components/RadioButtons';
import { Title } from '../components/Title';
import { Tabs } from '../components/Tab';
import { Group } from '../views/partials/Group';
import { TextArea } from '../components/TextArea';
import TimePicker from '../components/TimePicker';
import { Multiline } from '../components/Multiline';
import { Table } from '../components/Table';
import { ImageComponent } from '../components/Image';

export const ComponentMap: Record<string, any> = {
  edit: Edit,
  multiline: Multiline,
  editprompt: Edit,
  titles: Title,
  table: Table,
  // table: DataTable,
  button: Button,
  label: Label,
  radio: RadioButton,
  checkbox: Checkbox,
  group: Group,
  date: Edit,
  datenative: DatePicker,
  combo: Combo,
  'combo-editable': Edit,
  dynamictable: Label,
  stylededit: Label,
  textarea: TextArea,
  tab: Tabs,
  time: TimePicker,
  image: ImageComponent
};
