import { LogLevel, LogInfo, AppenderSettings } from '../loggerTypes';
import { LoggerAppender } from './LoggerAppender';
import ApplicationEvents from '../../framework/ApplicationEvents';

interface IRequestLogInfo {
  logLevel: keyof typeof LogLevel;
  logData: any;
  userId?: string;
  companyId?: string;
  environmentId?: string;
  sessionId?: string;
  timeStamp: Date;
  tags?: string[];
  meta?: Record<string, any>;
}

export class ServerAppender extends LoggerAppender {
  private _userId?: string;
  private _companyId?: string;
  private _environmentId?: string;
  private _sessionId?: string;

  public constructor(settings: AppenderSettings, defaultLevel: keyof typeof LogLevel) {
    super(settings, defaultLevel);
    ApplicationEvents.addListener.onCompanyChanged((companyId) => (this._companyId = companyId));
  }

  protected get sessionId(): string | undefined {
    if (!this._sessionId) {
      this._sessionId = sessionStorage.getItem('eui_session_id') || undefined;
    }
    return this._sessionId;
  }
  protected get userId(): string | undefined {
    if (!this._userId) {
      const user = sessionStorage.getItem('user');
      if (!!user) this._userId = JSON.parse(user).username;
    }
    return this._userId;
  }
  protected get companyId(): string | undefined {
    if (!this._companyId) {
      const companyInfo = sessionStorage.getItem('company');
      if (!!companyInfo) this._companyId = JSON.parse(companyInfo).id;
    }
    return this._companyId;
  }
  protected get environmentId(): string | undefined {
    if (!this._environmentId) {
      this._environmentId = sessionStorage.getItem('env') || undefined;
    }
    return this._environmentId;
  }

  public log(logInfo: LogInfo, logLevel: LogLevel): void {
    if (logLevel > this.logLevel) return;

    try {
      const data: IRequestLogInfo = {
        logLevel: LogLevel[logLevel] as keyof typeof LogLevel,
        logData: logInfo.logData,
        userId: this.userId,
        companyId: this.companyId,
        environmentId: this.environmentId,
        sessionId: this.sessionId,
        timeStamp: new Date(),
        tags: logInfo.tags,
        meta: logInfo.meta
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      };

      // axios.interceptors use this logger - to avoid loop requests use fetch instead of axios
      fetch('/logclient', requestOptions).catch((e) => {
        //NTH_SKE: change to socket? consult with Archit
        console.error(`Error from ServerAppender step1 ${e}`);
      });
    } catch (error) {
      console.error(`Error from ServerAppender step1 ${error}`);
    }
  }
}
