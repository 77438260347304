import { ChangeEvent, FunctionComponent, useContext, useEffect, useRef, useState, FocusEvent } from 'react';
import { Form } from 'react-bootstrap';
import './../styles/TextArea.scss';
import PromptArrow from './../assets/edit-prompt-arrow.svg';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { useStateCallback } from '../types/hooks';
import { string } from 'prop-types';
import { Column } from './DataTable';

/**
 * @TextAreaProps
 * attributes: XML attributes for selected element
 */

type TextAreaProps = {
  attributes: Record<string, any>;
  className?: string;
  id: string;
  limit: number;
  printLimit?: number;
  readOnly?: boolean;
  type?: 'single-line' | 'multi-line';
  variant?: 'text' | 'prompt';
  panelID: string;
  loadMore?: (
    panelID: string,
    EOF?: boolean,
    columns?: Column[],
    rowCount?: number,
    attributes?: Record<string, any>,
    cb?: (data: Record<string, any>) => void
  ) => void;
};

export const TextArea: FunctionComponent<TextAreaProps> = ({
  attributes,
  className = '',
  id,
  limit,
  printLimit = -1,
  readOnly = false,
  variant = 'text',
  type = 'single-line',
  panelID,
  loadMore
}) => {
  const { addToRowCommand, windowData, cursor } = useContext(CommandContext); //Creating a command for the XT
  let [rows, setRows] = useStateCallback<{ id: string; value: string }[]>([]);

  useEffect(() => {
    let panel = windowData.form.panel.find((x: any) => panelID === x.$.id);
    let _rows: { id: string; value: string }[] = [];
    for (let i in panel.row) {
      let row = panel.row[i];
      let val = XT.getValueFromWindowRow({ data: windowData }, panelID, row.$.id, attributes.rowCtrl) || '';
      if (val.trim() === '') {
        val = val.trim();
      }
      _rows.push({ id: row.$.id, value: val });
    }
    setRows(_rows);
  }, [windowData]);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);

  useEffect(() => {
    if (textRef.current) {
      const scrollInfo = XT.getScrollInfo(textRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  const keyPressed = (e: ChangeEvent<HTMLInputElement>) => {
    let { value, selectionStart } = e.target as HTMLInputElement;
    let _rows = value.split('\n');
    let index = 0;
    index = value.substring(0, selectionStart || 0).split('\n').length;

    if (index > rows.length || (_rows.length > rows.length && _rows[_rows.length - 1].trim() !== '')) {
      loadMore?.(panelID);
    }
    setRows(
      rows.map((r, i) => ({ id: r.id, value: (_rows[i] || '')?.substring(0, limit) })),
      () => {
        e.target.selectionEnd = selectionStart;
      }
    );
    rows.forEach((r, i) => {
      addToRowCommand(panelID, r.id, attributes.rowCtrl, _rows[i] || '', -1);
    });
    e.target.selectionEnd = selectionStart;
    e.stopPropagation();
  };

  return (
    <div
      className='area-container'
      data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
      data-html={true}
    >
      <Form.Control
        ref={textRef}
        data-event={'textarea'}
        onFocus={(e: FocusEvent<HTMLTextAreaElement>) => {
          e.target.selectionEnd = 0;
          e.target.scrollTo({ top: 0, behavior: 'auto' });
        }}
        onChange={type === 'multi-line' ? undefined : keyPressed}
        data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
        tabIndex={!readOnly ? x + y : -1}
        as='textarea'
        className={`monospace text-area text-area-style ${variant === 'prompt' && 'text-right-padding'} ${className}`}
        value={rows.map((x) => x.value).join('\n')}
        cols={limit}
        readOnly={readOnly}
      />
      {printLimit !== -1 && <div style={{ left: printLimit + 'ch' }} className='divider' />}
      {variant === 'prompt' && (
        <button className={'text-font'}>
          <img src={PromptArrow} style={{ transform: 'rotate(180deg)' }} />
        </button>
      )}
    </div>
  );
};
