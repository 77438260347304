import { ValueFormatterExtension } from './ValueFormatterExtension';

export class IntegerFormatter implements ValueFormatterExtension {
  in(value: string) {
    value = value.trim();
    if (+value == 0) value = '';

    let idx = 0;
    while (idx < value.length && value.charAt(idx) == '0') {
      const char = value.charAt(idx);
      value.replace(char, ' ');
      idx++;
    }
    return value;
  }
  out(value: string, limit: number = 0) {
    if (value == null || value.trim().length == 0) {
      // let limit = 0;
      value.padEnd(limit, '0');

      // for (let i = 0; i < limit; i++) value = `${value}0`;
    }

    return value;
  }
}
