import {
  ChangeEvent,
  FocusEvent,
  MouseEventHandler,
  MouseEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
  useContext
} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './../styles/Edit.scss';
import PromptArrow from './../assets/edit-prompt-arrow.svg';
import { CommandContext } from '../framework/parsers/layout/types';
import { XT } from '../framework/handlers/xt';
import { LocalStorage } from '../framework/handlers/localStorage';

/**
 * @EditProps
 * attributes:  XML attributes for selected element
 */

type EditProps = {
  attributes: Record<string, any>;
  className?: string;
  autoCapitalize?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  id: string;
  isInvalid?: boolean;
  isValid?: boolean;
  maxLength?: number;
  name: string;
  placeholder?: string;
  readOnly?: boolean;
  textDirection?: 'left' | 'right';
  uppercase?: boolean;
  variant?: 'edit' | 'prompt' | 'remote-prompt';
  visible?: boolean;
  panelID?: string;
  rowID?: string;
  rowFlags?: string;
  rowField?: string;
};

type MultilineState = {
  hasFocus: boolean;
};

export const Multiline: FunctionComponent<EditProps> = ({
  attributes,
  autoCapitalize = true,
  className = '',
  defaultValue = '',
  disabled = false,
  errorMessage,
  id,
  isInvalid = false,
  isValid = false,
  name,
  placeholder,
  readOnly = false,
  textDirection = 'left',
  variant = 'edit',
  visible = true,
  panelID = '',
  rowID,
  rowFlags,
  rowField
}) => {
  const editRef = useRef<any>(null);
  const [value, setValue] = useState('');
  const [dirty, setDirty] = useState('');
  const [multilineState, setMultilineState] = useState<MultilineState>({ hasFocus: false });

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  useEffect(() => {
    if (editRef.current) {
      const scrollInfo = XT.getScrollInfo(editRef);
      setX(scrollInfo.x);
      setY(scrollInfo.y);
    }
  }, []);

  const {
    addToRowCommand,
    removeFromRowCommand,
    addToCommand,
    removeFromCommand,
    setScope,
    errors,
    windowData,
    updateFlag,
    promptHandler,
    cursor
  } = useContext(CommandContext);
  const [dirtyflag, setDirtyFlag] = useState<number>(-1);

  useEffect(() => {
    if (editRef && editRef.current) {
      let _dirtyflag = +(editRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
    }
  }, []);

  useEffect(() => {
    let _value =
      (rowID
        ? XT.getValueFromWindowRow({ data: windowData }, panelID, rowID, name)
        : XT.getValueFromWindow({ data: windowData }, panelID, id)) || '';
    /**
     * Getting the value from the framework
     */

    _value = _value
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&amp;/gi, '&')
      .replace(/&apos;/gi, "'")
      .replace(/&quot;/gi, '"');

    let mask = attributes.mask;
    if (!attributes.mask && attributes.formatter === 'FormattedNumeric') mask = 'numeric';
    if (dirty && rowID && !_value?.trim()) {
      _value = dirty;
    }
    if (attributes.formatter === 'RemoveTextLimitMarker') {
      _value = _value.replace(']', '');
    }
    _value = _value || attributes?.text || '';
    setValue(_value.trimEnd());
    if (editRef && editRef.current) {
      let _dirtyflag = +(editRef.current.closest('[data-dirtyflag]')?.getAttribute('data-dirtyflag') || '-1');
      setDirtyFlag(_dirtyflag);
      updateFlag(_dirtyflag, false);
      if (attributes.hiddendata === 'true') {
        updateFlag(_dirtyflag, true);
        addToCommand(panelID, id, XT.getValueFromWindow({ data: windowData }, panelID, id) || '', _dirtyflag);
      }
    }
  }, [windowData]);

  const controlType = 'text';

  const onChangeEvent = async (e: ChangeEvent<HTMLInputElement>) => {
    if (autoCapitalize) e.target.value = e.target.value.toUpperCase();
    setValue(e.target.value);
    let padChar = e.target.closest('[data-padchar]')?.getAttribute('data-padchar');
    if (padChar === '') {
      padChar = ' ';
    }
    let limit = +(e.target.closest('[data-limit]')?.getAttribute('data-limit') || 0);
    let value = e.target.value;

    if (!!value.trim()) {
      //Pad only if value is not empty
      value = value.padStart(limit, padChar || '');
    }
    if (e.target.value.trim() !== defaultValue) {
      //Formatters and mask checks here
      if (rowID) {
        addToRowCommand(panelID, rowID, name, value, dirtyflag, rowFlags);
        setDirty(value);
      } else {
        addToCommand(panelID, id, value, dirtyflag);
      }
    } else {
      if (rowID) removeFromRowCommand(panelID, rowID, id, dirtyflag);
      else removeFromCommand(panelID, id, dirtyflag);
    }
  };

  const handlePrompt: MouseEventHandler<HTMLImageElement> = (e: MouseEvent) => {
    e.preventDefault();
    let panel =
      editRef?.current?.closest('[data-ddspanel]')?.getAttribute('data-ddspanel') ||
      /**
       *  Checking the Element and it's parent untill it finds a node that matches the provided the selector string.
       */
      editRef?.current?.closest('[data-panel]')?.getAttribute('data-panel') ||
      '';
    setScope(attributes?.ddspos || '1,1');
    promptHandler(name, panel, rowField, rowID);
  };

  const determineArrowNavigationAllowed = (): boolean => {
    if (!visible) {
      return false;
    } else if (disabled || readOnly) {
      return false;
    } else if (rowID) {
      return false;
    } else if (multilineState.hasFocus) {
      // --> keep default behavior arrow (navigation in text)
      return false;
    }
    return true;
  };

  return (
    <>
      <InputGroup
        className={'multiline ' + (!readOnly && !disabled ? variant : '')}
        data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
        data-html={true}
        onFocus={() => {
          if (!multilineState.hasFocus) setMultilineState({ ...multilineState, hasFocus: true });
        }}
        onBlur={(e: FocusEvent) => {
          if (!e.currentTarget.contains(e.relatedTarget) && multilineState.hasFocus)
            setMultilineState({ ...multilineState, hasFocus: false });
        }}
      >
        <Form.Control
          as={'textarea'}
          style={{ resize: 'none' }}
          // onKeyDown={(e: KeyboardEvent) => {
          //   if (e.key.toLowerCase() === 'f4' && rowID) {
          //   }
          // }}
          onFocus={(e: FocusEvent<HTMLTextAreaElement>) => {
            e.target.select();
          }}
          data-invalid={isInvalid}
          data-mask={attributes.mask}
          autoCapitalize={autoCapitalize ? 'on' : 'off'}
          ref={editRef}
          autoComplete={'off'}
          id={id}
          type={controlType}
          placeholder={placeholder}
          data-hiddendata={attributes.hiddendata}
          maxLength={value?.includes('.') ? +attributes?.limit + 1 : attributes?.limit}
          className={`text-style text-font padding 
              ${autoCapitalize ? 'auto-capitalise' : ''} 
              ${className} 
              text-${textDirection || 'left'}`}
          name={name}
          value={value}
          readOnly={readOnly}
          hidden={!visible}
          disabled={disabled}
          data-autofocus={cursor === attributes.ddspos ? 'true' : 'false'}
          data-arrow-navigation={determineArrowNavigationAllowed() ? 'true' : 'false'}
          tabIndex={!readOnly && !disabled ? x + y : -1}
          isValid={isValid}
          isInvalid={errors && isInvalid}
          onChange={onChangeEvent}
          onKeyDown={(e: React.KeyboardEvent) => {
            // Handle numeric pad +/- "act as tab" behavior
            // ============================================
            if (
              ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
              LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
            ) {
              e.preventDefault(); //swallow sign
            }
          }}
          onKeyUp={(e: React.KeyboardEvent) => {
            // Handle numeric pad +/- "act as tab" behavior
            // ============================================
            if (
              ['numpadsubtract', 'numpadadd'].indexOf(e.code.toLowerCase()) > -1 &&
              LocalStorage.NumpadSignsBehavior.getSettings().actAsTab
            ) {
              XT.selectNextElement();
            }
          }}
        />
        {variant !== 'edit' && !readOnly && !disabled && (
          <InputGroup.Append>
            <InputGroup.Text className={'text-font'} onClick={handlePrompt}>
              <img src={PromptArrow} style={{ transform: 'rotate(180deg)' }} alt={'prompt arrow'} />
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
      {errorMessage && isInvalid && <span className='error-msg-style'>{errorMessage}</span>}
    </>
  );
};
