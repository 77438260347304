import { ChangeEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  ListGroup,
  ListGroupItem,
  Modal,
  Row
} from 'react-bootstrap';
import './../../styles/Setting.scss';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Localization } from '../../framework/localization/Localization';
import { LocaleContext } from '../../App';
import { Dark } from '../../components/Dark';
import { XT } from '../../framework/handlers/xt';
import axios, { AxiosResponse } from 'axios';
import { LoaderContainer, LoaderContainerType } from '../../components/LoaderContainer';
import withReactContent from 'sweetalert2-react-content';
import _Swal, { SweetAlertResult } from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { SettingsActions, WindowActions } from '../../types/actionTypes';

const Swal: any = withReactContent(_Swal);

export type ImportantLinksProps = {
  className?: string;
  hide: () => void;
  isShown: boolean;
};

export type Role = { name: string; settings: Record<string, any> };

export const RoleTemplates: FunctionComponent<ImportantLinksProps> = ({ isShown, hide }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [fetchingRolesRequired, setFetchingRolesRequired] = useState<boolean>(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isShown && fetchingRolesRequired) {
      //Load roles
      setLoading(true);
      axios
        .get('/client/role-templates')
        .then((res: AxiosResponse) => setRoles(res.data.roles))
        .catch(() =>
          Swal.fire({
            text: Localization.instance.getString('ROLE_UnableToFetchRolesTemplates'),
            icon: 'warning',
            confirmButtonColor: '#00a3a5',
            cancelButtonColor: '#00a3a5',
            showCancelButton: false,
            allowOutsideClick: false,
            stopKeydownPropagation: false,
            confirmButtonText: Localization.instance.getString('TXT_OK')
          })
        )
        .finally(() => {
          setLoading(false);
          setFetchingRolesRequired(false);
        });
    }
  }, [isShown, fetchingRolesRequired]);

  const upsertRole = (name: string) => {
    setLoading(true);
    axios
      .post('/client/role-templates', {
        name: name,
        settings: JSON.parse(sessionStorage.getItem('clientSettings') || '{}')
      })
      .then((res: AxiosResponse) => setRoles(res.data.roles || []))
      .catch(() =>
        Swal.fire({
          text: Localization.instance.getString('ROLE_UnableToSetRolesTemplates'),
          icon: 'warning',
          confirmButtonColor: '#00a3a5',
          cancelButtonColor: '#00a3a5',
          showCancelButton: false,
          allowOutsideClick: false,
          stopKeydownPropagation: false,
          confirmButtonText: Localization.instance.getString('TXT_OK')
        })
      )
      .finally(() => setLoading(false));
  };
  const deleteRole = (name: string) => {
    setLoading(true);
    axios
      .delete('/client/role-templates', {
        data: {
          name: name
        }
      })
      .then(() => setFetchingRolesRequired(true))
      .catch(() =>
        Swal.fire({
          text: Localization.instance.getString('ROLE_DeleteError'),
          icon: 'warning',
          confirmButtonColor: '#00a3a5',
          cancelButtonColor: '#00a3a5',
          showCancelButton: false,
          allowOutsideClick: false,
          stopKeydownPropagation: false,
          confirmButtonText: Localization.instance.getString('TXT_OK')
        })
      )
      .finally(() => setLoading(false));
  };

  const applySettings = (settings: Record<string, any>) => {
    sessionStorage.setItem('clientSettings', JSON.stringify(settings));
    dispatch({ type: SettingsActions.LOAD, payload: settings });
    hide();
  };

  const applyAll = (name: string) => {
    let role = roles.find((r: Role) => r.name === name);
    if (role) applySettings(role.settings);
  };

  const applyQuickLinksOnly = (name: string) => {
    let role = roles.find((r: Role) => r.name === name);
    let settings = JSON.parse(sessionStorage.getItem('clientSettings') || '{}');
    settings.quickLinks = role?.settings?.quickLinks || [];
    if (role) applySettings(role.settings);
  };

  const applyTablesOnly = (name: string) => {
    let role = roles.find((r: Role) => r.name === name);
    let settings = JSON.parse(sessionStorage.getItem('clientSettings') || '{}');
    settings.tables = role?.settings?.tables || {};
    if (role) applySettings(role.settings);
  };

  const addRole = () => {
    hide();
    Swal.fire({
      title: Localization.instance.getString('ROLE_EnterTemplateName'),
      input: 'text',
      inputValidator: (value: string) => {
        if (!value.trim()) {
          return Localization.instance.getString('ROLE_TemplateNameRequired');
        }
      },
      width: 400,
      focusConfirm: false,
      confirmButtonColor: '#00a3a5',
      cancelButtonColor: '#00a3a5',
      showCancelButton: true,
      allowOutsideClick: false,
      stopKeydownPropagation: false,
      confirmButtonText: Localization.instance.getString('ROLE_BUTTON_Add'),
      cancelButtonText: Localization.instance.getString('ROLE_BUTTON_Cancel')
    }).then((result: SweetAlertResult) => {
      if (result && result.isConfirmed) {
        upsertRole(result.value);
      }
    });
  };

  return (
    <>
      <Modal className={'right-overlay'} show={isShown} animation={false} onHide={hide} size='lg' keyboard={false}>
        {loading ? (
          <LoaderContainer
            loaderType={LoaderContainerType.ImageOnly}
            text={Localization.instance.getString('ROLE_Loading')}
          />
        ) : null}
        <Modal.Header closeButton>
          <Modal.Title>
            <span className='mr-3'>
              <SquareIcon className='icon-modal-header' size='20px'>
                {Icons.PersonalSettings}
              </SquareIcon>
            </span>
            <span>{Localization.instance.getString('client_settings_temp_mgr_header_txt')}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='Setting-Content-container'>
            <Row>
              <Col md={12}>
                <ListGroup>
                  {roles.map((role: Role, index: number) => (
                    <ListGroupItem className='pointer'>
                      {role.name}
                      <DropdownButton as={ButtonGroup} title='' className='role-settings-button' id={`${index}`}>
                        <Dropdown.Item active={false} onClick={() => applyQuickLinksOnly(role.name)} eventKey='1'>
                          {Localization.instance.getString('ROLE_ApplyQuicklinks')}
                        </Dropdown.Item>
                        <Dropdown.Item active={false} onClick={() => applyTablesOnly(role.name)} eventKey='2'>
                          {Localization.instance.getString('ROLE_ApplyTableSettings')}
                        </Dropdown.Item>
                        <Dropdown.Item active={false} onClick={() => applyAll(role.name)} eventKey='3'>
                          {Localization.instance.getString('ROLE_ApplyAll')}
                        </Dropdown.Item>
                        <Dropdown.Item active={false} onClick={() => upsertRole(role.name)} eventKey='4'>
                          {Localization.instance.getString('ROLE_UpdateWithLocal')}
                        </Dropdown.Item>
                        <Dropdown.Item active={false} onClick={() => deleteRole(role.name)} eventKey='5'>
                          {Localization.instance.getString('ROLE_Delete')}
                        </Dropdown.Item>
                      </DropdownButton>
                    </ListGroupItem>
                  ))}
                  <Button block variant='outline-dark' onClick={() => addRole()}>
                    {Localization.instance.getString('ROLE_SaveCurrentSettingsAsTemplate')}
                  </Button>
                </ListGroup>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
