export const ColorMap: Record<string, string> = {
  CB_ERROR_COLOR: 'rgb(255,144,34)',
  CB_FOCUS_BORDER_COLOR: 'rgb(255,152,6)',
  DOWNLOADED_BG_COLOR: 'rgb(220,250,208)',
  EDIT_BORDER_COLOR: 'rgb(217,217,217)',
  EDIT_ERROR_COLOR: 'rgb(254,212,170)',
  EDIT_FOCUS_BG_COLOR: 'rgb(255,255,223)',
  EDIT_FOCUS_BORDER_COLOR: 'rgb(255,191,106)',
  EDIT_OUTPUT_FOCUS_BORDER_COLOR: 'rgb(128,128,128)',

  FORM_BG2_COLOR: 'rgb(250,251,252)',
  FORM_BG_COLOR: 'rgb(250,251,252)',

  FORM_BORDER_COLOR: 'rgb(255,255,255)',
  FORM_FG2_COLOR: 'rgb(255,255,255)',
  FORM_FG_COLOR: 'rgb(255,255,255)',
  FORM_TEXT_COLOR: '#2e2e2e',

  GROUP_BORDER_COLOR: 'rgb(217,217,217)',
  GROUP_FG2_COLOR: 'rgb(174,189,224)',
  GROUP_FG_COLOR: 'rgb(0,0,0)',
  GROUP_TEXT_COLOR: 'rgb(0,0,0)',
  KEY_SEQ_1_BG_COLOR: 'rgb(205,211,255)',
  KEY_SEQ_2_BG_COLOR: 'rgb(195,249,195)',
  KEY_SEQ_3_BG_COLOR: 'rgb(255,198,109)',
  KEY_SEQ_4_BG_COLOR: 'rgb(255,184,236)',
  KEY_SEQ_5_BG_COLOR: 'rgb(233,234,146)',
  KEY_SEQ_6_BG_COLOR: 'rgb(235,246,255)',
  KEY_SEQ_7_BG_COLOR: 'rgb(174,210,146)',
  KEY_SEQ_8_BG_COLOR: 'rgb(146,203,206)',
  KEY_SEQ_9_BG_COLOR: 'rgb(222,223,84)',
  LABEL_SECTION_FG_COLOR: 'rgb(98,157,239)',
  NEW_BG_COLOR: 'rgb(254,251,180)',
  PERSPECTIVE_BG_COLOR: 'rgb(0,0,0)',
  RADIO_BG2_COLOR: 'rgb(250,251,252)',
  RADIO_ERROR_COLOR: 'rgb(254,212,170)',
  RADIO_FOCUS_BG_COLOR: 'rgb(255,255,223)',
  RADIO_FOCUS_BORDER_COLOR: 'rgb(255,152,6)',
  SERVER_JOB_ACTIVE_BG_COLOR: 'rgb(192,255,192)',
  SERVER_JOB_HELD_BG_COLOR: 'rgb(251,145,145)',
  SERVER_JOB_JOBQ_BG_COLOR: 'rgb(255,255,255)',
  SERVER_JOB_OUTQ_BG_COLOR: 'rgb(208,209,254)',
  SERVER_JOB_SCHEDULED_BG_COLOR: 'rgb(255,255,128)',
  SERVER_MSG_SEVERITY_10_BG_COLOR: 'rgb(255,255,255)',
  SERVER_MSG_SEVERITY_20_BG_COLOR: 'rgb(255,255,255)',
  SERVER_MSG_SEVERITY_30_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_40_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_50_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_60_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_70_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_80_BG_COLOR: 'rgb(254,204,164)',
  SERVER_MSG_SEVERITY_90_BG_COLOR: 'rgb(254,204,164)',
  TABLE_ALT_BG_COLOR: 'rgb(243,243,243)',
  TABLE_BG2_COLOR: 'rgb(255,255,223)',
  TABLE_BG_COLOR: 'rgb(255,255,255)',
  TABLE_BS_BG2_COLOR: 'rgb(255,255,230)',
  TABLE_ERROR_COLOR: 'rgb(247,235,66)',
  TABLE_TEXT_ITEM_BG_COLOR: 'rgb(226,234,236)',
  TABLE_UNSELECTABLE_BG_COLOR: 'rgb(250,250,250)'
};
