import { Localization } from '../localization/Localization';
import { Formatters } from '../parsers';

export const XTValueToNote = (
  contentType: string | undefined,
  value: string,
  limit: string | undefined,
  decSep: string,
  localization: Localization,
  yes: string,
  no: string,
  settings: any
) => {
  let noteValue = value;
  switch (contentType) {
    case 'boolean':
      noteValue = (Formatters.BooleanFormatter.in((value || ' ').trim(), yes, no) ?? '').toString();
      break;
    case 'time':
      value = value.trim();
      // if (value.startsWith('24')) value = value.replace('24', '00');
      if (limit === '5') {
        value = `0${value}`;
      }
      let time = Formatters.Time.in(value?.trim() || '');
      value = time ? Formatters.Time.out(time, +(limit || 4) >= 5 ? 'HH:mm:ss' : 'HH:mm') : '';
      noteValue = value;
      break;
    case 'date':
      noteValue = Formatters.Date.convertValueFromServerFormatToClientFormat(
        noteValue,
        settings?.regionals?.dateformats[0].$,
        localization.dateFormat,
        true
      );
      break;
    case 'positiveinteger':
      const temp = Formatters.PositiveInteger.in(value);
      value = temp === 'NaN' ? value : temp;
      noteValue = value;
      break;
    case 'positiveinteger_with_zeros':
      const temp0 = Formatters.PositiveIntegerWithZeros.in(value);
      value = temp0 === 'NaN' ? value : temp0;
      noteValue = value;
      break;
    case 'decimal':
      noteValue = Formatters.Decimal.in(noteValue, decSep, true);
      break;
    case 'positivedecimal':
      let temp1 = Formatters.Decimal.in(noteValue, decSep, true);
      noteValue = temp1.startsWith('-') ? temp1.substring(1) : temp1;
      break;
    case 'integer':
      value = Formatters.Integer.in(value);
      noteValue = value;
      break;
    case 'numeric':
      noteValue = Formatters.Decimal.in(noteValue, decSep, true);
      break;
    case 'decimal_with_zeros':
      noteValue = Formatters.Decimal.in(noteValue, decSep, true);
      break;
    case 'image':
      noteValue = value;
      break;
    default:
      value = value.trimEnd();
      noteValue = value;
      break;
  }
  return noteValue;
};
