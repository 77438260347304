import { FunctionComponent } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Icons, SquareIcon } from './SquareIcon';
import ApplicationError from '../framework/ApplicationError';
import { Localization } from '../framework/localization/Localization';

type ApplicationErrorsProps = {
  errors?: ApplicationError[];
};

export const ApplicationErrors: FunctionComponent<ApplicationErrorsProps> = ({ errors }) => {
  if ((errors?.length || 0) < 1) return null;

  return (
    <>
      <div>
        {errors?.map((error, i: number) => (
          <Card>
            <Card.Header>
              <SquareIcon size={'24px'} color={'red'} className={'icon-rss-alert-red'}>
                {Icons.Alert}
              </SquareIcon>
              {' ' + Localization.instance.getParametrizedString(error.messageKey, error.params)}
            </Card.Header>

            <Card.Body>
              <ul>
                {error.details?.map((detail, index) => (
                  <li>{detail}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        ))}
      </div>
    </>
  );
};

export default ApplicationErrors;
