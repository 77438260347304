import { FunctionComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Header } from '../../../framework/dashboard/IWidget';
import { Localization } from '../../../framework/localization/Localization';

export type WidgetTableProps = {
  title?: string;
  headers: Header[];
  rows: Record<string, any>[];
};

export const WidgetTable: FunctionComponent<any> = (props: WidgetTableProps) => {
  return (
    <>
      {props.title ? (
        <Row>
          <Col sm={12}>
            <p>
              <strong>{props.title}</strong>
            </p>
          </Col>
        </Row>
      ) : (
        ''
      )}
      <div className={'widget-container'}>
        <span className={'table'}>
          {props.headers.length > 0 && (
            <Row noGutters className='dark-bg table-row'>
              {props.headers.map((header: Header, index: number) => {
                return (
                  <Col style={{ width: header.label?.length + 'em' }} className={'table-cell'} key={index}>
                    <p style={{ textTransform: 'capitalize' }}>
                      <strong>{header.label}</strong>
                    </p>
                  </Col>
                );
              })}
            </Row>
          )}

          {props.rows?.length > 0 ? (
            props.rows.map((row: Record<string, any>) => (
              <Row noGutters className='table-row'>
                {props.headers.map((header: Header, index: number) => {
                  return (
                    <Col style={{ width: row[header.key]?.length + 'em' }} className={'table-cell'} key={index}>
                      <p>
                        <strong>{row[header.key]}</strong>
                      </p>
                    </Col>
                  );
                })}
              </Row>
            ))
          ) : (
            <p className='text-center' style={{ top: '50%', position: 'absolute', width: '100%' }}>
              <strong>{Localization.instance.getString('DASHBOARD_NoData')}</strong>
            </p>
          )}
        </span>
      </div>
    </>
  );
};
