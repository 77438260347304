import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Doughnut } from 'react-chartjs-2';
import { string } from 'prop-types';
import { format } from 'date-fns';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import { Localization } from '../../framework/localization/Localization';
const dkCompanyInformation: CellPlugin = {
  Renderer: ({ data }: any) => {
    const [response, setResponse] = useState<any>({});
    const [registrationExists, setRegistrationExists] = useState<boolean>(true);
    const [nemhandelRegistered, setNemhandelRegistered] = useState<boolean>(false);
    const getData = async () => {
      const date = new Date();
      const millisec = date.getTime();
      const id = millisec.toString(36);
      let payload = {
        IptorAPI: '1.0',
        method: 'companyInfo.get',
        id: id
      };
      let res = await axios
        .request({
          url: '/aperio/api/service',
          data: payload,
          method: 'POST'
        })
        .catch((err) => {
          console.error(`Error from dkCompanyInformation ${err}`);
        });
      setResponse(res?.data || {});
    };

    useEffect(() => {
      getData();
    }, [data]);

    const checkCVR = async (regNumber: any) => {
      let res = await axios
        .request({
          url: '/client/cvrNumberCheck/' + regNumber,
          method: 'GET'
        })
        .catch((err) => {
          console.log(err);
        });

      setNemhandelRegistered(res?.data?.registered || false);
    };

    useEffect(() => {
      if (response?.data?.registrationNo) {
        setRegistrationExists(true);
      } else {
        setRegistrationExists(false);
      }
    }, [response]);

    useEffect(() => {
      if (response?.data?.registrationNo) {
        setRegistrationExists(true);
      } else {
        setRegistrationExists(false);
      }
      // COUNTRY NEEDS TO BE CHANGED TO DK.
      if (response?.data?.systemCountry === 'DK' && registrationExists && !nemhandelRegistered)
        checkCVR(response?.data?.registrationNo);
    }, [response, registrationExists, nemhandelRegistered]);

    return (
      <div className={'card'} style={{ height: data.height || '300px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <span style={{ overflow: 'auto' }}>
            <span style={{ paddingRight: '15px', overflow: 'auto' }}>
              <div className='row'>
                {registrationExists && !nemhandelRegistered && (
                  <div className='col-sm-12'>
                    <span className='text-danger blink h5 text-decoration-underline'>
                      Your software is not registered in Nemhandelsregisteret.
                    </span>
                    <br></br>
                    <a
                      href='https://registration.nemhandel.dk/NemHandelRegisterWeb/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-danger h5 text-decoration-underline'
                    >
                      <span> Click here</span>
                    </a>
                    <span className='text-danger h5 text-decoration-underline'> to register.</span>
                  </div>
                )}
                {!registrationExists && response?.data?.systemCountry === 'DK' && (
                  <div className='col-sm-12 text-danger blink h6'>Company registration number does not exists</div>
                )}
                <div className='col-sm-12'>
                  <p>
                    <strong>{Localization.instance.getString('Company_Information_Widget')}</strong>
                  </p>
                </div>
                <div className='col-sm-4'>{Localization.instance.getString('CON_name')}</div>
                <div className='col-sm-8'>{response?.data?.name || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('Attachment_link_address')} 1</div>
                <div className='col-sm-8'>{response?.data?.addressLine1 || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('Attachment_link_address')} 2</div>
                <div className='col-sm-8'>{response?.data?.addressLine2 || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('Attachment_link_address')} 3</div>
                <div className='col-sm-8'>{response?.data?.addressLine3 || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_Postal_Code')}</div>
                <div className='col-sm-8'>{response?.data?.postalCode || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_Country')}</div>
                <div className='col-sm-8'>{response?.data?.systemCountry || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_Currency')}</div>
                <div className='col-sm-8'>{response?.data?.systemCurrency || ''}</div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_CompanyRegistrationNo')}</div>
                <div className='col-sm-8'>{response?.data?.registrationNo || ''}</div>

                <hr />
                <div className='col-sm-12'>
                  <p>
                    <strong>{Localization.instance.getString('TXT_System_Information')}</strong>
                  </p>
                </div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_Environment')}</div>
                <div className='col-sm-8'>
                  {response?.data?.environmentId || ''} <br /> {response?.data?.environmentDescription || ''}
                </div>

                <div className='col-sm-4'>{Localization.instance.getString('TXT_code')}</div>
                <div className='col-sm-8'>{response?.data?.company || ''}</div>
              </div>
            </span>
          </span>
        </Widget>
      </div>
    );
  },
  id: 'dk-company-information',
  title: 'DK Company information',
  description: 'Display company information with DK compliance',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default dkCompanyInformation;
