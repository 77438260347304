export const ParseFlags = (flags: string): boolean[] => {
  return flags.split('').map((f: string) => f === '1');
};

export interface IConstraint {
  evaluate(data: boolean[], value: boolean): boolean;
}

export class FlagConstraint implements IConstraint {
  protected flagsList: string[] = [];
  protected notList: boolean[] = [];

  constructor(constraints: string) {
    if (!constraints) return;
    constraints = constraints.trim();
    let length: number = constraints.length;
    for (let i: number = 0; i < length; i++) {
      let invert: boolean = constraints[i] === 'N';
      let start: number = i + ~~invert;
      let end: number = start + 2;
      this.notList.push(invert);
      this.flagsList.push(constraints.substring(start, end));
      i = end - 1;
    }
  }

  evaluate(flags: boolean[], value: boolean): boolean {
    let length = this.flagsList.length;
    for (let i: number = 0; i < length; i++) {
      let flag: string = this.flagsList[i];
      let notFlag: boolean = this.notList[i];
      if (!this.determineValue(flag, notFlag, flags)) return false;
      else value = true;
    }
    return value;
  }

  protected determineValue(flag: string, notFlag: boolean, flags: boolean[]): boolean {
    return !flag || +flag <= 0 || notFlag !== flags[+flag - 1];
  }

  toString(): string {
    let buf: string = 'Flags(';
    if (this.flagsList.length) buf += ' list: ' + this.flagsList + '.';
    if (this.notList.length) buf += ' not-list: ' + this.notList;
    buf += ')';
    return buf;
  }
}

export class MPPlatformConstraint extends FlagConstraint {
  static readonly MP_FLAG: string = 'MP';

  protected determineValue(flag: string, notFlag: boolean, flags: boolean[]): boolean {
    return flag.trim().toUpperCase() === MPPlatformConstraint.MP_FLAG
      ? notFlag
      : super.determineValue(flag, notFlag, flags);
  }
}
