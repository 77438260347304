import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './Constants';
import { Dispatch } from 'redux';

export const initialState = {
  loading: false,
  hasErrors: false,
  wareHouses: {}
};

// A slice for wareHouses with our three reducers
const wareHousesSlice = createSlice({
  name: 'wareHouses',
  initialState,
  reducers: {
    getwareHouses: (state) => {
      state.loading = true;
    },
    getwareHousesSuccess: (state, { payload }) => {
      state.wareHouses = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getwareHousesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    }
  }
});

// Three actions generated from the slice
export const { getwareHouses, getwareHousesSuccess, getwareHousesFailure } = wareHousesSlice.actions;

// A selector
export const wareHousesSelector = (state: any) => state;

// The reducer
export default wareHousesSlice.reducer;

// Asynchronous thunk action
export function fetchwareHouses(token: string) {
  return async (dispatch: Dispatch) => {
    // dispatch(getwareHouses())
    try {
      const url = BASE_URL + '/service';
      await axios({
        method: 'POST',
        url: url,
        data: {
          IptorAPI: '1.0',
          method: 'uidWarehouses.get'
        }
      })
        .then((result) => {
          dispatch(getwareHousesSuccess(result));
          // alert("wareHouses Successful")
        })
        .catch((err) => {
          // Do somthing
          dispatch(getwareHousesFailure());
          console.error(`Error from fetchwareHouses ${err}`);
          // alert("wareHouses Failed. Please check your credentials")
        });
    } catch (error) {
      dispatch(getwareHousesFailure());
      // alert("Failed")
    }
  };
}
