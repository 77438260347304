import { ValueFormatterExtension } from './ValueFormatterExtension';

export class PadLeadingZerosOrShowEmptyValueFormatter implements ValueFormatterExtension {
  in(value: string) {
    let zeroValue = false;
    const trimmedValue = value.trim();
    if (trimmedValue.length > 0) {
      zeroValue = !trimmedValue.includes('0') && !trimmedValue.includes('.') && !trimmedValue.includes(' ');
      if (!zeroValue) return '';
    }
    return value;
  }
  out(value: string) {
    if (value.trim().length == 0) {
      return '0';
    }
    return value.trim();
  }
}
