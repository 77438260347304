import React, { useState, FunctionComponent } from 'react';
import './index.scss';

type TransportNotePrintProps = {
  handleClose?: () => void;
  shipmentData: any;
  onTransportNotePrint: (arg: any) => void;
};

const selectPrintOptions = [
  { label: 'Print amount', name: 'printAmount' },
  { label: 'Allow re-print', name: 'allowRePrint' }
];

const TransportNotePrint: FunctionComponent<TransportNotePrintProps> = ({
  handleClose,
  shipmentData,
  onTransportNotePrint
}) => {
  const [params, setParams] = useState({
    shipment: shipmentData.shipmentNumber,
    printAmount: false,
    allowRePrint: false
  });

  const handleChange = (e: any) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setParams({
      ...params,
      [e.target.name]: value
    });
  };

  return (
    <React.Fragment>
      <div className='header'>
        <h5>Transport note printout</h5>
      </div>
      <div style={{ height: 'calc(100vh - 235px)', overflow: 'auto' }}>
        <div style={{ padding: '20px 10px 20px 50px' }}>
          <div style={{ marginBottom: '20px' }}>
            <label className='label'>Shipment number</label>
            <input className='uidOutputOnly' type='text' readOnly value={params.shipment} name='shipmentNumber' />
          </div>
          <div style={{ marginBottom: '15px' }}>
            {selectPrintOptions.map((obj, idx) => {
              return (
                <div key={idx}>
                  <label className='label'>{obj.label}</label>
                  <input className='boxes' type='checkbox' name={obj.name} onChange={(e) => handleChange(e)} />
                </div>
              );
            })}
          </div>
          {/* <div>
            <div>
              <label className="label">Printer queue</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Number of copies</label>
              <input type="text" />
            </div>
            <div>
              <label className="label">Hold on spool file</label>
              <input type="checkbox" />
            </div>
          </div> */}
        </div>
      </div>
      <div className='footer'>
        <button onClick={() => onTransportNotePrint(params)}>Ok</button>
        <button onClick={handleClose}>Back</button>
      </div>
    </React.Fragment>
  );
};

export default TransportNotePrint;
