import { Environment, ExtendedProfile, User, UserProfile } from 'oidc-client-ts';
import { base64 } from '../helpers/base64';

declare module 'oidc-client-ts' {
  interface User {
    idTokenParsed(): any;
    accessTokenParsed(): any;
    extendedUserProfile(): ExtendedProfile;
  }
  type Environment = {
    key: string;
    label: string;
  };

  type ExtendedProfile = UserProfile & {
    environments?: Environment[]; // array of available environments, like: dev, prod, stagging, etc.
    app_user_id?: string; // used for communication with DC1
    envConfigKey?: string;
    roles?: string[]; // available roles for user, used in Dashboard editor, e.g. 'marketing_admin', 'dashboard_admin', 'marketing_user', 'offline_access', 'default-roles-iptor.com', 'uma_authorization', 'dashboard_global'
    authClientName?: string;
  };
}

User.prototype.idTokenParsed = function (this: User) {
  return JSON.parse(base64.base64ToBytes(this?.id_token?.split('.')[1] ?? '', true));
};
User.prototype.accessTokenParsed = function (this: User) {
  return JSON.parse(base64.base64ToBytes(this?.access_token.split('.')[1] ?? '', true));
};

User.prototype.extendedUserProfile = function (this: User): ExtendedProfile {
  const tokenParsed = JSON.parse(base64.base64ToBytes(this?.access_token.split('.')[1] ?? '', true));
  let userProfileWithIptorSpecificAttributes: ExtendedProfile;
  userProfileWithIptorSpecificAttributes = {
    environments: tokenParsed?.['environments'] as Environment[],
    app_user_id: tokenParsed?.app_user_id,
    envConfigKey: tokenParsed?.envConfigKey,
    roles: tokenParsed?.realm_access?.roles,
    authClientName: this.profile.azp,
    ...this.profile
  };
  return userProfileWithIptorSpecificAttributes;
};
