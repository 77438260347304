import { useDrag, useDrop } from 'react-dnd';
import { SortableHeaderCell } from 'react-data-grid';
import type { HeaderRendererProps } from 'react-data-grid';

import React, { createContext, useCallback, useContext } from 'react';
import { Dnd } from '../types/item-types';

export function useCombinedRefs<T>(...refs: React.Ref<T>[]) {
  return useCallback(
    (handle: T | null) => {
      for (const ref of refs) {
        if (typeof ref === 'function') {
          ref(handle);
        } else if (ref !== null) {
          // @ts-expect-error: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
          ref.current = handle;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    refs
  );
}

interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
  onColumnsReorder: (sourceKey: string, targetKey: string) => void;
}

export interface Filter extends Omit<any, 'id' | 'complete'> {
  complete?: number;
  enabled: boolean;
}

export const FilterContext = createContext<Filter | undefined>(undefined);

// function FilterRenderer<R, SR>({
//   column,
//   children
// }: HeaderRendererProps<R, SR> & {
//   children: (filters: Filter) => React.ReactElement;
// }) {
//   const filters = useContext(FilterContext)!;
//   return (
//     <>
//       <div>{column.name}</div>
//       {filters.enabled && <div>{children(filters)}</div>}
//     </>
//   );
// }

export function DraggableHeaderRenderer<R>({
  onColumnsReorder,
  column,
  sortDirection,
  onSort,
  priority,
  children
}: DraggableHeaderRendererProps<R> & {
  children: (filters: Filter) => React.ReactElement;
}) {
  const [{ isDragging }, drag] = useDrag({
    type: Dnd.ItemTypes.COLUMN_DRAG,
    item: { key: column.key },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: Dnd.ItemTypes.COLUMN_DRAG,
    drop({ key }: { key: string }) {
      onColumnsReorder(key, column.key);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const filters = useContext(FilterContext)!;

  return (
    <div
      ref={useCombinedRefs(drag, drop)}
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isOver ? '#ececec' : 'inherit',
        cursor: 'move'
      }}
    >
      {column.sortable ? (
        <SortableHeaderCell sortDirection={sortDirection} onSort={onSort} priority={priority}>
          {column.name || ' '}
        </SortableHeaderCell>
      ) : (
        <>{column.name || ' '}</>
      )}
      {filters.enabled && <div>{children(filters)}</div>}
    </div>
  );
}
