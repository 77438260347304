import { LogSettings, LogAppenderType, AppenderSettings, LogLevel, ILoggerAppender, LogInfo } from './loggerTypes';
import { stringToNumerableEnum } from './loggerUtils';
import { ConsoleAppender } from './appenders/ConsoleAppender';
import { ServerAppender } from './appenders/ServerAppender';

export class ClientLogger {
  private logLevel: LogLevel;
  private appenders: ILoggerAppender[] = [];
  private tags: string[] = [];
  private meta: Record<string, any> = {};
  private highestLogLevel = 0;
  private functionCount = 1;

  constructor(settings: LogSettings) {
    this.logLevel = stringToNumerableEnum<LogLevel>(LogLevel as any, settings.level);

    settings.appenders.forEach((appender: AppenderSettings) => {
      const logAppenderType = stringToNumerableEnum<LogAppenderType>(LogAppenderType as any, appender.type);
      this.determineHighestLevel(appender.level);

      switch (logAppenderType) {
        case LogAppenderType.CONSOLE:
          this.appenders.push(new ConsoleAppender(appender, settings.level));
          break;
        case LogAppenderType.SERVER:
          this.appenders.push(new ServerAppender(appender, settings.level));
          break;
        default:
          break;
      }
    });
  }

  protected createChild(): ClientLogger {
    const child = new ClientLogger({ level: 'ERROR', appenders: [] });
    child['logLevel'] = this.logLevel;
    child['appenders'] = this.appenders;
    child['tags'] = [...this.tags];
    child['meta'] = { ...this.meta };
    child['highestLogLevel'] = this.highestLogLevel;
    child['functionCount'] = this.functionCount;
    return child;
  }
  protected loggingNeeded(logLevel: LogLevel): boolean {
    return logLevel <= this.highestLogLevel && !!this.appenders;
  }

  private determineHighestLevel(appenderLogLevel: keyof typeof LogLevel | undefined) {
    if (appenderLogLevel === undefined) {
      if (this.logLevel > this.highestLogLevel) this.highestLogLevel = this.logLevel;
    } else {
      const logLevel = stringToNumerableEnum<LogLevel>(LogLevel as any, appenderLogLevel);
      if (logLevel > this.highestLogLevel) this.highestLogLevel = logLevel;
    }
  }

  public log(data: any, level: LogLevel, meta: Record<string, any> = {}) {
    if (this.loggingNeeded(level)) {
      this.appenders.forEach((appender) =>
        appender.log({ logData: data, tags: this.tags, meta: { ...this.meta, ...meta } } as LogInfo, level)
      );
    }
  }

  public error(data: any, meta?: Record<string, any>) {
    this.log(data, LogLevel.ERROR, meta);
  }
  public warn(data: any, meta?: Record<string, any>) {
    this.log(data, LogLevel.WARN, meta);
  }
  public info(data: any, meta?: Record<string, any>) {
    this.log(data, LogLevel.INFO, meta);
  }
  public trace(data: any, meta?: Record<string, any>) {
    this.log(data, LogLevel.TRACE, meta);
  }
  public debug(data: any, meta?: Record<string, any>) {
    this.log(data, LogLevel.DEBUG, meta);
  }
  public childWithTags(...tags: string[]): ClientLogger {
    const child = this.createChild();
    child['tags'] = [...this.tags, ...tags];
    return child;
  }
  public childWithMeta(meta: Record<string, any>): ClientLogger {
    const child = this.createChild();
    child['meta'] = { ...this.meta, ...meta };
    return child;
  }
  public childWithFunctionInfo(name: string, args: Record<string, any> = {}): ClientLogger {
    const key = `Function(${this.functionCount++})`; // create unique key to allow logging of multiple calls to same function (or to handle recursive calls)
    return this.childWithMeta({ [key]: { name, args } });
  }
}
