import { format, parse } from 'date-fns';
export class TimeValueFormatter {
  in(value: string, limit?: number): Date | null {
    if (!value || !value.trim() || !+value) return null;
    value = value.trim();
    let separator = value.match(/[^0-9]/)?.[0] || '';
    value = value.replaceAll(separator, '');
    if (value.length === 3 || value.length === 5 || value.length === 1) value = `0${value}`;
    let hasSeconds = limit ? limit >= 5 : value.length === 6;

    // if (value.includes(':')) {
    //   return parse(value, 'hh:mm' + (hasSeconds ? ':ss' : ''), new Date());
    // } else if (value.includes('.')) {
    //   return parse(value, 'hh.mm' + (hasSeconds ? '.ss' : ''), new Date());
    // } else {
    //   {
    //     return parse(value, 'hhmm' + (hasSeconds ? 'ss' : ''), new Date());
    //   }
    // }
    if (value.length === 2 && hasSeconds) value = `0000${value}`;
    if (value.length === 4 && hasSeconds) value = `00${value}`;
    if (value.length === 2 && !hasSeconds) value = `00${value}`;
    value = value.startsWith('24') ? (value.length === 4 ? '0000' : '000000') : value;
    try {
      if (hasSeconds) {
        const date = parse(value, 'HHmmss', new Date());
        if (isNaN(date.getTime())) {
          return null;
        }
        return date;
      } else {
        const date = parse(value, 'HHmm', new Date());
        if (isNaN(date.getTime())) {
          return null;
        }
        return date;
      }
    } catch (e) {
      console.error(`unable to IN parse time, ${value}`);
      return null;
    }
    // if (formatter.includes(':')) {
    //   if (!value.includes(':')) {
    //     value = value.replace(/(?=(?:\d{2})+\b)(?!\b)/g, ':');
    //   }
    //   return parse(formatter, formatter, new Date());
    // } else {
    //   formatter.replace('.', '');
    //   formatter = formatter.replace(/(?=(?:\d{2})+\b)(?!\b)/g, ':');
    //   if (formatter.includes('.')) {
    //     value = value.replace(/(?=(?:\d{2})+\b)(?!\b)/g, '.');
    //   }
    //   return parse(value, formatter, new Date());
    // }
  }

  out(value: Date, formatter: string) {
    let result = '';
    try {
      result = format(new Date(value), formatter);
    } catch (e) {
      console.error(`unable to Out parse time ${e}`);
    }
    return result;
  }
}
