import React, { Component } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../styles/ForgotPassword.scss';
import '../styles/Login.scss';
import { Localization } from '../framework/localization/Localization';

class ForgotPasswordMessage extends Component {
  render() {
    return (
      <Container
        fluid
        style={{
          height: '100vh',
          background:
            'url("' +
            process.env.REACT_APP_LOGIN_BACKGROUND +
            '"), url("/Sign-in-page-bg-image_flying-car-text-1920x1080.jpg")',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Row style={{ height: '100vh' }} className='align-items-end'>
          <Col sm={0} md={7} lg={8} style={{ padding: '0px 100px', margin: '160px 0px' }}>
            {/*<Image src="/logo-white.png" hidden style={{ margin: '32px 0px' }} />*/}
            <p className='login-banner' hidden>
              A milestone in the Iptor transformation journey
            </p>
            <p className='login-subtitle' hidden>
              Version 12 is the key platform for distribution and publishing businesses to turn digital disruption into
              digital opportunity.
            </p>
          </Col>
          <Col style={{ height: '100vh' }} sm={12} md={5} lg={4}>
            <div
              style={{
                height: 'calc(100vh - 30px)',
                margin: '15px 0',
                padding: '24px 0px',
                position: 'relative',
                overflow: 'auto'
              }}
              className='bg-white'
            >
              <Image style={{ paddingLeft: '24px' }} src={'/logo-color.png'} />
              <h6 className='welcome-message' style={{ marginTop: '32px' }}>
                {Localization.instance.getString('TXT_FORGOT_PAGE_FORGOT_PASSWORD')}
              </h6>
              <div style={{ marginTop: '40px', padding: '0 48px' }}>
                <div className='login-form forgot-password-message-text'>
                  {Localization.instance.getString('TXT_HI')},
                  <br />
                  <br />
                  {Localization.instance.getString('TXT_FORGOT_PASSWORD_MESSAGE')}
                  <br />
                  <button
                    onClick={() => (window.location.href = '/')}
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: '8px',
                      marginTop: '120px'
                    }}
                  >
                    <div
                      style={{
                        display: 'inline-block',
                        width: '22px',
                        height: '22px',
                        marginRight: '13px',
                        verticalAlign: 'middle'
                      }}
                    >
                      <svg width='22' height='23' viewBox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M1.375 11.5C1.375 14.0527 2.38906 16.5009 4.1941 18.3059C5.99913 20.1109 8.44729 21.125 11 21.125C13.5527 21.125 16.0009 20.1109 17.8059 18.3059C19.6109 16.5009 20.625 14.0527 20.625 11.5C20.625 8.94729 19.6109 6.49913 17.8059 4.6941C16.0009 2.88906 13.5527 1.875 11 1.875C8.44729 1.875 5.99913 2.88906 4.1941 4.6941C2.38906 6.49913 1.375 8.94729 1.375 11.5ZM22 11.5C22 14.4174 20.8411 17.2153 18.7782 19.2782C16.7153 21.3411 13.9174 22.5 11 22.5C8.08262 22.5 5.28473 21.3411 3.22183 19.2782C1.15893 17.2153 0 14.4174 0 11.5C0 8.58262 1.15893 5.78473 3.22183 3.72183C5.28473 1.65893 8.08262 0.5 11 0.5C13.9174 0.5 16.7153 1.65893 18.7782 3.72183C20.8411 5.78473 22 8.58262 22 11.5ZM15.8125 10.8125C15.9948 10.8125 16.1697 10.8849 16.2986 11.0139C16.4276 11.1428 16.5 11.3177 16.5 11.5C16.5 11.6823 16.4276 11.8572 16.2986 11.9861C16.1697 12.1151 15.9948 12.1875 15.8125 12.1875H7.84712L10.7992 15.1383C10.8632 15.2022 10.9139 15.2781 10.9485 15.3616C10.9831 15.4451 11.0009 15.5346 11.0009 15.625C11.0009 15.7154 10.9831 15.8049 10.9485 15.8884C10.9139 15.9719 10.8632 16.0478 10.7992 16.1117C10.7353 16.1757 10.6594 16.2264 10.5759 16.261C10.4924 16.2956 10.4029 16.3134 10.3125 16.3134C10.2221 16.3134 10.1326 16.2956 10.0491 16.261C9.96556 16.2264 9.88967 16.1757 9.82575 16.1117L5.70075 11.9867C5.63673 11.9229 5.58593 11.847 5.55127 11.7635C5.51661 11.68 5.49877 11.5904 5.49877 11.5C5.49877 11.4096 5.51661 11.32 5.55127 11.2365C5.58593 11.153 5.63673 11.0771 5.70075 11.0133L9.82575 6.88825C9.95484 6.75916 10.1299 6.68663 10.3125 6.68663C10.4951 6.68663 10.6702 6.75916 10.7992 6.88825C10.9283 7.01734 11.0009 7.19243 11.0009 7.375C11.0009 7.55757 10.9283 7.73266 10.7992 7.86175L7.84712 10.8125H15.8125Z'
                          fill='#00A3A5'
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        display: 'inline-block',
                        fontStyle: 'normal',
                        fontSize: '18px',
                        lineHeight: '25px',
                        verticalAlign: 'middle',
                        color: '#00A3A5',
                        paddingTop: '6px'
                      }}
                    >
                      {Localization.instance.getString('Back_to_login')}
                    </div>
                  </button>
                </div>
              </div>
              <p className='copyright-notice-login'>
                {Localization.instance.getString('TXT_COPYRIGHT_FOOTER')}
                <br />
                {Localization.instance.getString('TXT_PRIVACY_POLICY')} |{' '}
                {Localization.instance.getString('TXT_UK_LEGAL')} |{' '}
                {Localization.instance.getString('TXT_CODE_OF_CONDUCT')}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ForgotPasswordMessage;
