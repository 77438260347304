import { SettingsActions, TableActions } from '../../types/actionTypes';
import { Program } from '../../types/program';

type TableSetting = {
  hCols: string[];
  columnWidths?: { [key: string]: string };
  colOrder?: string[];
  tooltipSequence: { [key: string]: string };
  isTooltipEnabled: boolean;
};
export type settingsState = {
  tables: {
    [formID: string]: {
      [panelID: string]: {
        [tableID: string]: {
          [toggleID: string]: TableSetting;
        };
      };
    };
  };
  quickLinks: Program[];
  autoLinks: Program[];
  sortingOrder: {
    [windowID: string]: {
      [formID: string]: {
        [panelID: string]: {
          [tableID: string]: {
            sortBy: { columnKey: string; direction: string }[];
          };
        };
      };
    };
  };
};
const initialState: settingsState = {
  tables: {},
  quickLinks: [],
  autoLinks: [],
  sortingOrder: {}
};

export const SettingsReducer = (
  state = initialState,
  action: { type: TableActions | SettingsActions; payload: any }
) => {
  switch (action.type) {
    case SettingsActions.QUICK_LINKS_UPDATE: {
      if (state.quickLinks.some((x: any) => x.id === action.payload.program.id))
        state.quickLinks = state.quickLinks.filter((x: any) => x.id !== action.payload.program.id);
      else state.quickLinks.push(action.payload.program);
      sessionStorage.setItem('clientSettings', JSON.stringify(state));
      return { ...state };
    }
    case SettingsActions.AUTO_LINKS_UPDATE: {
      if (!state.autoLinks) state.autoLinks = [];
      if (state.quickLinks.some((x: any) => x.id === action.payload.program.id))
        state.quickLinks = state.quickLinks.map((x: any) => {
          if (x.id === action.payload.program.id) {
            x.isAutoStart = !x.isAutoStart;
          }
          return x;
        });
      sessionStorage.setItem('clientSettings', JSON.stringify(state));
      return { ...state };
    }
    case SettingsActions.QUICK_LINKS_REORDER: {
      /*
          expected payload: {
            linkIdA: string,
            position: 'BEFORE' | 'AFTER',
            linkIdB: string
          }
      */
      if (action.payload.linkIdA === action.payload.linkIdB) return state;
      const indexA = state.quickLinks.findIndex((link) => link.id === action.payload.linkIdA);
      const indexB = state.quickLinks.findIndex((link) => link.id === action.payload.linkIdB);
      if (indexA < 0 || indexB < 0) return state;
      if (indexA === indexB) return state;

      let resultingLinks: Program[] = state.quickLinks;
      if (action.payload.position === 'BEFORE') {
        if (indexA < indexB) {
          const beforeA = state.quickLinks.slice(0, indexA); //                           --> remark slice(0, 0) = []
          const betweenAandB = state.quickLinks.slice(indexA + 1, indexB); //             --> remark slice(x, x) = []
          const fromBtillEnd = state.quickLinks.slice(indexB, state.quickLinks.length);
          resultingLinks = [...beforeA, ...betweenAandB, state.quickLinks[indexA], ...fromBtillEnd];
        } else {
          const beforeB = state.quickLinks.slice(0, indexB);
          const fromBuntilBeforeA = state.quickLinks.slice(indexB, indexA);
          const afterAtillEnd = state.quickLinks.slice(indexA + 1, state.quickLinks.length);
          resultingLinks = [...beforeB, state.quickLinks[indexA], ...fromBuntilBeforeA, ...afterAtillEnd];
        }
      } else if (action.payload.position === 'AFTER') {
        if (indexA < indexB) {
          const beforeA = state.quickLinks.slice(0, indexA);
          const afterAtillB = state.quickLinks.slice(indexA + 1, indexB + 1);
          const afterBtillEnd = state.quickLinks.slice(indexB + 1, state.quickLinks.length); // remark slice[length, length] = []
          resultingLinks = [...beforeA, ...afterAtillB, state.quickLinks[indexA], ...afterBtillEnd];
        } else {
          const tillB = state.quickLinks.slice(0, indexB + 1);
          const betweenBandA = state.quickLinks.slice(indexB + 1, indexA);
          const afterAtillEnd = state.quickLinks.slice(indexA + 1, state.quickLinks.length);
          resultingLinks = [...tillB, state.quickLinks[indexA], ...betweenBandA, ...afterAtillEnd];
        }
      }
      if (resultingLinks === state.quickLinks) return state;
      return { ...state, quickLinks: resultingLinks };
    }
    case SettingsActions.LOAD: {
      let settingsString = sessionStorage.getItem('clientSettings');
      if (!!settingsString) {
        let settings = JSON.parse(settingsString);
        return { ...settings };
      } else if (action.payload) {
        sessionStorage.setItem('clientSettings', JSON.stringify(action.payload));
        return { ...action.payload };
      } else {
        return { ...state };
      }
    }
    case TableActions.SET_COLUMN_ORDER: {
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  [action.payload.toggleID]: {
                    ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                      action.payload.toggleID
                    ],
                    colOrder: action.payload.colOrder
                  }
                }
              }
            },
            [action.payload.tableID]: {
              ...state.tables[action.payload.tableID],
              colOrder: action.payload.colOrder
            }
          }
        })
      );

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                [action.payload.toggleID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                    action.payload.toggleID
                  ],
                  colOrder: action.payload.colOrder
                }
              }
            }
          },
          [action.payload.tableID]: {
            ...state.tables[action.payload.tableID],
            colOrder: action.payload.colOrder
          }
        }
      };
    }
    case TableActions.RESET_COLUMN_ORDER: {
      if (
        state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
          action.payload.toggleID
        ]?.colOrder
      )
        delete state.tables[action.payload.formID][action.payload.panelID][action.payload.tableID][
          action.payload.toggleID
        ].colOrder;
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state
        })
      );

      return {
        ...state
      };
    }
    case TableActions.SET_HIDDEN_COLUMNS: {
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  [action.payload.toggleID]: {
                    ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                      action.payload.toggleID
                    ],
                    hCols: action.payload.hCols
                  }
                }
              }
            },
            [action.payload.tableID]: {
              ...state.tables[action.payload.tableID],
              hCols: action.payload.hCols
            }
          }
        })
      );
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                [action.payload.toggleID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                    action.payload.toggleID
                  ],
                  hCols: action.payload.hCols
                }
              }
            }
          },
          [action.payload.tableID]: {
            ...state.tables[action.payload.tableID],
            hCols: action.payload.hCols
          }
        }
      };
    }
    case TableActions.SET_COLUMN_WIDTHS: {
      //columnResizing.columnWidths[columnResizing.isResizingColumn]: width
      let table = state.tables[action.payload.tableID];
      let column = action.payload.column;
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  [action.payload.toggleID]: {
                    ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                      action.payload.toggleID
                    ],
                    columnWidths: {
                      ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                        action.payload.toggleID
                      ]?.columnWidths,
                      [column]: action.payload.width
                    }
                  }
                  // ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  // columnWidths: {
                  //   ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID].columnWidths,
                  //   [column]: action.payload.width
                  // }
                }
              }
            }
          }
        })
      );
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                [action.payload.toggleID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                    action.payload.toggleID
                  ],
                  columnWidths: {
                    ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
                      action.payload.toggleID
                    ]?.columnWidths,
                    [column]: action.payload.width
                  }
                }
              }
            }
          }
        }
      };
    }
    case TableActions.RESET_COLUMN_WIDTH: {
      if (
        state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID]?.[
          action.payload.toggleID
        ]?.columnWidths
      )
        state.tables[action.payload.formID][action.payload.panelID][action.payload.tableID][
          action.payload.toggleID
        ].columnWidths = {};
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state
        })
      );

      return {
        ...state
      };
    }
    case TableActions.SET_DEFAULT_ACTION: {
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  defaultAction: action.payload.defaultAction
                }
              }
            },
            [action.payload.tableID]: {
              ...state.tables[action.payload.tableID],
              defaultAction: action.payload.defaultAction
            }
          }
        })
      );

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                defaultAction: action.payload.defaultAction
              }
            }
          },
          [action.payload.tableID]: {
            ...state.tables[action.payload.tableID],
            defaultAction: action.payload.defaultAction
          }
        }
      };
    }
    case TableActions.SET_TOOLTIP_SEQUENCE: {
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  tooltipSequence: action.payload.tooltipSequence
                }
              }
            },
            [action.payload.tableID]: {
              ...state.tables[action.payload.tableID],
              tooltipSequence: action.payload.tooltipSequence
            }
          }
        })
      );

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                tooltipSequence: action.payload.tooltipSequence
              }
            }
          },
          [action.payload.tableID]: {
            ...state.tables[action.payload.tableID],
            tooltipSequence: action.payload.tooltipSequence
          }
        }
      };
    }
    case TableActions.TOGGLE_TOOLTIP: {
      sessionStorage.setItem(
        'clientSettings',
        JSON.stringify({
          ...state,
          tables: {
            ...state.tables,
            [action.payload.formID]: {
              ...state.tables[action.payload.formID],
              [action.payload.panelID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                  isTooltipEnabled: !state.tables[action.payload.formID]?.[action.payload.panelID]?.[
                    action.payload.tableID
                  ]?.isTooltipEnabled
                }
              }
            }
            // [action.payload.tableID]: {
            //   ...state.tables[action.payload.tableID],
            //   isTooltipEnabled: !state.tables[action.payload.tableID]?.isTooltipEnabled
            // }
          }
        })
      );

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.payload.formID]: {
            ...state.tables[action.payload.formID],
            [action.payload.panelID]: {
              ...state.tables[action.payload.formID]?.[action.payload.panelID],
              [action.payload.tableID]: {
                ...state.tables[action.payload.formID]?.[action.payload.panelID]?.[action.payload.tableID],
                isTooltipEnabled: !state.tables[action.payload.formID]?.[action.payload.panelID]?.[
                  action.payload.tableID
                ]?.isTooltipEnabled
              }
            }
          }
          // [action.payload.tableID]: {
          //   ...state.tables[action.payload.tableID],
          //   isTooltipEnabled: !state.tables[action.payload.tableID].isTooltipEnabled
          // }
        }
      };
    }
    case TableActions.SET_COLUMN_SORT_BY: {
      return {
        ...state,
        sortingOrder: {
          ...state.sortingOrder,
          [action.payload.windowID]: {
            ...state.sortingOrder?.[action.payload.windowID],
            [action.payload.formID]: {
              ...state.sortingOrder?.[action.payload.windowID]?.[action.payload.formID],
              [action.payload.panelID]: {
                ...state.sortingOrder?.[action.payload.windowID]?.[action.payload.formID]?.[action.payload.panelID],
                [action.payload.tableID]: {
                  ...state.sortingOrder?.[action.payload.windowID]?.[action.payload.formID]?.[action.payload.panelID]?.[
                    action.payload.tableID
                  ],
                  sortBy: action.payload.sortBy
                }
              }
            }
          }
        }
      };
    }

    default:
      return state;
  }
};
