import {
  DependencyList,
  Dispatch,
  EffectCallback,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useStateCallback = <T extends unknown>(
  initialState: T
): [T, (state: T, cb?: (state: T) => void) => void] => {
  const [state, setState] = useState<T>(initialState);
  const cbRef = useRef<((state: T) => void) | null | undefined>(null); // mutable ref to store current callback

  const setStateCallback = useCallback((state: T, cb?: (state: T) => void) => {
    cbRef.current = cb; // store passed callback to ref
    setState(state);
  }, []);

  useEffect(() => {
    // cb.current is `null` on initial render, so we only execute cb on state *updates*
    if (cbRef && cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

export const useLoggedState = <S extends unknown>(defaultValue: S | (() => S)): [S, (newState: S) => void] => {
  const [state, setState] = useState(defaultValue);
  const logAndSetState = (newState: S) => {
    console.info(`Changing state: ${state} ---> ${newState}`);
    setState(newState);
  };
  return [state, logAndSetState];
};

//====================================================================================================================
// useEffect that allows you to specify two dependency arrays:
//  ° first array contains the variables that should be triggering the effect when their value is changed
//  ° second array contains the other dependencies for variables that you want to consult only from inside the effect
// Remark:
//  ° should be used with care as it hides missing dependencies according to REACT linting rules
//  ° might need refactoring when current experimental react event effects become available in production
//====================================================================================================================
export const useAdvancedEffect = (
  effect: EffectCallback,
  triggers: DependencyList = [],
  otherDeps: DependencyList = []
): void => {
  const cb = useCallback(() => {
    return effect();
  }, [effect, ...otherDeps]);

  const refEffect = useRef(cb);
  refEffect.current = cb;

  useEffect(() => {
    return refEffect.current();
  }, triggers);
};

//====================================================================================================================
// useState that returns as a third parameter a reference to the "future" state value
//  ° "set state" functions do NOT immediatly updating the state value. They are dispathing an action to get a changed
//    value at next rendering time
//  ° the reference can be used to inject an up-to-date value of a state (vs stale value) in for instance an (event)
//    effect
// Remark:
//  ° should be used with care as it feels like an anti-pattern for React
//  ° might need refactoring when current experimental react event effects become available in production
// Beware:
//  ° if states are changed by supplying object values (eg setState({ x: 0, y: 0})), the reference.current will hold the
//    same object instance as the state itself. RESPECT THE IMMUTABILTY OF THE STATE (so do not change any child through
//    the referene)
//  ° if states are changed by supplying function values (eg setState((oldState) => {...oldState, changedAttr: newValue)),
//    the reference.current can refer to a different instance holding the same instances to the direct childs. RESPECT THE
//    IMMUTABILTY OF THE STATE !!
//  ° Keep also in mind that, as React batches some state changes, the reference may refer temporarily to a value that will
//    never be the resulting value of the state in the next rendering
//====================================================================================================================
export const useStateWithLookAheadRef = <S extends unknown>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>] => {
  const lookAheadRef = useRef<S>(typeof initialState === 'function' ? (initialState as () => S)() : initialState);
  const [state, setState] = useState<S>(lookAheadRef.current);

  const customSetState = useCallback((newState: SetStateAction<S>) => {
    lookAheadRef.current =
      typeof newState === 'function' ? (newState as (prev: S) => S)(lookAheadRef.current) : newState;

    setState(newState);
  }, []);

  return [state, customSetState, lookAheadRef];
};
