import { Locale, Translations } from './translations';
import { da, de, enUS, es, fi, fr, it, nl, nlBE, nb, pl, pt, sv, zhCN } from 'date-fns/locale';
import { Locale as FNSLocale } from 'date-fns';
import { Option } from '../../components/SettingsCombo';

let LocaleOptions: Option[] | null = null;
export namespace LocalizationInfo {
  export type Settings = {
    locale: Locale;
    dateFormat: string;
    decimalSeparator: string;
    groupSeparator: string;
  };
  export type SettingsList = Partial<Record<Locale, Settings>>;
}

type LocalizationInfo = {
  currentLocale: Locale;
  useBrowserDefault: boolean;
  settingsList: LocalizationInfo.SettingsList;
};

export class Localization {
  private _localizationInfo: LocalizationInfo;
  private static _instance: Localization;
  private static LOCAL_STORAGE_KEY = 'localizationInfo';

  private _formats: Record<string, FNSLocale> = {
    da: da,
    de: de,
    en: enUS,
    es: es,
    es_co: es,
    es_mx: es,
    fi: fi,
    fr: fr,
    it: it,
    nl: nl,
    nl_be: nlBE,
    no: nb,
    pl: pl,
    pt: pt,
    sv: sv,
    zh: zhCN
  };

  private constructor(locale: Locale = 'en') {
    this._localizationInfo = {
      currentLocale: locale,
      useBrowserDefault: false,
      settingsList: {
        [locale]: { locale: locale, dateFormat: 'MM/dd/yyyy', decimalSeparator: '.', groupSeparator: ',' }
      }
    };
  }

  public static get instance(): Localization {
    if (!Localization._instance) {
      // Intitialize
      Localization._instance = new Localization();
      let browserLanguage = navigator?.language || 'en';

      // Get localization info from local storage
      const info = localStorage.getItem(Localization.LOCAL_STORAGE_KEY);
      let localizationInfo: LocalizationInfo = !!info ? JSON.parse(info) : null;

      // First time initialization (==> default behavior: use default settings for current browser language)
      if (!localizationInfo) {
        let settings: LocalizationInfo.Settings = Localization._getDefaultSettings(
          browserLanguage,
          Localization._instance
        );
        localizationInfo = {
          currentLocale: settings.locale,
          useBrowserDefault: true,
          settingsList: { [settings.locale]: settings }
        };

        // Case use browser default: browser default language might be changed since last usage
      } else if (localizationInfo.useBrowserDefault) {
        const locale = Localization.getLocaleFromLanguage(browserLanguage);
        if (locale !== localizationInfo.currentLocale) {
          localizationInfo.currentLocale = locale;
          if (!localizationInfo.settingsList[locale])
            localizationInfo.settingsList[locale] = Localization._getDefaultSettings(
              browserLanguage,
              Localization._instance
            ); // -> case browser default changed since last usage and corresponding locale not previously selected
        }
      }

      // Set result
      Localization._instance._localizationInfo = localizationInfo;

      // Store resulting localization info in local storage
      localStorage.setItem(Localization.LOCAL_STORAGE_KEY, JSON.stringify(localizationInfo));
    }
    return Localization._instance;
  }

  public get locale(): Locale {
    return this.localizationInfo.currentLocale;
  }

  public get settings(): LocalizationInfo.Settings {
    return this.localizationInfo.settingsList[this.locale]!;
  }

  public get decimalSeparator(): string {
    return this.localizationInfo.settingsList[this.locale]!.decimalSeparator;
  }

  public get groupSeparator(): string {
    return this.localizationInfo.settingsList[this.locale]!.groupSeparator;
  }

  public get dateFormat(): string {
    return this.localizationInfo.settingsList[this.locale]!.dateFormat;
  }

  public get dateSeparator(): string {
    return this.dateFormat.replace(/[Mdy]/g, '').charAt(0) || '';
  }

  public get strings(): Record<string, string> {
    return Translations[this.locale];
  }

  public get format(): FNSLocale {
    return this._formats[this.locale];
  }

  public getString(key: string): string {
    return this.strings?.[key] || Translations['en']?.[key] || key;
  }

  public getParametrizedString(key: string, params?: string[]): string {
    /*
      eg key = ERROR_Warehouse_0_does_not_exist 
         getString ==> 'Warehouse {0} does not exist', 'Magazijn {0} bestaat niet', ...
         params = ['MAIN'] ==> 'Warehouse MAIN does not exist', 'Magazijn MAIN bestaat niet', ...
    */
    let text = this.getString(key) || ''; //
    if (params && params.length > 0) {
      params.forEach((value: string, index: number) => {
        text = text.replaceAll(`{${index}}`, value);
      });
    }
    return text;
  }

  public async setSettingsFromLanguage(language: string) {
    // Get locale from language
    if (!LocaleOptions) {
      LocaleOptions = await this.getLocaleOptions();
    }
    const locale = Localization.getLocaleFromLanguage(language);

    // Get corresponding settings
    let settings =
      Localization.instance.localizationInfo.settingsList[locale] || Localization.instance.getDefaultSettings(language);

    // Set result
    await Localization.instance.setLocalizationInfo({
      currentLocale: settings.locale,
      useBrowserDefault: false,
      settingsList: { ...Localization.instance.localizationInfo.settingsList, [settings.locale]: settings }
    });

    // Store resulting localization info in local storage
    localStorage.setItem(Localization.LOCAL_STORAGE_KEY, JSON.stringify(Localization.instance.localizationInfo));
  }

  private static getLocaleFromLocaleOptionsValue(value: string): Locale {
    /*
      Developper remark: this is a pure function, keep it that way. Do not use any class members here as function called through initialization functions (members may be incomplete)
    */
    return value.toLowerCase();
  }

  private static getLocaleFromLanguage(language: string): Locale {
    /*
      Developper remark: this is a pure function, keep it that way. Do not use any class members here as function called through initialization functions (members may be incomplete)
    */

    // Full language
    let value = LocaleOptions?.find((option) => option.value === language.replace('-', '_').toUpperCase())?.value;

    // Base language (eg German (Switzerland): de-CH ==> German (Standard): de)
    if (!value && language.includes('-')) {
      const mainLanguage = language.split('-')[0];
      value = LocaleOptions?.find((option) => option.value === mainLanguage.toUpperCase())?.value;
    }

    // Use english if language not supported
    return Localization.getLocaleFromLocaleOptionsValue(value || 'EN');
  }

  private static getSeparatorsFromLanguage(language: string) {
    /*
      Developper remark: this is a pure function, keep it that way. Do not use any class members here as function called through initialization functions (members may be incomplete)
    */

    let number = 1000.1;
    let matches = number.toLocaleString(language).match(/[^\d]/g);
    let group = matches?.[0] || ',';
    let decimal = matches?.[1] || '.';
    return { decimal, group };
  }

  private static _getDefaultDateFormat(locale: Locale, _instance: Localization): string {
    /*
      Developper remark: this is a pure function, keep it that way. Do not use any class members here as function called through initialization functions (members may be incomplete)
      Remark also that the passed _instance might be "incomplete"
    */
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    const dateFormat = formatObj
      .map((obj) => {
        switch (obj.type) {
          case 'day':
            return 'dd';
          case 'month':
            return 'MM';
          case 'year':
            return 'yyyy';
          default:
            return obj.value;
        }
      })
      .join('');
    let f = dateFormat || 'yyyy-MM-dd';
    f = f.indexOf('y') === f.lastIndexOf('y') ? f.replace('y', 'yyyy') : f;
    f = f.indexOf('M') === f.lastIndexOf('M') ? f.replace('M', 'MM') : f;
    f = f.indexOf('d') === f.lastIndexOf('d') ? f.replace('d', 'dd') : f;
    return f;
  }

  private static _getDefaultSettings(language: string, _instance: Localization): LocalizationInfo.Settings {
    /*
      Developper remark: this is a pure function, keep it that way. Do not use any class members here as function called through initialization functions (members may be incomplete)
      Remark also that the passed _instance might be "incomplete"
    */
    const locale = Localization.getLocaleFromLanguage(language);
    const { decimal, group } = Localization.getSeparatorsFromLanguage(language);
    const dateFormat = Localization._getDefaultDateFormat(locale, _instance);

    return { locale: locale, dateFormat: dateFormat, decimalSeparator: decimal, groupSeparator: group };
  }

  private getDefaultSettings(language: string): LocalizationInfo.Settings {
    return Localization._getDefaultSettings(language, Localization.instance);
  }

  private get localizationInfo(): LocalizationInfo {
    return this._localizationInfo;
  }

  private async setLocalizationInfo(localizationInfo: LocalizationInfo) {
    await this.getTranslations(localizationInfo.currentLocale);
    if (localizationInfo.currentLocale !== 'en') {
      await this.getTranslations('en');
    }
    this._localizationInfo = localizationInfo;
  }

  private async getTranslations(language: string) {
    if (!Translations[language]) {
      Translations[language] = await (
        await fetch(`${process.env.REACT_APP_TRANSLATIONS_ROOT || '/translations'}/${language}.json `)
      ).json();
    }
  }
  private async getLocaleOptions() {
    const localeOptions = await fetch(
      `${process.env.REACT_APP_TRANSLATIONS_ROOT || '/translations'}/localeOptions.json `
    ).then((res) => res.json());
    return localeOptions || {};
  }

  public static Maintenance = {
    getLocaleOptionsList: (): Option[] => {
      return (
        LocaleOptions?.map((option) => {
          if (option.value === '*DFT')
            return { value: '*DFT', label: Localization.instance.getString('LOCALE_BrowserDefault') };
          return option;
        }) || []
      );
    },

    getLocaleOptionValueFromCurrentLocale: (): string => {
      if (Localization.instance.localizationInfo.useBrowserDefault) {
        return '*DFT';
      } else {
        return Localization.instance.localizationInfo.currentLocale;
      }
    },

    getSettingsByLocaleOptionValue: (value: string): LocalizationInfo.Settings => {
      // Get corresponding locale & language
      let locale: Locale;
      let language: string;
      if (value === '*DFT') {
        language = navigator?.language || 'en'; // browser language
        locale = Localization.getLocaleFromLanguage(language);
      } else {
        language = LocaleOptions?.find((option) => option.value === value)?.language || 'en';
        locale = Localization.getLocaleFromLocaleOptionsValue(value);
      }

      // Return corresponding settings
      return (
        Localization.instance.localizationInfo.settingsList[locale] ||
        Localization.instance.getDefaultSettings(language)
      );
    },

    setSettingsByLocaleOptionValue: async (
      value: string,
      dateFormat?: string,
      decimalSeparator?: string,
      groupSeparator?: string
    ) => {
      // Get corresponding current settings
      let settings = Localization.Maintenance.getSettingsByLocaleOptionValue(value);

      // Apply changes (if any)
      settings = {
        ...settings,
        dateFormat: dateFormat || settings.dateFormat,
        decimalSeparator: decimalSeparator || settings.decimalSeparator,
        groupSeparator: groupSeparator || settings.groupSeparator
      };

      // Set result
      await Localization.instance.setLocalizationInfo({
        currentLocale: settings.locale,
        useBrowserDefault: value === '*DFT',
        settingsList: { ...Localization.instance.localizationInfo.settingsList, [settings.locale]: settings }
      });

      // Store resulting localization info in local storage
      localStorage.setItem(Localization.LOCAL_STORAGE_KEY, JSON.stringify(Localization.instance.localizationInfo));

      // Return resulting setting
      return settings;
    },
    getDefaultValueBasedOnLanguage: (value: string, instance: Localization) => {
      return Localization._getDefaultSettings(value, instance);
    }
  };
}

export {};
