import React, { FunctionComponent, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import '../../styles/SelectView.scss';
import { RootState } from '../../framework/base';
import { desktopState } from '../../framework/base/desktopReducer';
import { Localization } from '../../framework/localization/Localization';
import { WindowActions } from '../../types/actionTypes';

type ViewInfo = {
  id: string;
  description: string;
  localShortcut?: string;
  disabled: boolean;
  select: () => void;
};

type SelectViewProps = {
  onClose: () => void;
  restoreWindowInputFocus: (timeoutInterval: number) => void;
};

export const SelectView: FunctionComponent<SelectViewProps> = ({ onClose, restoreWindowInputFocus }) => {
  const state = useSelector<RootState, desktopState>((state) => state.desktop);
  const dispatch = useDispatch();
  const refViews = useRef<ViewInfo[]>(initializeViews());
  const views = refViews.current;
  const [selectedIndex, setSelectedIndex] = useState<number>(initializeSelectedIndex(views));

  return (
    <div onKeyDown={onKeyDown} onKeyUp={onKeyUp} className='select-view'>
      <Modal
        show={true}
        onHide={() => onClose()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='select-view-modal'
      >
        <Modal.Header>
          <Modal.Title>
            <h4>{Localization.instance.getString('SelectView')}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {views.map((view, i) => {
              return (
                <Row
                  key={view.id}
                  id={view.id}
                  className={
                    'select-view-item' + (view.disabled ? ' disabled' : '') + (i === selectedIndex ? ' selected' : '')
                  }
                  onMouseDown={() => selectView(i)}
                >
                  <Col>{view.description}</Col>
                  <Col>
                    {view.localShortcut === 'Space'
                      ? Localization.instance.getString('KEYBOARD_BUTTON_Space')
                      : view.localShortcut}
                  </Col>
                </Row>
              );
            })}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button data-event='ignore' className='btn btn-primary col-sm-2' onClick={() => selectView(selectedIndex)}>
            {' '}
            {Localization.instance.getString('TXT_OK')}{' '}
          </button>
          <button data-event='ignore' className='btn btn-primary col-sm-2' onClick={() => onClose()}>
            {' '}
            {Localization.instance.getString('TXT_Cancel')}{' '}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  function initializeViews(): ViewInfo[] {
    // Collect info current active window
    // ==================================
    const currentWindow = state.windows[state.activeWindow];
    let isContextAreaAvailable = true;
    if (currentWindow.id === 'dashboard') {
      isContextAreaAvailable = false;
    } else if (
      currentWindow.program?.view &&
      ['QueryManagerView', 'BrowserView', 'DashboardView'].findIndex((el) => el === currentWindow.program.view) >= 0
    ) {
      isContextAreaAvailable = false;
    } else if (currentWindow.isDialog) {
      isContextAreaAvailable = false;
    }
    const attachmentsAvailable = currentWindow.attachments && currentWindow.attachments.length > 0 ? true : false;
    const aperioInfoAvailable =
      state.activeWindow === 'dashboard'
        ? false
        : state.contextAreas[state.activeWindow]?.activeAperioLinks?.length > 0;

    // Create list of available views
    // ==============================
    const views: ViewInfo[] = [];

    // --> add views for all window tabs (including dashbooard)
    Object.values(state.windows).forEach((window, index) => {
      views.push({
        id: window.id,
        description: index === 0 ? Localization.instance.getString('Dashboard') : window.title,
        localShortcut: index === 0 ? 'D' : index < 10 ? String(index) : undefined,
        disabled: false,
        select: () => selectWindow(window.id)
      });
    });

    // --> add view for context help
    views.push({
      id: 'help',
      description: Localization.instance.getString('CONTEXT_AREA_ContextHelp'),
      localShortcut: 'F1',
      disabled: !isContextAreaAvailable,
      select: () => selectContextArea('help')
    });

    // --> add view for attachments
    views.push({
      id: 'attachments',
      description: Localization.instance.getString('CONTEXT_AREA_Attachments'),
      localShortcut: 'A',
      disabled: !isContextAreaAvailable || !attachmentsAvailable,
      select: () => selectContextArea('attachments')
    });

    // --> add view for error messages
    views.push({
      id: 'error',
      description: Localization.instance.getString('CONTEXT_AREA_Messages'),
      localShortcut: 'M',
      disabled: !isContextAreaAvailable,
      select: () => selectContextArea('error')
    });

    // --> add view for aperio view
    views.push({
      id: 'aperio',
      description: Localization.instance.getString('CONTEXT_AREA_MoreInformation'),
      localShortcut: 'I',
      disabled: !isContextAreaAvailable || !aperioInfoAvailable,
      select: () => selectContextArea('aperio')
    });

    // --> add view for menu
    views.push({
      id: 'menu',
      description: Localization.instance.getString('View_menu'),
      localShortcut: 'Space',
      disabled: false,
      select: selectMenu
    });

    return views;
  }

  function initializeSelectedIndex(views: ViewInfo[]): number {
    // Get index current selected window tab
    // =====================================
    let selectedIndex = views.findIndex((v) => v.id === state.activeWindow);

    // Adjust selected if context area has input focus
    // ===============================================
    const currentWindow = state.windows[state.activeWindow];
    if (currentWindow && !currentWindow.isDialog && currentWindow.contextArea) {
      const divContextArea: HTMLDivElement | null = document.querySelector('.window.active div.context-area');
      if (divContextArea && divContextArea.contains(document.activeElement)) {
        const selectedIndex2 = views.findIndex((v) => v.id === currentWindow.contextArea && v.disabled === false);
        if (selectedIndex2 >= 0) selectedIndex = selectedIndex2;
      }
    }

    return selectedIndex;
  }

  function selectView(index: number) {
    if (index < 0) return;
    if (!views[index].disabled) {
      views[index].select();
      onClose();
    }
  }

  function selectContextArea(contextArea: string) {
    // --> Dispatch redux action
    dispatch({
      type: WindowActions.UPDATE_CONTEXT,
      payload: { contextArea }
    });

    // --> Give input focus to selected area (remark 2023/02/06: no cases today, but I guess this needs to be changed; eg attachments?)
    setTimeout(() => {
      const getNextInput = (elements: NodeListOf<Element>): Element | null => {
        if (elements.length > 0) {
          let tabindexList = Array.from(elements).map((x: any) => +(x.getAttribute('tabindex') || '-2'));

          if (tabindexList.some((index) => index > 0)) {
            // first tabindex > 0
            tabindexList = tabindexList.filter((index) => index > 0);
          } else if (tabindexList.some((index) => index === 0)) {
            // then tabindex 0
            return Array.from(elements).find((x: any) => +(x.getAttribute('tabindex') || '-2') === 0) || null;
          } else {
            // finally without tabindex
            return Array.from(elements).find((x: any) => x.getAttribute('tabindex') === null) || null;
          } // remark: tabindex -1 intentionally not included

          if (tabindexList.length > 0) {
            let minIndex = Math.min(...tabindexList);
            return Array.from(elements).find((x: any) => +x.getAttribute('tabindex') === minIndex) || null;
          }
        }
        return null;
      };

      const divContextArea: HTMLDivElement | null = document.querySelector('.window.active div.context-area');
      if (divContextArea) {
        const elements = divContextArea.querySelectorAll(
          '[tabindex]:not([visible=false]):not(.event-dispatcher-dummy):not([readonly]):not([disabled]:not([hidden]))'
        );
        const element = getNextInput(elements);
        if (element) {
          try {
            (element as HTMLElement).focus();
          } catch {
            // ignore
          }
        }
      }
    }, 500); //NTH_SKE: figure out why such a long time is needed (issue mainly when switching between contextarea modes, eg error <--> help)
  }

  function selectWindow(id: string) {
    dispatch({
      type: WindowActions.SWITCH,
      payload: { id }
    });

    if (state.activeWindow !== 'dashboard') {
      restoreWindowInputFocus(0);
    }
  }

  function selectMenu() {
    /*
      Remarks: 
        ° function Client.toggleMenu not available here ==> dirty solution: simulate pressing shortcut key
        ° the fact that current component is shown guarantees that the menu is currently not visible, so "pressing" shortcut will toggle to "show menu"
    */
    setTimeout(() => {
      document.body.dispatchEvent(
        new KeyboardEvent('keydown', {
          bubbles: true,
          cancelable: true,
          ctrlKey: true,
          keyCode: 32,
          key: ' ',
          code: 'Space'
        })
      );

      document.body.dispatchEvent(
        new KeyboardEvent('keyup', {
          bubbles: true,
          cancelable: true,
          ctrlKey: true,
          keyCode: 32,
          key: ' ',
          code: 'Space'
        })
      );
    }, 0);
  }

  function onKeyDown(e: React.KeyboardEvent) {
    function getNext(increment: number, currentIndex: number = selectedIndex, retry: number = 0) {
      if (retry >= views.length) {
        return; // --> infinite loop protection
      }

      let nextIndex = currentIndex + increment;
      if (nextIndex > views.length - 1) {
        nextIndex = 0;
      } else if (nextIndex < 0) {
        nextIndex = views.length - 1;
      }

      if (views[nextIndex].disabled) {
        getNext(increment, nextIndex, retry + 1);
      } else {
        setSelectedIndex(nextIndex);
      }
    }

    e.stopPropagation();
    e.preventDefault();

    if (e.key.toUpperCase() === 'ARROWDOWN') {
      getNext(1);
    } else if (e.key.toUpperCase() === 'ARROWUP') {
      getNext(-1);
    } else if (e.key.toUpperCase() === 'ENTER' && selectedIndex >= 0) {
      views[selectedIndex].select();
      onClose();
    } else if (e.key.toUpperCase() === 'ESCAPE') {
      onClose();
    } else {
      const view = views.find(
        (v) => v.localShortcut?.toUpperCase() === (e.key === ' ' ? 'SPACE' : e.key.toUpperCase())
      );
      if (view && !view.disabled) {
        view.select();
        onClose();
      }
    }
  }

  function onKeyUp(e: React.KeyboardEvent) {
    // e.g. hotkeys key up should not be triggered when pressing <F12>
    e.stopPropagation();
    e.preventDefault();
  }
};
