import EventEmitter from 'events';

export type AddListeners = {
  onCompanyChanged: (eventHandler: (companyId: string) => void) => void;
};
export type Emitters = {
  companyChanged: (companyId: string) => void;
};
export default class ApplicationEvents {
  private static eventEmitter = new EventEmitter();
  private static emitCompanyChanged(companyId: string) {
    ApplicationEvents.eventEmitter.emit('onCompanyChanged', companyId);
  }
  private static onCompanyChanged(eventHandler: (companyId: string) => void) {
    ApplicationEvents.eventEmitter.addListener('onCompanyChanged', eventHandler);
  }

  private static _emit = {
    companyChanged: ApplicationEvents.emitCompanyChanged
  };
  private static _addListener = {
    onCompanyChanged: ApplicationEvents.onCompanyChanged
  };

  public static get emit(): Emitters {
    return ApplicationEvents._emit;
  }
  public static get addListener(): AddListeners {
    return ApplicationEvents._addListener;
  }
}
