import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Doughnut } from 'react-chartjs-2';
import { string } from 'prop-types';
import { format } from 'date-fns';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';

const customComponent: CellPlugin = {
  Renderer: ({ data }: any) => {
    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          <span style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: data.html }}></span>
        </Widget>
      </div>
    );
  },
  id: 'html-component',
  title: 'Custom HTML component',
  description: 'Component for adding custom HTML code to the dashboard',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        html: {
          type: 'string',
          uniforms: {
            // you can also pass other props.
            // refer to the official uniforms documentation
            multiline: true,
            rows: 4
          }
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default customComponent;
