import { Route } from 'react-router-dom';

export function PrivateRoute({ component: Component, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        let { componentProps } = rest;
        return <Component {...componentProps} />;
      }}
    />
  );
}
