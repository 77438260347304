import React, { FunctionComponent, useState, useRef, useEffect, useMemo, useContext } from 'react';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { Button, Modal, ModalBody, ModalFooter, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Action } from '../../components/Button';
import { Localization } from '../../framework/localization/Localization';
import { Setting } from './Setting';
import axios, { AxiosResponse } from 'axios';
// import XLSX from 'xlsx';
import _Swal, { SweetAlertResult } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import hotkeys from 'hotkeys-js';
import { RoleTemplates } from './RoleTemplates';
import { XT } from '../../framework/handlers/xt';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../framework/base';
import { desktopState } from '../../framework/base/desktopReducer';
import { Company } from '../../types/company';
import { User } from '../../types/user';
import ModalHeader from 'react-bootstrap/ModalHeader';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import packageJSON from '../../../package.json';
import { ClientContext } from '../../App';
import { Program } from '../../types/program';
import { Workbook } from 'exceljs';
import { downloadFile } from '../../components/helpers/exportUtils';
import { useAuth } from 'react-oidc-context';
const Swal: any = withReactContent(_Swal);

const dependencies = packageJSON.dependencies;

type ToolbarProps = {
  actionHandler?: (action: Action) => void;
  startLoading?: Function;
  endLoading?: Function;
  quickLaunch?: Function;
  refresh?: Function;
  restoreFocusWindow?: () => void;
  isDialog?: boolean;
  setEditing?: () => void;
  editing?: boolean;
  company: Company;
  user: User;
  saveDashboard?: (targets: string[]) => void;
  resetDashboard?: (targets: string[]) => void;
  print?: Function;
  disableRefresh?: boolean;
};

export enum ToolbarOption {
  PRINT = 'PRINT',
  REFRESH = 'REFRESH',
  SERVER_MESSAGES = 'SERVER_MESSAGES',
  SERVER_JOBS = 'SERVER_JOBS',
  PRINTER_OUTPUT = 'PRINTER_OUTPUT',
  DOCUMENT_OUTPUT = 'DOCUMENT_OUTPUT',
  CLIENT_SETTINGS = 'CLIENT_SETTINGS',
  ROLE_TEMPLATES = 'ROLE_TEMPLATES',
  FILE_UPLOAD = 'FILE_UPLOAD',
  HELP = 'HELP',
  ERROR_REPORT = 'ERROR_REPORT',
  QUERY_BUILDER = 'QUERY_BUILDER'
}

export const ToolbarComponent: FunctionComponent<ToolbarProps> = ({ actionHandler, ...props }) => {
  const { flattenedMenu, getMenuItems } = useContext(ClientContext);
  const queryBuilderProgram: Program | undefined = getMenuItems('QueryBuilder', flattenedMenu);
  const [isShown, setIsShown] = useState<boolean>(false);
  const [roleTemplateOpen, setRoleTemplateOpen] = useState<boolean>(false);
  const isFirstCycle = useRef<boolean>(true);
  const handleShow = () => setIsShown(!isShown);
  const handleRoleShow = () => setRoleTemplateOpen(!roleTemplateOpen);
  const desktopState = useSelector<RootState, desktopState>((state) => state.desktop);
  const auth = useAuth();

  const [targets, setTargets] = useState<string[]>([]);
  // const versionInfo = useSelector<RootState, desktopState>((state) => state.desktop).versionInfo;
  // windows: Record<string, WindowProps>;
  // layouts: Record<string, any>;
  // activeWindow: string;

  const computedUser = useMemo(() => {
    if (auth?.isAuthenticated) {
      if (auth?.user?.profile.given_name || auth?.user?.profile.family_name) {
        return `${auth?.user?.profile.given_name || ''} ${auth?.user?.profile.family_name || ''}`;
      } else if (auth?.user?.profile.email) {
        return auth?.user?.profile.email;
      } else {
        return auth?.user?.profile.preferred_username || '';
      }
    }
    return '';
  }, [auth]);

  useEffect(() => {
    if (!isShown && !roleTemplateOpen && !isFirstCycle.current) restoreFocus();
  }, [isShown, roleTemplateOpen]);

  const restoreFocus = () => {
    props.restoreFocusWindow?.();
  };
  const exportErrorReport = async () => {
    const maxNoOfSignsInExcelCell = 32767;
    try {
      const oldScope = hotkeys.getScope();
      hotkeys.setScope('none');
      const { value: description, isConfirmed } = await Swal.fire({
        input: 'textarea',
        inputLabel: Localization.instance.getString('ERROR_REPORT_EnterErrorDescription'),
        inputValue: '',
        inputValidator: (value: string) => {
          if (!value) {
            return Localization.instance.getString('ERROR_REPORT_ErrorDescriptionMandatory');
          }
          if (value && value.length > 300) {
            return Localization.instance.getString('ERROR_REPORT_MaximumLengthError');
          }
        },
        heightAuto: false,
        width: '600px',
        focusConfirm: false,
        confirmButtonColor: '#00a3a5',
        cancelButtonColor: '#00a3a5',
        showCancelButton: true,
        stopKeydownPropagation: false,
        allowOutsideClick: false,
        confirmButtonText: Localization.instance.getString('ERROR_REPORT_BUTTON_GenerateReport'),
        cancelButtonText: Localization.instance.getString('ERROR_REPORT_BUTTON_Cancel'),
        customClass: {
          inputLabel: 'error-report-title',
          popup: 'error-report-popup',
          input: 'error-report-input',
          confirmButton: 'error-report-buttons',
          cancelButton: 'error-report-buttons'
        }
      });
      setTimeout(() => hotkeys.setScope(oldScope), 500);
      setTimeout(restoreFocus, 300); // NTH_SKE: figure out why such a high time-out is needed
      if (!description || !isConfirmed) return;
      props.startLoading?.();
      let rows = [
        ['Issue Description', description],
        ['UserID', JSON.parse(sessionStorage.getItem('user') || '{}')?.username],
        ['URL', window.location.href],
        ['Company', JSON.parse(sessionStorage.getItem('company') || '{}')?.id],
        ['Locale', Localization.instance.locale]
      ];
      // api bridge, xt bridge
      for (const key in desktopState.connectionInfo) {
        if (typeof desktopState.connectionInfo[key] === 'string') {
          rows.push([key, desktopState.connectionInfo[key]]);
        }
      }

      // Simple layout information (panels)
      const formId =
        desktopState.windows[desktopState.activeWindow]?.data?.form?.$?.id ||
        desktopState.windows[desktopState.activeWindow]?.id;
      if (formId) rows.push(['form', formId]);
      const panelsObj = desktopState.windows[desktopState.activeWindow]?.data?.form?.panel;
      if (panelsObj) {
        const panels = panelsObj.map((x: any) => x?.$?.id);
        if (panels) {
          // message panel, standard, not specific for form - move it at the end
          const msgIndex = panels.indexOf('MSGSFL');
          if (msgIndex > -1) {
            panels.splice(msgIndex, 1);
            panels.push('MSGSFL');
          }
          rows.push(['panels', panels.toString()]);
        }
      }

      // version field
      for (const key in desktopState.versionInfo) {
        rows.push([key, desktopState.versionInfo[key]]);
      }

      // oidc adapters - client
      const adapters = Object.entries(dependencies).filter(([key]) => key.includes('oidc'));
      if (adapters) {
        for (const key in adapters) {
          rows.push([adapters[key][0], adapters[key][1]]);
        }
      }

      const wb = new Workbook();
      const wsMain = wb.addWorksheet('main');
      for (let row of rows) {
        wsMain.addRow(row);
      }
      wsMain.columns = prepareColumnsWidthForAutofit(rows, 100);

      // const wsMain = XLSX.utils.aoa_to_sheet(rows);
      // wsMain['!cols'] = prepareColumnsWidthForAutofit(rows, 100);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, wsMain, 'main');

      // xt connection info from XTDispatcher.xml
      if (desktopState.connectionInfo['xtConnInfo']) {
        rows = [];
        for (const key in desktopState.connectionInfo['xtConnInfo']) {
          rows.push([key, desktopState.connectionInfo['xtConnInfo'][key]]);
        }
        if (rows.length > 0) {
          const wsXtConnInfo = wb.addWorksheet('xtConnInfo');
          for (let row of rows) {
            wsXtConnInfo.addRow(row);
          }
          wsXtConnInfo.columns = prepareColumnsWidthForAutofit(rows, 100);
          // const wsXtConnInfo = XLSX.utils.aoa_to_sheet(rows);
          // wsXtConnInfo['!cols'] = prepareColumnsWidthForAutofit(rows, 100);
          // XLSX.utils.book_append_sheet(wb, wsXtConnInfo, 'xtConnInfo');
        }
      }

      // logs from server
      const errReport = (await axios.get('/client/error-report')) as AxiosResponse;
      if (errReport && errReport.data && errReport.data.length > 0) {
        rows = [];
        const log = errReport.data as Record<string, any>[];
        rows.push(Object.keys(log[0] || {}));
        // log.forEach((l: any) => {
        //   delete l._id;
        //   delete l.__v;
        // });
        rows.push(
          ...log.map((l: any) => {
            const row = Object.values(l).map((x: any) => {
              let cell = `${x}`.replaceAll(`\"`, '').replaceAll('"', '').replaceAll(`\\`, '');
              if (cell.length > maxNoOfSignsInExcelCell) {
                cell = cell.substring(0, maxNoOfSignsInExcelCell - 4).concat(' ...');
              }
              return cell;
            });
            return row;
          })
        );
        const wsLogs = wb.addWorksheet('logs');
        for (let row of rows) {
          wsLogs.addRow(row);
        }
        wsLogs.columns = prepareColumnsWidthForAutofit(rows, 100);
        // const wsLogs = XLSX.utils.aoa_to_sheet(rows);
        // wsLogs['!cols'] = prepareColumnsWidthForAutofit(rows, 100);
        // XLSX.utils.book_append_sheet(wb, wsLogs, 'logs');
      }
      // wb.xlsx.writeFile(`error_report_${new Date().toTimeString()}.xlsx`);
      wb.xlsx.writeBuffer().then((data: any) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadFile(`error_report_${new Date().toTimeString()}.xlsx`, blob);
      });
      // XLSX.writeFile(wb, `error_report_${new Date().toTimeString()}.xlsx`);
    } catch (error) {
      console.error(`Error from exportErrorReport ${error}`);
      restoreFocus();
    } finally {
      props.endLoading?.();
    }
  };

  const prepareColumnsWidthForAutofit = (json: any[], maxWidth?: number) => {
    const colsCount = Math.max(...json.map((x) => x.length));

    let objectMaxLength: any[] = [];
    for (let i = 0; i < json.length; i++) {
      let value = json[i];
      for (let j = 0; j < colsCount; j++) {
        const l = value[j] ? value[j].length : 0;

        objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l;
        if (maxWidth && objectMaxLength[j] > maxWidth) objectMaxLength[j] = maxWidth;
      }
    }
    const wscols = objectMaxLength.map((w) => {
      return { width: w };
    });

    return wscols;
  };

  const isToolbarOptionAllowed = (option: ToolbarOption) => {
    if (props.isDialog) {
      if (option === ToolbarOption.REFRESH && props.disableRefresh) {
        return false;
      }
      // Remark: do NOT allow toolbaroption to open new program tab (otherwise the existing dialog will be shown with the new (=wrong) tab)
      if (
        [
          ToolbarOption.PRINT,
          ToolbarOption.REFRESH,
          ToolbarOption.CLIENT_SETTINGS,
          ToolbarOption.ROLE_TEMPLATES,
          ToolbarOption.HELP,
          ToolbarOption.ERROR_REPORT
        ].findIndex((opt) => option === opt) > -1
      )
        return true;
      return false;
    } else {
      return true;
    }
  };

  const determineDisabledClass = (option: ToolbarOption) => {
    if (!isToolbarOptionAllowed(option)) return ' disabled';
    return '';
  };

  useEffect(() => {
    isFirstCycle.current = false;
  }, []);

  const [showSave, setShowSave] = useState(false);
  const [showReset, setShowReset] = useState(false);

  const saveDashboards = () => {
    props.saveDashboard?.(targets);
    setShowSave(false);
  };

  const resetDashboards = () => {
    props.resetDashboard?.(targets);
    setShowReset(false);
  };

  return (
    <div
      className='floating-toolbar'
      onMouseDown={(e: React.MouseEvent) => {
        if (e.target === e.currentTarget) restoreFocus(); // no toolbar button clicked? ==> give focus back to active window
      }}
    >
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-print`}>
            {Localization.instance.getString('Formview_action_print_tooltip')}
          </Tooltip>
        }
      >
        <div
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.PRINT)}
          onClick={() => {
            if (props.print) {
              props.print();
            } else {
              restoreFocus();
              window.print();
            }
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Printer}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-refresh`}>
            {Localization.instance.getString('QB_refresh')}
          </Tooltip>
        }
      >
        <div
          onClick={() => {
            if (props.refresh) {
              props.startLoading?.();
              props.refresh();
              setTimeout(() => props.endLoading?.(), 2000);
            } else {
              XT.resetScrolling();
              actionHandler?.({ event: 'F5' });
            }
          }}
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.REFRESH)}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Refresh}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      {/*<div className="toolbar-item">*/}
      {/*  <SquareIcon className="grey-icon" size="24px">*/}
      {/*    {Icons.Table}*/}
      {/*  </SquareIcon>*/}
      {/*</div>*/}
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-server-messages`}>
            {Localization.instance.getString('TXT_DISPLAY_SERVER_MESSAGES')}
          </Tooltip>
        }
      >
        <div
          id='server-messages'
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.SERVER_MESSAGES)}
          onClick={() => {
            restoreFocus();
            props.startLoading?.();
            props.quickLaunch?.(
              {
                id: 'MessageQueue',
                text: 'Display Server Messages'
              },
              props.endLoading,
              'static'
            );
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Chat1}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-server-jobs`}>
            {Localization.instance.getString('TXT_DISPLAY_SERVER_JOBS')}
          </Tooltip>
        }
      >
        <div
          id='server-jobs'
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.SERVER_JOBS)}
          onClick={() => {
            restoreFocus();
            props.startLoading?.();
            props.quickLaunch?.({ id: 'Jobs', text: 'Display Server Jobs' }, props.endLoading, 'static');
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.ServerView}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-printer-output`}>
            {Localization.instance.getString('TXT_DISPLAY_PRINTER_OUTPUT')}
          </Tooltip>
        }
      >
        <div
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.PRINTER_OUTPUT)}
          id='printer-output'
          onClick={() => {
            restoreFocus();
            props.startLoading?.();
            props.quickLaunch?.(
              {
                id: 'Spooledfiles',
                text: 'Display Printer output'
              },
              props.endLoading,
              'static'
            );
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Copy}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-doc-output`}>
            {Localization.instance.getString('TXT_DISPLAY_DOCUMENT_OUTPUT')}
          </Tooltip>
        }
      >
        <div
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.DOCUMENT_OUTPUT)}
          id='enquiries'
          onClick={() => {
            restoreFocus();
            props.startLoading?.();
            props.quickLaunch?.({ id: 'DocumentOutput', text: 'Display document output' }, props.endLoading, 'static');
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.XmlDoc}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-settings`}>
            {Localization.instance.getString('enhanced_miscellaneous_settings')}
          </Tooltip>
        }
      >
        <div className={'toolbar-item' + determineDisabledClass(ToolbarOption.CLIENT_SETTINGS)} onClick={handleShow}>
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Settings}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <Setting isShown={isShown} hide={handleShow} />
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-role-templates`}>
            {Localization.instance.getString('client_settings_temp_mgr_header_txt')}
          </Tooltip>
        }
      >
        <div className={'toolbar-item' + determineDisabledClass(ToolbarOption.ROLE_TEMPLATES)} onClick={handleRoleShow}>
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.PersonalSettings}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <RoleTemplates isShown={roleTemplateOpen} hide={handleRoleShow} />
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-doc-output`}>
            {Localization.instance.getString('TXT_FILE_UPLOAD')}
          </Tooltip>
        }
      >
        <div
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.FILE_UPLOAD)}
          id='enquiries'
          onClick={() => {
            restoreFocus();
            props.startLoading?.();
            props.quickLaunch?.(
              {
                id: 'UI_FILEUP',
                text: 'File Upload',
                view: 'DashboardView'
              },
              props.endLoading,
              'aperio_program'
            );
          }}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.Upload}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      <OverlayTrigger
        placement='left'
        overlay={
          <Tooltip className='custom' id={`tooltip-error`}>
            {Localization.instance.getString('Formview_action_create_error_report')}
          </Tooltip>
        }
      >
        <div
          className={'toolbar-item' + determineDisabledClass(ToolbarOption.ERROR_REPORT)}
          id='error-report'
          onClick={() => exportErrorReport()}
        >
          <SquareIcon className='icon-grey-outline' size='24px'>
            {Icons.ErrorReport}
          </SquareIcon>
        </div>
      </OverlayTrigger>
      {queryBuilderProgram && (
        <OverlayTrigger
          placement='left'
          overlay={
            <Tooltip className='custom' id={`tooltip-error`}>
              {Localization.instance.getString('QueryBuilder_Program')}
            </Tooltip>
          }
        >
          <div
            className={'toolbar-item' + determineDisabledClass(ToolbarOption.QUERY_BUILDER)}
            id='query-builder'
            onClick={() => {
              restoreFocus();
              props.startLoading?.();
              props.quickLaunch?.(queryBuilderProgram, props.endLoading, 'aperio_program');
            }}
          >
            <SquareIcon className='icon-grey-outline' size='24px'>
              {Icons.Query}
            </SquareIcon>
          </div>
        </OverlayTrigger>
      )}
      {props.saveDashboard &&
        auth.isAuthenticated &&
        (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user') ||
          auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin') ||
          auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global')) && (
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip className='custom' id={`tooltip-error`}>
                {props.editing
                  ? Localization.instance.getString('Dashboard_save')
                  : Localization.instance.getString('Dashboard_edit')}
              </Tooltip>
            }
          >
            <div
              className={'toolbar-item'}
              id='error-report'
              onClick={() => {
                if (!props.editing) {
                  props.setEditing?.();
                } else if (
                  auth.isAuthenticated &&
                  (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                    auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin'))
                ) {
                  setShowSave(true);
                } else if (
                  auth.isAuthenticated &&
                  auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user')
                ) {
                  props.saveDashboard?.([
                    `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${props.company.id}/${
                      auth?.user?.profile.preferred_username
                    }`
                  ]);
                }
              }}
            >
              <SquareIcon className='icon-grey-outline' size='24px'>
                {props.editing ? Icons.Save : Icons.Edit}
              </SquareIcon>
            </div>
          </OverlayTrigger>
        )}
      <Modal centered size={'sm'} show={showSave} id={'save-dashboard-modal-dialog'}>
        <ModalHeader>
          <Modal.Title>
            {Localization.instance
              .getString('Dashboard_save')
              .toLowerCase()
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <span className='select-span'>{Localization.instance.getString('Save_Dashboard_Modal_Body_Text')}</span>
          <FormGroup>
            {auth.isAuthenticated && auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={!!targets.find((target) => target === 'iptor')}
                    onChange={(e, checked) => {
                      if (checked) setTargets([...targets, 'iptor']);
                      else setTargets(targets.filter((target) => target !== 'iptor'));
                    }}
                  />
                }
                label='Iptor'
              />
            )}
            {auth.isAuthenticated &&
              (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={!!targets.find((target) => target === 'iptor/customer')}
                        onChange={(e, checked) => {
                          if (checked) setTargets([...targets, 'iptor/customer']);
                          else setTargets(targets.filter((target) => target !== 'iptor/customer'));
                        }}
                      />
                    }
                    label='Global'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          !!targets.find(
                            (target) =>
                              target === `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                          )
                        }
                        onChange={(e, checked) => {
                          if (checked)
                            setTargets([
                              ...targets,
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                            ]);
                          else
                            setTargets(
                              targets.filter(
                                (target) =>
                                  target !== `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                              )
                            );
                        }}
                      />
                    }
                    label={`Environment (${sessionStorage.getItem('env-config-key') || 'default'})`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          !!targets.find(
                            (target) =>
                              target ===
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                props.company.id
                              }`
                          )
                        }
                        onChange={(e, checked) => {
                          if (checked)
                            setTargets([
                              ...targets,
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                props.company.id
                              }`
                            ]);
                          else
                            setTargets(
                              targets.filter(
                                (target) =>
                                  target !==
                                  `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                    props.company.id
                                  }`
                              )
                            );
                        }}
                      />
                    }
                    label={`Company (${props.company.id})`}
                  />
                </>
              )}
            {auth.isAuthenticated &&
              (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user')) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={
                        !!targets.find(
                          (target) =>
                            target ===
                            `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                              props.company.id
                            }/${auth?.user?.profile.preferred_username}`
                        )
                      }
                      onChange={(e, checked) => {
                        if (checked)
                          setTargets([
                            ...targets,
                            `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                              props.company.id
                            }/${auth?.user?.profile.preferred_username}`
                          ]);
                        else
                          setTargets(
                            targets.filter(
                              (target) =>
                                target !==
                                `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                  props.company.id
                                }/${auth?.user?.profile.preferred_username}`
                            )
                          );
                      }}
                    />
                  }
                  label={`User (${computedUser})`}
                />
              )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {auth.isAuthenticated &&
            (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
              auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
              <Button variant='primary' onClick={() => saveDashboards()} id={'modal-button'}>
                {Localization.instance.getString('TXT_Save')}
              </Button>
            )}
          {auth.isAuthenticated &&
            (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
              auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
              <Button variant='primary' onClick={() => setShowSave(false)} id={'modal-button'}>
                {Localization.instance.getString('TXT_Close')}
              </Button>
            )}
        </ModalFooter>
      </Modal>
      {props.saveDashboard &&
        props.editing &&
        auth.isAuthenticated &&
        (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user') ||
          auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin') ||
          auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global')) && (
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip className='custom' id={`tooltip-error`}>
                {Localization.instance.getString('TXT_Reset')}
              </Tooltip>
            }
          >
            <div
              className={'toolbar-item'}
              id='error-report'
              onClick={() => {
                if (
                  auth.isAuthenticated &&
                  (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                    auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin'))
                ) {
                  setShowReset(true);
                } else if (
                  auth.isAuthenticated &&
                  auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user')
                ) {
                  props.resetDashboard?.([
                    `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${props.company.id}/${
                      auth?.user?.profile.preferred_username
                    }`
                  ]);
                }
              }}
            >
              <SquareIcon className='icon-grey-outline' size='24px'>
                {Icons.Refresh2}
              </SquareIcon>
            </div>
          </OverlayTrigger>
        )}
      {props.saveDashboard && props.editing && auth.isAuthenticated && (
        <OverlayTrigger
          placement='left'
          overlay={
            <Tooltip className='custom' id={`tooltip-error`}>
              {Localization.instance.getString('Formview_action_quit')}
            </Tooltip>
          }
        >
          <div
            className={'toolbar-item'}
            id='error-report'
            onClick={() => {
              props.setEditing?.();
            }}
          >
            <SquareIcon className='icon-grey-outline' size='24px'>
              {Icons.LeftArrowCircle}
            </SquareIcon>
          </div>
        </OverlayTrigger>
      )}
      <Modal centered size={'sm'} show={showReset} id={'reset-dashboard-modal-dialog'}>
        <ModalHeader>
          <Modal.Title>{Localization.instance.getString('Dashboard_Delete')}:</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <span className='select-span'>{Localization.instance.getString('Delete_Dashboard_Modal_Body_Text')}</span>
          <FormGroup>
            {auth.isAuthenticated && auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') && (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={!!targets.find((target) => target === 'iptor')}
                    onChange={(e, checked) => {
                      if (checked) setTargets([...targets, 'iptor']);
                      else setTargets(targets.filter((target) => target !== 'iptor'));
                    }}
                  />
                }
                label='Iptor'
              />
            )}
            {auth.isAuthenticated &&
              (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={!!targets.find((target) => target === 'iptor/customer')}
                        onChange={(e, checked) => {
                          if (checked) setTargets([...targets, 'iptor/customer']);
                          else setTargets(targets.filter((target) => target !== 'iptor/customer'));
                        }}
                      />
                    }
                    label='Global'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          !!targets.find(
                            (target) =>
                              target === `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                          )
                        }
                        onChange={(e, checked) => {
                          if (checked)
                            setTargets([
                              ...targets,
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                            ]);
                          else
                            setTargets(
                              targets.filter(
                                (target) =>
                                  target !== `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}`
                              )
                            );
                        }}
                      />
                    }
                    label={`Environment (${sessionStorage.getItem('env-config-key') || 'default'})`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          !!targets.find(
                            (target) =>
                              target ===
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                props.company.id
                              }`
                          )
                        }
                        onChange={(e, checked) => {
                          if (checked)
                            setTargets([
                              ...targets,
                              `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                props.company.id
                              }`
                            ]);
                          else
                            setTargets(
                              targets.filter(
                                (target) =>
                                  target !==
                                  `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                    props.company.id
                                  }`
                              )
                            );
                        }}
                      />
                    }
                    label={`Company (${props.company.id})`}
                  />
                </>
              )}
            {auth.isAuthenticated &&
              (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin') ||
                auth.user?.extendedUserProfile()?.roles?.includes('dashboard_user')) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={
                        !!targets.find(
                          (target) =>
                            target ===
                            `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                              props.company.id
                            }/${auth?.user?.profile.preferred_username}`
                        )
                      }
                      onChange={(e, checked) => {
                        if (checked)
                          setTargets([
                            ...targets,
                            `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                              props.company.id
                            }/${auth?.user?.profile.preferred_username}`
                          ]);
                        else
                          setTargets(
                            targets.filter(
                              (target) =>
                                target !==
                                `iptor/customer/${sessionStorage.getItem('env-config-key') || 'default'}/${
                                  props.company.id
                                }/${auth?.user?.profile.preferred_username}`
                            )
                          );
                      }}
                    />
                  }
                  label={`User (${computedUser})`}
                />
              )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {auth.isAuthenticated &&
            (auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global') ||
              auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
              <Button variant='primary' onClick={() => resetDashboards()} id={'modal-button'}>
                {Localization.instance.getString('TXT_Reset')}
              </Button>
            )}
          {auth.isAuthenticated &&
            ((this && auth.user?.extendedUserProfile()?.roles?.includes('dashboard_global')) ||
              auth.user?.extendedUserProfile()?.roles?.includes('dashboard_admin')) && (
              <Button variant='primary' onClick={() => setShowReset(false)} id={'modal-button'}>
                {Localization.instance.getString('TXT_Close')}
              </Button>
            )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ desktop, settings }: any) => {
  let props: {
    company: Company;
    settings: Record<string, any>;
    user: User;
  } = {
    company: {
      id: desktop.settings?.regionals?.user[0].$.activeCompany,
      name:
        desktop.settings?.regionals?.company.find(
          (x: any) => x.$.code === desktop.settings.regionals.user[0].$.activeCompany
        )?.$.name || ''
    },
    user: {
      id: desktop.settings?.regionals?.user[0].$.userId,
      username: desktop.settings?.regionals?.user[0].$.userId,
      name: desktop.settings?.fullName
    },
    settings: desktop.settings
  };
  return props;
};

export const Toolbar = connect(mapStateToProps)(ToolbarComponent);
