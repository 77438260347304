import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { format } from 'date-fns';
import { Widget } from '../../views/Dashboard';
import { CellPlugin } from '@react-page/editor';
import * as DOMPurify from 'dompurify';
import * as htmlparser2 from 'htmlparser2';
import { useLogger } from '../../logger/react/useLogger';
import { debounce } from '../../helpers/debouncer';
import { Localization } from '../../framework/localization/Localization';
import ApplicationErrors from '../../components/ApplicationErrors';
import ApplicationError from '../../framework/ApplicationError';

type ExtendedFeedItem = htmlparser2.DomUtils.FeedItem & {
  img?: string;
  desc: string | null;
};

const rssReader: CellPlugin = {
  Renderer: ({ data }: any) => {
    const isValidUrl = useRef<boolean>(false);
    const prevUrl = useRef<string>('');
    const [feedItems, setFeedItems] = useState<ExtendedFeedItem[]>([]);
    const [errors, setErrors] = useState<ApplicationError[]>([]);
    const [loadData, setLoadData] = useState<{ requested: boolean }>({ requested: false });
    const rssLogger = useLogger('rssReader');

    // Handle data changed
    // ===================
    useEffect(() => {
      const handleChangedUrl = (url: string) => {
        prevUrl.current = url;
        if (url) {
          try {
            const validUrl = new URL(url);
            isValidUrl.current = true;
            setLoadData({ requested: true });
          } catch (e: any) {
            setErrors([ApplicationError.createLogicalError('ERROR_0_is_not_a_valid_url', [url])]);
          }
        }
      };

      // Case url changed
      // ----------------
      const url = (data?.url || '').trim();
      if (url !== prevUrl.current) {
        // --> Initialize
        isValidUrl.current = false;
        setFeedItems([]);
        setErrors([]);

        // --> handle changed url (debounced)
        const [debounced, cancel] = debounce(handleChangedUrl, 500);
        debounced(url);
        return cancel;
      }

      // Case valid url
      // --------------
      if (isValidUrl.current === true) {
        setLoadData({ requested: true });
      }
    }, [data]);

    // load feed data (if requested)
    // =============================
    useEffect(() => {
      // recursive traverse through childNodes, return first found image
      const getFirstImg = (parentNode: Element): HTMLImageElement | undefined => {
        let img: HTMLImageElement | undefined;
        for (let node of parentNode.childNodes) {
          if ((node as Element).tagName === 'IMG') {
            img = node as HTMLImageElement;
            break;
          } else if (node.childNodes) {
            const traverse = getFirstImg(node as Element);
            if (traverse) {
              img = traverse as HTMLImageElement;
              break;
            }
          }
        }
        return img;
      };

      const getData = async () => {
        const url = prevUrl.current;
        let axiosErrorFound = false;
        const response = await axios.get(`/client/rss-proxy/${encodeURIComponent(url)}`).catch((err) => {
          const error = ApplicationError.fromAxiosError(err);
          rssLogger.warn('Failed to load rss feed', error.toJSON());
          setErrors([error]);
          axiosErrorFound = true;
        });
        if (axiosErrorFound) return;

        const _feedItems: ExtendedFeedItem[] = [];
        let error: ApplicationError | undefined;
        try {
          if (response?.data?.error) {
            error = ApplicationError.fromJSON(response?.data?.error);
            // In edit mode the url is editable so refine the message:
            if (error.group === ApplicationError.Group.AXIOS && error.originalCode) {
              if (error.originalCode === '404') {
                error.changeMessage('ERROR_Page_not_found__Check_if_there_is_a_typo_in_0_', url);
              } else if (error.originalCode === 'ENOTFOUND') {
                error.changeMessage('ERROR_This_site_can_not_be_reached_Check_if_there_is_a_typo_in_0_', url);
              }
            }
          } else if (!response?.data?.xmlFeed) {
            error = ApplicationError.createProgrammingError('Server error - unexpected response');
          } else if (Object.keys(response.data.xmlFeed).length === 0) {
            error = ApplicationError.createLogicalError('ERROR_Feed_is_currently_empty__retry_later', undefined);
          } else {
            const feed = htmlparser2.parseFeed(response.data.xmlFeed);
            feed?.items?.forEach((item) => {
              const placeholder = document.createElement('div');
              placeholder.innerHTML = item.description || '';
              const img = getFirstImg(placeholder);
              _feedItems.push({
                ...item,
                img: img ? DOMPurify.sanitize(img.src) : undefined,
                desc: placeholder.textContent
              });
            });
            if (_feedItems.length < 1) {
              error = ApplicationError.createLogicalError('ERROR_Feed_does_not_contain_any_valid_items', undefined);
            }
          }
        } catch (err) {
          error = ApplicationError.createLogicalError(
            'ERROR_Unexpected_feed_data__not_able_to_parse_the_data',
            undefined
          );
          rssLogger.trace('Failed to parse rss feed data', error.toJSON());
        }
        if (error) {
          setErrors([error]);
        } else {
          setFeedItems(_feedItems);
        }
      };

      if (loadData.requested) {
        const [getDebouncedData, cancel] = debounce(getData, 500); //avoid too many requests e.g. when changing title
        getDebouncedData();
        return cancel;
      }
    }, [loadData]);

    const anyItemHasImage = () => {
      return feedItems.some((item) => item.img);
    };

    return (
      <div className={'card'} style={{ height: data.height || '200px' }}>
        <Widget height={'auto'} className={'low-pad'}>
          {data.title && (
            <Row className='rss-reader-title'>
              <Col sm={12}>
                <p>
                  <strong>{data.title.toString()}</strong>
                </p>
              </Col>
            </Row>
          )}
          <span style={{ paddingRight: '15px', overflow: 'auto' }}>
            {errors.length > 0 ? (
              <ApplicationErrors errors={errors} />
            ) : (
              feedItems.map((item) => (
                <Row className='rss-reader-entry'>
                  {anyItemHasImage() ? (
                    <Col className='col-auto'>
                      <div style={{ backgroundImage: `url(${item.img})` }} className='image' />
                    </Col>
                  ) : null}
                  <Col className='content'>
                    <a target='_blank' rel='noopener noreferrer' href={item.link} className='title'>
                      {item.title}
                    </a>
                    <br />
                    <span className={'body'}>{item.desc}</span>
                  </Col>
                  <Col sm={{ span: 4, offset: 8 }} className='date text-right'>
                    <small>
                      {item.pubDate && format(new Date(item.pubDate), `${Localization.instance.dateFormat} HH:mm`)}
                    </small>
                  </Col>
                </Row>
              ))
            )}
          </span>
        </Widget>
      </div>
    );
  },
  id: 'rss-reader',
  title: 'RSS news',
  description: 'Configurable RSS feed',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        url: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default rssReader;
