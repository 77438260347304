// src/slices/index.js
import { combineReducers } from 'redux';

import loginReducer from './login';
import shipmentHeadersReducer from './shipmentHeaders';
import shipmentLinesReducer from './shipmentLines';
import shipmentAddressReducer from './shipmentAddresses';
import warehousesReducer from './warehouses';

const combineReducer = combineReducers({
  login: loginReducer,
  shipmentHeaders: shipmentHeadersReducer,
  shipmentLines: shipmentLinesReducer,
  shipmentAddress: shipmentAddressReducer,
  wareHouses: warehousesReducer
});

const rootReducer = (state: any, action: any) => {
  return combineReducer(state, action); //Use combine reducers with default state if no "sign_out" action is dispatched
};

export default rootReducer;
