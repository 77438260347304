export enum KnotElement {
  FORM = 'form',
  PANEL = 'panel',
  CTRL = 'ctrl',
  ROW = 'row',
  INFDS = 'infds',
  VALUE = 'value'
}

export class Knot {
  public elementName: KnotElement;
  protected attributes: Record<string, string>;
  protected _children!: Record<string, Knot>;
  public get children(): Record<string, Knot> {
    return this._children;
  }

  attributeGet = (attributeName: string) => {
    return this.attributes[attributeName] || null;
  };

  constructor(element: KnotElement, attribute: Record<string, string> = {}) {
    this.elementName = element;
    this.attributes = attribute;
  }

  attributeSet = (attributeName: string, attributeValue: string): void => {
    this.attributes[attributeName] = attributeValue;
  };

  childSet = (id: string, child: Knot) => {
    if (!this._children) this._children = {};
    this._children[id] = child;
  };

  childDel = (id: string) => {
    if (this._children && this._children[id]) delete this._children[id];
  };

  updateFlag(index: number, setTo: boolean) {
    let flags = this.attributes.flags;
    if (!flags || index === -1) return;
    let bools = flags.split('').map((x) => x === '1');
    bools[index - 1] = setTo;
    this.attributes.flags = bools.map((x) => ~~x).join('');
  }

  toString = () => {
    let attrStr: string = '';
    let keys = Object.keys(this.attributes).sort((a: string, b: string) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    for (const i in keys) {
      let key = keys[i];
      attrStr += ` ${key}="${this.attributes[key]}"`;
    }

    if (this._children && Object.keys(this._children).length > 0) {
      let childStr: string = '';
      //this._children.forEach((child: Knot) => {
      //childStr += child.toString();
      //});
      for (let index in this._children) {
        let child = this._children[index];
        childStr += child.toString();
      }
      return `<${this.elementName}${attrStr}>${childStr}</${this.elementName}>`;
    } else {
      return `<${this.elementName}${attrStr}/>`;
    }
  };
  //toJson = () => xmlToJson(this.toString());
}
