import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { LoaderContainer, LoaderContainerType } from './LoaderContainer';
import { XT } from '../framework/handlers/xt';
import { Localization } from '../framework/localization/Localization';

type ContextHelpProps = {
  aperioFormId: string;
  anchor: string;
  windowId: string;
};

export const ContextHelp: FunctionComponent<ContextHelpProps> = ({ aperioFormId, anchor, windowId }) => {
  const [loading, setLoading] = useState(true);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  //TODO: move hardcoded value to env/global settings
  const source = `${XT.globalConfig?.helpLinkBase}/help/${aperioFormId}/?simple`;

  const setiFrameAnchor = () => {
    if (iFrameRef.current?.getAttribute('src') !== `${source}#${anchor}`)
      iFrameRef.current?.setAttribute('src', `${source}#${anchor}`);
  };

  useEffect(() => {
    if (!loading) {
      setiFrameAnchor();
    }
  }, [anchor]);

  return (
    <>
      {loading ? (
        <LoaderContainer
          loaderType={LoaderContainerType.ImageOnly}
          text={Localization.instance.getString('TXT_Loading')}
        />
      ) : null}
      <iframe
        ref={iFrameRef}
        title='Context Help View'
        id={windowId}
        src={`${source}#${anchor}`}
        style={{
          zIndex: loading ? -1 : 1
        }}
        onLoad={() => {
          if (loading) {
            setiFrameAnchor();
          }
          setLoading(false);
        }}
      />
    </>
  );
};
