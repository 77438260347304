import { AperioViewCommand, AperioViews } from '../../types/AperioViews';
import { Command } from '../base/command';
import { XT } from './xt';

export namespace AperioView {
  export const getCommandBase = (
    link: AperioViews.Link,
    command: Command | undefined,
    windowData: any,
    xtFormLayout: any,
    regionalSettings: Record<string, any>
  ): AperioViewCommand => {
    // Initialize
    // ==========
    const aperioForm = link.aperioFormId;
    const params: AperioViewCommand.Params = {};
    const errors: AperioViewCommand.Error[] = [];

    // Loop through all defined parameters
    // ===================================
    const parameters = link.parameters.filter((parameter) => !parameter.tableId); // Remark: BEWARE columns not included in base function !!
    for (const parameter of parameters) {
      // Get actual value of linked field
      // --------------------------------

      // --> Execute
      const fieldInfo: { fieldId: string; panelId: string; value: string } | undefined = XT.getActualFieldInfo(
        command,
        windowData,
        parameter.fieldId,
        parameter.panelId
      );

      // --> Error handling
      if (!fieldInfo) {
        // add error
        if (parameter.panelId) {
          errors.push({
            type: AperioViewCommand.ErrorTypes.ERROR,
            message: `Data XML contains no info for field ${parameter.fieldId} in panel ${parameter.panelId}.`
          });
        } else {
          errors.push({
            type: AperioViewCommand.ErrorTypes.ERROR,
            message:
              `Data XML contains no info for field ${parameter.fieldId} ` +
              `in panels ${XT.stringifyDataPanels(windowData)}`
          });
        }

        // handle next parameter
        continue;
      }

      // Get layout info of component binded to linked field
      // ---------------------------------------------------

      // --> Execute
      const layoutInfo:
        | undefined
        | {
            componentType: string;
            componentLayout: Record<string, any>;
            parentLayout: Record<string, any>;
          } = XT.getFieldComponentLayoutInfo(parameter.fieldId, fieldInfo.panelId, xtFormLayout);

      // --> Error handling
      if (!layoutInfo) {
        // add error
        errors.push({
          type: AperioViewCommand.ErrorTypes.ERROR,
          message: `Layout XML contains no info for field ${parameter.fieldId} in panel ${fieldInfo.panelId}.`
        });

        // handle next parameter
        continue;
      }

      // Convert actual value from server format to "aperio format"
      // ----------------------------------------------------------

      // Initialize
      const valueInfo: {
        value: string | number | boolean | undefined;
        error: string | undefined;
        warning: string | undefined;
      } = XT.convertValueFromServerToAperioFormat(
        fieldInfo,
        layoutInfo.componentType,
        layoutInfo.componentLayout.$, // Remark: guaranteed to be NOT undefined (as layout result of filtering on $.id)
        parameter.fieldType,
        regionalSettings
      );

      // Handle error/warning
      if (valueInfo.error) {
        errors.push({ type: AperioViewCommand.ErrorTypes.ERROR, message: valueInfo.error });
        continue;
      } else if (valueInfo.warning) {
        errors.push({ type: AperioViewCommand.ErrorTypes.WARNING, message: valueInfo.warning });
      }

      params[parameter.aperioId] = valueInfo.value;
    }

    return { aperioForm, params, errors };
  };

  export const getFieldComponentEventInfo = (
    fieldId: string,
    panelId: string,
    formAperioLinks: AperioViews.Link[]
  ): { event: AperioViews.Link.Event; link: AperioViews.Link } | undefined => {
    /*
      Remark: formAperioLinks is a subset of all the configured links. It contains only
      the links that can be triggered on the actual form (and panels). It is build inside
      the Window component where the result is dispatched to redux.
    */

    // Initialize
    // ==========
    let eventInfos: { event: AperioViews.Link.Event; link: AperioViews.Link }[] = [];

    // Filter out the the events that are defined for a specific field
    // ===============================================================

    // --> filter
    for (const link of formAperioLinks) {
      const _eventInfos: { event: AperioViews.Link.Event; link: AperioViews.Link }[] = link.events
        .filter(
          (event) =>
            (event.type === AperioViews.EventTypes.ON_FIELD_UPDATE ||
              event.type === AperioViews.EventTypes.ON_FIELD_BLUR) &&
            event.fieldId === fieldId &&
            (!event.panelId || event.panelId === panelId)
        )
        .map((event) => {
          return { event, link };
        });
      eventInfos = [...eventInfos, ..._eventInfos];
    }

    // --> sort (done to get consistent result: if both an update and a blur event is defined, the update event will be used)
    eventInfos.sort((info1, info2) => {
      if (info1.event.type === AperioViews.EventTypes.ON_FIELD_UPDATE) {
        if (info1.event.type === info2.event.type) return 0; // keep order
        return -1;
      } else {
        if (info1.event.type === info2.event.type) return 0; // keep order
        return 1;
      }
    });

    // --> try to find a panel specific link first
    let eventInfo = eventInfos.find((info) => ((info.event as any).panelId = panelId));
    if (!eventInfo && eventInfos.length > 0) eventInfo = eventInfos[0];
    return eventInfo;
  };
}
