import { FunctionComponent } from 'react';
import { Form } from 'react-bootstrap';
import '../styles/Label.scss';
import React from 'react';

type LabelProps = {
  className?: string;
  id: string;
  text: string;
};

export const Label: FunctionComponent<LabelProps> = ({ className = '', id, text }) => (
  <Form.Label
    className={className}
    id={id}
    data-tip={window.location.href.toLowerCase().endsWith('dev') ? id : undefined}
    data-html={true}
  >
    {text}
  </Form.Label>
);
