export const desktopHotkeys = ['CMD+F6', 'CTRL+F6', 'CMD+SHIFT+F6', 'CTRL+SHIFT+F6', 'CMD+F7', 'CTRL+F7'];

export const windowHotKeys = [
  'F1',
  'F2',
  'F3',
  'F4',
  'F5',
  'F6',
  'F7',
  'F8',
  'F9',
  'F10',
  'F11',
  'F12',
  'ENTER',
  'PAGEDOWN',
  'PAGEUP',
  'ALT+F1',
  'ARROWUP',
  'ARROWDOWN',
  'ALT+T'
];

export const enquiryHotKeys = [
  'SHIFT+F1',
  'SHIFT+F2',
  'SHIFT+F3',
  'SHIFT+F4',
  'SHIFT+F5',
  'SHIFT+F6',
  'SHIFT+F7',
  'SHIFT+F8',
  'SHIFT+F9',
  'SHIFT+F10',
  'SHIFT+F11',
  'SHIFT+F12'
];

export const clientHotkeys = ['CMD+SPACE', 'CTRL+SPACE', 'CTRL+Q', 'CMD+Q'];

export const enterHotkeys = ['SHIFT+ENTER', 'ALT+ENTER', 'CTRL+ENTER', 'CMD+ENTER'];
