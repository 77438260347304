import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { LoaderContainer, LoaderContainerType } from './LoaderContainer';
import { Localization } from '../framework/localization/Localization';
import { AperioViewCommand } from '../types/AperioViews';
import { AuthTokenEmitter } from '..';
import { useAuth } from 'react-oidc-context';
type AperioProps = {
  aperioForm: string;
  params: AperioViewCommand.Params;
  source: string;
  errors: AperioViewCommand.Error[];
};

export const Aperio: FunctionComponent<AperioProps> = ({ aperioForm, params, source, errors }) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const aperioRef = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const updateToken = useCallback(
    (token: string) => {
      if (loaded) {
        aperioRef.current?.contentWindow?.postMessage({ cmd: 'update', access_token: token }, source); // Remark: paremeter source added for security reasons (allows caller to check it is us))
      }
    },
    [loaded]
  );

  useEffect(() => {
    //Update token if ready and token has changed;
    AuthTokenEmitter.on('token', updateToken);
    updateToken(auth?.user?.access_token || '');
    return () => {
      AuthTokenEmitter.off('token', updateToken);
    };
  }, [updateToken, auth?.user?.access_token]);

  // Remark sequence of this and previous effect is important !!! Make sure update token is executed first becaused we need to use the updated token for showing the next aperio view !!
  useEffect(() => {
    // loads the aperio view based on form
    if (loaded && !!aperioForm && !!params) {
      if (!!aperioRef && !!aperioRef.current) {
        aperioRef.current.contentWindow?.postMessage({ form: aperioForm, params: params }, source); // Remark: paremeter source added for security reasons (allows caller to check it is us))
      }
    }
  }, [loaded, aperioForm, params, source]);

  const onMessage = (e: MessageEvent) => {
    // Only handle messages from the expected origin (security reasons)
    // ================================================================
    if (!e.origin || e.origin !== new URL(source).origin) return;
    if (!e.source || e.source !== aperioRef?.current?.contentWindow) return;

    // Handle data
    // ===========
    if (!e.data) return;
    if (e.data.cmd === '*waiting') {
      setLoaded(true);
    }
  };

  return (
    <>
      {loading ? (
        <LoaderContainer
          loaderType={LoaderContainerType.ImageOnly}
          text={Localization.instance.getString('TXT_Loading')}
        />
      ) : null}
      {errors.length > 0 ? <p>{JSON.stringify({ errors }, undefined, 2)}</p> : null} {/*NTH nice list */}
      <iframe
        ref={aperioRef}
        title='Aperio View'
        src={source}
        hidden={loading || !aperioForm || errors.some((error) => error.type === AperioViewCommand.ErrorTypes.ERROR)}
        onLoad={(e: any) => {
          setLoading(false);
        }}
      />
    </>
  );
};
