import { FunctionComponent, Fragment } from 'react';
import '../styles/ErrorMessages.scss';
import { ReactComponent as Alert } from './../assets/alert.svg';
import { Localization } from '../framework/localization/Localization';

export type ErrorMessage = { title: string; body: string };

type ErrorMessagesProps = {
  className?: string;
  messages?: ErrorMessage[];
  type: 'error' | 'warning';
  variant?: 'sm' | 'lg';
  visible: boolean;
  showMore: () => void;
};

export const ErrorMessages: FunctionComponent<ErrorMessagesProps> = ({
  showMore,
  className = '',
  messages,
  type,
  variant = 'sm',
  visible
}) => {
  return !!messages ? (
    <Fragment>
      {visible && (
        <div
          className={`error-main ${variant === 'sm' ? 'small' : 'large'} ${
            type === 'error' ? 'error' : 'warning'
          } ${className}`}
        >
          <Alert className='alert-symbol' />
          {messages[0] && !!messages[0].title ? <span>{messages[0].title}</span> : <span>{messages[0].body}</span>}
          <button onClick={() => showMore()}>
            {messages.length === 1
              ? Localization.instance.getString('TXT_VIEW_MORE')
              : Localization.instance.getString('TXT_SHOW_ALL')}
          </button>
        </div>
      )}
    </Fragment>
  ) : (
    <></>
  );
};
