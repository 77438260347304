interface FunctionWithArguments {
  (...args: any): any;
}

interface DebouncedFunction<F extends FunctionWithArguments> {
  (...args: Parameters<F>): Promise<ReturnType<F>>;
}

interface DebounceReturn<F extends FunctionWithArguments> extends Array<DebouncedFunction<F> | (() => void)> {
  0: (...args: Parameters<F>) => Promise<ReturnType<F>>;
  1: () => void;
}

export function debounce<F extends FunctionWithArguments>(fn: F, ms: number): DebounceReturn<F> {
  let timer: ReturnType<typeof setTimeout>;

  const debouncedFunction: DebouncedFunction<F> = (...args) =>
    new Promise((resolve) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => resolve(fn(...(args as unknown[]))), ms);
    });

  const teardown = () => {
    clearTimeout(timer);
  };

  return [debouncedFunction, teardown];
}

interface DebounceWithChangeTimeoutIntervalReturn<F extends FunctionWithArguments> extends Object {
  debouncedFunction: DebouncedFunction<F>;
  cancel: () => void;
  changeTimeoutInterval: (ms: number) => void;
}

export function debounceWithChangeTimeoutInterval<F extends FunctionWithArguments>(
  fn: F,
  ms: number
): DebounceWithChangeTimeoutIntervalReturn<F> {
  let timer: ReturnType<typeof setTimeout>;
  let _ms: number = ms;

  const debouncedFunction: DebouncedFunction<F> = (...args) =>
    new Promise((resolve) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => resolve(fn(...(args as unknown[]))), _ms);
    });

  const teardown = () => {
    clearTimeout(timer);
  };

  const changeTimeoutInterval = (ms: number) => {
    _ms = ms;
  };

  return { debouncedFunction, cancel: teardown, changeTimeoutInterval };
}
