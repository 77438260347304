import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Program } from '../../types/program';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import { WindowManger } from '../../framework/base/windowManager';
import { Widget } from '../../views/Dashboard';
import { ClientContext } from '../../App';
import { CellPlugin } from '@react-page/editor';
import { v4 as uuidv4 } from 'uuid';
import { Localization } from '../../framework/localization/Localization';
import { Icons, SquareIcon } from '../../components/SquareIcon';
import { SettingsActions } from '../../types/actionTypes';
import { XT } from '../../framework/handlers/xt';
import Swal from 'sweetalert2';
import { DragPreviewImage, XYCoord, useDrag, useDragLayer, useDrop } from 'react-dnd';
import { CSSProperties } from 'styled-components';
import { RootState } from '../../framework/base';
import { Dnd } from '../../types/item-types';

type QuickLinkProps = {
  program: Program;
  text: string;
  className?: string;
  onLaunchLinkRequested: (program: Program) => void;
  onRemoveLinkRequested: (program: Program) => void;
  onToggleAutostartRequested: (program: Program) => void;
};
const QuickLink: FunctionComponent<QuickLinkProps> = (props) => {
  const [showAutoplayTooltip, setShowAutoplayTooltip] = useState(false);
  return (
    <div
      className={'link' + (props.className ? ` ${props.className}` : '')}
      onClick={() => props.onLaunchLinkRequested(props.program)}
      id={props.program.id}
    >
      {props.text}
      <div className={'icon-list'}>
        <div
          className='remove-link'
          onClick={(e) => {
            props.onRemoveLinkRequested(props.program);
            e.stopPropagation();
            return false;
          }}
        >
          <OverlayTrigger
            placement='top-start'
            overlay={
              <Tooltip className='custom' id={`tooltip-remove-link-${props.program.id}`}>
                {Localization.instance.getString('Remove_Favourite')}
              </Tooltip>
            }
          >
            <span>
              <SquareIcon className='icon-grey-fill' size='18px'>
                {Icons.Close}
              </SquareIcon>
            </span>
          </OverlayTrigger>
        </div>
        <div
          className={'autoplay' + (props.program.isAutoStart ? ' active' : '')}
          onClick={(e) => {
            props.onToggleAutostartRequested(props.program);
            e.stopPropagation();
            return false;
          }}
        >
          <OverlayTrigger
            placement='top-start'
            trigger={['hover']}
            overlay={
              <Tooltip className='custom' id={`tooltip-autoplay-${props.program.id}`}>
                {props.program.isAutoStart
                  ? Localization.instance.getString('Disable_Autoplay')
                  : Localization.instance.getString('Enable_Autoplay')}
              </Tooltip>
            }
            show={showAutoplayTooltip}
            onToggle={(open) => setShowAutoplayTooltip(open)}
          >
            <span onClick={() => setShowAutoplayTooltip(false)}>
              <SquareIcon className='icon-primary-nav' size='18px'>
                {props.program.isAutoStart ? Icons.PlayClassicStop : Icons.PlayClassicStart}
              </SquareIcon>
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

const transparentPixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC';
type DragItem = {
  program: Program;
  text: string;
  dropId: string; // to restrict drop possibilility to current parent container only (in case of multiple quicklinks widgets)
  getSize: () => { height: number; width: number };
};
const DraggableQuickLink: FunctionComponent<QuickLinkProps & { dropId: string }> = (props) => {
  const refQuickLink = useRef<HTMLDivElement>(null);
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: Dnd.ItemTypes.QUICKLINK,
    item: {
      program: props.program,
      text: props.text,
      dropId: props.dropId,
      getSize: () => ({
        height: refQuickLink.current?.scrollHeight || 0,
        width: refQuickLink.current?.scrollWidth || 0
      })
    } as DragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <>
      <DragPreviewImage connect={previewRef} src={transparentPixel} />
      <div ref={dragRef} style={{ backgroundColor: isDragging ? '#d3e3fd' : 'inherit' }}>
        <div ref={refQuickLink}>
          <QuickLink {...props} />
        </div>
      </div>
    </>
  );
};

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 999999,
  left: 0,
  top: 0
};
function getItemStyles(currentOffset: XYCoord | null) {
  if (!currentOffset) {
    return {
      display: 'none'
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform
  };
}
type QuickLinksDragLayerProps = {
  onDragging: (id: string, deltaY: number) => void;
  onDragEnd: () => void;
};
const QuickLinksDragLayer: FunctionComponent<QuickLinksDragLayerProps> = (props) => {
  // Initialize
  // ==========
  const prevQuickLinkDragInfo = useRef<{ id: string; deltaY: number }>({ id: '', deltaY: 0 });

  // Collect dragging info
  // =====================
  const { isDragging, itemType, item, currentOffset, deltaOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    item: monitor.getItem<DragItem>(),
    currentOffset: monitor.getSourceClientOffset(),
    deltaOffset: monitor.getDifferenceFromInitialOffset(),
    isDragging: monitor.isDragging()
  }));

  // Comunicate drag info (only if changed)
  // ======================================
  if (isDragging) {
    if (itemType === Dnd.ItemTypes.QUICKLINK) {
      const deltaY = deltaOffset ? deltaOffset.y : 0;
      if (item.program.id !== prevQuickLinkDragInfo.current.id || deltaY !== prevQuickLinkDragInfo.current.deltaY) {
        props.onDragging(item.program.id, deltaY);
        prevQuickLinkDragInfo.current = { id: item.program.id, deltaY };
      }
    }
  } else {
    if (prevQuickLinkDragInfo.current.id !== '') {
      props.onDragEnd();
      prevQuickLinkDragInfo.current = { id: '', deltaY: 0 };
    }
  }

  // Render clone when dragging
  // ==========================
  if (isDragging) {
    return (
      <div style={layerStyles}>
        <div style={getItemStyles(currentOffset)}>{renderItem()}</div>
      </div>
    );
  } else {
    return null;
  }

  function renderItem() {
    switch (itemType) {
      case Dnd.ItemTypes.QUICKLINK:
        const size = item.getSize();
        const height = size?.height || 0;
        const width = size?.width || 0;
        return (
          <div
            style={{
              height: height,
              width: width
            }}
          >
            <QuickLink
              program={item.program}
              text={item.text}
              onLaunchLinkRequested={() => {}}
              onRemoveLinkRequested={() => {}}
              onToggleAutostartRequested={() => {}}
              className={'dragging-clone'}
            />
          </div>
        );
      default:
        return null;
    }
  }
};

const mapStateToProps = ({ settings }: RootState) => {
  return { quickLinks: settings.quickLinks };
};
const mapDispatchToProps = {
  quickLaunch: (program: Program, endLoading: Function) => WindowManger.Launch(program, endLoading, 'program'),
  updateQuickLinks: (program: Program) => ({ type: SettingsActions.QUICK_LINKS_UPDATE, payload: { program } }),
  updateAutoLinks: (program: Program) => ({ type: SettingsActions.AUTO_LINKS_UPDATE, payload: { program } }),
  saveSettings: () => WindowManger.UpdateSettings(),
  reorderLinks: (linkIdA: string, position: 'BEFORE' | 'AFTER', linkIdB: string) => ({
    type: SettingsActions.QUICK_LINKS_REORDER,
    payload: { linkIdA, position, linkIdB }
  })
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const QuicklinksComponent: FunctionComponent<QuickLinksData & PropsFromRedux> = (props) => {
  const { startLoading, flattenedMenu, endLoading, getMenuItems } = useContext(ClientContext);
  const dropIdRef = useRef(''); // used to uniquely identify the parent container (to prevent quicklink items to be dragged from one widget to another)
  const dropVisualizationRef = useRef<HTMLDivElement>(null); // "thick blue line" used to visualize where the dragged item will be dropped
  const linksContainerRef = useRef<HTMLDivElement>(null);
  const dragInfoRef = useRef({ isDragging: false, initialScrollTop: 0, initialBoundingClientRectTop: 0 });
  const [, setIsDragging] = useState(false); // Only needed to force rerendering after drag abortion (restore onHover behavior), eg by pressing ESC

  const [{ isOver }, dropRef] = useDrop({
    accept: Dnd.ItemTypes.QUICKLINK,
    canDrop(item: DragItem) {
      return item.dropId === dropIdRef.current; // Assure item can not be dropped in other container (in case multiple quicklink widgets would be available)
    },
    drop(item: DragItem, monitor) {
      const result = getDropInfo(item.program.id, monitor.getDifferenceFromInitialOffset()?.y || 0);
      if (result) props.reorderLinks(item.program.id, result.position, result.target.id);
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
      // IMPORTANT REMARK:   offset: monitor.getDifferenceFromInitialOffset()   ==> value seems only to be updated when isOver is changing ==> use callback from layer component to get actual values
    })
  });

  const linksInfo = useMemo(() => {
    if (!linksContainerRef.current) return [];
    const linkRefs = Array.from(linksContainerRef.current.querySelectorAll('.link'));
    let totalHeight = 0;
    // IMPORTANT remark: to guarantee the right sequence of the links, the calculation should always start from the reordered quicklinks (looks like the sequence of refs in linkRefs is not affexted by the reordering)
    return props.quickLinks.map((quickLink) => {
      const linkRef = linkRefs.find((ref) => ref.id === quickLink.id);
      const linkInfo = { id: quickLink.id, height: linkRef?.scrollHeight || 0, scrollTop: totalHeight };
      totalHeight += linkRef?.scrollHeight || 0;
      return linkInfo;
    });
  }, [props.quickLinks, linksContainerRef.current?.clientHeight, linksContainerRef.current?.scrollHeight]);

  useEffect(() => {
    dropIdRef.current = `${uuidv4()}`;
  }, []);

  useEffect(() => {
    if (!isOver && dropVisualizationRef.current) {
      dropVisualizationRef.current.style.top = '-10px'; // hide drop indicator
    }
  }, [isOver]);

  return (
    <div className={'card'} style={{ height: props.height || '200px' }}>
      <Widget height={'auto'} className={'low-pad'}>
        <Row>
          <Col sm={12}>
            <p>
              <strong>{props.title || Localization.instance.getString('TXT_QUICK_LINKS')}</strong>
            </p>
          </Col>
        </Row>
        <div
          className={'links-container' + (dragInfoRef.current.isDragging ? ' dragging' : '')}
          ref={linksContainerRef}
        >
          <div ref={dropRef}>
            <span
              style={{
                display: 'block',
                position: 'relative',
                height: 0,
                width: '100%',
                left: 0,
                zIndex: 99,
                overflow: 'visible'
              }}
            >
              <div
                ref={dropVisualizationRef}
                className={'drop-visualization'}
                style={{
                  display: 'block',
                  position: 'absolute',
                  height: 4,
                  width: '100%',
                  left: 0,
                  backgroundColor: 'blue',
                  top: -10,
                  zIndex: 99
                }}
              />
            </span>
            {props.quickLinks.map((program: Program) => (
              <DraggableQuickLink
                key={program.id}
                program={program}
                text={getMenuItems(program.id, flattenedMenu)?.text || program.text}
                onLaunchLinkRequested={onLaunchLinkRequested}
                onRemoveLinkRequested={onRemoveLinkRequested}
                onToggleAutostartRequested={onToggleAutostartRequested}
                dropId={dropIdRef.current}
              />
            ))}
          </div>
          <QuickLinksDragLayer onDragging={onDragging} onDragEnd={onDragEnd} />
        </div>
      </Widget>
    </div>
  );

  function onLaunchLinkRequested(program: Program) {
    startLoading();
    props.quickLaunch(program, endLoading);
  }
  function onRemoveLinkRequested(program: Program) {
    props.updateQuickLinks(program);
    props.saveSettings();
  }
  function onToggleAutostartRequested(program: Program) {
    if (!program.isAutoStart) {
      if (
        (props.quickLinks?.filter((p: Program) => p.isAutoStart)?.length || 0) >=
        (XT.globalConfig?.window?.autoStartLimit || 5)
      ) {
        Swal.fire({
          text: Localization.instance.getString('AUTO_START_MaximumNumberError'),
          icon: 'warning',
          confirmButtonColor: '#00a3a5',
          cancelButtonColor: '#00a3a5',
          showCancelButton: false,
          allowOutsideClick: false,
          stopKeydownPropagation: false,
          confirmButtonText: Localization.instance.getString('TXT_OK')
        });
        return false;
      }
    }
    props.updateAutoLinks(program);
  }
  function onDragging(id: string, deltaY: number) {
    // --> Save initial positions on dragging start
    if (!dragInfoRef.current.isDragging) {
      dragInfoRef.current.isDragging = true;
      dragInfoRef.current.initialScrollTop = linksContainerRef.current?.scrollTop || 0;
      dragInfoRef.current.initialBoundingClientRectTop = linksContainerRef.current?.getBoundingClientRect()?.top || 0;
      setIsDragging(true);
    }

    // --> Get drop info
    const dropInfo = getDropInfo(id, deltaY);

    // --> Visualize drop zone ("thick blue line" That shows where ragged quicklink will be inserted)
    let dropVisualizationTop: number;
    if (!dropInfo) {
      dropVisualizationTop = -10; // move indicator out of view
    } else if (dropInfo.position === 'BEFORE') {
      dropVisualizationTop = dropInfo.target.scrollTop - 2;
    } else {
      dropVisualizationTop = dropInfo.target.scrollTop + dropInfo.target.height - 2;
    }

    if (dropVisualizationRef.current) {
      dropVisualizationRef.current.style.top = `${dropVisualizationTop}px`;
    }
  }
  function onDragEnd() {
    dragInfoRef.current.isDragging = false;
    setIsDragging(false);
  }
  function getDropInfo(id: string, deltaY: number) {
    // Leave when not needed
    // =====================
    if (!isOver) return undefined;
    if (!id) return undefined;

    // Get info dragged link
    // =====================
    const linkInfo = linksInfo.find((link) => link.id === id);
    if (!linkInfo) return undefined;

    // Get link that is currently hovered
    // ==================================
    const y =
      linkInfo.scrollTop +
      Math.floor(linkInfo.height / 2) +
      deltaY +
      ((linksContainerRef.current?.scrollTop || 0) - dragInfoRef.current.initialScrollTop) +
      (dragInfoRef.current.initialBoundingClientRectTop -
        (linksContainerRef.current?.getBoundingClientRect()?.top || 0));

    const targetLinkInfo = linksInfo.find((link) => link.scrollTop <= y && y < link.scrollTop + link.height);
    if (!targetLinkInfo) return undefined;
    if (targetLinkInfo.id === linkInfo.id) return undefined;

    // Get position
    // ============
    const position: 'BEFORE' | 'AFTER' =
      y <= targetLinkInfo.scrollTop + Math.floor(targetLinkInfo.height / 2) ? 'BEFORE' : 'AFTER';

    // Do not drop on yourself
    // =======================
    if (position === 'BEFORE' && targetLinkInfo.scrollTop === linkInfo.scrollTop + linkInfo.height) {
      return undefined;
    } else if (position === 'AFTER' && targetLinkInfo.scrollTop + targetLinkInfo.height === linkInfo.scrollTop) {
      return undefined;
    }

    // return result
    // =============
    return { position, target: targetLinkInfo };
  }
};
const QuickLinks = connector(QuicklinksComponent);

type QuickLinksData = {
  title: string;
  height: string;
};
const quickLinks: CellPlugin<QuickLinksData> = {
  Renderer: ({ data }) => {
    return <QuickLinks title={data.title} height={data.height} />;
  },
  id: 'quick-links',
  title: 'Quick Links',
  description: 'Quicklinks to launch programs quickly from dashboard',
  version: 1,
  controls: {
    type: 'autoform',
    columnCount: 1,
    schema: {
      properties: {
        title: {
          type: 'string'
        },
        height: {
          type: 'string'
        }
      },
      required: []
    }
  }
};
export default quickLinks;
